var React = require('react');
var TimeAgo = require('react-timeago').default
var config = require('../../../settings/config.json')
import T from 'i18n-react';
import TweetEmbed from 'react-tweet-embed'
import Video from '../media/video.jsx';
import {browserHistory} from 'react-router';
import {setFocusedPuff} from '../../actions/puff';
import store from '../../store';

class Context extends React.Component {
  	
 	searchPuffs(widget, e){
    //e.preventDefault();
    // store.dispatch(setFocusedPuff(0));
    let query = widget.query ? widget.query : (widget.text ? widget.text : widget.name ? widget.name : widget.description ? widget.description : '')
    browserHistory.push({pathname: '/search', query:{q:query, view:'magazine', feed:'free'}})
  }

  stopPropagation(e){
    e.stopPropagation();
  }

  gotoMap(lat, lng, e){
    e.stopPropagation();
    browserHistory.push({pathname: '/map',search: '?lat='+lat+'&lng='+lng}) 
  }

  trimText(text, title){
    let dynamicTitle = title ? ('^(the )?'+title.split(' ').map(word=>word.replace(/^\((.+)\)$/,'(\\($1\\))?')).join(' (\\b\\w+\\b )?')+'[\\b\\s,]*((or|is|are) (an|a)?)?') : title
    // console.log(dynamicTitle)
    // .replace(/\(.+\)/,'')
    let titleTrimmed = text.replace(new RegExp(dynamicTitle,'i'), '')
    let reg = /[,\.;:]|\band\b|\bthat\b|\bbased\b/ig
    let splitters = titleTrimmed.match(reg);
    let done = false
    let trimmed = titleTrimmed.split(reg).reduce((a,b,i)=>{
      let t = a+(i===0 || [';',':'].includes(splitters[i-1]) ? '' : splitters[i-1])+b
      done = done || t.length > 70
      return done ? a : t
    },'')
    if(trimmed === ''){
      return titleTrimmed.indexOf('.')>-1 ? titleTrimmed.split('.')[0] : titleTrimmed
    }
    return trimmed
  }

  render(){

  	let article = this.props.article
    let widget = this.props.widget ? this.props.widget : {}
  
    if(widget.data && !widget.avatar){
      widget = widget.data[0]
    }

    //TODO: We really need to standardize
    if(this.props.widget && this.props.widget.context){
      widget.context = this.props.widget.context
    }

    let context = true
    let description = true
    let hover = true

    let thumbSize = this.props.thumbSize ? this.props.thumbSize+'%' : '30%'

    let trimmedDescription = widget.description
 
    let url = null
    if(widget.image && widget.image.url){
      url = (widget.image.url.indexOf('http') === 0 || widget.image.url.indexOf('/') === 0 )  ? widget.image.url : config.server[window.env].S3.replace('{bucketname}',('monokthumb-'+widget.image.url[0])) + widget.image.url.substring(0,2) + '/' + widget.image.url
    }

    let thumbStyle = {
      backgroundImage: widget.image ? "url("+url+")" : null
    }

    if(widget.lat && widget.source!=='wikipedia' && !widget.image && widget.code){
      thumbStyle.backgroundImage = "url('static/img/flags/"+widget.code+".png') no-repeat center center / cover"
    }


    //TODO: move to backend

    switch (this.props.type) {
      case 'header':
        thumbStyle.borderRadius = 20
        break;
      case 'avatar':
        hover = false
        description = false
        thumbSize=115
      case 'card':
        
        break;
      case 'small':
        
        break;
      case 'tiny':
        thumbStyle.marginRight=5
        trimmedDescription = this.trimText(trimmedDescription, widget.text)
        // trimmedDescription = widget.text.length < 60 ? this.trimText(trimmedDescription, widget.text) : ''
        context=false
        break;
      case 'sidemenu':
        thumbSize='25%'
        thumbStyle.borderRadius='15%'
        style.container.marginBottom=0
        this.props.style.marginBottom=0
        context=false
        break;
      default:
        // console.log('Sorry');
    }



    thumbStyle = {...thumbStyle, ...{width:thumbSize, paddingBottom:thumbSize}} 

    let logobackend = [".png",".svg","logo",".gif"]
    if(widget.type!=='topic' && widget.image && logobackend.find(key=>widget.image.url && widget.image.url.toLowerCase().indexOf(key)>-1)){
      thumbStyle.backgroundRepeat = "no-repeat"
      thumbStyle.backgroundSize = "contain"
      thumbStyle.backgroundPositionY = "50%"
      thumbStyle.borderRadius=0
      thumbStyle.boxShadow='none'
      thumbStyle.filter = "drop-shadow(rgba(0, 0, 0, 0.2) 0px 5px 4px)"
    }



    //############ TRUMP & MUSK
    let hotfix=[{url:'https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Donald_Trump_official_portrait.jpg/250px-Donald_Trump_official_portrait.jpg',
                title:"donald trump",
                gif:'animatedtrump.gif'},
                {url:'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ed/Elon_Musk_Royal_Society.jpg/250px-Elon_Musk_Royal_Society.jpg',
                title:"elon musk",
                gif:'animatedmusk.gif'}]
    let found = hotfix.find(h=>widget && widget.text && h.title===widget.text.toLowerCase() || (widget.image && widget.image.url===h.url))
    
    if(found || (widget.image && widget.image.type==='face')){
      let personStyle = {
          backgroundImage: 'url("'+ (found ? '/static/img/'+found.gif : url) +'")',//, url("/static/img/icons/spinner.gif")',
          backgroundSize:'contain, auto',
          borderRadius: '50%',
          filter: 'drop-shadow(rgba(0, 0, 0, 0.3) -3px 3px 7px)',
          boxShadow:null
        }
           // drop-shadow(rgba(0, 0, 0, 0.47) 1px 4px 8px)
      thumbStyle = {...thumbStyle, ...personStyle}
    }
    //############ TRUMP & MUSK
    


    let isMobile = this.props.isMobile

  	return (<div  style={{...style.container,...this.props.style,...{padding:10}}}>
    		  <div style={{...style.thumb,...thumbStyle}}/>
    			{description ?  
                <div style={{paddingBottom:10, paddingLeft: isMobile ? 0 : '27%', marginLeft: '2%'}}>    
	                {isMobile && false ? null : 
                    <b  
                    style={{...style.title,...{fontSize: 14}}} 
                    className={'secondary'}>{widget.text}</b>}  

	                <p style={{...style.description,...{marginBottom:0, maxHeight: isMobile ? 40 : null}}}>{trimmedDescription}</p>
	                {context ? <p style={{...style.description,...style.context,...{marginBottom:0, maxHeight: isMobile ? 65 : null}}}>{widget.context}</p> : null}
              	</div> : null}

              	<div>
	                {widget.url && !isMobile && false ? 
	                <a onClick={self.stopPropagation} href={widget.url} target="_blank" >
	                  <T.span text="puff.visualFocus.readmore" className="badge mouseOverButton" style={style.button}/>
	                </a> : null}
	                {widget.lat ? <T.span text="tabs.map" className="badge mouseOverButton" onClick={this.gotoMap.bind(this, widget.lat,widget.lng)} style={style.button}/> : null}
	                {isMobile && false ?
                   <T.span text="puff.visualFocus.findrelated" className="badge mouseOverButton" style={style.button}/>
                   : null}
	              </div>
              </div>)
  }

}

module.exports = Context;

const style = {
  title:{
    fontSize: '15px',
    marginBottom: '5px',
    marginTop:'0px',
    fontWeight: 600,
    fontFamily: "'Cabin', sans-serif" 
  },
  article:{
    marginTop:'10px',
    marginBottom:'10px',
    fontSize: '12pt',
    fontFamily: "'Open Sans', sans-serif", // "'Open Sans', sans-serif"
    overflowWrap: 'break-word'
  },
  content:{
    padding: 15,
    paddingBottom: 0,
    minHeight:180
  },
  timestamp:{
    fontSize: '14px',
  },
  description:{
    fontSize: '14px',
    overflow:'hidden'
  },
  context:{
    fontSize: '12pt',
    fontFamily: '"Open Sans", sans-serif'
  },
  thumb:{
    verticalAlign: 'text-top', 
    width: '30%', 
    paddingBottom:'30%',
    minHeight: 40, 
    minWidth: 40,
    borderRadius: "50%",
    backgroundSize:'cover',
    float:'left',
		backgroundPositionX: 'center',
    boxShadow:'rgba(0, 0, 0, 0.16) 0px 0px 2px 0.5px inset'
  },
	container:{
	      position:'relative',
	      borderRadius: 20,
	      paddingTop:"0%",
	      backgroundRepeat:"no-repeat",
	      backgroundSize:"cover",
	      maxHeight: 700,
	      width:'100%',
	      display: 'inline-block', 
	      padding:15
	},
	button:{
		cursor: 'pointer',
		marginRight: '5px',
    borderRadius: '20px',
    float: 'right',
    padding:8,
    borderStyle: 'solid',
    borderWidth: 2,
    height:34,
    fontSize:'small'
  }
}