import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { connect } from 'react-redux';
import store from '../../store';
import { setFocusedPuff } from '../../actions/puff';
import {browserHistory} from 'react-router';
import {settingsToParams} from '../../util';

const s =  {
  wrapper: {
    zIndex:10000,
    position:'fixed',
    left:0,
    right:0,
    top:0,
    bottom:0,
    overflowY:'scroll',
    webkitOverflowScrolling: 'touch',
    backgroundColor: 'rgba(234, 234, 234, 0.93)'
  }
}

class Modal extends React.Component {

      
    constructor(props) {
        super(props);
        this.deselectPuffs = this.deselectPuffs.bind(this);
    }  

    feedPath(feed){
      return '/' + (feed.prefix ? feed.prefix + '/' : '') + feed.id
    }

    updateScroll(e){
      window.dispatchEvent(new Event('parentScroll'));
    }

    
    deselectPuffs(e){
      //on scrollbar
      if((e.clientX + 10) >= e.target.clientWidth){
        return
      }

      window.virtualText=null

      if(this.props.onBlurr){
        if(window.entrypointMonok===window.location.href){
        // console.log('-2')
          this.props.onBlurr()
        }else{
        // console.log('-1')
          browserHistory.goBack()
        }
        return
      }
      let selectedFeed = this.props.feeds[this.props.selectedView]

        // console.log(this.props.content.puff)
    
      if(this.props.user && this.props.user.writer){
        browserHistory.push({pathname: '/search', query: {feed:'orders', sort:'date'}}) 
      }else if(this.props.content && this.props.content.options && this.props.content.options.parent){
        store.dispatch(setFocusedPuff(0));
      }else if(window.entrypointMonok===window.location.href){
        browserHistory.push({pathname: this.feedPath(selectedFeed), query:settingsToParams(selectedFeed)}) //
      }else if(this.props.content && 
              this.props.content.puff && 
              this.props.content.puff.id &&
              window.location.href.includes(this.props.content.puff.id.replace(this.props.content.puff.feed+'/',''))){
        // console.log('2')
          browserHistory.goBack()
      }else{
        // console.log('3')
        // console.log(settingsToParams(selectedFeed))
        // console.log(this.feedPath(selectedFeed))
          let qq = {...settingsToParams(selectedFeed), new:undefined}
     
          browserHistory.push({pathname: this.feedPath(selectedFeed), query:qq})
      }
    }

    render () {
        var self = this  

        return (
            <ReactCSSTransitionGroup transitionName="big-puff-wrapper" transitionEnterTimeout={600} transitionLeaveTimeout={600}>
             {this.props.children ? 
              <div id="modalContainer" onScroll={this.updateScroll} style={{...s.wrapper, ...(this.props.style ? this.props.style : {})}} onMouseDown={this.deselectPuffs}>
                  {this.props.children}
              </div> : null}
            </ReactCSSTransitionGroup>
        );
    }
}

const mapStateToProps = function(store) {
  return {
    feeds: store.feeds,
      // selectedPuff: store.puff.selectedPuff,
    // feeds: store.profile.feeds,
    selectedView: store.feeds.findIndex(f=>f.id === store.view.selectedView)
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setFocusedPuff: (id)=>dispatch(setFocusedPuff(id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);