var React = require('react');
var config = require('../../../settings/config.json')

class Quote extends React.Component {

  constructor(props){
      super(props);
  }

  imageUrl(url){
    if(url.indexOf('http')===0){
      return url
    }else{
      let filename = url.indexOf('media')===0 ? url.split('media/')[1] : url
      return (config.server[window.env].S3).replace('{bucketname}',('monokthumb-'+filename[0])) + filename.substring(0,2) + '/' + filename   
    }
    return url
  }


  render(){
    var quote = this.props.quote
    let widget = quote.widget ? quote.widget.data[0] : null

    let thumbStyle = {
      backgroundImage: widget ? "url("+this.imageUrl(widget.image.url)+")" : ""
    }

    //######## TRUMP AND MUSK HOTFIX ############
    let hotfix=[{url:'https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Donald_Trump_official_portrait.jpg/250px-Donald_Trump_official_portrait.jpg',
                gif:'animatedtrump.gif'},
                {url:'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ed/Elon_Musk_Royal_Society.jpg/250px-Elon_Musk_Royal_Society.jpg',
                gif:'animatedmusk.gif'}]
    let found = hotfix.find(h=>widget && widget.image && h.url===widget.image.url)
    if(found || (widget && widget.image && widget.image.type==='face')){

      let personStyle = {
            backgroundImage: (found ? 'url("/static/img/'+found.gif+'")' : thumbStyle.backgroundImage),
            borderRadius: '50%',
            filter: 'drop-shadow(rgba(0, 0, 0, 0.37) -4px 2px 13px)',
            boxShadow:null
          }
      thumbStyle = {...thumbStyle, ...personStyle}
    }
    //######## TRUMP AND MUSK HOTFIX ############

 
    return (<div style={{padding:15, paddingLeft:40}}>
            <img src={config.server[window.env].staticUrl+"img/quote.png"}  style={{marginLeft: -40, width: 30}} />
             <div style={{fontSize:25}}>{quote.text}</div>
              {widget ? 
                
                <div style={{marginTop:10}}>
                  <div style={{...s.thumb,...thumbStyle}}/>
                  <div style={{width:'80%', paddingLeft:10,display:'inline-block'}}>
                    <b style={s.title}>- {widget.text}</b>
                    <p style={{...s.description,...{marginBottom:0}}}>{widget.description}</p>
                  </div>
                  
                </div>
                :
                <div className="red">- {(quote.canonicalSpeaker ? quote.canonicalSpeaker : (quote.speaker ? quote.speaker : 'Who said this??'))}</div>}
     </div>) 
  }

}

module.exports = Quote;

const s = {
    thumb:{
    // verticalAlign: 'text-top', 
    width: '20%', 
    paddingBottom:'20%',
    // marginRight:10,
    minHeight: 40, 
    minWidth: 40,
    borderRadius: "50%",
    backgroundSize:'cover',
    // float:'left',
    backgroundPositionX: 'center',
    display:'inline-block'
  },
  description:{
    fontSize: '14px',
    color: '#999',
    overflow:'hidden'
  },
    title:{
    color: '#d83d38',
    fontSize: '15px',
    marginBottom: '5px',
    marginTop:'0px',
    fontWeight: 600,
    fontFamily: "'Cabin', sans-serif" 
  }
}