


import React from 'react';

const style = {

}

class Dropdown extends React.Component {

  constructor(props) {
      super(props);
      this.state = {index:0}
  }  




  render () {
  	return  <div className="popover-basic" style={this.props.style ? this.props.style : {}}>
       {this.props.children}
      </div>

  }
}

export default Dropdown;