module.exports={
	SET_FOCUSED_PUFF: 'SET_FOCUSED_PUFF',
	SENDPUFF_REQUEST: 'SENDPUFF_REQUEST',
	SENDPUFF_SUCCESS: 'SENDPUFF_SUCCESS',
	SENDPUFF_FAILURE: 'SENDPUFF_FAILURE',
	SAVEPUFF_REQUEST: 'SAVEPUFF_REQUEST',
	SAVEPUFF_SUCCESS: 'SAVEPUFF_SUCCESS',
	SAVEPUFF_FAILURE: 'SAVEPUFF_FAILURE',
	BUYPUFF_REQUEST: 'BUYPUFF_REQUEST',
	BUYPUFF_SUCCESS: 'BUYPUFF_SUCCESS',
	BUYPUFF_FAILURE: 'BUYPUFF_FAILURE',
	BUILDPUFF_REQUEST: 'BUILDPUFF_REQUEST',
	BUILDPUFF_SUCCESS: 'BUILDPUFF_SUCCESS',
	BUILDPUFF_FAILURE: 'BUILDPUFF_FAILURE',
	ORDERPUFF_REQUEST: 'ORDERPUFF_REQUEST',
	ORDERPUFF_SUCCESS: 'ORDERPUFF_SUCCESS',
	ORDERPUFF_FAILURE: 'ORDERPUFF_FAILURE'
}