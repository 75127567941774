import React  from 'react';
import { connect } from 'react-redux';
import TT from '../modal/tt.jsx';
// import Cost from '../billing/cost.jsx';
import {buildPuff} from '../../actions/puff';
import {browserHistory} from 'react-router';
import {saveProfile} from '../../actions/auth';
import {fetchFeed, get} from '../../apiFunctions';
import Flip from '../modal/flip.jsx';
import {setting, settingsToParams} from '../../util.js';
import Checkout from '../modal/checkout.jsx';
import Integrate from '../modal/integrate.jsx';
import Spinner from '../modal/spinner.jsx';
import TagInput from './taginput.jsx';

let tagifies = {}

let refreshTime = null

const icons = window.icons
const prices = {0:0, 1:5, 2:7.5, 3:25, 4:15, 5:20}


class Config extends React.Component {

  constructor(props) {
    super(props);
  
    let feed = this.props.id && this.props.user.feeds.find(f=>f.id===this.props.id) ? 
              this.props.user.feeds.find(f=>f.id===this.props.id) : (this.props.feed ? this.props.feed : this.props.feedMenu)
    this.aicurate = this.aicurate.bind(this);
  
    this.state = {
                  feed:JSON.parse(JSON.stringify(feed)), 
                  originalFeed:feed, 
                  saved:true, 
                  article:this.props.article,
                  forceSimple:window.location && window.location.search && window.location.search.includes('configureFeed=true'),
                  sure:{}, 
                  hidden:feed.project==='archive' || (feed.hidden && (!this.props.user || !this.props.user.admin)),
                  owner:feed.owner,
                  curator: this.props.user && this.props.user.admin ? '' : ''
                }



    let validIntegrations = this.props.user && this.props.user.websites ? this.props.user.websites : []
    let publishable = validIntegrations.filter(action=>action.apikey).map(action=>({...action, publish:true}))

    validIntegrations = validIntegrations.concat(feed.onSubmit ? feed.onSubmit : []).concat(publishable).filter((action,i,a)=>action && a.findIndex(b=>b.url===action.url && b.publish === action.publish)===i)
    whitelists.onSubmit = validIntegrations.map(integration=>{
      let icon = icons[integration.type] ? icons[integration.type] : (integration.url && integration.url.includes('@') ? 'envelope-o' : (integration.title ? 'share' : (integration.channel ? 'slack' : 'wordpress')))
    
      return {...integration, icon:icon, value:(integration.publish ? 'publish:' : (icon==='wordpress' ? 'draft:' : ''))+(integration.title ? integration.title : (integration.channel ? integration.channel : integration.url))}
    }).filter(p=>p)

    if(this.state.forceSimple){
        if(!this.state.article){
          let puffpath = 'feed?q='+feed.id+'&s=1&product=cluster&sort=popular&full=true'
          get(puffpath,(err, res)=>{
            this.setState({article:this.props.article ? this.props.article : res.body})
          })
        }
    }

  }

  update(feed, save){
    // if(this.props.update){
    //   this.props.update(feed)
    // }else {
      // if(feed!==this.state.feed)
      if(!this.state.save || (feed && JSON.stringify(feed)!==JSON.stringify(this.state.feed))) {
        // console.log('OK') 
        if(feed.delete){
          this.save(feed)
          browserHistory.push({pathname: '/profile'}) 
        }else{
     
            if(this.state.feed && this.state.feed.title==='Untitled'){
              let title = null
              let cats = this.state.feed.categoryFilter[0].categories
              if(cats && cats.length>0){
                title=feed.categoryFilter[0].categories.slice(0,2).map(c=>c.replace(/.+:/,'')).join(' & ')
              }
              feed.title = title ? title : 'Untitled'
            }
            this.setState({feed:feed, saved:false})
          if(save){
            // this.setState({feed:feed, saved:true})
            this.save(feed)
          }
          // else{
          // }
        }
      }
    // }
  }

  save(newFeed){
    this.setState({loading:true}, ()=>{
      this.props.saveProfile({feeds:newFeed ? newFeed : this.state.feed})  
    })
      // this.setState({forceSimple:false})
  }



  componentDidUpdate(prevProps) {
 
    if(this.props.feed && (JSON.stringify(this.props.feed)!==JSON.stringify(prevProps.feed))) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
    {
      this.setState({feed:this.props.feed, loading:false})
    }


    if(this.props.feedMenu && this.state.saved===false && (JSON.stringify(prevProps.user.feeds) !== JSON.stringify(this.props.user.feeds))){
      // console.log('Feed refresh')
      fetchFeed(this.props.feedMenu, 0)
      this.setState({saved:true, loading:false})
    }
} 

  queryText(feed, curator){

    let contentType = setting(feed.articleGeneration, 'typeOfContent', 'news')
    let cats = feed.searchQuery ? feed.searchQuery : feed.categoryFilter[0].categories
   
    if(curator){
      return <div style={{margin:10}}>{cats.map(c=>c.replace(/.+:/,'')).filter((c,i,a)=>a.indexOf(c)===i).map(cat=>{
          return <span className="badge" style={{background: cat.indexOf('!')===0 ? 'red' : 'lightgreen', color: 'black', padding: '10px 20px', margin: 5}}>{cat.indexOf('!')===0 ? cat.slice(1, cat.length) : cat}</span>
        })}
        
        <span className="badge" style={{background: contentType==='blog' ? '#345ec7' : (contentType==='mixed' ? '#a634c7' : 'black'), color: 'white', padding: '10px 20px', margin: 5}}>{(contentType+' Article Type').toUpperCase()}</span>


        {feed.location && feed.location.length>0 ? <div>
          {feed.location.map(loc=>{
            return <span className="badge" style={{background: '#d2f4ff', color: 'black', padding: '10px 20px', margin: 5, fontSize: 'large'}}>
            <span className="glyphicon glyphicon-map-marker" style={{
                      float: 'left',
                      fontSize: 'large',
                      marginRight: 10,
                      color:feed.location && feed.location.length > 0 ? 'black' : '#9c9c9c'
                    }}></span> {loc.value}</span>
          })}
        </div> : null}


        
      </div>
    }
    return <p style={style.small}>{feed.categoryFilter[0].categories.map(c=>c.replace(/.+:/,'')).filter((c,i,a)=>a.indexOf(c)===i).join(', ')}</p>
  }

  intergrationForm(){

    let {user} = this.props
    let {feed} = this.state

     return <div>
                <p style={{margin: '0px 0px 10px 7px', fontWeight: 'bold', fontSize: 'initial'}}>Publish To</p>

                <Integrate
                  user={user} 
                  style={{maxWidth:800}} 
                  feed={feed}
                  addNew={true}
                  onUpdate={(newTargets)=>{
                    this.update({...feed, onSubmit:newTargets})
                  }}
                  onNewTarget={(newTarget)=>{
                    let newFeed = null
                    if(newTarget.categories && feed.categoryFilter[0].categories.length===0){
                      newFeed = {...(newFeed ? newFeed : feed), categoryFilter:[{categories:newTarget.categories}]}
                    }
                    if(newTarget.logo && !feed.logo){
                      newFeed = {...(newFeed ? newFeed : feed), logo:newTarget.logo}
                    }

                    newFeed ? this.update(newFeed) : null

                  }}
                />

            
              </div>
  }

  body(){

    let {publications, simple, user, feedMenu} = this.props
    let {feed, forceSimple, hidden, curator, owner} = this.state
    let small = simple && !forceSimple
    let dev = false//window.env==='development'

    let perDay = feed ? setting(feed.articleGeneration, 'curate', 1) : 0
    let freq = feed ? setting(feed.articleGeneration, 'frequency', 'daily') : 0
    let order = feed ? setting(feed.articleGeneration, 'order', 0) : 0

    publications = publications ? publications.filter(p=>p && p.value && p.value!=='') : []
    
    let product = feedMenu && feedMenu.menu ? feedMenu.menu.find(m=>m.type==='product' && m.selected) : null
  


    feed.onSubmit = feed.onSubmit ? feed.onSubmit : []

   

    let pubs = feed.publications ? feed.publications.map(pub=>{
                  if(pub.id){
                    let candidate = whitelists.publications.find(p=>pub.keyid ? p.keyid===pub.keyid : p.id===pub.id)
                    return candidate ? candidate.value : pub.value
                  }else if(pub.keyid){
                    let candidate = publications.find(p=>pub.keyid ? p.keyid===pub.keyid : p.id===pub.id)
                    return candidate ? candidate.value : pub.value
                  // }else if(pub.value.indexOf('http')>0){

                  }else{
                    return pub.value
                  }
                }) : []

    let enabled = {
      advancedSelector:(feed.selector && feed.selector.trim()!=="" && feed.selector.trim().length>0) || feed.plugins.find(p=>p.type==='advancedSelector'),              
      // accessRights:(feed.accessRights && feed.accessRights.length>0) || feed.plugins.find(p=>p.type==='accessRights'),
      // onSubmit:(feed.onSubmit && feed.onSubmit.length>0) || feed.plugins.find(p=>p.type==='onSubmit'),
      // cluster:(feed.cluster && feed.cluster.length>0) || feed.plugins.find(p=>p.type==='cluster'),
      articleGeneration:(feed.articleGeneration && feed.articleGeneration.length>0) || feed.plugins.find(p=>p.type==='articlegeneration'),
      publications: ((feed.publications && feed.publications.length>0) || feed.plugins.find(p=>p.type==='scrape'))
    }

    // whitelists.onSubmit = this.props.user.websites
    // console.log("hidden")
    
    // console.log(feed.location)
    
    
    let queryField = feed.project==='archive' ? this.queryText(feed, user.curator) : [

          <TagInput 
              placeholder={"Add your topics here"} 
              value={feed.categoryFilter[0].categories}
              onChange={(newTags)=>this.update({...feed, categoryFilter:[{categories:newTags}]})}
              />,
          
          <span className="glyphicon glyphicon-map-marker" style={{
                      float: 'left',
                      fontSize: 'large',
                      margin: '12px 0px 7px 7px',
                      color:feed.location && feed.location.length > 0 ? 'black' : '#9c9c9c'
                    }}></span>
                    ,
          <TagInput 
              placeholder={feed.location && feed.location.length>0 ? 
                "Add more geo-filters here":
                "Stories from any country or place (or add the names of countries, states or cities to geo-filter)"
              } 
              recommendations={window.countries}
              value={feed.location ? feed.location.map(l=>l.value) : []}
              onChange={(newTags)=>this.update({...feed, location:newTags.map(nt=>({value:nt}))})}
              />
         ]

    if(this.props.justQuery){
      return queryField
    }
    if(small){
      if(!feed.enabled ||  (product && product.value==='cluster')){
        return queryField
      }else{
        return null
      }
    }else{
     
      return <div>

              {feed.categoryFilter ? queryField : null}

              {(this.state.article || this.props.article) && !user.curator ? <Checkout 
                                      showcase={true} 
                                      targetFeed={feed.id}
                                      onNewBilling={(newBilling)=>{
                                        this.setState({newBilling:newBilling})
                                      }}
                                      hide={['articlegeneration', 'humanedit']}
                                      update={(newfeed)=>{
                                        
                                        this.update({...feed, plugins:newfeed.plugins.filter(p=>p.enabled).map(p=>{
                                          
                                          let cleanOption = {type:p.type}
                                          if(p.options){
                                            Object.keys(p.options).forEach(key=>{p[key] ? cleanOption={...cleanOption, [key]:p[key]} : null})
                                          }
                                          
                                          return cleanOption
                                          
                                        })})
                                      // this.setState({feed:{...feed, plugins:plugins}}
                                        }}
                                      feed={feed} 
                                      article={this.props.article ? this.props.article : this.state.article} /> : null}
              

            
                          
        
            
              {this.intergrationForm()}
              
       
             {user.projects && user.projects.length>0 ?<Flip tooltip={"This feed belongs to "} style={{display: 'flex',flexWrap: 'nowrap', padding:15, fontWeight:'bold'}} dropdown={true}  light={true}  value={feed.project ? [{title:'Default Project', id:''}].concat(user.projects).findIndex(f=>f.id===feed.project) : 0} options={[{title:'Default Project', id:''}].concat(user.projects)} 
                                   onChange={(i, option)=>{
                                     this.update({...feed, project:i===0 ? null : user.projects[i-1].id})
                                   }}/> : null}

              {this.state.edit!=='accessRights' ? <div style={{display:'flex',padding:'15px 5px'}}>
              {feed.accessRights && feed.accessRights.length > 0 ? feed.accessRights.filter(person=>person.value!==curator).map((person,i)=>{
                return <div className="puff-avatar" style={{textTransform: 'capitalize', boxShadow:'rgba(0, 0, 0, 0.1) 0px 1px 10px',border:'solid 3px white', zIndex:i*-1, marginLeft:i>0 ? -25 : 0, width:43, height:43, background:person.avatar ? 'url("'+person.avatar+'")':'#03A9F4', backgroundSize:'cover', color:'white', fontSize:'large', textAlign:'center', lineHeight:'39px'}}>{person.avatar ? '' : person.value[0]}</div>
              }) : null}
               

              <p style={style.shared}>
                {feed.accessRights && feed.accessRights.filter(u=>u.value!==curator).length>0 ? "Shared with " + feed.accessRights.filter(u=>u.value!==curator).length + (feed.accessRights.filter(u=>u.value!==curator).length >1 ? " people" : " person") : 'This feed is private'} <a style={{cursor:'pointer'}} onClick={()=>this.setState({edit:'accessRights'})}>{feed.accessRights && feed.accessRights.length>0 ? "Edit" : <TT text="Share access to this feed with others"><span>Share this feed <i className="fa fa-share-alt" aria-hidden="true"></i></span></TT>}</a>
              </p>

              </div> : 
              <div>
                <p style={{margin: '30px 0px 0px 7px', fontWeight: 'bold', fontSize: 'initial'}}>Feed access</p>
                  
         
                <TagInput 
                placeholder={feed.accessRights && feed.accessRights.length > 1 ? 'Add another email here' : 'Type someones email to share this feed with them'} 
                value={feed.accessRights ? feed.accessRights.map(t=>t.value) : []}
                onChange={(newTags)=>this.update({...feed, categoryFilter:[{categories:newTags.map(t=>({value:t}))}]})}
                />
              </div>
              }

               <p style={{color:'#8f8f8f', marginLeft:10}}>Estimated cost for {perDay} article{perDay>1 ? 's' : ''} {{monthly:'monthly', daily:'daily', weekdays:'per weekday', weekends:'per weekend', weekly:'per week'}[freq]} is €{(prices[order])*perDay*{monthly:1, daily:30, weekdays:20, weekends:4, weekly:4}[freq]} per month</p>
      
              {user.admin && false ? <input type="text" value={feed.teams} onChange={(e)=>{
                              let teams = e.target.value
                              this.update({...feed, teams:teams ? teams.split(',').map(team=>team.trim()) : null})
                            }}/> : null}
            
            </div>
    }

  }

  resolveTagValues(taglist, whitelist){
    return taglist ? taglist.map(p=>{
      // console.log(whitelist)
      // .filter(p=>(this.props.user && this.props.user.admin) || p.type!=='funnel')
      let tag = whitelist.find(w=>w.type==p.type)
      return tag ? tag.value : null
    }).filter(tag=>tag) : []
  }

  smallButton(title, icon, sure, callback, styleWrapper, children){
    let rusure = sure && this.state.sure[sure]
    return <div tabIndex="0" onBlur={()=>rusure ? this.setState({sure:{}}) : null} onClick={callback} className="blueButton borderButton highlight" style={{...(styleWrapper ? styleWrapper : {}),...(rusure || sure==='save' ? {background:'black', color:'white', padding:'13px 15px'} : {padding:'13px 15px'})}}>
        {children}
        {rusure ? 'Click again to confirm ' + title : title} <span className={"fa fa-"+icon+" buttonConf ani"} style={rusure ? {width:20, marginLeft:5} : {}}></span>
    </div>
  }

  setValue(feed, field, attribute, value){
   let property = {value:attribute+':'+value}
   //initialize the field array if it's missing, otherwise remove any existing atrribute value
   if(feed[field]){
      feed[field] = [property].concat(feed[field]) 
    }else{
      feed[field] = [property]
    }
   feed[field] = feed[field].filter((prop, i)=>{
     if(prop && prop.value){
       if(prop.value.indexOf(':')>-1 && prop.value.split(':')[0]===attribute){
         //Remove all other declarations of this propery from the list
         return feed[field].findIndex(oProp=>oProp.value && oProp.value.split(':')[0]===attribute) === i
       }else{
         return true
       }
     }else{
       return true
     }
   })
   this.update({...feed})
 }

  buttons(alwaysON){
    let {selected, billing, newFeed, header, simple} = this.props
    let {feed, forceSimple, hidden, newBilling, owner} = this.state
    
    let frequencies = ['daily', 'weekdays','weekends', 'weekly', 'monthly']
    let typeOfContent = ['news', 'blog', 'mixed']
    // let articleLengths = ['300', '600', '1200']
    let articleLengths = ['300', '600', '800', '1000', '1200']
    let deadlines = [{title:'Anytime', value:'anytime'}].concat([11,12,13,14,15,16,17,18,19,20,21,22,23].map(h=>({title:h+':00', value:h+'00'})))
    
    let languages = window.languages
    let freq = setting(feed.articleGeneration, 'frequency', 'daily')
    let deadline = setting(feed.articleGeneration, 'deadline', 'anytime').toString()
    
    let typeOfCont = setting(feed.articleGeneration, 'typeOfContent', 'news')
    let nrOfWords = setting(feed.articleGeneration, 'nrOfWords', 300).toString()
    let lang = setting(feed.articleGeneration, 'language', 'en')

    let hasBilling = this.props.user.apikey || billing || newBilling
    let canToggleEnable = (hasBilling || feed.enabled) && feed.plugins.length>0
    let toggleEnableMessage = !hasBilling ? 'You need to insert a payment method to start this feed' : 
                              (feed.plugins.length < 1 && !feed.enabled ? 'Please choose at least one plugin' : 
                              (feed.enabled ? "Pause this feed" : "Build " + setting(feed.articleGeneration, 'curate', 1) + " " + feed.title +" article"+ (setting(feed.articleGeneration, 'curate', 1)>1 ? "s" : "") + " " + (['daily', 'weekly','monthly'].includes(freq) ? freq : 'per ' +freq.slice(0, freq.length-1))))
    let small = simple  && !forceSimple                         

    if(feed.project==='archive'){
      return null
    }

    return <div style={{display: 'flex', margin: '10px 0px',pointerEvents:feed.project==='archive' && false ? 'none' : 'all'}}>
      {feed.project==='archive' ? <div style={{fontWeight:'bold', borderRadius:30, background: '#e4f1f8', margin: 'auto 0px', border: 'none', padding: 15}}>Archived</div> : null}

      {feed.project==='archive' || alwaysON || (!feed.enabled && feed.plugins.length===0) ? null :  <TT text={toggleEnableMessage}>{
                                                                    this.smallButton(
                                                                    feed.enabled ? "Pause" : "Start",
                                                                    feed.enabled ? "pause" : "play", 
                                                                    null,
                                                                    ()=>{
                                                                      if(canToggleEnable){
                                                                        this.update({...feed, enabled: !feed.enabled}, true)
                                                                      }
                                                                    })
                                                            }</TT>}

      {feed.project==='archive' ? null : <TT text="Number of Articles">
            <input onChange={(e)=>{
              let amount = parseInt(e.target.value)
              this.setValue(feed, 'articleGeneration','curate', (amount < 0 ? 1 : (amount>100 ? 100 : ((amount>0 && amount <101) ? amount : 3))))
            }}
            type="number"
            value={setting(feed.articleGeneration, 'curate', 1)} 
            min="1" 
            max="100" 
            style={style.input}/></TT>}
      
      {feed.project!=='archive' && user && user.admin ? <Flip style={{display: 'flex',flexWrap: 'nowrap', padding:15, fontWeight:'bold'}} dropdown={true} light={true} value={setting(feed.articleGeneration, 'order', 0)} options={['AI','300', '600', '1200', '800', '1000']} onChange={(i)=>
        this.setValue(feed, 'articleGeneration','order', i)
      }/> : null}
      
      {feed.project==='archive' ? null : <Flip tooltip="Content Length" suffix="Words" style={{display: 'flex',flexWrap: 'nowrap', padding:15, fontWeight:'bold'}} dropdown={true}  light={true}  value={articleLengths.indexOf(nrOfWords)} options={articleLengths} onChange={(i, option)=>{
              this.setValue(feed, 'articleGeneration','order', {0:1, 1:2, 2:4, 3:5, 4:3}[i])
              // this.setValue(feed, 'articleGeneration','order', i+1)
              this.setValue(feed, 'articleGeneration','nrOfWords', option)
            }}/>}

      <Flip tooltip="Type of Content" style={{display: 'flex',flexWrap: 'nowrap', padding:15, fontWeight:'bold'}} dropdown={true}  light={true}  value={typeOfContent.indexOf(typeOfCont)} options={typeOfContent} onChange={(i, option)=>this.setValue(feed, 'articleGeneration','typeOfContent', option)}/>
      
      <Flip tooltip="Target language & audience" style={{display: 'flex',flexWrap: 'nowrap', padding:15, fontWeight:'bold'}} dropdown={true}  light={true}  value={languages.map(lang=>lang.code).indexOf(lang)} options={languages.map(lang=>lang.title)} onChange={(i, option)=>this.setValue(feed, 'articleGeneration','language', languages.map(lang=>lang.code)[i])}/>
      
      <Flip tooltip="Frequency of generation" style={{display: 'flex',flexWrap: 'nowrap', padding:15, fontWeight:'bold'}} dropdown={true}  light={true}  value={frequencies.indexOf(freq)} options={frequencies} onChange={(i, option)=>this.setValue(feed, 'articleGeneration','frequency', option)}/>
      
      {user && user.admin ? <Flip tooltip="Submission Deadline" style={{display: 'flex',flexWrap: 'nowrap', padding:15, fontWeight:'bold'}} dropdown={true}  light={true}  value={deadlines.findIndex(op=>op.value===deadline)} options={deadlines.map(h=>h.title)} onChange={(i, option)=>this.setValue(feed, 'articleGeneration','deadline', deadlines.map(h=>h.value)[i])}/> : null}

      {simple ? <TT text={forceSimple ? "Close settings" : "Configure this feed"}>
              <div className="blueButton borderButton highlight" 
                onClick={()=>{
                if(!this.state.article){
                    let puffpath = 'feed?q='+feed.id+'&s=1&product=cluster&sort=popular&full=true'
                    get(puffpath,(err, res)=>{
                      this.setState({article:res.body})
                    })
                  }
                  this.setState({forceSimple:!forceSimple})
                }} 
                style={{border:'none', float:'right', margin:10}}>
                {forceSimple ? 'Show less' : 'Settings'} <span className={"fa fa-times buttonConf animate"} style={{fontSize:'large', width:20, marginLeft:5, transform: forceSimple ? "rotate(0deg)" : "rotate(45deg)"}} aria-hidden="true" />
              </div>
            </TT> : null}
      
        
        {!this.state.saved && ((feed.location && feed.location.length>0) || feed.categoryFilter[0].categories.length>0) ?  
        <TT text="Save your changes">
          {this.smallButton(
          'Save',
          'check',
          'save',
          ()=>{this.save()},
          null,
          (this.state.loading ? <Spinner style={{marginLeft:10, float:'right',fontSize:'20px', width:20, height:20, color:'white'}}/> : null))}
        </TT> : null}

    </div>
  }

  aicurate(){
 
    this.props.buildPuff(null, 
                        this.state.feed.plugins, 
                        this.state.feed.id,
                        {aicurate:true, articleGeneration:this.state.feed.articleGeneration})
  }

  render(){
    let {selected, newFeed, header, simple} = this.props
    let {feed, forceSimple, originalFeed, owner} = this.state

    if(!feed){
      return <p>Feed corrupt 404</p>
    }

    if(this.props.justQuery){
      return <div style={{...style.container, ...this.props.style}}>{this.body()}</div>
    }

    let alwaysON = monthlyPayment.reduce((a,pluginName)=> a || feed.plugins.find(p=>pluginName.indexOf(p.type)>-1),false)
    
    let small =  (simple && !forceSimple)
    
    let perDay = feed ? setting(feed.articleGeneration, 'curate', 1) : 0
    let freq = feed ? setting(feed.articleGeneration, 'frequency', 'daily') : 0
    let order = feed ? setting(feed.articleGeneration, 'order', 0) : 0

    header = true 
    let titlelength = originalFeed.title.length > feed.title.length ? originalFeed.title.length : feed.title.length

    let newArticleButton = <div className="button blackButton" style={{float:'right'}} onClick={()=>{
                  let params=settingsToParams({menu:feed.menu ? feed.menu : [], lastRequest:feed.lastRequest})
                  let sub = params.category ? params.category : (params.page ? params.page : null)
                  browserHistory.push({pathname: '/'+feed.id + (sub ? '/'+sub.toLowerCase() : ''), query:{...params, category:undefined, page:undefined, new:'draft'}})
              }}><i className="fa fa-plus" aria-hidden="true"></i> New Article</div>
    
    let AICurateButton = <div className="button blackButton" style={{float:'right'}} onClick={()=>this.aicurate()}>AI Curate</div>


    return <div style={{margin:'30px 15px 50px', position:'relative'}}>
            {header ? <div style={{display: 'inline-block', minHeight: 60}}>
                        {feed.logo ? <img style={{height: 60, padding:10, filter:'contrast(0) brightness(0)'}} src={'https://www.monok.com/api/v1/logoProxy?url='+feed.logo}/> : null}
                        {feed.logo && !forceSimple ? null : <input key='plugintitle'  autoFocus={feed.title==='Untitled'} 
                                  className="plugin-title" 
                                  onBlur={(e)=>e.target.value==='' ? this.update({...feed, title:'Untitled'}) : null} 
                                  onChange={(e)=>this.update({...feed, title:e.target.value.length<40 ? e.target.value : feed.title})} 
                                  type="text" 
                                  value={feed.title} 
                                  style={{pointerEvents:owner ? 'none' : undefined, width:titlelength/2+2+'em', position:'absolute', maxWidth:'-webkit-fill-available', zIndex:100}}/>}
                        {feed.logo ? null : <div className="plugin-title" style={{opacity:0.2, boxShadow: 'none', visibility:(this.state.originalFeed.title.toLowerCase().indexOf(feed.title.toLowerCase())===0) ? 'initial' : 'hidden'}}>{this.state.originalFeed.title}</div>}
                                  </div> : null}   

            {feed.id==='z5nqmq' ? (window.location.search && window.location.search.includes('product=cluster') ? this.smallButton(
                                                    'Go Back!',
                                                    'arrow-left', 
                                                    null,
                                                    ()=>{
                                                      
                                                        browserHistory.push({pathname: '/z5nqmq', query:{product:'articlegeneration', sort:'date'}})
                                                     
                                                    }, 
                                                    {float:'right'}) : this.smallButton(
                                                    'Discover',
                                                    'binoculars', 
                                                    null,
                                                    ()=>{
                                                      
                                                        browserHistory.push({pathname: '/z5nqmq', query:{product:'cluster', sort:'date'}})
                                                     
                                                    }, 
                                                    {float:'right'}) ) : null}        

             {!feed.enabled && forceSimple ? <TT text="Archive this feed, you can undo this">
                                        {this.smallButton(
                                        'Delete',
                                        'trash', 
                                        'delete',
                                        ()=>{
                                          if(this.state.sure.delete){
                                            this.update({...feed, project:'archive'}, true)
                                            browserHistory.push({pathname: '/profile'+(feed.project ? '/'+feed.project : '')})
                                            // this.update({id:feed.id, delete:true})
                                          }else{
                                            this.setState({sure:{delete:true}})
                                          }
                                        }, 
                                        {float:'right'})}
                                      </TT> : null}

              {feed.project!=='archive'  && (user.curator || user.admin) ? AICurateButton : null}
              {feed.project==='archive' ? <TT text="Restore this feed">
                                        {this.smallButton(
                                        'Restore',
                                        'refresh', 
                                        null,
                                        ()=>{
                                          this.update({...feed, project:null}, true)
                                          browserHistory.push({pathname: '/'+feed.id})
                                        }, 
                                        {float:'right'})}
                                      </TT> : null}
              {user.curator ? newArticleButton : null}
            {owner && !user.admin ? <div>
              {this.props.loading ? <Spinner style={{marginLeft:10}}/> : null} 
                  <p style={{margin: '0px 10px', fontWeight: 'bold'}}>
                    {feed.enabled ? 'Running for' : 'Paused, '} {perDay} article{perDay>1 ? 's' : ''} ({{0:'AI Only', 1:'300 words', 2:'600 words', 3:'1200 words', 4:'800 words', 5:'1000 words'}[order]}) {{daily:'daily', weekdays:'per weekday', weekends:'per weekend', weekly:'weekly', monthly:'monthly'}[freq]}
                    &nbsp;- With {feed.plugins && feed.plugins.find(p=>p.type==='photo') ? 'Photos' : 'No photos!'}
                  </p>
                  {this.queryText(feed, user.curator)}
                  {user.curator ? null : <p style={style.small}>{owner.name} has shared this feed with you</p>}
              </div> : <div style={{ ...style.simple, ...this.props.style}}>
              {user.admin && owner ? <p style={style.small}>Owner: {owner.name}</p> : null}
              {this.buttons(alwaysON)}
              {this.body()}
            </div>}
          </div>

    // if(this.props.justQuery){
    //   return <div style={{...style.container, ...this.props.style}}>{this.body()}</div>
    // }else if(small){
    // }else{
      // return <div style={{...style.container, ...this.props.style}} onClick={this.props.onClick}>
      //         {!feed.enabled && !alwaysON ? <div style={style.line}/> : null}
      //         {selected ? this.buttons(alwaysON) : null}
      //                              
      //         {selected ? this.body() : null}
      //       </div>  
    // }


  }

}


const monthlyPayment = ['search'] 

let selectorWhitelist = [
    {value: "text:revolution"},
    {value: "text:insurgents"},
    {value: "nationality:british"},
    {value: "nationality:french"},
    {value: "religion:catholic"},
    {value: "ordinal:first"},
    {value: "authors:robert"},
    {value: "cause_of_death:illness"},
    {value: "ideology:socialist"},
    {value: "title:died"},
    {value: "person:napoleon"},
    {value: "organization:who"},
    {value: "organization:nba"},
    {value: "organization:samsung"},
    {value: "criminal_charge:drugs"},
    {value: "criminal_charge:homicide"},
    {value: "criminal_charge:selling heroin"},
    {value: "time:night"},
    {value: "time:evening"},
    {value: "duration:hours"},
    {value: "location:village"},
    {value: "location:downtown"},
    {value: "location:bronx"},
    {value: "misc:COVID-19"},
    {value: "money:$1 million"},
    {value: "state_or_province:florida"},
    {value: "country:uk" },
    {value: "city:london"},
    {value: "city:manchester"},
    {value: "world" },
    {value: "music" },
    {value: "movies" },
    {value: "business" },
    {value: "entertainment" },
    {value: "sport" },
    {value: "politics" }
  ]

let locationWhitelist = [
    {value: "United Kingdom"},
    {value: "United States"},
    {value: "California"},
    {value: "New York"},
    {value: "Europe"},
    {value: "England"},
    {value: "Ireland"},
    {value: "Scotland"},
    {value: "Manchester"},
    {value: "Glasgow"}
]

const whitelists = {
  plugins:[
    { value:'Funnel', icon:'filter', type:'scrape' },
    // { value:'Clustering', icon:'sitemap', type:'cluster'},
    { value:'Article Generation', icon:'align-left', type:'articlegeneration'},
    { value:'Interlinking', icon:'link', type:'interlinking'},
    { value:'Video Embeds', icon:'film', type:'videoembeds'},
    { value:'Social Media Embeds', icon:'play', type:'mediaembeds'},
    // { value:'Article Search', icon:'search', type:'search'},
    { value:'Social Media Comments', icon:'comment', type:'socialmediacomments'},
    // { value:'Video Generation', icon:'film', type:'videogeneration'},
    // { value:"Geo Location", icon:'globe', type:'geolocation'},
    { value:"Images", icon:'picture-o', type:'photo'},
    // { value:"Editable", icon:'pencil', type:'editable'},
    // { value:"Access Rights", icon:'eye', type:'accessRights'},
    { value:"Push upon Submit", icon:'share', type:'onSubmit'}//,
    // { value:"Advanced Selector", icon:'hand-lizard-o', type:'advancedSelector'}
  ],
  categories:selectorWhitelist,
  publications:[
     { value:'Relevant News Articles', icon:'bolt', id: 'relevant'},
     { value:'All News', icon:'globe', id: 'all'}
  ],
  accessRights:[
    { value:'Public', icon:'users', id:'public' }
  ],
  // cluster:[],
  articleGeneration:[],
  onSubmit:[],
  location:locationWhitelist,
  selector:selectorWhitelist
}

const style = {
  container:{
    padding:15, 
    overflow:'hidden',
    maxWidth: 1170,
    margin: 'auto',
    textAlign:'left'
  },
  shared:{
    lineHeight: '39px',
    verticalAlign: 'middle',
    margin:0,
    color: '#606060',
    fontSize: 'initial'
  },
  input:{
    borderRadius: 30,    
    pointerEvents: 'all',
    outline: 0,
    margin:5,
    border: 'solid 2px #f1f0f0',
    paddingRight:15,
    textAlign: 'center',
    fontWeight:'bold',
    background: 'none',
    maxHeight:50
  },
  simple:{
    margin: 'initial',
    borderRadius: 24,
    // padding: '20px 0px',
    // display:'grid',
    // gridTemplateColumns:'auto 140px'
  },
  small:{fontSize:'small', color:'#b9b9b9', margin: '0px 10px', textTransform: 'capitalize'},
  title:{
      color: 'black',
      fontSize: '50px',
      marginBottom: '30px',
      fontWeight: 'bold',
      fontFamily: "'PT Sans Narrow', sans-serif" 
    },
    feed:{
      marginBottom: 10, 
      overflow:'hidden',
      borderRadius:15,
      padding:0,
      width:'100%',
      float:'left'
    },
    line:{
      position: 'absolute',
      bottom: 0,
      top: 0,
      right: 0,
      backgroundColor: "#ff5151",
      width: 4
    },
    buttonLined:{
      cursor: 'pointer',
      borderRadius: '50%',
      borderStyle: 'solid',
      borderWidth: 1,
      fontSize:'small',
      padding: 20,
      width:55,
      height:55,
      float:'right'
    },
    tag:{
      categories:'--tag-bg:transparent;border: solid 1px #e6e6e6;border-radius: 8px;',
      location:'--tag-bg:transparent;border: transparent;border-radius: 8px;',
      articleGeneration:'--tag-bg: #ffcf3e;',
      plugins:'--tag-bg: #fff02c;',
      onSubmit:'--tag-bg:#3a3a3a;--tag-text-color:white;',
      publications:'--tag-bg:#3a3a3a;--tag-text-color:white;',
      // cluster:'--tag-bg:#ff5722;--tag-text-color:white;',
      accessRights:'--tag-bg:#c7e6ff;',
      blue:'--tag-bg:#c7e6ff;',
      green:'--tag-bg:#dfed57;',
      selector:'--tag-bg:#E5E5E5;margin:3px 0px;margin-right: 6px;',
      prefix:'background-color: #0000005e;color: white;padding: 1px 7px;margin-right: 5px;border-radius: 5px;font-size: small;'
    } 
}

const mapStateToProps = (state)=>{
  return {
    user:state.auth.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile:(newProfile)=>dispatch(saveProfile(newProfile)),
    buildPuff:(id, plugins, targetFeed, body)=>dispatch(buildPuff(id, plugins, targetFeed, body))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Config)
// export default connect(mapStateToProps)(Config);