var React = require('react');
var TimeAgo = require('react-timeago').default
var config = require('../../../settings/config.json')
import T from 'i18n-react';
import TweetEmbed from 'react-tweet-embed'
// import InstagramEmbed from 'react-instagram-embed'
import Video from '../media/video.jsx';
import Context from './context.jsx';
import {browserHistory} from 'react-router';
import {setFocusedPuff} from '../../actions/puff';
import store from '../../store';
import EmbedContainer from 'react-oembed-container';

var VfImage = require('../media/vfImage.jsx')

class Widget extends React.Component {
  
	searchPuffs(widget, e){
    //e.preventDefault();
    store.dispatch(setFocusedPuff(0));
    let query = widget.text ? widget.text : widget.name ? widget.name : widget.description ? widget.description : ''
    browserHistory.push({pathname: '/search', query:{q:query, view:'magazine', feed:'free'}})
  }

  stopPropagation(e){
    e.stopPropagation();
  }

  gotoMap(lat, lng, e){
    e.stopPropagation();
    browserHistory.push({pathname: '/map',search: '?lat='+lat+'&lng='+lng}) 
  }

	getPage(widget){
    let originalWidget = widget
    
    let article = this.props.article

    let c = style.container

    if(!widget){
      widget={}
    }
    if(widget.data && !widget.avatar && widget.type!=='twitter'){
      widget = widget.data[0]
    }

    //TODO: We really need to standardize
    if(originalWidget && originalWidget.context){
      widget.context = originalWidget.context
    }

    //TODO: We need to create a widget type system!!
   
    if(widget.type==="reddit" && widget.url){
      let cleanUrl = widget.url//matches && matches[0] ? 'https://www.instagram.com'+matches[0] : widget.url
      let post = `<blockquote class="reddit-embed-bq" style="height:316px" data-embed-height="786"><a href="${cleanUrl}"></a></blockquote><script async="" src="https://embed.reddit.com/widgets.js" charset="UTF-8"></script>`
      return <EmbedContainer markup={post}>
          <div dangerouslySetInnerHTML={{ __html: post}} />
      </EmbedContainer>
    
    }else if(widget.type==="facebook" && widget.url){

      // <blockquote cite="https://www.facebook.com/MarionCountySheriff/posts/1046995607470798" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/MarionCountySheriff/posts/1046995607470798"></a></blockquote>
      //<script async defer crossorigin="anonymous" src="https://connect.facebook.net/af_ZA/sdk.js#xfbml=1&version=v22.0&appId=387411317431132"></script>
      return <iframe src={`https://www.facebook.com/plugins/${widget.url.includes('/watch/') ? 'video' : 'post'}.php?href=${encodeURI(widget.url)}&show_text=true&width=500`} width="500" height="657" style={{border:'none', overflow:'hidden'}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    
    }else if(widget.type==="spotify" && widget.id){
    
      return <iframe style={{borderRadius:"12px"}} src={widget.url.replace("spotify.com/","spotify.com/embed/")} width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
    
    }else if(widget.type==="tiktok" && widget.url){
      let cleanUrl = widget.url
      let post = `<blockquote class="tiktok-embed" cite="${cleanUrl}" data-video-id="${widget.id}" style="max-width: 605px;min-width: 325px;" ><a href="${cleanUrl}"></a></blockquote> <script async src="https://www.tiktok.com/embed.js"></script>`
      return  <EmbedContainer markup={post}>
          <div dangerouslySetInnerHTML={{ __html: post}} />
      </EmbedContainer>
    
    }else if(widget.type==="instagram" && widget.url){
    
      //INSTAGRAM
      let matches = widget.url.match(/\/(p|reel)\/(.*?)\//)
      let cleanUrl = matches && matches[0] ? 'https://www.instagram.com'+matches[0] : widget.url
      let post = '<blockquote class="instagram-media" data-instgrm-captioned data-instgrm-permalink="'+cleanUrl+'?utm_source=ig_embed&amp;utm_campaign=loading" data-instgrm-version="13" style="border:0; border-radius:3px; box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); margin: 1px; max-width:658px; min-width:326px; padding:0; width:99.375%; width:-webkit-calc(100% - 2px); width:calc(100% - 2px);"></blockquote><script async src="//platform.instagram.com/en_US/embeds.js"></script>'
      // console.log(widget.url)
      return   <EmbedContainer markup={post}>
            <div dangerouslySetInnerHTML={{ __html: post}} />
        </EmbedContainer>
    }
    //VIDEO
    // || (widget.type==='twitter' && widget.data  && widget.data[0] && widget.data[0].video  && this.props.type==='header')
    if(widget.type==="video" || widget.type==="embedVideo" ){
    	switch (this.props.type) {
			 	case 'card':
          let vidS = {marginBottom:10, borderRadius:5, ...(this.props.style ? this.props.style : {})}
			 	
          if(widget.description){
			 			return <div style={{marginBottom: 10}}>
			 							<Video editmode={this.props.editmode} opened={true} style={vidS} selected={true} focus={true} video={widget}/>
			 							{widget.description}
			 						</div>
			 		}else{
			 			return <Video editmode={this.props.editmode} opened={true} style={vidS} selected={true} focus={true} video={widget}/>
			 		}
			  case 'header':
			  	return <Video big={true} maxHeight={73} thumbHeight={'40%'} transitions={this.props.transitions} opened={this.props.opened} selected={this.props.selected} focus={this.props.focus} video={widget} style={this.props.style}/>
        default:
			    return <Video opened={this.props.opened} selected={this.props.selected} focus={this.props.focus} video={widget} style={this.props.style}/>
			}     
    	//set height attribute for fixed height
      
    //MAP
    }else if(widget.lat && widget.source!=='wikipedia'){  

    	var mapStyle = {
    		background:"#d4dadc url('"+config.server[window.env].staticUrl+'maptile/'+widget.lat+'-'+widget.lng+'-'+widget.zoom+".png') no-repeat center center / cover",
    		backgroundSize:'cover',
    		height:250
    	}

    	switch (this.props.type) {
			 	case 'card' :
			    return 	<div style={{...{marginBottom: 5},...this.props.style}}>
				      			  <div style={{display: 'inline-block', width: '100%', marginTop:10, marginBottom:10}}>
				      			  		{widget.type === 'CountryRegion' || widget.type === 'country' ? <img style={{width:34, float:'left'}} src={'img/flags/'+widget.code+'.png'}/> : null}
				                  <p style={{float: 'left', fontWeight: 'bold', margin: 0, padding: 7}}>{widget.description}</p>
				                  {widget.url ? 
				                  <a onClick={self.stopPropagation} href={widget.url} target="_blank" >
				                    <T.span text="puff.visualFocus.readmore" className="badge mouseOverButton" style={style.button}/>
				                  </a> : null}
				                  {widget.lat ? <T.span text="tabs.map" className="badge mouseOverButton" onClick={this.gotoMap.bind(this, widget.lat,widget.lng)} style={style.button}/> : null}
				                  <T.span text="puff.visualFocus.findrelated" onClick={this.searchPuffs.bind(this, widget)} className="badge mouseOverButton" style={style.button}/>
				              </div>
			      			</div>
        case 'sidemenu':
          return <Context isMobile={this.props.isMobile} type={this.props.type} style={this.props.style} thumbSize={this.props.thumbSize} widget={widget} article={article} />
			  default:
			    return null
			}      			
    //IMAGE
    }else if(!this.props.topImage && (widget.image)?(widget.image.url||undefined):undefined){
    	return <Context isMobile={this.props.isMobile} type={this.props.type} style={this.props.style} thumbSize={this.props.thumbSize} widget={widget} article={article} />
    }else if(this.props.topImage && (widget.image)?(widget.image.url||undefined):undefined){
      return <VfImage onReady={this.props.onReady} id={this.props.id ? this.props.id : undefined} target={500}  offset={250} resolution={this.props.type==='header' ? 800 : 500} topImage={true} article={article} vf={article.visualFocus} style={{borderRadius:0, marginBottom:0, position:'relative',borderRadius:'10px 10px 0px 0px', overflow:'hidden'}}>
            {this.props.children}
            </VfImage>
    // }else if(this.props.image && widget.url){
    //   return <VfImage target={500} image={widget} offset={250} resolution={this.props.type==='header' ? 800 : 500} style={{borderRadius:0, marginBottom:0, position:'relative',borderRadius:'10px 10px 0px 0px', overflow:'hidden'}}>
    //         {this.props.children}
    //         </VfImage>  
    //TWEET
    }else if(widget.type==='twitter' && this.props.type!=='header'){
      return (<div style={{...c,...{textAlign: '-webkit-center'},...this.props.style}} onClick={this.stopPropagation}>  
        					<TweetEmbed  id={widget.id} style={{margin:0}} options={{conversation:'none',lang:article.language}}></TweetEmbed>
            	</div>)

    }else if(widget.type==='website'){
      return (<div onClick={this.stopPropagation}>  
              <a href={widget.url} target="_blank">
              <b style={{fontWeight:'bold'}}>{widget.title}</b>
              <p>{widget.url}</p></a>
              </div>)
            
    }
  
    return null
  }

  render(){
      return this.getPage(this.props.widget)
  }
// <TweetEmbed  id={widget.id} style={{margin:0}} options={{widgetType:'video', conversation:'none',lang:article.language}}></TweetEmbed>
        	
}

module.exports = Widget;

const style = {
  title:{

    fontSize: '15px',
    marginBottom: '5px',
    marginTop:'0px',
    fontWeight: 600,
    fontFamily: "'Cabin', sans-serif" 
  },
  article:{
    marginTop:'10px',
    marginBottom:'10px',
    fontSize: '12pt',
    fontFamily: "'Open Sans', sans-serif", // "'Open Sans', sans-serif"
    overflowWrap: 'break-word'
  },
  content:{
    padding: 15,
    paddingBottom: 0,
    minHeight:180
  },
  timestamp:{
    fontSize: '14px',
  },
	container:{
	      position:'relative',
	      borderTopRightRadius: 10,
	      borderTopLeftRadius: 10,
	      paddingTop:"0%",
	      backgroundRepeat:"no-repeat",
	      backgroundSize:"cover",
	      width:'100%'
	},
	button:{
		cursor: 'pointer',
		marginRight: '5px',
    borderRadius: '20px',
    float: 'right',
    padding:8,
    borderStyle: 'solid',
    borderWidth: 2,
    height:34,
    fontSize:'small',
  }
}