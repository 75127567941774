import React from 'react';

const priceList = {
  articlegeneration:1,
  photo:0.5,
  socialmediacomments:0.5
}

class Cost extends React.Component {

  constructor(props){
     super(props);
     this.state = {change:{}}
  }

  price(feed){
    // console.log(feed.plugins)
 

    if(feed.plugins){   
      if(feed.plugins.find(p=>p.type==='articlegeneration')){
        
        let singleCost = feed.plugins.reduce((sum, plugin)=>{
          return sum + (priceList[plugin.type] ? priceList[plugin.type] : 0)
        },0)
        
        let maxAmount = feed.articleGeneration ? feed.articleGeneration.find(attr=>attr.value.split(':')[0]==='maxAmount') : null
        maxAmount = maxAmount ? parseInt(maxAmount.value.split(':')[1]) : 30
        let total = singleCost*maxAmount
        let runOnce = feed.articleGeneration ? feed.articleGeneration.find(attr=>attr.value.split(':')[0]==='run' && attr.value.split(':')[1]==='once') : false
        return runOnce ? ('€' + total) : '€0-' + total + ' / day'
      }
  
    }
    return 'Select a Product'
  }

  render() {
    return <span>
          {this.price(this.props.feed)}
        </span>
  }

}

export default Cost