'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});


// English (Template)
var strings = {
  prefixAgo: null,
  prefixFromNow: null,
  suffixAgo: 'ago',
  suffixFromNow: 'from now',
  seconds: 'less than a minute',
  minute: 'a minute',
  minutes: '%d minutes',
  hour: 'an hour',
  hours: '%d hours',
  day: 'a day',
  days: '%d days',
  month: 'a month',
  months: '%d months',
  year: 'a year',
  years: '%d years',
  wordSeparator: ' '
};
exports.default = strings;