import React from 'react';
import ReactDOM from 'react-dom';
import {urlToObject} from '../../util';
import ArticleParser from '../editor/toHTML.js';
let articleParser  = new ArticleParser()

if (typeof document !== 'undefined') {
  var MediumEditor = require('medium-editor');
}

class MonokEditor extends React.Component {
  // static defaultProps = {
  //   tag: 'div'
  // };

  constructor(props) {
    super(props);

    // this.spanRef = React.createRef();
    // this.val = props.value;

    this.state = {
      text: this.props.text
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return false
  }

  decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

  componentDidMount() {
    const dom = ReactDOM.findDOMNode(this);

    let {onChange, onLoad, allowedMedia, anchors} = this.props

    this.medium = new MediumEditor(dom, this.props.options);
    onLoad(this.medium)
    this.medium.subscribe("editableClick", function(e){if (e.target.href) {window.open(e.target.href)}})

     // this.medium.subscribe('editablePaste', e=>{
     //  console.log(e.target)
     // }); 

    // dom.addEventListener('paste',e=>{
  //   console.log(e.clipboardData.getData('Text'));
    // }, true)

    this.medium.subscribe('editableInput', e => {
      // this._updated = true;
      if(onChange){
        
        if(dom.innerHTML.match(/(?<![";])(https?:.+?)(?=[\s<>\n])/ig)){
          let match = dom.innerHTML.match(/(?<![";])(https?:.+?)(?=[\s<>\n])/ig)
          dom.childNodes.forEach(child=>{
            let candURL = this.decodeHtml(match[0])
            if(child.innerText && child.innerText.includes(candURL) && !anchors.find(anchor=>anchor.anchorText.trim().toLowerCase()===candURL.trim().toLowerCase())){
              let obj = urlToObject(candURL, allowedMedia ? allowedMedia : ['video', 'twitter', 'instagram', 'reddit', 'tiktok', 'facebook', 'spotify'])
            
              if(obj){
                let widgetString = JSON.stringify(obj)
                var newP = document.createElement('p');
                newP.innerHTML = ''
                newP.setAttribute('data-widget', widgetString.encode())
                newP.setAttribute('id', widgetString.hashCode()+'-container')
                child.parentNode.replaceChild(newP, child);
                child = newP
              }
            }
          })
        }
        

        onChange(dom.innerHTML);
      }
    });
  }

  componentDidUpdate() {
    // console.log(this.prevSelection)
    // console.log(this.prevElement)

    // this.medium.importSelection(this.prevSelection, true)
    
    // this.medium.selectElement(this.prevElement)
    // this.medium.importSelection(this.prevSelection)
    // this.medium.restoreSelection();
  }

  componentWillUnmount() {
    this.medium.destroy();
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.text !== this.state.text && !this._updated) {
    //   // this.setState({ text: nextProps.text });
    // }

    // if (this._updated) this._updated = false;
  }

  render() {
    const {
      options,
      text,
      tag,
      contentEditable,
      dangerouslySetInnerHTML,
      ...props
    } = this.props;

    props.dangerouslySetInnerHTML = { __html: this.state.text };

    if (this.medium) {
      // this.prevElement = this.medium.getSelectedParentElement()
                                                                            // this.prevSelection = this.medium.exportSelection()
      // console.log(this.prevElement)
      // console.log(this.prevSelection)
      // this.medium.saveSelection();
    }
    // console.log(React.createElement(tag, props))
    // return null
    return React.createElement(tag ? tag : 'div', props);
  }


}

module.exports = MonokEditor;