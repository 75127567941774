var React = require('react');
var TimeAgo = require('react-timeago').default
var config = require('../../../settings/config.json')
import T from 'i18n-react';

class Sources extends React.Component {
  
    constructor(props){
        super(props);
        this.showMore = this.showMore.bind(this);
        this.showAll = this.showAll.bind(this);
        let open = props.open ? props.open : 0
        open = props.thumb ? 3 : props.open
        open = props.big ? 50 : props.open

        this.state = {visibleSources: null}
    }

    timeDiff(date, otherDate){
      var date1 = new Date(date);
      var date2 = new Date(otherDate);
      return date2.getTime() - date1.getTime();
    }

    daysOld(date){
      let todayDiff = Math.abs(this.timeDiff(date, new Date().toString()))
      var diffDays = Math.ceil(todayDiff / (1000 * 3600 * 24)); 
      // alert(diffDays);
      return diffDays
      // return moment().diff(moment(date), 'days')
    }

    showMore(event){
        event.stopPropagation();

        if(this.props.onMore){
          this.props.onMore()
        }else{
          let visibleSources = this.state.visibleSources ? this.state.visibleSources : this.props.open 
          this.setState({visibleSources:visibleSources+5})
        }  
    }

    showAll(event){
        event.stopPropagation();

        if(this.props.onMore){
          this.props.onMore()
        }else{
          // console.log(this.props.article.sources.length)
          this.setState({visibleSources:(this.props.type==='published' ? this.props.article.published :  this.props.article.sources).filter(source=>this.hideDuplicate(source,this.props.user)).length})
        }
    }

    hideDuplicate(source, user){
      if(user && (user.writer || user.admin || user.curator)){
        return true
      }else{
        return !source.duplicate
      }
    }

  render(){
    var self = this
    let {article, disabled, user} = this.props
    var id = article.id
    var limit = this.props.limit
    let visibleSources = this.state.visibleSources ? this.state.visibleSources : this.props.open 

    var desc = {marginTop: 0}
    var thumbSize = this.props.thumbsize ? this.props.thumbsize : 100
    let old = this.daysOld(article.date)>3 
    
    let published = this.props.type==='published'
    let listOfSources = published ? article.published : article.sources
    listOfSources = listOfSources ? listOfSources : []

    let fullLength = listOfSources.filter(source=>this.hideDuplicate(source,user)).length

    let missingImage = config.server[window.env].staticUrl+'img/missing.png'

    // var sources = article.sources.slice(0, limit ? limit : article.sources.length).map(function(source, index){
    var sources = listOfSources
                  .filter(source=>this.hideDuplicate(source,user))
                  .sort((a,b)=>(b.image && b.image.thumbnail ? 1 : 0)-(a.image && a.image.thumbnail ? 1 : 0))
                  .slice(0, visibleSources)
                  .map((source, index)=>{
      
      var thumb = source.thumbnail ? source.thumbnail : (source.image ? source.image : article.images.find((x)=>{
              return x && (x.source===source.source)
            }))
      
      if(thumb && thumb.thumbnail){
        if(thumb.thumbnail.indexOf('http')===0){
          thumb = thumb.thumbnail
        }else{
          let filename = thumb.thumbnail.indexOf('media')===0 ? thumb.thumbnail.split('media/')[1] : thumb.thumbnail
          thumb = (config.server[window.env].S3).replace('{bucketname}',('monokthumb-'+filename[0])) + filename.substring(0,2) + '/' + filename   
        }
      }else if(thumb && thumb.original){
         thumb = thumb.original
      }

      var imageOverlay = source.videos ? config.server[window.env].staticUrl + 'img/play.png' : null

      let words = Math.round(source.words/10)*10

      let sMicons = {
        "facebook.com":{icon:"fbLogo.png", gradient:'linear-gradient(to right, #05698540, #03a9f49e)'},
        "linkedin.com":{icon:"linkedinLogo.png", gradient:'linear-gradient(to right, #00c6ff40, #0072ffa6)'},
        "x.com":{icon:"xlogo.png", gradient:'linear-gradient(to right, #05698540, #000000b5)'},
      }

      return(
            <div key={id + "-bt-" +index} style={{...s.wrapper, pointerEvents:disabled ? 'none' : undefined}} className='mouseOverSource'>
            {user && (user.writer || user.admin || user.curator) ? 
              <a rel="nofollow"
              href={source.language && source.language!=='en' ? 'https://www-monok-com.translate.goog/clean/'+encodeURIComponent(source.url)+'?date='+source.date+'&_x_tr_sl=auto&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=wapp'
                    : '/clean/'+encodeURIComponent(source.url)+'?date='+source.date} 
              target="_blank" >
              <div className="button blackButton" 
                style={{float: 'right'}}
               >Clean version</div>
              </a> : null}
            <a rel="nofollow" 
              href={source.url} 
              target="_blank" 
              className="secondary"
              style={{display:'grid', gridTemplateColumns: 'min-content auto', gridGap:10, textDecoration: 'none'}} 
              onClick={e=>(e.stopPropagation())}>
              <div style={{width:thumbSize, height:thumbSize, borderRadius:10, background: (source.page && sMicons[source.source] ? sMicons[source.source].gradient+', ': '')+(thumb ? "url('"+thumb+"'), url('"+missingImage+"')" : "url('"+missingImage+"')"), backgroundSize:'cover', backgroundPosition:'center'}}>
                {source.page && sMicons[source.source] ? <div style={{filter:'invert(1)',borderRadius:10, width:'100%', height:'100%', background:`url("/static/img/integrations/${sMicons[source.source].icon}") center / 67% no-repeat`}}></div> : null}
              </div>
          
              <div style={{paddingBottom:10}}>    
                <b style={s.title}>{source.title}</b>         
                <p style={{...s.timestamp, ...desc,...{marginBottom:0}}}>{source.source}{source.opinion ? ' - Opinion' : null} {source.authors && source.authors.length>0 ? ' - ' + source.authors[0]: null}</p>
                
                <p className="red" style={{...s.article, ...desc,...{marginBottom:0}}}>
                
                {words > 0 ? <span style={{margin:0}}>{words+" "}<T.span text="puff.source.words"/></span> : null}
                
                {old && user && !user.curator ? null : <TimeAgo className="secondary" style={{...s.timestamp,...{marginLeft: 5}}} date={source.date} />}
                </p>
                {source.language && window.languages.find(lang=>lang.code===source.language) ? window.languages.find(lang=>lang.code===source.language).title : null}
                <div style={{width:source.length/100,maxWidth: 180, backgroundColor:'#d83d38', height:3,float: 'left'}}></div>
              </div>
            </a>
            {published && article.updated && self.timeDiff(article.updated, source.date)<0 ? 
              <div 
                onMouseDown={e=>{
                  e.stopPropagation()
                }}
                onClick={e=>{
                  e.preventDefault()
                  e.stopPropagation()
                  if(self.props.onPublish){
                    self.props.onPublish(source)
                  }}} 
                className="button blackButton" 
                style={{position: 'absolute', right: 10, bottom: 10}}>Update</div> : null}
          </div>
      )

    })
        
    
        
    return (
      <div style={{...s.sources,...this.props.style}}>
          {listOfSources.length>0 && this.props.type!=="checkout" ? <h3>{published ? 'Published' : 'Sources'}</h3> : null}
          {sources}
          {listOfSources.filter(source=>this.hideDuplicate(source, user)).length > visibleSources ? 
          <div onClick={fullLength < 11 ? self.showAll : self.showMore} className={'mouseOverText'} style={{display:'inherit'}} >
              <span style={{padding:10}} className="fa fa-newspaper-o" aria-hidden="true"/>
              <span style={s.large}>{fullLength < 11 ? "Show all " + listOfSources.filter(source=>this.hideDuplicate(source, user)).length + " sources" : 'Show 5 more sources'}</span>
          </div>
          : null}        
        </div>
      
      )
  }

}

module.exports = Sources;

const s = {
  sources:{
    position:'relative'
  },
  title:{
    fontSize: '15px',
    marginBottom: '5px',
    marginTop:'0px',
    fontWeight: 600,
    fontFamily: "'Lora', serif"// "'Cabin', sans-serif" 
  },
  article:{
    marginTop:'10px',
    marginBottom:'10px',
    fontSize: '12pt',
    fontFamily: "'Open Sans', sans-serif", // "'Open Sans', sans-serif"
    overflowWrap: 'break-word'
  },
  content:{
    padding: 15,
    paddingBottom: 0,
    minHeight:180,

  },
  timestamp:{
    fontSize: '14px'
  },
  thumb:{
    verticalAlign: 'text-top', 
    width: '10%',
    borderRadius: 10,
    backgroundSize:'cover',
    float:'left',
    marginBottom:10
  },  
  large:{
    fontFamily:'sans-serif',
    fontSize:'larger',
    cursor: 'pointer'
  },
  wrapper:{
    display: 'inline-block',
    width: '100%',
    padding: 10,
    borderRadius: 20
  }
}