import React from 'react';
import {IbanElement, injectStripe} from 'react-stripe-elements';
import {addIban} from '../../apiFunctions';
import Spinner from "../modal/spinner.jsx";

// Custom styling can be passed as options when creating an Element.
const IBAN_STYLE = {
  base: {
    color: '#32325d',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    },
    ':-webkit-autofill': {
      color: '#32325d',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a',
    },
  }
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
  // Elements can use a placeholder as an example IBAN that reflects
  // the IBAN format of your customer's country. If you know your
  // customer's country, we recommend that you pass it to the Element as the
  // placeholderCountry.
  // placeholderCountry: 'SE',
  style: IBAN_STYLE,
};

class IbanForm extends React.Component {

  constructor(props){
    super(props);
    this.state = {name:props.user ? props.user.name : "", email:props.user ? props.user.email : ""}
  }

 handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    
    this.props.onSubmit()

    const {stripe, elements, addMethodResult} = this.props
    const {name, email} = this.state

    if (!stripe || !elements) {
      return;
    }

    addIban((intent)=>{ 
      const clientSecret = intent.cs;
      const iban = elements.getElement('iban');

      stripe.confirmSepaDebitSetup(clientSecret, {
        payment_method: {
          sepa_debit: iban,
          billing_details: {
            name: name,
            email: email,
          },
        }
      }).then(addMethodResult);

    })    
  };

render(){
  let inputStyle = {border:'none', fontWeight:'normal', display:'block', background:'none'}
  // console.log(IbanElement)
  return (
    <form onSubmit={this.handleSubmit}>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        textAlign:'left'
      }}>
       
          <label>
            Name
            <input
              style={inputStyle}
              onChange={(e)=>this.setState({name:e.target.value})}
              name="accountholder-name"
              placeholder="Name of account holder"
              value={this.state.name}
              required
            />
          </label>

          <label>
            Email
            <input
              style={inputStyle}
              onChange={(e)=>this.setState({email:e.target.value})}
              name="email"
              type="email"
              value={this.state.email}
              placeholder="Email Address"
              required
            />
          </label>
    
        <label style={{textAlign:'left',width:'100%'}}>
          IBAN
          <IbanElement onReady={this.props.onReady} {...IBAN_ELEMENT_OPTIONS} />
        </label>
      </div>


      {this.props.loading ? <Spinner/> : <button className="button blackButton"  type="submit" disabled={false}>
              Set up Direct Debit
            </button>}
      {/* Display mandate acceptance text. */}
    { 
     <div className="mandate-acceptance" style={{marginTop: 15, color: 'grey'}}>
            By providing your payment information and confirming this payment,
            you authorise (A) Semsomi AB (Monok.com) and Stripe, our payment service
            provider, to send instructions to your bank to debit your account and
            (B) your bank to debit your account in accordance with those
            instructions. As part of your rights, you are entitled to a refund
            from your bank under the terms and conditions of your agreement with
            your bank. A refund must be claimed within 8 weeks starting from the
            date on which your account was debited. Your rights are explained in
            a statement that you can obtain from your bank. You agree to receive
            notifications for future debits up to 2 days before they occur.
          </div>
        }
    </form>
  );
}

}

export default injectStripe(IbanForm)