var React = require('react');
var config = require('../../../settings/config.json')
import { connect } from 'react-redux';
import Article from './article.jsx';
import {Grid, Col, Row, Glyphicon,Tooltip,OverlayTrigger} from 'react-bootstrap';
var Comments = require('./comments.jsx');
var TimeAgo = require('react-timeago').default
import {ShareButtons} from 'react-share';
var Widget = require('./widget.jsx')
var VfImage = require('../media/vfImage.jsx')
import T from 'i18n-react';
import {browserHistory} from 'react-router';
import Sources from './sources.jsx';
import store from '../../store';
import {settingsToParams} from '../../util';
import {setFocusedPuff} from '../../actions/puff';

const {
  FacebookShareButton,
  TwitterShareButton
} = ShareButtons;

class MobileArticle extends React.Component {

	constructor(props){
    	super(props);
      this.state = {scroll:0}
      this.deselectPuffs = this.deselectPuffs.bind(this);
	}

  android(){
    return /(android)/i.test(navigator.userAgent);
  }
 

  feedPath(feed){
    return '/' + (feed.prefix ? feed.prefix + '/' : '') + feed.id
  }

  updateScroll(e){
    window.dispatchEvent(new Event('parentScroll'));
  }

  deselectPuffs(){
      // store.dispatch(setFocusedPuff(0));
      window.virtualText=null
      let selectedFeed = this.props.feeds[this.props.selectedView]

      if(this.props.content && this.props.content.options && this.props.content.options.parent){
          store.dispatch(setFocusedPuff(0));
      }else if(window.entrypointMonok===window.location.href){
          browserHistory.push({pathname: this.feedPath(selectedFeed), query:settingsToParams(selectedFeed)}) //
      }else{
          browserHistory.goBack()
      }
  }



	render(){
		let article = this.props.article

    //TODO temporary hotfix
    let widgetVF = (article.visualFocus && 
                    article.visualFocus.data && 
                    article.visualFocus.data[0].image) 
                    //&& article.visualFocus.data[0].image.width/article.visualFocus.data[0].image.height>1)

    let colA = this.props.singleFeed ? 12 : 7
    let colB = this.props.singleFeed ? 12 : 5

    let shareBar = [<div style={{display: 'inline-block'}} onClick={self.stopPropagation}>
                      <FacebookShareButton url={this.getPuffUrl(article)}>
                        {this.wrapTT('Share on Facebook',
                          <span style={{...style.button,...style.round}} className={"fa fa-facebook"} aria-hidden="true"></span>)}
                      </FacebookShareButton>
                    </div>,
                    <div style={{display: 'inline-block'}} onClick={self.stopPropagation}>
                      <TwitterShareButton  url={this.getPuffUrl(article)} title={article.title} >
                          {this.wrapTT('Share on Twitter',
                            <span style={{...style.button,...style.round}} className={"fa fa-twitter"} aria-hidden="true"></span>)}
                      </TwitterShareButton>
                    </div>]
// {this.android() || this.props.noCloseButton ? null : [ ]}
    
    let usedWidgets = article.article ? article.article.split('\n').filter(s=>s.indexOf('{')===0).map(s=>s.hashCode()) : []
    let widgetInContent = article.widgets ? article.widgets.concat(article.videos).map(widget=>JSON.stringify(widget).hashCode()).some(r=> usedWidgets.includes(r)) : []


		return (
	
        <div onScroll={this.updateScroll} style={{...style.wrapper,...style.puff,...this.props.style}} className={'active-bigpuff '+this.props.className} onMouseDown={(event)=>{event.stopPropagation()}}>
            {!this.props.noCloseButton ? 
              [<span className={"fa fa-times"} aria-hidden="true" style={{...style.close,...{backgroundColor: 'black', color:'white'}}} onClick={this.deselectPuffs}></span>,
            <span className={"fa fa-times"} aria-hidden="true" style={{...style.close, ...{opacity:0.4, zIndex:0, backgroundColor: 'black'}}} ></span>] : null}
           
          
           {!widgetVF ? 
            <Widget transitions={true} type={'header'} opened={true} selected={true} focus={true} article={article} widget={article.visualFocus} style={{height:null, borderRadius:0, zIndex:-1}}/>
            : null}

          <Row style={style.row}>
              <Col md={colA} style={{padding:0,paddingTop:30}}>
               
                  <div style={{...{paddingBottom:30},...style.padded}}>
                    <h1 style={style.title}>{article.title}</h1>        
                    <TimeAgo style={style.timestamp} date={article.date} formatter={typeof window.timeagoFormatter === 'function' ? window.timeagoFormatter : null}/>{this.getLocation(article)}
                  </div>
                  {article.byline ? <div>
                                        <VfImage vf={article.visualFocus} style={{borderRadius:0}} descriptionStyle={{paddingLeft:30}}/>
                                      <p style={style.article}>
                                        {shareBar}
                                        {article.article.trim()}.. <br/>  
                                        <span style={style.byline} >{article.byline}</span>
                                      </p>
                                    </div> : null}
                  {!article.byline ? <VfImage target={300} vf={article.visualFocus} style={{borderRadius:0}} descriptionStyle={{paddingLeft:30}}/> : null}
                  <div style={style.padded}>
                    {!article.byline ?   <Article includeAd={article.feed==='en' && !(this.props.user && this.props.user.name)} locked={article.locked} text={article.article} style={{padding:0}}>
                        {shareBar}
                      </Article> : null
                    }
                  </div>

              </Col>

              <Col  md={colB} style={{...{padding:0},...style.padded}}>
                    <div style={style.categoryContainer}>
                      {article.categories ? article.categories.slice(0,5).map((category,i)=>{
                        return <div key={i+'-category-big-'+article.id} style={style.buttonLined} className="badge">{category.name}</div>
                      }) : null}
                    </div>
                    {false && article.subreddits ? this.subreddits(article.subreddits) : null}


              </Col>
            </Row>
           
              <Comments big={false} open={this.props.single ? 50 : 3} style={{width: '100%',margin:0, paddingRight: 30, paddingLeft: 30}} article={article}></Comments>
            
        </div>

	   
	    );
	}

  subreddits(subreddits){
      let article = this.props.article
      //TODO: when this becomes more complex, refactor to its own component
      return subreddits.map((subreddit,i)=>{
        return <div key={i+'-subreddit-big-'+article.id}  style={style.button}>
                <a href={'https://www.reddit.com/r/'+subreddit.subreddit+'/comments/'+subreddit.post} target="_blank">
                  <span className={"fa fa-reddit"} aria-hidden="true"></span>
                  &nbsp;r/{subreddit.subreddit}
                  <div style={style.timestamp}>{subreddit.post}</div>
                </a>
              </div>
      })
  }

  getWordPressPushUrl(article){

    let wordpressUrl=this.props.user.wordpress ? this.props.user.wordpress : "http://localhost:8000"
    let dockerLocal = '&source=http://172.17.0.1'
    let urlParams = window.env==='production' ? '' : (window.env==='development' ? dockerLocal : '&source='+location.protocol+"//"+location.host)

    return wordpressUrl+"/wp-admin/post-new.php?monokid="+article.id.split('/')[1] + urlParams
  }

  getLocation(article, style){
    var text = null
    var place = null
    style = style ? style : {}
    if(article.place && article.place.data && article.place.data[0] && article.place.data[0].description){
      place = article.place.data[0]
      text = place.description
      if(text.indexOf(',')>-1){
        var temp = text.split(',')
        text = temp[0]+", "+temp[temp.length-1]
      }

      if(place.text && text.length>20){
        text = place.text
      }

      return 
    }else if(article.place && article.place.name){
      place = {lat:article.place.location.lat, lng:article.place.location.lon}
      text = article.place.name
    }

    if(text && text.length<20){
      return (<span onClick={this.gotoMap.bind(this, place.lat, place.lng)} style={{...{fontSize: 14 ,cursor: 'pointer'}, ...style}} className={'mouseOverText'}> - <Glyphicon glyph="map-marker" /> {text}</span>)
    }else{
      return null
    }
  }


  gotoMap(lat, lng, e){
    e.stopPropagation();
    browserHistory.push({pathname: '/map',search: '?lat='+lat+'&lng='+lng}) 
  }

  getPuffUrl(article){
    var url = config.server[window.env].url + "puff/"+article.id.split("/")[1]
    return url
  }

  isMobile(){
     return window.innerWidth<800
  }

  wrapTT(tt, child,keyId){
    const ttDom = (<Tooltip id="tooltip"><strong>{tt}</strong></Tooltip>);
    if(this.isMobile()){
      return child
    }else{
      return (<OverlayTrigger key={keyId} placement="bottom" overlay={ttDom}>
              {child}
              </OverlayTrigger>)
    }
  }

}

const mapStateToProps = function(store) {
  return {
    selectedPuff: store.puff.selectedPuff,
    user:store.auth.user,
    feeds: store.feeds,
    selectedView: store.feeds.findIndex(f=>f.id === store.view.selectedView)
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setFocusedPuff: (id)=>dispatch(setFocusedPuff(id))
  }
}

const style = {
	wrapper:{
		marginTop:30, 
    overflowX:'hidden',
    background:'white'
	},
	puff:{
	    paddingBottom:130,
      position: 'fixed',
      zIndex: 1000,
      top: 0,
      bottom: -100, //some phones take time to resize when removing the top bar
      borderRadius:0,
      overflowY: 'scroll', 
      webkitOverflowScrolling: 'touch',
      margin:0,
      width:'100%'
  }, 
  title:{
    fontSize: '30px',
    marginBottom: '5px',
    marginTop:'0px',
    fontWeight: 600,
    fontFamily: "'Lora', serif"// "'Cabin', sans-serif" 
  },
  byline:{
    color: 'grey',
    fontStyle: 'italic'
  },
  article:{
    marginTop:'10px',
    marginBottom:'10px',
    fontSize: '12pt',
    fontFamily: "'Open Sans', sans-serif", // "'Open Sans', sans-serif"
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap'
  },
  content:{
    padding: 30,
    paddingBottom: 0,
    width:'60%',
    float:'left'
  },
  padded:{
    paddingLeft:30,
    paddingRight:30
  },
  timestamp:{
    fontSize: '14px',
    color: '#999'
  },
  button:{
    marginRight: '5px',
    padding: '10px',
    borderRadius: '20px',
    color: '#777777'
  },
  round:{
    width:'34px',
    height:'34px',
    textAlign: 'center',
    borderRadius: '50%',
    backgroundColor:'transparent',
    fontSize: 'larger',
    padding:8
  },
  popularity:{
    backgroundColor:'#d83d38',
    color:'white',
    padding: 8,
    borderRadius: '50%',
    color: 'rgb(119, 119, 119)',
    height: 34,
    textAlign: 'center',
    backgroundColor: 'transparent',
    fontSize: 'larger',
  },
  row:{
    margin:0,
    padding: 0,
    maxWidth: 460,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  buttonLined:{
    cursor: 'pointer',
    marginRight: '5px',
    borderRadius: '20px',
    padding:9,
    borderStyle: 'solid',
    borderWidth: 1,
    height:34,
    fontSize:'small',
    marginTop: 5
  },
  categoryContainer:{
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    display: 'table',
    marginTop:20
  },
  close:{
    position: 'fixed',
    right: 15,
    top: 15,
    fontSize: 'x-large',
    color: 'lightgrey',
    zIndex: 100,
    width: 45,
    height: 45,
    width: 45,
    height: 45,
    textAlign: 'center',
    borderRadius: '50%',
    padding: 9
  }
}

export default connect(mapStateToProps)(MobileArticle);

