import React from 'react';
import T from 'i18n-react';
import {browserHistory} from 'react-router';
import {connect} from 'react-redux';
import store from '../store';
import {setTabs} from '../actions/feed';
import Settings from "./page/settings.jsx";
import Onboarding from "./modal/onboarding.jsx";
import Feed from'./feed.jsx';

var config = require('../../settings/config.json');


const s =  {
  title:{
      color: 'black',
      fontSize: '50px',
      marginBottom: '5px',
      fontWeight: 'bold',
      fontFamily: "'PT Sans Narrow', sans-serif" 
    },
    article:{
      padding: '30px',
      color:'#444444',
      paddingTop: 0,
      fontSize: '12pt',
      fontFamily: "'Open Sans', sans-serif" // "'Open Sans', sans-serif"
    }
}


class Profile extends React.Component {

  constructor(props) {
    super(props);

  }

  componentDidMount(){
    if(window.location.pathname==="/login"){
      browserHistory.push({pathname: '/'+"profile"})
    }
  }

  render () {
    var self = this  
    const {feed, user, mobile, lFormatter, myFeeds} = this.props
    // If you're not logged in anymore, then set the tabs to the default ones
    if(!user.email){
      this.props.setTabs(window.defaultFeeds)
    }


    let page = feed.menu.find(m=>m.selected && m.type==='page')
    let selectedProject = this.props.location && this.props.location.query && this.props.location.query.project ? feed.menu.find(m=>m.type==='page' && m.value===this.props.location.query.project) : null
  

    let hasNoGeneratedArticles = feed.puffs.length===0 && !feed.isFetching

    let hasFeeds = this.props.allFeeds.filter(f=>!['profile', 'search','en','login'].includes(f.id)).length>0

    let currentPage = page ? page.value : ''
   
  

    switch(currentPage) {
      case '':
        return (hasFeeds ? myFeeds : <Onboarding project={selectedProject} mobile={mobile} user={user} firstname={user.firstname}>{user.curator ? myFeeds : null}</Onboarding> )
      case 'new':
        return <Onboarding project={selectedProject} mobile={mobile} newFeed={true} user={user}></Onboarding>
      case 'settings':
        return <div>
          <Settings/>
        </div> 
      default:
        return (hasFeeds ? myFeeds : <Onboarding project={selectedProject} mobile={mobile} user={user} firstname={user.firstname}>{user.curator ? myFeeds : null}</Onboarding> )
        
    }
  }
}
 // <img style={{margin:'15px'}} src="img/semsomi.png"/>

const mapStateToProps = (state)=>{
  return {
    allFeeds: state.feeds,
    user:state.auth.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setTabs:(feed)=>dispatch(setTabs(feed, undefined))
  }
}

// export default Login;
export default connect(mapStateToProps, mapDispatchToProps)(Profile)
