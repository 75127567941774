import React from 'react';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';

class TT extends React.Component {

      
    constructor(props) {
        super(props);
    }  

	  isMobile(){
	     return window.innerWidth<600
	  }

    render () {
	    if(this.isMobile() || !this.props.text){
	      return this.props.children
	    }else{
 	    	let ttDom = (<Tooltip id="tooltip"><strong>{this.props.text}</strong></Tooltip>);
	      return (<OverlayTrigger placement="bottom" overlay={ttDom}>
	              {this.props.children}
	              </OverlayTrigger>)
	    }
    }
}



export default TT;