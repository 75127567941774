import React from 'react';

const style = {
  title:{overflow: 'hidden',textShadow:'rgb(77 77 77 / 80%) 0px 0px',fontWeight: 'bold', fontSize: '11pt', letterSpacing: '-0.4pt', lineHeight: '15px'},
	start:{marginRight: 0, borderRadius:'10px 0px 0px 10px'},
	middle:{marginRight: 0, marginLeft: 0, borderRadius:'0px'},
	end:{marginLeft: 0, borderRadius:'0px 10px 10px 0px'},
  talkbubble:{
    padding: "3px 9px",
    borderRadius: 5,
    fontSize: "11px",
    margin: "9px 0px 0px 5px",
    boxShadow: "rgb(0 0 0) 0px 1px 7px -7px"
  },
  avatar:{
    fontSize: "19px",
    margin: "2px 0px",
    borderRadius: "50%",
    overflow: "hidden",
    background: "#fbb",
    padding: "7px 4px 0px"
  }
}

class Mini extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        loaded:props.loaded, 
      //   plugins:props.plugins ? props.plugins : [
      //     {title:"Unique Title & Text Summary", type:'articlegeneration', price:2, enabled:true, description:"Generate multiple unique texts and headlines for this story using Artificial Intelligence"},
      //     {title:"Relevant photos", type:'photo', price:0.5, enabled:true, description:"Retrieve up to 10 relevant photos for this story"},
      //     {title:"Relevant Social Media Comments", type:'socialmediacomments', price:0.5, enabled:false, description:"Retrieve comments from Twitter and Reddit about this story"},
      //     {title:"Human written", type:'humanedit', price:2.5, enabled:false, description:"Task a human editor with writing a unique, reviewed version of this story"}
      // ]
    }
  }  

  componentDidMount(){
    !this.state.loaded ? this.setState({loaded:true}) : null
  }



  miniArticle(article, isMobile, confirmed){
    let {loaded} = this.state
    let {plugins, right, example} = this.props
    // console.log(plugins)
    let load = !this.props.stop && loaded

    let pluginActive = (type)=>plugins ? (load && plugins.find(p=>p.enabled && p.type===type)) : true
    let loremipsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." 
    let photos = pluginActive('photo')
    let comments = pluginActive('socialmediacomments')
    let articletext = pluginActive('articlegeneration')
    let interlinking = pluginActive('interlinking') || pluginActive('links')
    let mediaembeds = pluginActive('mediaembeds')
    let videoembeds = pluginActive('videoembeds')
    let takeaways = pluginActive('takeaways')
    let takeaway = takeaways && article.article ? article.article.split('\n').find(t=>t.includes('takeaways"')) : null

    if(takeaway){
      try{
        takeaway = JSON.parse(takeaway)
        // console.log(takeaway)
      }catch(e){

      }
    }
    let text = article.article && article.article.length>10 ? article.article.split('\n').filter(s=>!s.startsWith('{'))
                                                                              .map(s=>s.replace(/\[(.*?)\]\((.*?)\)/g,'$1')
                                                                                        .replace(/^#+|\*+|_*/g,'')
                                                                                        .trim())
                                                                              .join('\n') : loremipsum
    let ready = (confirmed || !load || this.props.loaded===false)
    let linkWords = text.slice(0,200).split(' ').sort((a,b)=>b.length-a.length)
    return <div className={'showcaseArticle showcase-'+(right ? 'right' : 'left') +""} 
                style={{opacity: load ? 1 : 0, 
                        textAlign: 'initial',
                        margin: confirmed ? 'auto 30%' : 0, 
                        boxShadow: ready ? '0px 0px 1px 0px #cfd9e1, -16px 41px 60px -12px #70869ab8, inset 0px 0px 3px 0px #cfd9e1' : undefined, 
                        transform: ready ? 'perspective(690px) rotateY(0deg) rotateX(0deg)' : undefined, ...(this.props.style ? this.props.style : {})}}>
                        {this.props.logo ? <img src={this.props.logo} style={{
                          width: 98,
                          position: 'absolute',
                          margin: '0px auto',
                          left: 0,
                          right: 0}}/> : null}
            { <div className="animate featuredShowcaseArticle" 
                  style={{backgroundImage:'linear-gradient(rgb(245 246 252 / 28%), rgb(239 245 252)), '+(article.thumbnail ? 'url("'+article.thumbnail+'")' : '#000000f2'),
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          opacity:photos ? 1 : 0,
                          height:photos ? 110 : 60}}></div>}
            <p style={{...style.title, marginTop:(article.thumbnail ? -30 : 0)}}>{article.title}</p>
            
            {article.videos ? article.videos.sort((a,b)=>{
              return a.thumbnail ? -1 : (b.thumbnail ? 1 : 0)
            }).concat({thumbnail:null}).slice(0,1).map((video,i)=>{

              return<div key={'miniVideo'+i} className="animate miniVideo" style={{
                        height: load && videoembeds ? 75 : 0,
                        background: video.thumbnail ? `linear-gradient(155deg, #00000047, #1454863b), url("${video.thumbnail}") center center / cover` : 'linear-gradient(155deg, black, #145486)',
                        borderRadius: 6,
                        overflow:'hidden',
                        margin: '5px 20px',
                        width: 170
                    }}>
                      <i className="fa fa-play-circle" aria-hidden="true" style={{
                          color: 'white',
                          width: '100%',
                          textAlign: 'center',
                          fontSize: '45px',
                          height: '100%',
                          lineHeight: '75px'
                      }}></i>
              </div>}): null}

            {true ? null : <div style={{textAlign:'center'}}><span style={{color:'#00000036', fontSize:'17pt'}} className="fa fa-spinner fa-pulse"/></div>}
            {<div className="animate" style={{overflow:'hidden', padding:takeaways ? 7 : 0, height:takeaways ? 70 : 0, fontSize: '10px', fontWeight: 'bold', background: 'rgb(33 150 243 / 9%)',borderRadius: 10}}>
              <p style={{margin:0}}>Key Takeaways</p>
              {(takeaway ? takeaway.points.slice(0,3) : ['This article talks about','The important part is that', 'Finally consider the implication']).map(point=><p style={{margin:0, fontWeight:'normal'}}>• {point.slice(0,35)}...</p>)}
              
            </div>}
            {<p className="animate textInShowcaseArticles" style={{height:articletext ? 100 : 0}}>{interlinking ? text.split(' ').map((word, i, arr)=>linkWords.indexOf(word)<4 ?  <a style={{textDecoration: 'underline'}}>{word}&nbsp;</a> :  <span>{word}&nbsp;</span>) : text}</p>}
            {article.widgets ? article.widgets.filter(widget=>['twitter', 'instagram'].includes(widget.type)).sort((a,b)=>{
              return a.data &&  a.data[0] && a.data[0].text ? -1 : (b.data && b.data[0] && b.data[0].text ? 1 : 0)
            }).concat(example ? [{type:'twitter'}] : []).slice(0,1).map(widget=>{
              return <div className="animate miniTweet" style={{maxHeight:mediaembeds ? 200 : 0, margin:widget.type==='instagram' ? '0px 40px' : '0px 25px', padding: mediaembeds ? 10 : 0, borderRadius: 5}}>
                 {widget.type==='twitter' ? <div style={{gridTemplateColumns:'25px auto 25px'}}>
                   <div style={{overflow:'hidden', textAlign:'center', width: 25, height: 25, borderRadius: '50%', background: '#b6cad9', float: 'left', marginRight: 5}}>
                   <i className="glyphicon glyphicon-user" style={{fontSize: '19px', marginTop: 5}}></i>
                   </div><span style={{fontWeight: 'bold'}}>{widget.data && widget.data[0] && widget.data[0].name!=='unknown' ? widget.data[0].name : (widget.username ? widget.username : 'username')} </span>
                   <img className="logo" src="/static/img/integrations/xlogo.png" style={{width:14, float:'right'}}/>
                 </div> : null}
                 {widget.type==='instagram' ?
                  <div style={{width:'100%', height:60, background:'url("https://www.gizmochina.com/wp-content/uploads/2020/10/Instagram-Logo.png") center / cover'}}></div> :
                   <p className="miniTweetText">{widget.data && widget.data[0] ? widget.data[0].text : "A relevant tweet"}</p>
                 }
                 {widget.type==='instagram' ? <div style={{margin: '1px 0px -9px', fontSize: '13px'}}><i className="fa fa-heart-o" aria-hidden="true"></i><i className="fa fa-comment-o" style={{marginLeft:5}} aria-hidden="true"></i></div>: null}
                 </div>
            }) : null}
            <div className="animate" style={{display:'flex', height:comments ? 30 : 0, overflow:'hidden'}}>
                          <i className="glyphicon glyphicon-user" style={style.avatar}></i>
                          <p className="mini-form" style={style.talkbubble}>Relevant comment about this</p>
            </div>
          </div>
  }

  render () {
  	let {article, confirmed} = this.props
  	return this.miniArticle(article, false, confirmed)

  }
}

export default Mini;