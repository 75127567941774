var React = require('react');
import canvid from 'canvid';

  const style = {
    allowButton:{
      position: 'absolute',
      top: 0,
      right: 0,
      margin: 10,
      padding: '10px 15px',
      borderRadius: 28,
      border: '3px solid #ffffffde',
      color: '#fffffff0',
      fontSize: 17,
      fontWeight: 600,
      backgroundColor: '#000000b5'
    },
    phoneIcon:{
      fontSize: 'xx-large',
      verticalAlign: 'middle',
      marginRight: 5,
      // transform: 'perspective(22px) rotateY(-10deg) rotateX(0deg)'
    }
  }

  const tiltWindow = 40

class DepthPhoto extends React.Component {


 	constructor(props){
    super(props);
    this.handleOrientation = this.handleOrientation.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.calcTracker = this.calcTracker.bind(this)
    this.updateFrame = this.updateFrame.bind(this)
    this.askForPermission = this.askForPermission.bind(this)
    let requestPermission = typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function'
    this.state={tracker: 90, top:null, allowButton:requestPermission}
    
    if (window.DeviceOrientationEvent || !requestPermission) {
      window.addEventListener("deviceorientation", this.handleOrientation);
    }
    window.addEventListener("parentScroll", this.handleScroll);
    // console.log(this.testDeviceOrientation())
  }

  testDeviceOrientation() {
    if (typeof DeviceOrientationEvent !== 'function') {
      return 'DeviceOrientationEvent not detected'
    }
    if (typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission !== 'function') {
      return 'DeviceOrientationEvent.requestPermission not detected'
    }
  }


  askForPermission() {
    // console.log(DeviceOrientationEvent)
    // feature detect
    if (DeviceOrientationEvent && typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function') {
      DeviceOrientationEvent.requestPermission()
        .then(permissionState => {
          if (permissionState === 'granted') {
            window.addEventListener('deviceorientation', this.handleOrientation);
            this.setState({allowButton:false})
          }
        })
        .catch(console.error);
    } else {
    //   // handle regular non iOS 13+ devices
    }
  }

  calcTracker(diff){
      let newTracker = this.state.tracker + diff
      return newTracker < 0 ? 0 : (newTracker > 100 ? 100 : newTracker)
  }

  handleOrientation(event) {



    let resolution = 5
    let tilt = event.beta+180

    if(this.state.tilt){
      let diff = (tilt-this.state.tilt)*resolution
      // console.log('DIFF: '+diff)
      if(Math.abs(tilt-this.state.tilt)>180){
        diff = 0
      }

      let newTracker = this.calcTracker(diff)
      
      let newState = {tilt:tilt, tracker:newTracker}
      if(this.state.allowButton){
        newState.allowButton=false
      }

      this.setState(newState)
      
      if(diff!==0){
        this.updateFrame() 
      }
    }else{
      let newState = {tilt:tilt}
      if(this.state.allowButton){
        newState.allowButton=false
      }
      this.setState(newState)
    }

 
  }

  handleScroll(event){
    let resolution = 0.4
    let container = this.vidContainer.getClientRects()[0]
    if(this.state.top){
      let diff = (container.top-this.state.top)*resolution
      let newTracker = this.calcTracker(diff)
      this.setState({top:container.top, tracker:newTracker})
      if(diff!==0){
        this.updateFrame()
      }
    }else{
      this.setState({top:container.top})
    }
    
    // this.setState()
  }

  componentDidMount(){
    let {url, width, height} = this.props
    this.frames = url.indexOf('indianapolis1')>-1 ? 41 : 44
    this.canvidControl = canvid({
    selector: '.canvid-container',
    videos: {
      clip1: {
        src: url,
        frames: this.frames,
        cols: 6
      }
    },
    width: width ? width : 600,
    height: height ? height : 338,
    loaded: (canvidControl) =>{
      canvidControl.play('clip1')
      canvidControl.pause();
      this.updateFrame()
    }
    });
    
  }

  componentWillUnmount(){
     window.removeEventListener("deviceorientation", this.handleOrientation)
     window.removeEventListener("parentScroll", this.handleScroll)
  }


 updateFrame(){
    // if(this.canvidControl && this.canvidControl.setCurrentFrame){
      let targetFrame = Math.round(this.frames*this.state.tracker/100)
      this.canvidControl.setCurrentFrame(targetFrame);
    // }
  }






  render(){
    let {url} = this.props
    // mkdir frames
    // ffmpeg -i myvideo.mp4 -vf scale=375:-1 -r 5 frames/%04d.png
    // Then, use ImageMagicks montage to stich all the frames into one big image:
    // montage -border 0 -geometry 375x -tile 6x -quality 60% frames/*.png myvideo.jpg
    return <div style={{...{width:'100%',position: 'relative'},...(this.props.style ? this.props.style : {})}} ><div ref={(component)=>{this.vidContainer = component}} className={"canvid-container" + (this.props.roundedCorners ? ' roundedCorners' : '')}></div>
            {this.state.allowButton  ? <div onClick={this.askForPermission} style={style.allowButton}>
              <span className="fa fa-mobile swingimage" aria-hidden="true" style={style.phoneIcon}/>Allow Tilt
            </div> : null}
            {this.props.children}
          </div>
  
  }
}

module.exports = DepthPhoto;