import React from 'react';
import Config from '../feed/config.jsx';
import Flip from './flip.jsx';
import Creditcards from '../billing/creditcards.jsx';
import Cost from '../billing/cost.jsx';
import { connect } from 'react-redux';
import {saveProfile} from '../../actions/auth';
import {browserHistory} from 'react-router';
import TT from './tt.jsx';
import Spinner from './spinner.jsx';
import Mini from './mini.jsx';
import {get} from '../../apiFunctions';
import {setting} from '../../util.js';
import Pie from '../modal/pie.jsx';
import moment from 'moment';

const style={
	container:{
	    maxWidth: 1270,
	    // minWidth: 1040,
	    position:'relative',
	    margin: '0px auto',
	    borderRadius: 30,
	    display: 'flex',
	    // minHeight:700,
	    textAlign: 'center',
	    // overflow:'hidden'
	    width: '100%',
	    // boxShadow:'rgb(0 0 0 / 11%) 0px 88px 67px',
	    // background:'linear-gradient(45deg, #4A00E0, #8E2DE2)', 
	    // color:'white'
	},
	queryContainer:{
			maxWidth: 600,
		    boxShadow: 'inset 0px 2px 10px -1px #00000045',
		    margin: '40px auto 10px',
		    borderRadius: 24,
		    padding: '10px 15px'
	},
	title:{
		fontSize:'24pt',
		margin: '30px auto',
		maxWidth:500
	},
	subtitle:{
		fontSize: 'clamp(10pt,4vw,15pt)'
	},
	numberInput:{
		width: 60,
    	textAlign: 'right',
    	marginRight:7
	},
	icon:{
		width:100
	},

	dot:{
		margin:'0px 10px',
		width:10,
		height:10,
		backgroundColor:'#c7c7c7',
		borderRadius:10,
		display: 'inline-block',
		border: 'solid 2px white'
	},
	selectedDot:{
		border:'initial',
		backgroundColor:'#00BCD4'
	},
	buttons:{
		display: 'inline-block',
    	width: '100%',
    	paddingTop:50
	},
	button:{
		lineHeight:'30px',
		ontSize: 'large', 
		borderRadius:30, 
		padding:'10px 30px',
		margin:0, 
    	marginTop: 68,
		boxShadow:'none'},
	spinner:{
	    marginLeft: -66,
    	background: 'white',
    	padding: '0px 15px'
	},
	dropdownButton:{
		border:'none',
		padding:'10px 20px',
		margin:'15px 0px 0px',
		background:'white',
		fontWeight:'bold',
		color: '#777',
		boxShadow: '0px 1px 3px #0000003d'
	}
}

let refreshTime = null

class Onboarding extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        	hasBilling:this.props.user && this.props.user.apikey,
        	page:0,
        	calendar:false,
        	agree:this.props.user && this.props.user.agree,
        	search:'',
        	feed:{
        		focus:true,
        		new:true,
        		id:Math.random().toString(36).substring(7),
		        title: "My First Feed",
		        enabled: true,
	        	publications:[],
	        	onSubmit:this.props.user && this.props.user.feeds && this.props.user.feeds.length===0 && this.props.user.email ? [{
            		url: this.props.user.email,
            		type: 'email'
        		}] : [],
	        	articleGeneration:[
	        		{value:'curate:2'},
	        		{value:"order:1"},
	        		{value:"nrOfWords:300"},
	        		{value:"typeOfContent:mixed"},
	        		{value:"frequency:weekly"}
	        		],
	        	plugins:[
	        			{type:'articlegeneration'},
	        			{type:'photo'},
	        			{type:'videoembeds'},
	        			{type:'mediaembeds'},
	        			{type:'interlinking'},
	        			{type:'onSubmit'}
	        	],
	        	teams:[
                'monok',
                'phoria',
                'hub',
                'whale'
            	],
	        	categoryFilter:[{categories:[]}]
        	}
    	}


    	// this.search = this.search.bind(this);
        // this.searchPage = this.searchPage.bind(this);
    	this.writer = this.writer.bind(this);
    	this.subscription = this.subscription.bind(this);
    	// this.topics = this.topics.bind(this);
    	this.amount = this.amount.bind(this);
    	// this.done = this.done.bind(this);
    	this.billing = this.billing.bind(this);

    	this.amountSlider = "amountSlider"
    }

    normalizeUrl(url) {
	  try {
	    // If the URL doesn't start with a protocol, add "http://" by default
	    if (!/^https?:\/\//i.test(url)) {
	      url = 'https://' + url;
	    }

	    // Parse the URL
	    const parsedUrl = new URL(url);

	    // Normalize protocol to lowercase
	    parsedUrl.protocol = parsedUrl.protocol.toLowerCase();

	    // Normalize hostname to lowercase
	    parsedUrl.hostname = parsedUrl.hostname.toLowerCase();

	    // Remove the default port if present (e.g., 80 for HTTP, 443 for HTTPS)
	    if (
	      (parsedUrl.protocol === "http:" && parsedUrl.port === "80") ||
	      (parsedUrl.protocol === "https:" && parsedUrl.port === "443")
	    ) {
	      parsedUrl.port = "";
	    }

	    // Remove trailing slash from pathname if it's not the root
	    if (parsedUrl.pathname !== "/") {
	      parsedUrl.pathname = parsedUrl.pathname.replace(/\/+$/, "");
	    }

	    // Sort query parameters (optional, but often useful for normalization)
	    parsedUrl.searchParams.sort();

	    return parsedUrl.toString();
	  } catch (error) {
	    console.error("Invalid URL:", error);
	    return null;
	  }
	}

    setQuery(q){
    	this.setState({feed:{...this.state.feed,...{title:q[0],categoryFilter:[{categories:q}]}}})
    }

    setSearch(q){

  
    	let isWebsite = (q)=>{
	    	try{
	    		return !q.trim().includes(' ') && /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi.test(q.trim())
	    	}catch(e){
	    		return false
	    	}
    	}

    	let isWeb = isWebsite(q)

    	this.setState({
    		search:q, 
    		searchIsWebsite:isWeb,
    		websiteLoaded:false,
    		lastWebsiteUrl: isWeb ? this.normalizeUrl(q) : this.state.lastWebsiteUrl,
    		feed:{
    			...this.state.feed,
    			...{
    				title:q.split(',')[0].trim(),
    				categoryFilter:[{categories:q.split(',').map(c=>c.replace(/\s+/,' '))}]
    			}
    		}}, 

    		()=>{
    			clearTimeout(refreshTime);
		      	refreshTime = setTimeout(()=>{
		      		if(q.trim().length>0){
	        			this.fetchFull(isWeb ? this.normalizeUrl(q) : this.state.feed.categoryFilter[0].categories.join(', '))
		      		}
		    	}, isWeb ? 2000 : 1000);
    		})
    }

    sendSearch(){
    	// if(this.state.searchIsWebsite && !this.state.loading){
    	// 	clearTimeout(refreshTime);
    	// 	this.fetchFull(this.state.lastWebsiteUrl)
    	// }
    }

    // search(query){
    // 	browserHistory.push({pathname: '/search', query:{q:query, feed:'store', view:'masonry'}})
    // }

    next(page){
    	this.setState({page:this.state.page+(page ? page : 1), calendar:page<0 ? false : this.state.calendar})
    }

    createFeed(){

    	let newFeed = {...this.state.feed}

    	// let newUserProjects = null

    	if(this.props.project){
    		// let id  = this.props.project.value
    		// if(id==='newproject'){
    		// 	const myArray = new Uint32Array(1);
    		// 	let newId = crypto.getRandomValues(myArray)[0].toString()
      //           newUserProjects = this.props.user.projects ? [{title:this.props.project.title, id:newId}].concat(this.props.user.projects) : [{title:this.props.project.title, id:newId}]
    		// 	console.log(newUserProjects)
    		// }else{
    			newFeed.project=this.props.project.value
    		// }
    	}

    	if(this.state.searchIsWebsite){
    		if(this.state.target){
    			if(this.state.target.wp){

	    			let newTarget = {
	                    url: this.state.lastWebsiteUrl.replace(/\/$/,'').trim(),
	                    type: 'wp',
	                    photoQuality:'high image quality'
	                }

	    			newFeed.onSubmit = newFeed.onSubmit ? [newTarget].concat(newFeed.onSubmit) : [newTarget]


    			}

    			if(this.state.target.predictedCategory && this.state.target.predictedCategory.length>0){
    				newFeed.categoryFilter = [{categories:this.state.target.predictedCategory}]
    			}
    		}
    	}

    	this.props.saveProfile({feeds:{...newFeed}})//, projects:newUserProjects ? newUserProjects : undefined

      	// this.props.saveProfile({feeds:{...this.state.feed}})
    }

  	subscription(index, last){

    	// let valid = this.state.search.length>1
    	let {feed, search, agree} = this.state
    	let {newFeed, firstname, theme, mobile} = this.props
    	//www.myWebsite.com OR 
    	let valid = this.state.feed.categoryFilter[0].categories.join('').length>0
    	return <div key={'subscriptionPage'}>
    				
    				{!mobile && !theme ? <div 
	    								// className="animate"
	    								style={{
	    									pointerEvents:'none',
	    									transition: 'opacity 3s cubic-bezier(0.165, 0.84, 0.44, 1)',
	    								    position: 'absolute',
	    								    width: '100%',
	    								    height: '100%',
	    								    zIndex: '-10',
	    								    opacity: '0.5',
	    								    filter: 'blur(2px)',
	    								    borderRadius: '24px',
	    								    margin:'-6vw',
	    								    opacity: (this.state.websiteLoaded || (this.state.target)) && this.state.searchIsWebsite ? '50%' : '0%',
	    								    overflow: 'hidden',
	    			    					'-webkit-mask-image': '-webkit-gradient(linear, 100% -26%, 50% 0%, from(rgb(0 0 0)), to(rgb(0 0 0 / 0%)))',
	    			}}><iframe src={this.state.lastWebsiteUrl} 
	    				onLoad={()=>{
	    					this.setState({websiteLoaded:true})
	    				}} 
	    				onError={()=>{
	    					this.setState({websiteLoaded:false})
	    				}} 
	    				allowTransparency="true" 
	    				style={{    
	    				// width: '104%',
	    			    height: '200%',
	    			    backgroundColor:'transparent',
	    			    width:1500,
	    			    // height:1500,
	    			    position: 'absolute',
	    			transform:'perspective(660px) rotateY(0deg) rotateX(-1deg) scale(0.5) translateY(-50%) translateX(-50%)'}} ></iframe></div> : null}



    				<div style={{display:'flex',alignItems: 'center'}}>
    					<div style={{textAlign:'left', width:'100%'}}>
    						<h1 style={{fontSize:'clamp(10pt, 7vw, 24pt)'}}>{newFeed ? (theme ? (this.state.loading && this.state.searchIsWebsite ? "Analyzing your website..." : 'Discover your options') : (this.props.project ? `New Feed for ${this.props.project.title} 🎉` : 'New Feed Subscription 🎉')) : 'Welcome' + (firstname ? ' ' + firstname : null)+'! 👋'}</h1>
    						<h1 style={{fontSize:'clamp(30px,9vw,47px)', fontWeight:'bold', marginBottom:60, maxWidth:700}}>{this.state.searchIsWebsite && this.state.target && this.state.target.predictedCategory ? 
    							<span style={{whiteSpace: mobile ? 'normal' : 'nowrap'}}>Get articles for your website<br/>about <span style={{ display: 'inline-flex', overflow: 'hidden', height: 'clamp(30px,10vw,51.68px)'}}><span className="rotator">{this.state.target.predictedCategory.slice(0,5).concat(this.state.target.predictedCategory[0]).map(word=><span style={{whiteSpace: 'nowrap'}}>{word}</span>)}</span></span></span> : "Input Your Website or Keywords"}</h1>
		    				<form onSubmit={(e)=>{
		    					e.preventDefault()
		    					// valid ? this.fetchFull(this.state.search) : null
		    				}} style={{boxShadow: 'rgba(0, 0, 0, 0.62) 0px 2px 13px -6px', 
		    						display:'flex',
		    						color:'black',
		    						alignItems: 'center',
		    						marginBottom:20, 
		    						overflow:'hidden', 
		    						borderRadius:50, 
		    						height:'clamp(50px, 8vw, 70px)', 
		    						position:'relative', 
		    						maxWidth:700, 
		    						fontSize:'clamp(15px, 6vw, 30px)',
		    						minWidth:300}}>
			    				<input 	style={{ 
			    								left:0, width:'100%', 
			    								height:'100%', 
			    								borderRadius:50,
			    								outline:'none', 
			    								padding:'10px 20px 10px 20px'}} 
			    						// onChange={(e)=>this.setQuery(e.target.value.split(',').map(c=>c.replace(/\s+/,' ')))} 
			    						spellCheck="false"
			    						className="monokSearch"
			    						onChange={(e)=>this.setSearch(e.target.value)} 
			    						onKeyPress={(e)=>e.key === 'Enter' ? this.sendSearch() : null}
			    						value={search} 
			    						// value={this.state.search} 
			    						placeholder="type www.website.com or keywords"/>
			    						{this.state.loading ? <div style={style.spinner}><Spinner/></div> : null}
		    				</form>
		    				<p style={{fontSize:'large', marginLeft: 25}}>You can also type in topic keywords, example: {[
		    					{title:'Business',keys:'business, economy, money'},
		    					{title:'Movies',keys:'Movies, Trailer, Netflix'},
		    					{title:'US Sports',keys:'US Sports, MLB, NFL, NHL, NBA'}
		    				].map((ss,i,arr)=><span className="classic" style={{fontWeight:'bold'}} onClick={()=>this.setSearch(ss.keys)}>{ss.title}{i===arr.length-1 ? null : <span>, </span>}</span>)}
		    				</p>
		    			
    						{!agree ? <label for="horns" style={{padding: '30px 0px 10px', display:'block'}}>
			                  <input type="checkbox" id="terms" name="terms" checked={agree} onChange={(e)=>this.setState({agree: e.target.checked})}/>&nbsp;I have read and agree to the <a style={{color:'#8df2ff'}} rel="nofollow" href="https://docs.google.com/document/d/e/2PACX-1vQGjfs0yMg8DGs2Oad_cyadIeX05Di0yWq7SaiFWBCV_rY266ggiA-at6Bs8bG8yoGqjuCIkPt_9eSQ/pub" target="_blank">terms of service</a>
			                </label> : null}
    						<TT text={valid ? null : 'You must type in a search term to proceed'}>
			    					<span className={"button " + (valid > 0 ? 'greenButton' : 'blackButton')} style={style.button} 
			    					onClick={()=>valid ? this.next() : null}>CONTINUE</span>
			                	</TT>
    					</div>
    					{this.props.mobile ? null : <Mini logo={this.state.target && this.state.target.logo && this.state.target.commercial ? this.state.target.logo : null} 
    														example={true} 
    														right={true} 
    														loaded={true} 
    														article={this.state.article ? this.state.article : {title:'Loading...', article:'Please wait'}} 
			    											style={{
			    												opacity: !this.state.article || !valid || this.state.loading ? '0%' : '100%' ,
			    												zIndex: 1, 
			    												margin:'auto',
			    												marginRight:this.state.loading ? '-15%' : '0%', 
			    												bottom:'30%'}} plugins={null} confirmed={false}/>}
    				
    				
    				</div>
    	</div>
    }

    // searchPage(){
    // 	let valid = this.state.search.length>1
    // 	return <div style={{padding:60, borderRadius:30, background:'linear-gradient(45deg, #4A00E0, #8E2DE2)', color:'white'}}>
    // 				<h1 style={style.title}>Welcome{this.props.firstname ? ' ' + this.props.firstname : null}! 👋</h1>
    // 				<div style={{display:'flex'}}>
    // 					<div style={{textAlign:'left'}}>
    // 						<h1 style={{fontSize:'xxx-large', fontWeight:'bold'}}>Setup Daily Subscription</h1>
		  //   				<form onSubmit={(e)=>valid ? this.search(this.state.search) : e.preventDefault()} style={{boxShadow: 'rgba(0, 0, 0, 0.62) 0px 2px 13px -6px', margin:'15px auto', overflow:'hidden', borderRadius:30, height:50, position:'relative', maxWidth:500, minWidth:300}}>
			 //    				<input 	style={{color:'black',
			 //    								position:'absolute', 
			 //    								left:0, width:'100%', 
			 //    								height:'100%', 
			 //    								border:'none', 
			 //    								outline:'none', 
			 //    								padding:'10px 100px 10px 20px'}} 
			 //    						onChange={(e)=>this.setSearch(e.target.value)} 
			 //    						value={this.state.search} 
			 //    						placeholder="www.myWebsite.com OR Sports, Baseball, NFL"/>
		  //   				</form>
		  //   				<p>example: <a className="classic" onClick={()=>this.setSearch('business, economy & money')}>business</a>, 
		  //   							&nbsp;<a className="classic" onClick={()=>this.setSearch('movie trailer')}>movies</a>, 
		  //   							&nbsp;<a className="classic" onClick={()=>this.setSearch('golf')}>golf</a>
		  //   				</p>
    // 						<p style={style.subtitle}>Let's increase your traffic! Start by typing in your <b>website url</b> or search terms</p>
    // 						<TT text={valid ? null : 'You must type in a search term to proceed'}>
			 //    					<span className={"button " + (valid > 0 ? 'greenButton' : 'greyButton')} style={{lineHeight:'30px', fontSize: 'large', borderRadius:30, margin:0, boxShadow:'none'}} 
			 //    					onClick={()=>valid ? this.search(this.state.search) : null}>Search</span>
			 //                	</TT>
    // 					</div>
    // 					<Mini example={true} right={true} loaded={true} article={{title:'hello'}} style={{zIndex: 1, margin:'auto', bottom:'30%'}} plugins={[]} confirmed={false}/>
    // 				</div>
    // 				<div>
    // 				</div>
    // 				{this.props.children}
    // 	</div>
    // }

    fetchFull(terms){
   
    	this.setState({loading:true},()=>{
		    get('sample?q='+terms,(err, res)=>{
		    	
		  		if(res.body){
		        	this.setState({target:res.body.target, loading:false, article:{...res.body, title: res.body.title ? res.body.title : (terms ? 'Article about '+terms : 'Type in a keyword or two!')}},()=>{
		        		if(res.body.target){
		        			if(res.body.target.langCode && window.languages.find(lang=>lang.code===res.body.target.langCode )){
		        				//TODO: do this with a single function
		        				this.setValue(this.state.feed, 'articleGeneration', 'language', res.body.target.langCode, ()=>{
		        					if(res.body.target.commercial){
		        						this.setValue(this.state.feed, 'articleGeneration', 'typeOfContent', 'blog')
		        					}
		        				})
		        			}
		        		}
		        	})
		  		}else{
		  			this.setState({loading:false})
		  		}
		    })
    	})
  	}

    writer(index, last){
    	// this.props.user.cost

    	let pricing = this.props.user.pricing ? this.props.user.pricing : {300:2, 600:3.5, 800:4.5 ,1000:6, 1200:10}

    	let costs = {
    		0:{cost:pricing[300], title:'300 words'},
    		1:{cost:pricing[600], title:'600 words'},
    		2:{cost:pricing[1200], title:'1200 words'},
    		3:{cost:pricing[800], title:'800 words'},
    		4:{cost:pricing[1000], title:'1000 words'}
    	}

    	let monok = this.props.user.team==='monok'

    	let month = moment().format('YYYY-MM')
    	// let today = moment().day()
    	let pMonth = moment().subtract(1, 'months').format('YYYY-MM')
    	let thisMonthData = this.props.user.pay.find(p=>p.date===month)
    	let previousMonthData = this.props.user.pay.find(p=>p.date===pMonth)
    	let previousPayout = this.getEarnings(previousMonthData, monok, costs)
    	previousPayout = previousPayout ? previousPayout.reduce((a,b)=>a+b.value,0) : null
        return <div  style={{padding:30}}>
            <h1 style={style.title}>Welcome{this.props.firstname ? ' ' + this.props.firstname : null}!</h1>
            <p style={style.subtitle}>{this.props.user.curator ? "You're a curator" : "Hello Writer, head over to todays orders!"}</p>
            <p>Make sure to read the <a href="https://docs.google.com/document/d/1ZEpz1RgBhPUav0sBNL723Gfp7J68b_mY_6ddX3IkiMw" target="_blank">guidelines</a></p>
            <div   style={{display: 'inline-block', marginTop: 15}}><span className={"button greenButton"} style={style.button} onClick={()=>browserHistory.push({pathname: '/search', query:{feed:'orders', sort:'date', view:'masonry'}})}><span className="fa fa-pencil" aria-hidden="true"></span> Order Page</span></div>
        	{this.props.user.pay ? <div style={{width:'100%', marginTop:20, color:'black'}}>
	            <Pie data={this.getEarnings(thisMonthData, monok, costs)} currency={monok ? '€' : ''} subTitle={monok ? 'Earned' : 'Jobs Done'} placeholder='No jobs completed this month'/>
	        </div> : null}
	        {monok && previousPayout ? <p>{moment(pMonth+'-13').format('MMMM')} Earnings: €{previousPayout}</p> : null}
            {this.props.children}
        </div>
    }

    getEarnings(monthData, money, costs){
    	return monthData && monthData.values ? monthData.values.map((p,i)=>({...p, value:money && costs[p.order] ? p.value*costs[p.order].cost : p.value, name:costs[p.order] ? costs[p.order].title+':' : 'Articles Edited '+i})) : null
    }

    // topics(){
    // 	let valid = this.state.feed.categoryFilter[0].categories.length>0
    // 	return <div >
    // 				<img style={style.icon} src="/static/img/icons/news.png"/>
    // 				<h1 style={style.title}>Welcome{this.props.firstname ? ' ' + this.props.firstname : null}!</h1>
    // 				<p style={style.subtitle}>Let's generate some AI news articles. Start by choosing a topic</p>
    // 				<Config update={(newFeed)=>this.setState({feed:newFeed})} style={style.queryContainer} feed={this.state.feed} justQuery={true}/>
    // 				<p>example: <a className="classic" onClick={()=>this.setQuery(['business','economy','money'])}>business</a>, 
    // 							&nbsp;<a className="classic" onClick={()=>this.setQuery(['entertainment','movies','music'])}>entertainment</a>, 
    // 							&nbsp;<a className="classic" onClick={()=>this.setQuery(['sport*','golf','football','cricket','and','united kingdom','uk'])}>uk sport</a>
    // 				</p>
    // 				<div style={style.buttons}>
	   //  				<TT text={valid ? null : 'You must type in at least one category to proceed'}>
	   //  					<span className={"button " + (valid > 0 ? 'greenButton' : 'greyButton')} style={{float:'right', fontSize: 'large'}} onClick={()=>valid ? this.next() : null}>Next</span>
	   //              	</TT>
    //             	</div>
    // 			</div>
    // }

    setValue(feed, field, attribute, value, callback){
    	let property = {value:attribute+':'+value}
    	if(feed[field]){
    		feed[field] = [property].concat(feed[field]) 
    	}else{
    		feed[field] = [property]
    	}
    	feed[field] = feed[field].filter((prop, i)=>{
    		if(prop && prop.value){
    			if(prop.value.indexOf(':')>-1 && prop.value.split(':')[0]===attribute){
    				//Remove all other declarations of this propery from the list
    				return feed[field].findIndex(oProp=>oProp.value && oProp.value.split(':')[0]===attribute) === i
    			}else{
    				return true
    			}
    		}else{
    			return true
    		}
    	})
    	this.setState({feed:{...feed}}, callback ? callback : undefined)
    }

   	amount(index, last){
   		let feed = this.state.feed
   		let {user, mobile, theme} = this.props
   		let cards = user && user.apikey
   		let freqs = {daily:'daily', weekdays:'per weekday', weekends:'per weekend', weekly:'weekly', monthly:'monthly'}
	    let freq = feed ? setting(feed.articleGeneration, 'frequency', 'weekly') : 0
	    let prices = {0:0, 1:2.5, 2:5, 3:22.5, 4:12.5, 5:17.5}
	    let cost = 2.5//feed.plugins.reduce((a,b)=>a+(b.enabled ? b.price : 0),0.00)
   		let curate = feed ? setting(feed.articleGeneration, 'curate', 2) : 2
	    let order = feed ? setting(feed.articleGeneration, 'order', 1) : 0
	    let typeOfContent = feed ? setting(feed.articleGeneration, 'typeOfContent', 'mixed') : 'mixed'
	    let lang = setting(feed.articleGeneration, 'language', 'en')
	    let perMonth = {monthly:1, weekly:4, daily:30, weekdays:20, weekends:4}[freq]
   		// frequency = frequency ? (frequency.value.split(':')[1]==='daily' ? 0 : 1) : 0
   		let check = <i className="fa fa-check" aria-hidden="true" style={{color:'#7feebc'}}></i>

    	return <div key={'amountPage'} style={style.purple} >
    			<div style={{display: 'flex', alignItems: mobile ? 'flex-end' : 'flex-start', flexDirection:mobile ? 'column' : 'row',justifyContent: 'space-between'}}>
    			<div style={{width: mobile ? '100%' : undefined, textAlign:'left', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
    
    				<h1 style={{fontSize:'clamp(30px,9vw,47px)', fontWeight:'bold'}}>{cards ? "How many & how often?" : "First 2 articles for free"}</h1>
    				<p style={style.subtitle} id="onboard-subtitle">You can stop the subscription <b>at any time</b></p>
    				<div style={{display:'flex'}}>
	    					<input style={{...style.numberInput, width:curate>9 ? 60 : 40}} 
	    					onChange={(e)=>{
	    						let curAmount = parseInt(e.target.value)
	    						this.setValue(feed, 'articleGeneration','curate', (curAmount < 0 ? 1 : (curAmount>20 ? 20 : ((curAmount>0 && curAmount <21) ? curAmount : 1))))
	    					}} value={curate} className="plugin-title"  />
    					<h1 style={{fontSize:'clamp(30px, 6vw, 40px)'}}>Article{curate > 1 ? 's' : ''} {freqs[freq]}</h1>
    				</div>
    				<div style={{marginTop:15, maxWidth:400, width:'100%'}}>
	    				<TT text={'Drag to change how many articles you want '+freqs[freq]}>
		    				<input ref="amountSlider" type="range" className="styled-slider slider-progress" value={curate} min="1" max="20" id="articleAmount" onChange={(e)=>{
		    					let curAmount = parseInt(e.target.value)
		    					this.setValue(feed, 'articleGeneration','curate', (curAmount < 0 ? 1 : (curAmount>20 ? 20 : ((curAmount>0 && curAmount <21) ? curAmount : 1))))
		    				}}/>
	    				</TT>
    				<div style={{
    					width:(this.refs['amountSlider'] ? this.refs['amountSlider'].offsetWidth : 400)*curate/20-5, 
    					background:'#7feebc',
					    position: 'relative',
					    top: -25,
					    borderRadius: 20,
					    left: 5,
					    height:20,
					    pointerEvents: 'none'
					}}></div>
    				</div>
    				
    				<Flip dropdown={mobile}   
    					light={mobile} 
    					className={mobile && theme ? 'dropdown-container' : null}
    					style={mobile ? style.dropdownButton : {marginLeft:-10, color:'#000000d6', whiteSpace: 'nowrap'}} 
    					tooltip="How often Articles are written" 
    					value={Object.keys(freqs).indexOf(freq)} options={Object.keys(freqs).map(k=>freqs[k])} onChange={(index)=>{
    					this.setValue(feed, 'articleGeneration','frequency', Object.keys(freqs)[index])
    				}}/>
    				<div style={{alignItems: 'flex-end', marginLeft:0, display:'flex', gap:10}}>
	    				<Flip 	  
	    						light={true} 
	    						className={theme ? 'dropdown-container' : null}
    							style={style.dropdownButton}
	    						tooltip="Length of Articles"  
	    						value={{1:0, 2:1, 3:4, 4:2, 5:3}[order]} 
	    						dropOffset={15}
	    						dropdown={true} 
	    						options={['300 words', '600 words','800 words', '1000 words', '1200 words']} onChange={(index)=>{
	    					 this.setValue(feed, 'articleGeneration','order', [1,2,4,5,3][index])
	    					 this.setValue(feed, 'articleGeneration','nrOfWords', [300, 600, 800, 1000, 1200][index])
	    				}}/>
	    				<Flip tooltip="Language of Articles" 
	    						style={style.dropdownButton} 
	    						dropdown={true}  
	    						className={theme ? 'dropdown-container' : null}
	    						light={true}
	    						dropOffset={15}
	    						dropHeight={theme ? 180 : null}
	    						value={window.languages.map(lang=>lang.code).indexOf(lang)} options={window.languages.map(lang=>lang.title)} 
	    						onChange={(i, option)=>this.setValue(feed, 'articleGeneration','language', window.languages.map(lang=>lang.code)[i])}/>
	      	
    				</div>
    				<div style={{marginLeft:-10, display:'flex'}}>
    						<Flip style={{color:'#000000d6'}} tooltip="Type of content"  value={['news', 'blog','mixed'].indexOf(typeOfContent)} options={['news', 'blog','mixed']} onChange={(index)=>{
	    					 this.setValue(feed, 'articleGeneration','typeOfContent', ['news', 'blog','mixed'][index])
	    				}}/>
    				</div>
    			</div>
    			{mobile && theme ? null : <div style={{textAlign:'right', width:300}}>
    			    				<h1 style={{fontSize:'xxx-large', fontWeight:'bold'}}>€{prices[order]+cost}<span style={{fontSize:'xx-large', fontWeight:'normal'}}> / Article</span></h1>
    			    				<p style={{fontSize:'x-large'}}>Estimated cost of <b>{curate*perMonth} article{perMonth>1 ? 's' : ''}</b> per month is €{(prices[order]+cost)*curate*perMonth}</p>
    			    				<p>{check} Unique {['300', '600', '1200', '800', '1000'][order-1]} word articles</p>
    			    				<p>{check} Beautiful images for each article</p>
    								<p>{check} Embedded Video & Social Media</p>
    								<p>{check} Integration with Wordpress, LinkedIn, X etc</p>
    								<p>{check} Charged only per delivered Article</p>
    								<p>{check} Experienced writers curate and produce</p>
    								<img className="bigAvatar" src="/static/avatar/diogo.jpg"/>
    								<img className="bigAvatar" src="/static/avatar/simon.jpg"/>
    								<img className="bigAvatar" style={{marginRight:0}} src="/static/avatar/camille.jpg"/>
    			    			</div>}
    			</div>
					<div style={style.buttons}>
						<span className={"whiteButton"} style={{...style.button, float:'left'}} onClick={()=>this.next(-1)}>BACK</span>
						<span className={"button greenButton" + (last ? "" : "")} style={{...style.button, float:'right'}} onClick={()=>theme ? (window.top.location.href = "http://www.monok.com/login") : (last ? this.createFeed() : this.next())}>{last ? 'FINISH' : 'NEXT'}</span>
					</div>

    	</div>
    }



   	billing(index, last){
   		let user = this.props.user
   		let cards = user && user.apikey
   		let {hasBilling, calendar} = this.state

   		let buttons = <div style={style.buttons}>
	    				<span className={"whiteButton"} style={{...style.button, float:'left'}} onClick={()=>this.next(-1)}>BACK</span>
	    				
	    				{hasBilling ? <span className={"button greenButton"} style={{...style.button, float:'right'}} onClick={()=>{	
	    					this.createFeed()
	    				}}>Finish</span> : (calendar ? null : <span className={"button greenButton"} style={{...style.button, float:'right'}} onClick={()=>{	
	    					    					// this.setState({calendar:true})
	    					    					window.open('https://calendly.com/monok', "_blank", "noreferrer");
	    					    				}}>SKIP</span>)}
    				</div>
    	
    	return cards ? <div>
    				<h1 style={style.title}>Cost</h1>
    				{this.state.search.length>0 ? null : <div style={{fontSize: '15pt'}}>Estimated cost: <Cost feed={this.state.feed}/></div>}
    				{buttons}
    			</div> : <div>
    				<h1 style={{fontSize:'xxx-large', fontWeight:'bold'}}>You can pause anytime</h1>
    				<p>Sign up now and get <b style={{
    					background: '#7feebc',
					    padding: '7px 15px',
					    borderRadius: 30,
					    margin: '0px 5px',
					    fontSize: 'clamp(10pt,4vw,15pt)'
					}}>$10 USD</b> in free credit. You can stop the subscription at any time</p>
    				
    				<Creditcards 
    					onRefresh={(newBilling)=>{
	                        this.setState({hasBilling:newBilling})
	                    }}
                       	style={{padding:0, color:'black'}}
                    />

    				{buttons}
    				<p style={{fontSize:'small', fontWeight:'bold', marginTop:50}}>TRUSTED BY THESE BRANDS</p>
    				<p style={{padding:'0px -100px',filter:'brightness(0)'}}>
	                    <img style={{width:200, margin:20}} src="/static/img/customers/jakartadaily.png" />
	                    <img style={{width:220, margin:20}} src="/static/img/customers/catenamedia.png" />
	                    <img style={{width:139, margin:20}} src="/static/img/customers/xpress.png" />
	                    <img style={{width:139, margin:20}} src="/static/img/customers/grueling.png" />
	                    <img style={{width:211, margin:20}} src="/static/img/customers/sportswriters.png" />
	                    <img style={{width:225, margin:20}} src="/static/img/customers/fw.png" />
                  	</p>
    			</div>
    }

    // done(){
    // 	let created = this.props.feeds.find(f=>f.id === this.state.feed.id)
    // 	return  <div style={{position:'relative'}}>
	   //  			<div style={{visibility: created ? 'initial' : 'hidden'}}>
	   //  				<img style={style.icon} src="/static/img/icons/check-circle.gif"/>
	   //  				<h1 style={style.title}>Great Job, you've configured your first AI News Feed</h1>
	   //  				<span className={"button greenButton"} style={{fontSize: 'large'}} onClick={()=>browserHistory.push({pathname: '/'+this.state.feed.id}) }>Go to feed</span>
	   //  			</div> 
	   //  			{created ? null : <div style={style.spinner}><Spinner/></div>}
    // 			</div>
    // }

    render () {
  		let {theme} = this.props
    	let cards = this.props.user && !this.props.user.apikey && !(this.props.user.writer || this.props.user.curator)
    	let pages = [
            (this.props.user && (this.props.user.writer || this.props.user.curator) ? this.writer: this.subscription),
    		this.amount,
    		(cards ? this.billing : null),
    		// this.topics,
    		// this.done
    	].filter(p=>p)

	    return (<div className={theme ? 'theme-'+theme : undefined} style={{...this.props.style, ...style.container}}>
	    			
	              <div className="onboarding-frame" style={{padding:'6vw', width:'100%', paddingBottom:this.props.mobile ? 100 : '6vw'}}>{pages[this.state.page](this.state.page, this.state.page===pages.length-1)}</div>
	               {cards && this.state.calendar ? <div style={{borderRadius: '0px 30px 30px 0px', overflow: 'hidden', position: 'relative', minWidth: 355}}><iframe src="https://calendly.com/monok/demo?embed_domain=localhost%3A3000&embed_type=Inline&hide_event_type_details=1&hide_gdpr_banner=1" 
	               	               			style={{border:'none', minWidth:355, background:'white', height:'100%', margin:'auto 0px 0px auto'}} />
	               <div style={{position: 'absolute', 
	               						right: -53, 
	               						padding: '14px 42px 11px 41px', 
	               						top: 9, 
	               						color: 'white', 
	               						background: '#8000e1', 
	               						fontSize: 'medium', 
	               						transform: 'rotate(45deg)', 
	               						width: 185,
	               						height:59,
	               						boxShadow: '0px 3px 18px 0px #0000003b',
	               						fontWeight: 'bold',
	               						lineHeight: '15px',
    									textAlign: 'center'
	               					}}>Book a meeting?</div> 
	               					</div>: null}
	            </div>)	
	}
}


	              // {this.state.page!==pages.length-1 ? <div style={{paddingTop: 20}}>{pages.map((dots,i)=><div key={'dot-'+i} style={{...style.dot,...(i===this.state.page ? style.selectedDot : {})}}></div>)}</div> : null}

const mapStateToProps = function(store) {
  return {
    feeds: store.feeds
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile:(newProfile)=>dispatch(saveProfile(newProfile))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
