import React, { Component } from 'react';

class AnchorGroups extends Component {
  constructor(props) {
    super(props);
  }

  generateRandomId = () => {
    return Math.random().toString(36).substr(2, 5);
  };

  addAnchorGroup = () => {
    const newGroupIndex = this.props.anchors.length + 1;
    this.props.onChange([...this.props.anchors, { id: this.generateRandomId(), name: `Group ${newGroupIndex}`, anchors: [{ anchorText: '', anchorURL: '' }] }]);
  };

  deleteAnchorGroup = (groupIndex) => {
    const updatedAnchors = [...this.props.anchors];
    updatedAnchors.splice(groupIndex, 1);
    this.props.onChange(updatedAnchors);
  };

  addAnchor = (groupIndex) => {
    const updatedAnchors = [...this.props.anchors];
    updatedAnchors[groupIndex].anchors = [...updatedAnchors[groupIndex].anchors, { anchorText: '', anchorURL: '' }];
    this.props.onChange(updatedAnchors);
  };

  deleteAnchor = (groupIndex, anchorIndex) => {
    const updatedAnchors = [...this.props.anchors];
    updatedAnchors[groupIndex].anchors.splice(anchorIndex, 1);
    this.props.onChange(updatedAnchors);
  };

  handleInputChange = (groupIndex, anchorIndex, field, value) => {
    const updatedAnchors = [...this.props.anchors];
    updatedAnchors[groupIndex].anchors[anchorIndex] = {
      ...updatedAnchors[groupIndex].anchors[anchorIndex],
      [field]: value
    };
    this.props.onChange(updatedAnchors);
  };

  handleGroupNameChange = (groupIndex, value) => {
    const updatedAnchors = [...this.props.anchors];
    updatedAnchors[groupIndex] = {
      ...updatedAnchors[groupIndex],
      name: value
    };
    this.props.onChange(updatedAnchors);
  };

  render() {
    const { anchors } = this.props;

    return (
      <div>
        <div style={{ marginTop: 30 }}>
          {anchors.map((group, groupIndex) => (
            <div key={group.id} style={{ marginTop: 30, borderRadius: 15, marginBottom: 30, wordBreak: 'break-all', padding: 15, border: '1px solid #ccc' }}>
              <p className="orangeLabel">Group ID: {group.id}</p>
              <div style={{ marginBottom: 15 , display:'flex',alignItems: 'center'}}>
                <label style={{ marginRight: '10px' }}>Group Name</label>
                <input
                  type="text"
                  className="inputNormal"
                  value={group.name}
                  onChange={(e) => this.handleGroupNameChange(groupIndex, e.target.value)}
                  style={{ flex: 1, padding: '8px', marginRight: '20px' }}
                />
              </div>
              {group.anchors.map((anchor, anchorIndex, arr) => (
                <div key={anchorIndex} style={{ marginBottom: 15, display: 'flex', alignItems: 'center' }}>
                  <label style={{ marginRight: '10px' }}>Anchor Text</label>
                  <input
                    type="text"
                    className="inputNormal"
                    value={anchor.anchorText}
                    onChange={(e) => this.handleInputChange(groupIndex, anchorIndex, 'anchorText', e.target.value)}
                    style={{ flex: 1, padding: '8px', marginRight: '20px' }}
                  />
                  <label style={{ marginRight: '10px' }}>Anchor URL</label>
                  <input
                    type="text"
                    className="inputNormal"
                    value={anchor.anchorURL}
                    onChange={(e) => this.handleInputChange(groupIndex, anchorIndex, 'anchorURL', e.target.value)}
                    style={{ flex: 1, padding: '8px', marginRight: '20px' }}
                  />
                  {arr.length > 1 && (
                    <span onClick={() => this.deleteAnchor(groupIndex, anchorIndex)} className="fa fa-times blueDeleteButton" aria-hidden="true"></span>
                  )}
                </div>
              ))}
              <button className="button blackButton" onClick={() => this.addAnchor(groupIndex)}>Add Anchor</button>
              <button className="button" onClick={() => this.deleteAnchorGroup(groupIndex)} style={{ marginLeft: '10px' }}>Delete Anchor Group</button>
            </div>
          ))}
        </div>
        <button className="button blackButton" onClick={this.addAnchorGroup}>Create Anchor Group</button>
      </div>
    );
  }
}

export default AnchorGroups;
