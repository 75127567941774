import React from 'react';
import ReactDOM from 'react-dom';

class MonokText extends React.Component {

  constructor(props) {
      super(props);
	}
	overlay(text, start, duration, highlight=[], time, style, position, force, sentence={}, play){


  	let active = force || (time>=start && time<start+duration)
  
  	//optimization
  	if(!force && (time<(start-1) || time>(start+duration+1))){
  		return null
  	}

  	let emoji = [
	  	{file:'laugh',keywords:['laughable', 'laughing']},
	  	{file:'angry',keywords:['upset']},
	  	{file:'beer',keywords:['drinks']},
	  	{file:'burger',keywords:['hamburger']},
	  	{file:'curse',keywords:['fuck']},
	  	{file:'earth',keywords:['world']},
	  	{file:'gross',keywords:['disgusting']},
	  	{file:'happy',keywords:['joy', 'glad']},
	  	{file:'heart',keywords:['empathy']},
	  	{file:'kiss',keywords:['flirt']},
	  	{file:'love',keywords:['crush']},
	  	{file:'pizza',keywords:['dominos']},
	  	{file:'shit',keywords:['poop']},
	  	{file:'shock',keywords:['shocking','infuriating']},
	  	{file:'victory',keywords:['peace']},
  	]

  	if(position==='bottom'){
  		style = {...style,
				alignContent: 'flex-end',
				justifyContent: 'flex-start',
				lineHeight: '35px',
				fontSize: 'xx-large',
				backgroundImage:'linear-gradient(0deg, black 5%, transparent 50%)',
				backgroundPositionY: active ? 0 : 400
			}
  	}

  	let icon = {    
  		position: 'absolute',
    	width: '100%',
    	bottom: 0,
    	left: '0%'
    }

  	let styleWord = (word)=>{
  		let badge = {padding:'0px 10px', textShadow:'0 0 3px black', borderRadius:7, ...(active ? {} : {backgroundPositionX:-100})}

  		if(word.startsWith('@')){
  			return {color: '#03a9f4'}
  		}else if(word.includes('%')){
  			return {background:'linear-gradient(360deg, rgb(218 129 0), rgb(255 152 0)) no-repeat', ...badge}
  		}else if(!isNaN(word) && (word.toString().indexOf('.') !== -1 && word.indexOf('.')!==word.length-1)){
  			return {background:'linear-gradient(360deg, rgb(218 129 0), rgb(255 152 0)) no-repeat', ...badge}
  		}else if(highlight.includes(word.toLowerCase())){
  			return {background:'red', ...badge}
  		}

  		return {}
  	}

  	return 		<div className={(play ? "animate " : "") + "txtOverlay"} 
  									style={{top:0, 
														lineHeight:text.length<100 ? '49px' : '35px',
														fontSize: text.length<100 ? 'xxx-large' : 'xx-large', 
														backgroundPositionY: active ? 0 : 400,
														width: '100%',
														...(style ? style : {})}}>
														{sentence.badge ? <div style={{
															position: 'relative',
														    top: -38,
														    left: 5,
														    width: 0
															}}>
															<div style={{
														    background: '#FF9800',
														    display: 'inline-block',
														    padding: '0px 8px',
														    textShadow: 'none',
														    width:'max-content',
														    position:'absolute',
														    textTransform: 'uppercase',
														    fontSize: 'x-large',
															}}>{sentence.badge}</div>
															</div> : null}
	  						{text.split(' ').map((word,i)=>{
	  							let delay = i*50
	  							let emoticon = emoji.find(em=>[em.file].concat(em.keywords).includes(word.toLowerCase().replace(/[!"\?,\.]/g,'')))
	  							return <div className={(play ? "animate " : "") + "tag"} style={{
	  								position:'relative',
	  								transitionDelay:delay+'ms',
	  								...styleWord(word),
	  								...(active ? {opacity:1} : {opacity:0, transform:'translateY(40px) scale(0.7)'})
	  							}}>{word.startsWith('\n') ? <br/> : null}{word}
	  							{emoticon ?
	  								<img className="emojiIcon" src={"/static/img/assets/emoji/"+emoticon.file+".gif"} style={{...icon, animationDelay:delay+500+'ms', transitionDelay:delay+500+'ms',...(active ? {animation:'1.2s ease 3s 1 normal forwards running gelatine2', opacity:1} : {opacity:0})}}/>
	  							 : null}
	  							</div>
	  						})}
	  					</div>
  }


render () {
  	let {text, start, duration, highlight, time, style, position, force, sentence, play} = this.props
  	return this.overlay(text, start, duration, highlight, time, style, position, force, sentence, true)
  
  }
}

module.exports = MonokText;