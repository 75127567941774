import React from 'react';
import {refreshBilling, removeCard} from '../../apiFunctions';
import {ElementsConsumer, StripeProvider, Elements, CardElement} from 'react-stripe-elements';
import {loadStripe} from '@stripe/stripe-js';
import InjectedCheckoutForm from './checkoutForm';
import { ListGroupItem, ListGroup} from 'react-bootstrap';
import TT from '../modal/tt.jsx';
import Flip from '../modal/flip.jsx';
import Spinner from "../modal/spinner.jsx";
import {post} from '../../apiFunctions';

var config = require('../../../settings/config.json');

const style = {
  title:{
      color: 'black',
      fontSize: '50px',
      marginBottom: '30px',
      fontWeight: 'bold',
      fontFamily: "'PT Sans Narrow', sans-serif" 
  }
}

class Creditcards extends React.Component {

  constructor(props){
    super(props);
    this.refresh = this.refresh.bind(this);
    this.freeOfDebt = this.freeOfDebt.bind(this);
    if(!props.justList){
      this.refresh()
    }
    this.state = {change:{}, loading:false}
  }

  freeOfDebt(){
    return !this.props.bills || (this.props.bills && this.props.bills.length<1) || (this.state.billing && this.state.billing.length>1)
  }

  refresh(){
      this.setState({loading:true})


      refreshBilling((billing)=>{
        //not {billing} because null must overide
        let newBilling = !billing || billing.length===0 ? null : billing
        
        post('feedback', {text:'Credit card form'}).then(data=>{})

        if(newBilling){
          post('feedback', {text:newBilling}).then(data=>{})
        }

        this.setState({billing:newBilling, loading:false})
        this.props.onRefresh ? this.props.onRefresh(newBilling) : null
      })
  }

  removeThisCard(id){
    if(this.freeOfDebt()){
      this.setState({billing:this.state.billing.map(method=>method.id===id ? {...method, loading:true} : method)})
      removeCard(id, (res)=>{
        this.refresh()
      })
    }
  }

  paymentMethods(justList){
    return this.state.billing ? this.state.billing.map((card, i)=>
      <div key={'card-'+i} style={{width: '100%', height: 30, marginBottom: 15, marginTop: 15,display: 'inline-block'}}>
        <span className={card.type==='sepa_debit' ? "fa fa-bank" : "fa fa-cc-"+card.brand} style={{fontSize:'30px', float:'left'}} aria-hidden="true" />
        {justList ? null : (card.loading ? <Spinner style={{float: 'right', marginLeft: 15}}/> : <TT key={'rm'+i} text={this.freeOfDebt() ? 'Remove this payment method' : 'You have unpaid bills and can therefore not remove your last payment method yet. Pause all your feeds and await your last payment at the end of the month.'}>
                                    <div style={{float:'right', padding: 10, marginLeft: 10, cursor: 'pointer',borderRadius: 20}} className="badge mouseOverButton" onClick={()=>this.removeThisCard(card.id)}> Remove {card.type==='sepa_debit' ? "Direct Debit" : "Card"}</div>
                                  </TT>)}
        <p style={{float: 'left', lineHeight: '30px', height: 30, paddingLeft: 20, fontSize: 'large', letterSpacing: 3}}>&bull;&bull;&bull;&bull;{card.last4}</p>
        <p style={{float: 'right', lineHeight: '30px', height: 30, fontSize: 'large'}}>{card.type==='sepa_debit' ? 'country: '+card.country : card.exp_month + '/' + card.exp_year}</p>
      </div>
      ) : null
  }
  
  render() {
    let {small, justList, user, iban} = this.props
    if(justList){
      return this.paymentMethods(true)
    }

    return <StripeProvider apiKey={config.stripe[window.env]}>
        <div style={{...(small ? {padding:0} : {padding:'0px 15px',width:'100%'}),...(this.props.style ? this.props.style : {})}}>
           
            <ListGroupItem style={{...(small ? {padding:0, border:'none', backgroundColor:'transparent'} : {marginTop:30, borderRadius:15})}}>
            {this.paymentMethods()}
            {iban ? <Flip tooltip="Choose Payment Method" style={{display: 'flex',flexWrap: 'nowrap',margin:'10px 0px', fontWeight:'bold', maxHeight:'none', padding:2}} dropdown={false}  light={true}  value={this.state.paymentMethod} options={['Credit Card', 'Direct Debit']} onChange={(i, option)=>this.setState({paymentMethod:i})}/> : null}
            {((this.state.billing && this.state.billing.length > 0) && small) ? null :
              <Elements>
                <InjectedCheckoutForm user={user} method={this.state.paymentMethod} small={small} refresh={this.refresh}/>
              </Elements>}
            </ListGroupItem>
        </div>
        </StripeProvider>
  }

}

export default Creditcards