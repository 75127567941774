import React  from 'react';
import T from 'i18n-react';
const config = require('../../../settings/config.json')

class Comments extends React.Component {
    
    constructor(props){
        super(props);
        this.expand = this.expand.bind(this);
        this.showAll = this.showAll.bind(this);
        let open = props.open ? props.open : 0
        open = props.thumb ? 10 : props.open
        open = props.big ? 50 : props.open

        this.state = {visibleComments: open, expandedComments: {}}
    }

    addDefaultSrc(ev){
      ev.target.src = config.server[window.env].staticUrl+'img/missing.png'
    }

    // goToComment(url, event){
    //     event.preventDefault()
    //     window.open(url,'mywindow');
    // }

    expandComment(index, event){
        event.stopPropagation();
        var self = this
        let newState = self.state.expandedComments
        newState[index] = true
        self.setState({expandedComments: newState})
    }

    expand(event){
        event.stopPropagation();
        var self = this
        if(this.props.onMore){
          this.props.onMore()
        }else{
            this.setState({visibleComments:self.state.visibleComments+10})
        }
    }

    
    showAll(event){
        event.stopPropagation();
        if(this.props.onMore){
          this.props.onMore()
        }else{
            this.setState({visibleComments:this.props.article.comments.length})
        }
    }

    formatComment(text){
        let formatted = text.replace(/\[(.+?)\]\((.+?)\)/gi, (match, p1, p2)=>{
                if(p2.charAt(0)==='/'){
                    p2='https://reddit.com'+p2
                }
                return '<a rel="nofollow" class="normalLink" href="'+p2+'" target="_blank">'+p1+'</a>'
        })
        formatted = formatted.replace(/\*\*(.+?)\*\*/gi, '<b>$1</b>')
        formatted = formatted.replace(/\*(.+?)\*/gi, '<em>$1</em>')
        // console.log(formatted)
        formatted = formatted.replace(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g, 
            (match, p1)=>{
                if(p1.charAt(p1.length-1)!='"'){
                    return ' <a rel="nofollow" class="normalLink" href="'+p1+'" target="_blank">Link</a> '
                }else{
                    return match
                }
            })
        return <p dangerouslySetInnerHTML={{ __html: formatted }} />
    }

    render () {

        var self = this
        var article = self.props.article
        let maxComments = self.state.visibleComments 

        // style.container.maxHeight = this.props.isMobile ? null : style.container.maxHeight


        // var comments = article.comments.slice(0,maxComments)
        var comments=[]
        // (article.comments.length-maxComments)
        var slideWindow = ((article.comments.length)-maxComments) < 0 ? 0 : ((article.comments.length)-maxComments)
        for(var i = slideWindow ; i<article.comments.length; i++){
        // for(var i =article.comments.length-1; i>slideWindow; i--){
            var comment = article.comments[i]
            let expandedComment = self.state.expandedComments[i]
            let big = comment.comment.length > 400
            let readmore = <div onClick={self.expandComment.bind(this, i)} style={{cursor: 'pointer'}} className={'mouseOverText'}>Read More</div>
            
            let page = <div key={"Puff-comment-"+i} style={style.comment}>
                        <img onError={self.addDefaultSrc} style={{borderRadius: '50%', marginLeft: self.props.thumb ? -30 : -45, marginRight: 5,width: 40,position: 'absolute'}} src={comment.avatar ? comment.avatar : config.server[window.env].staticUrl+'img/missing.png'}/>
                        <div style={style.commentBubble} >
                            <a rel="nofollow" href={comment.url} target="_blank" style={{color:'#333'}}><b>{comment.name} <span className={"fa fa-"+comment.source} aria-hidden="true"></span>&nbsp;</b> </a>
                            {!expandedComment && big ? this.formatComment(comment.comment.slice(0,400)) : this.formatComment(comment.comment)} 
                            {expandedComment ?  null : (big ? readmore : null) }
                        </div>
                    </div>


      
                comments.push(page)
            
        }

  
        
        if(self.props.thumb){
   
        }else if(self.props.big){
            return (
                <div style={{...style.container,...self.props.style}}>
                    <div onClick={self.expand} className={'mouseOverText'} style={{display:'inherit'}} >
                        <span style={{...style.button,...style.round}} className="fa fa-comment-o" aria-hidden="true"/>
                        <span style={style.large}>{article.comments.length + " " + T.translate("puff.comments")}</span>
                    </div>
                    {comments}
                </div> 
           ); 
        }else if(this.props.fixedHeight){
            return (  
                <div style={{...style.container,...self.props.style}}>
                    {article.comments.length > maxComments ? 
                    <div onClick={self.showAll} className={'mouseOverText'} style={{display:'inherit'}} >
                        <span style={{...style.button,...style.round}} className="fa fa-comment-o" aria-hidden="true"/>
                        <span style={style.large}>{" Show All " + article.comments.length + ' ' + T.translate("puff.comments")}</span>
                    </div>: null}
                    {comments}
           
                </div>     
             );
         
        }else{
             return (<div style={{...style.container,...self.props.style}}>
                        {article.comments.length > maxComments && article.comments.length > maxComments+15 ?
                            <div onClick={self.expand} className={'mouseOverText'} style={{display:'inherit'}} >
                                <span style={{...style.button,...style.round}} className="fa fa-comment-o" aria-hidden="true"/>
                                <span style={style.large}>{"Show more " + T.translate("puff.comments")}</span>
                            </div>
                        : null}
                        {article.comments.length > maxComments ? 
                            <div onClick={self.showAll} className={'mouseOverText'} style={{display:'inherit'}} >
                                <span style={{...style.button,...style.round}} className="fa fa-comment-o" aria-hidden="true"/>
                                <span style={style.large}>{"Show all " + article.comments.length + " " + T.translate("puff.comments")}</span>
                            </div>
                         : null}
                        {comments}
                    </div>)
        }                   

    }
}

const style={
    button:{
        padding: '10px',
        borderRadius: '20px',
        // color: '#777777',
        cursor: 'pointer'
    },
    round:{
        width:'34px',
        height:'34px',
        textAlign: 'center',
        borderRadius: '50%',
        backgroundColor:'transparent',
        fontSize: 'larger',
        // color: '#777777',
        padding:8
        
    },
    large:{
        fontFamily:'sans-serif',
        // color: '#777777',
        fontSize:'larger',
        cursor: 'pointer'
    },
    container:{
        marginTop: 15,
        // maxHeight:'300px',
        lineHeight: '1.4em',
        overflowY: 'auto',
        overflowX: 'hidden',
        borderRadius: '10px',
        paddingBottom:15
    },
   comment:{
        padding: '6px 0px 6px 0px',
        width:'100%',
        minHeight: 40,
        minHeight: 50,
        paddingLeft: 45,
        // maxHeight:100,
        overflow: 'hidden',
        width:'100%'
    },
    commentBubble:{
        backgroundColor: "rgb(243, 242, 242)",
        padding: "8px 10px",
        borderRadius: 18,
        display: "inline-block",
        // maxHeight: 100,
        overflow: "hidden",
        // cursor: 'pointer',
        // width:'100%',
        wordBreak: 'break-word',
           color: '#373636',
        backgroundColor: '#f5f5f5',
        borderColor: '#f1f1f1',
        borderStyle: 'solid',
        borderWidth: 1
    }
}

module.exports = Comments;