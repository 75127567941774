import React from 'react';

import Donut from "./pie/Donut.js"


const backgroundColors = [
        '#FF6384',
        '#36A2EB',
        '#FFCE56',
        '#58b893',
        '#e89a50',
        '#8a74b7',
        '#3499e0'
        ]

class Pie extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        	sum: props.data ? props.data.reduce((a,b)=>a+b.value,0) : 0,
        	data:props.data ? props.data.map((da,i)=>({...da, color:(backgroundColors[i % backgroundColors.length])})) : [{name:null, value:1, color:'#f3f3f3'}]}
     }

     componentDidMount(){
     	this.setState({mounted:true})
     }

     logoUrl(url){
        return url.startsWith('/') ? 'https://www.monok.com'+url : url
     }

    render(){
 	let currency = (this.props.currency||this.props.currency==='') ? this.props.currency : '€'
 	let subTitle = this.props.subTitle ? this.props.subTitle : 'excl. taxes'
 	let tooltip = this.props.placeholder ? this.props.placeholder : 'You have no costs this month'

    return <div>
   		{this.state.tooltip ? 	
            <div style={{background:'white', 
						borderRadius:5,
						pointerEvents:'none',
						boxShadow:'0px 2px 5px 0px #0000002e',
						position:'fixed',
						padding:10,
						left:this.state.tooltip.x, 
						top:this.state.tooltip.y}}>
				{this.state.tooltip.logo ? <img style={{maxWidth:100, marginRight:10, filter: 'brightness(0)'}} src={this.logoUrl(this.state.tooltip.logo)}/> : null}
                {this.state.tooltip.name ? (this.state.tooltip.logo ? '' : this.state.tooltip.name+' ')+currency+this.state.tooltip.value : tooltip}
	  		</div> : null}
	  	{Donut({
  		    subTitle:subTitle,
  		    title:currency+this.state.sum,
  		    size:200,
  		    className:'donut',
  		    data:this.state.data,
  		    colors:backgroundColors,
            titleColor:this.props.titleColor,
  		    innerRadius:0.5,
  		    outerRadius:0.9,
  		    mounted:this.state.mounted,
  		    activeOffset:0.05,
  		    onHover:(evt, i) => {
  				      if (i>=0) {
  				        // console.log(evt.pageX)
  				        let position = evt.target.getBoundingClientRect()
  				        // console.log(position)
  				        this.setState({active:i,tooltip:{...this.state.data[i], x:(position.x+position.width/2), y:(position.y+position.height/2-10)}})
  				        // this.setState({tooltip:{...this.state.data[i], x:evt.pageX, y:(evt.pageY-100)}})
  				      } else {
  				        this.setState({tooltip:null,active:null})
  				        // console.log("Mouse left donut")
  				      }
  				    },
  		    active:this.state.active>-1 ? this.state.active : -1
  		})}
    </div>
 
    }
               			
}
	              

export default Pie;