import React, {Component} from 'react'
import ReactDOM from 'react-dom';
// let Swiper = require('react-id-swiper');
import { connect } from 'react-redux';
import {browserHistory} from 'react-router';

// import store from '../store';
import actions from '../../actions'
import {fetchFeed} from '../../apiFunctions';
import {settingsToParams} from '../../util';
import {logout, saveProfile} from '../../actions/auth';
import {updateMenu, addFeed, removeFeed} from '../../actions/feed';
import T from 'i18n-react';

const config = require('../../../settings/config.json')

const dropdowns = ['product', 'view', 'page','project', 'filter']

let Scroll = require('react-scroll');
let scroll = Scroll.animateScroll;

let p = console.log

  const styles={
      bar:{
        zIndex:50,
        width:'100%',
        // height:'40px',
      },
      tab:{
        // color: 'grey',//'#ffc9c9',
        height:'40px',
        cursor: 'pointer', 
        width: '100%', 
        // display: 'block',
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 0,
        paddingBottom: 0,
        fontFamily:'ubuntu, Sans-Serif',
        borderRadius: 10,
        // textTransform: 'capitalize'
      },
      tabIcon:{
        color: 'grey',//'#ffc9c9',
        height:'40px',
        cursor: 'pointer', 
        width: 'inherit', 

        // paddingTop: 9,
        // textTransform: 'capitalize'
      },
      tabActive2:{
        color: '#0d0c22',
        padding: '5px 10px',
        margin: '5px 5px',
        // textTransform: 'capitalize',
        cursor: 'pointer',
        // backgroundColor: '#f1f0f0',
        borderRadius: '20px',
        fontWeight: "bold",
        fontFamily:'ubuntu, sans-serif',
        // display: 'inline',
        lineHeight:'20px'
      },
      iconActive:{
        backgroundColor: "#f1f0f0"
      },
      badge:{
        backgroundColor: "#b3b3b3",
        padding: 3,
        borderRadius: 10,
        paddingLeft: 10,
        paddingRight: 10
      },
      text:{
        color:"#777777",
        fontFamily:"\"Helvetica Neue\", Helvetica, Arial, sans-serif",
        // float:'left',
        marginLeft:5,
        marginBottom:0
      }
      
    }

class Menu extends React.Component {



  constructor(props) {
      super(props);
      this.state = {searchValue: '', expanded:false};
      this.selectThis = this.selectThis.bind(this);
      // p(parseInt(this.props.location.split('/')[1]))
  }

  componentDidMount() {
    this.focusDropDown();
  }

  componentDidUpdate() {
    if(this.state.dropdown){
      this.focusDropDown();
    }
  }

  gotoLogin(e){
    browserHistory.push({pathname: '/login'}) 
  }

  gotoTop(index){
    let self = this
     scroll.scrollToTop({
        duration: 500,
        delay: 100,
        smooth: true,
        containerId: index+"-scroll"
      })
  }


  SearchInputChange(e) {
    this.setState({searchValue: e.target.value});
  }

  focusDropDown() {
    let dropdown = ReactDOM.findDOMNode(this.refs.dropdown)
    dropdown ? dropdown.focus() : null
  }

  feedPath(feed){
    return '/' + (feed.prefix ? feed.prefix + '/' : '') + feed.id
  }

  selectThis(item, index){
  

    if(item.selected && dropdowns.includes(item.type)){
      this.state.dropdown === item.group ? null : this.setState({dropdown:item.group})
      return
    }

    if(item.type==='more'){
        this.setState({expanded:true})
        return
    }

    let {feed, feeds, feedIndex} = this.props

    let menu = JSON.parse(JSON.stringify(feed.menu))

    if(menu[index].type==='page' && menu[index].value === 'logout'){
      this.props.logout()
      return
    }

    if(menu[index].value==='support'){
      window.open("https://monok.news/docs/", "_blank")
      return
    }

    if(menu[index].type==='new' && menu[index].value === 'draft'){
      let params=settingsToParams({menu:menu, lastRequest:feed.lastRequest})
      let sub = params.category ? params.category : (params.page ? params.page : null)
      browserHistory.push({pathname: '/'+feed.id + (sub ? '/'+sub.toLowerCase() : ''), query:{...params, category:undefined, page:undefined, new:'draft'}})
      return
    }

    // Scroll to top
    this.gotoTop(feed.id)

    //If the selected item belongs to a group
    //then it's a radio button
    if(menu[index].group){
      //Deselect all items in that group

      menu.map((item)=>{
        if(item.group === menu[index].group){
          item.selected = false
        }
      })
      menu[index].selected = true
    }else{
      //If it doesn't belong to a group it's a checkbox
      if(!menu[index].selected){
        menu[index].selected = true
      }else{
        menu[index].selected = false
      }
    }

    if((menu[index].type==='category' && feed.id==='en') || menu[index].type==='feed'){
      let newView = (menu[index].value==='' || menu[index].value==='free') ? 'magazine' : 'masonry'
      menu.forEach(m=>m.type==='view' && m.value===newView ? m.selected=true : (m.type==='view' ? m.selected=false : null))
    }
    this.state.dropdown ? this.setState({dropdown:null}) : null
    let params=settingsToParams({menu:menu, lastRequest:feed.lastRequest})
   

    let sub = params.category ? params.category : (params.page ? params.page : null)
    browserHistory.push({pathname: '/'+feed.id + (sub ? '/'+sub.toLowerCase() : ''), query:{...params, category:undefined, page:undefined}})
  }



  menuDom(item, i, menu, children, disabled){
    
 
    let {user,feeds} = this.props

    let SW = this.props.feed.id === 'z5nqmq'

    let hasFeeds = feeds.filter(f=>!['profile', 'search','en','login'].includes(f.id)).length>0



    let drop = null
    let title = T.translate(item.title)
    let tabType=title

    if(!disabled && item.selected && this.state.dropdown && this.state.dropdown===item.group){
      let down = title && title.length>0
      let validChildren = children ? children.filter(m=>
                            
                            ((m.type==='product' && ['scrape','cluster','articlegeneration'].includes(m.value)) ? (SW || user.curator || user.admin) : true)

                            ) : null

      drop = validChildren && validChildren.length>1 ? <div ref="dropdown"  tabIndex={0} 
      onBlur={()=>this.setState({dropdown:null})} 
      className="dropdown"
      style={{marginLeft: down ? 0 : -60, display:down ? 'flex' : 'box',flexDirection: 'column', maxHeight:'80vh', overflowY:'auto'}}>
        {children ? validChildren.map((m,mi)=>m.selected ? this.menuDom(m, mi, null, null, true) : this.menuDom(m, mi)) : null}
      </div> : null
    }

    let self = this
    let isMobile = this.props.isMobile
    let open = this.props.open && this.state.expanded
    let index = item.index
    let selected = item.selected && !disabled

    let inList = !selected && dropdowns.includes(item.type)
 
    // let menu = this.props.feed.menu
    // let puffSpecific = ['sort','view']
    // if(this.props.feed.puffs && this.props.feed.puffs.length===0 && puffSpecific.indexOf(item.type)!==-1){
      // return null
    // }


  
    // console.log(item)
    if(item.icon){
      tabType = (<img className="tabIcon"  style={{filter:(selected ? 'invert(0%)' : 'invert(25%)')}} src={config.server[window.env].staticUrl+"img/icons/"+item.icon+".png"}/>)
    }

    if(item.faicon){
      tabType =  [<span className={'tabIcon '+ item.faicon} key={this.props.feed.id+'-'+index+"-FAICON"}  style={{filter:(selected ? 'invert(0)' : undefined), color:(selected ? (open ? 'white' : 'black') : 'black'), fontSize:(isMobile ? 18 : 'initial'), lineHeight: 'inherit',verticalAlign: 'middle'}} aria-hidden="true">
              </span>,
              <p key={this.props.feed.id+'-'+index+"-FAICONTITLE"} style={{...styles.text, ...{color:(selected ? '#0d0c22' : 'inherit')}}}>{isMobile ? null : title}</p>]
    }
    if(item.svgicon){
      tabType = <img className="tabIcon" style={{height:'20px',width:'20px', padding:'0px', filter:(selected ? (open ? 'invert(100%)' : 'invert(25%)'): undefined)}} src={config.server[window.env].staticUrl+"img/icons/"+item.svgicon+".svg"}/>
    }


    let isIcon = item.svgicon || item.icon || (item.faicon && !item.title)
    let wrapperStyle = isIcon && false ? (selected ? styles.iconActive : styles.tabIcon) : (selected ? styles.tabActive2 : styles.tab)


    let expandedState = !open ? {} : {
      //When the menu is expanded, the buttons should be:
      fontWeight:'bold',
      fontSize:'19pt',
      backgroundColor: selected ? '#d83d38' : 'transparent',
      color: selected ? 'white' : '#939393',
      textShadow: selected ? '0px 1px 2px #890b0b' : '0 1px 0 #fff',
      boxShadow: selected ? 'inset 0px 1px 5px 0px #00000070' : null,
      borderRadius: 20
    }

    let moreButton = item.type!=='more' ? {} : {
      position:  'absolute',
      right: 0,
      background:'linear-gradient(0.75turn, rgb(253 253 253), 95%, rgba(251, 251, 251, 0)) 0px 0px no-repeat',
      textAlign: 'center',
      borderRadius:0,
      zIndex:120
    }

// <div style={styles.badge}>{tabType}</div> 
    let tabWrapper = <div key={this.props.feed.id+'-'+index+"-MobilemenuItem"} 
                    className={"submenu"}
                    style={{...wrapperStyle, ...expandedState,...(inList && !title ? {display:'inline-table'} : {})}}  
                    onMouseDown={(event)=>{event.stopPropagation()}}
                    onClick={
                      (e)=>{
                          e.stopPropagation()
                          // e.nativeEvent.stopImmediatePropagation();
                          self.selectThis(item, index)
                    }}>
                {item.type === 'category' && item.value ? 
                <a onClick={(e)=>e.preventDefault()} href={"/"+this.props.feed.id+"/"+item.value.toLowerCase()} style={{color: 'unset'}}>
                  {tabType}
                </a> : 
                  tabType}
                  {item.count ? <div style={{margin:'0px 0px 0px auto'}}><span style={{background: '#d7f2fb', padding: '4px 10px', borderRadius: 10, margin: 5}}>{item.count}</span></div> : null}
                {selected && !isMobile && dropdowns.includes(item.type) ? 
                  <span style={{margin: 'auto 0px auto 7px', fontSize: 10, color: '#5c5c5c', transform: drop ? 'rotate(-180deg)' : 'rotate(0deg)'}} className="fa fa-chevron-down animate" aria-hidden="true">
                  </span> : null}
              </div>



    let menuWrapper = <div  key={this.props.feed.id+'-'+index+"-menuItem"}  style={{...{minWidth:40, textAlign:'left', display:'inline-block',lineHeight:'40px', marginLeft:0, marginRight:0},...moreButton,
                                    ...(disabled ? {background:'#efefef', borderRadius:10, textAlign:'left', width:isIcon ? 'fit-content': '100%'} : {})}} >
                                    {tabWrapper}
                                    {drop}
                                  </div>
    let newline = <br key={"newline-"+index}/>
    let newGroup = open && menu && menu[i] && menu[i-1] && menu[i-1].group !== menu[i].group
    // console.log(menu)
    return ([newGroup ? newline : null, menuWrapper])
  }



  render (){
    let self = this
    let {feed, isMobile, embed, user, feeds} = this.props

    if(feed && !['profile','login','z5nqmq'].includes(feed.id) && !(user.curator || user.admin)){
      return <div style={{marginTop:40}}></div>
    }
    // this.props.feeds[this.props.feedIndex]
    // console.log(feed)
    let menu = this.props.feed.menu

    let virtual = this.props.feed.plugins && this.props.feed.plugins.find(p=>p.type==='search') ? true : false

    // let keys = this.props.feed.
    let keywords  = []
    if(feed.categoryFilter){
      keywords  = this.props.feed.categoryFilter[0].categories.map(item =>({title:item, group:5}))  
    }
    // console.log(keywords)
    let open = this.props.open && this.state.expanded

    let menuItems = menu.map((m,i)=>({...m, ...{index:i}}))
    // console.log(location.search)

    let valid = (m)=>(!virtual ? m.type!=='remove' : true) 
                      // && (embed ? m.type==='category' : true)
                      && (menuItems.find(item=>item.type==='feed' && item.value==='store' && item.selected===true) && !(user && user.email) ? ['feed'].includes(m.type) : true)
                      // && (!this.props.feed.lastRequest.query ? m.type!=='save' : true) 
                      && (isMobile ? m.mobile!==false : true)
                      && (m.writer ? (user.curator || user.writer || user.admin) : true)
                      && (m.writer===false ? !user.writer : true)
                      // && (m.type === 'page' && ['new','settings','support'].includes(m.value) ? !(user.curator || user.writer) : true)
                      // && (m.type === 'page' && ['settings'].includes(m.value) ? false : true)
                      && (m.group!==3)
                      && (m.type==='new' ? (user.admin) : true)
                      && (isMobile || ((m.selected && dropdowns.includes(m.type)) || !dropdowns.includes(m.type)))
                      
                      // && !(m.type==='product' && m.value==='scrape')//((m.type==='product' && m.value==='scrape') ? user.admin : true)

  
    
    menuItems = menuItems.filter(m=>valid(m) || m.type==='dropdown')
    

  
    menuItems = isMobile ? menuItems.concat() : menuItems
    var tabDivs = []

    try{
      tabDivs = menuItems.map((item, index)=>(this.menuDom(item, index, menuItems, (item.group ? menu.map((m,i)=>({...m, ...{index:i}})).filter(m=>m.group===item.group) : null)).filter(m=>m))).filter(m=>m)
    }catch(e){
      tabDivs = null
    }

    var bar = <div
                onClick={()=> open ? this.setState({expanded:false}):null} 
                style={{
                        display:isMobile ? 'initial' : 'flex',
                        justifyContent: 'flex-end',
                        paddingLeft:open ? 50 : 15, 
                        paddingRight:open ? 50 : (isMobile ? 30 : 15),
                        paddingTop: open ? 50 : 0,
                        width:'100%', 
                        // overflowY:isMobile ? 'scroll' : 'hidden',
                        // overflowX:'hidden',
                        background: (open ? 'linear-gradient(rgb(255, 255, 255), 90%, rgba(255, 255, 255, 0)) 0px 0px no-repeat' : 'linear-gradient(rgb(255, 255, 255), 90%, rgba(255, 255, 255, 0)) 0px -500px no-repeat'), 
                        height:(open ? '100%' : 40),
                        textAlign:'center', 
                        position: isMobile ? 'absolute' : 'relative',
                        maxWidth: 1165,
                        margin: 'auto',
                        // borderRadius: open ? '71px' : '10px',
                        // paddingTop: open ? 20 : 0, 
                        whiteSpace: open ? 'initial' : 'nowrap',
                        overflowX: isMobile ? 'auto' : 'initial',
                        scrollbarWidth: 'none',
                        // marginTop: 0, 
                        // boxShadow: open ? 'none' : 'rgba(0, 0, 0, 0.48) 0px 0px 15px',
                        zIndex: 110}}>
                {tabDivs}
            </div> 


    return (
        <div style={styles.bar} className={this.props.open ? 'show-submenu' : 'hide-submenu'} >
         {bar}
         {false && isMobile && !open && this.props.open &&  menuItems.length > 5 ? this.menuDom({faicon:'fa fa-ellipsis-v',title:'more', type:'more'}, 0, null) : null} 
        </div>);
  }
}


const mapStateToProps = function(store) {
  return {
    feeds: store.feeds,
    selectedView: store.feeds.findIndex(f=>f.id === store.view.selectedView),
    // feedTemp: store.feeds[store.view.selectedView],
    geoLocation: store.app.geoLocation
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    saveProfile:(newProfile)=>dispatch(saveProfile(newProfile)),
    updateMenu: (feed, menu) => dispatch(updateMenu(feed, menu)),
    // setTabs:(feeds, user)=>dispatch(setTabs(feeds, user)),
    addFeed:(feed, index)=>dispatch(addFeed(feed, index)),
    removeFeed:(feedId, index)=>dispatch(removeFeed(feedId, index))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);