import React from 'react';
var config = require('../../settings/config.json');
import {search} from '../apiFunctions';
import {browserHistory} from 'react-router';
import Context from './puff/context.jsx';
import moment from 'moment'
import {Grid, Col, Row} from 'react-bootstrap';
import T from 'i18n-react';
import Spinner from './modal/spinner.jsx';
import {settingsToParams} from '../util';

class Infobox extends React.Component {
  
  isMobile(){
    return window.innerWidth<800
  }

  getTitle(widgetData, headerOnly){

    let feed = this.props.feed
    let orders = feed && feed.menu && feed.menu.find(m=>m.type==='feed' && m.value==='orders' && m.selected)
    let title = feed && feed.title && feed.type==='virtual' ? 
                  feed.title : (widgetData && !headerOnly ? 
                 (widgetData.text ? widgetData.text : widgetData.description) : 
                 (feed && feed.lastRequest.query ? feed.lastRequest.query  : (orders ? orders.title : feed.title)))

    return T.translate(title)

  }


  getSortTitle(sort){
    let selected = this.props.feed.menu.find(m=>m.type==='sort' && m.value===sort)
    return selected ? T.translate(selected.title) : ''
  }

  render () {  
    let {feed, small, headerOnly, info} = this.props
    var widget = null
    var widgetData = null
    let total = 0
    let settings = settingsToParams(feed)

    let categories = settings.category ? [settings.category.toLowerCase()] : (feed && feed.lastRequest && feed.lastRequest.categories ? feed.lastRequest.categories : [])
    if(info){
      widget = info.widget
      widgetData = widget && widget.data && widget.data[0] ? widget.data[0] : null
      total = info.total ? info.total : 0
    }else if(this.props.profile){
      widgetData = this.props.profile
      widget = widgetData
      total = 0
    }
      

   
    let title = <div>
                  {<h1 style={{...style.title, ...(small ? {width:'100%', fontSize:'20pt'} : {})}}>
                  {info && info.website ? 'Stories for' : (categories.length > 0 ? categories[0] : this.getTitle(widgetData, headerOnly))} 
                  </h1>}
                  {feed && !headerOnly ? <h1 style={{...style.subTitle, textTransform:'initial'}}>{info && info.website ? this.getTitle(widgetData, headerOnly) : this.getSortTitle(settings.sort)}</h1> : null}
                  {feed && !headerOnly ? <h1 style={style.subTitle}>{this.props.loading ? <Spinner style={{marginLeft:10}}/> : null}</h1> : null}
                </div>
    let widgetFrame = (<div  style={{gridRow:'1 / 10'}}>
              <Col md={small ? 12 : 8} style={{paddingLeft:0, paddingRight:small ? 0 :30, textAlign: small ? 'center' : 'initial'}}>

                <div style={{...style.content,...(small ? {padding:0} : {})}}>
                  {small ? null : title}
                 {widget && widgetData.image && !headerOnly ?  <Context isMobile={this.isMobile()} type={'avatar'} style={{...this.props.style,margin:'auto auto 20px', width:small ? 'initial' : '100%'}} widget={widget}/>:null}
                   {small ? title : null}
                  <div style={{display:'flex'}}>
                    {total && !small && !headerOnly ? <div style={{marginRight:20, float:(widget && widgetData.image ? 'left' : 'initial')}}>
                                                        <b  style={style.number}>{total}</b><p style={style.numberLabel}>Stories</p>
                                                      </div> : null}
                   
                  </div>
                  {widgetData && !widgetData.code && !headerOnly ? <div style={style.description}>{widgetData.description}</div> : null}
                 
                </div>
              </Col>
             

              </div>)

    if(small){
      return widgetFrame
    }

    return (
          <div style={style.container}>

          <Grid>
            <Row style={style.row}>
              {widgetFrame}
             </Row>
          </Grid>
      

          </div>
    );
  }

}

const style = {
                container:{
                  // height: 280,
                  paddingTop:35, //1 px minimum, long story.. probably, this is faster
                  color:'#444444',
                  fontSize:'14px',
                  paddingLeft:20
                },
                infobox:{
                  maxWidth: 700, 
                  float:'left',
                  paddingRight: 10
                  // margin: 'auto'
                },
                avatar:{
                  display: "block",
                  maxWidth:'100%',
                  // maxHeight:95,
                  width: 'auto',
                  height: 'auto'
                },
                thumb:{
                  width:150,
                  height:150,
                  backgroundRepeat: 'no-repeat',
                  backgroundPositionX: 'center',
                  // boxShadow: 'rgba(0, 0, 0, 0.298039) 5px 6px 8px 1px inset',
                  backgroundSize: 'cover',
                  // float:'right',
                  borderRadius: 20,
                  // border:'solid',
                  // borderColor:'white',
                  // borderWidth:5,

                  // border: '1px solid rgba(0,0,0,.0975)',
                  boxShadow:'rgba(0, 0, 0, 0.298039) 0px 0px 1px 0.5px inset',
                  position: 'relative',
                  marginTop:30,
                  marginBottom:30
                  // margin: 'auto'
                  
                },
                content:{
                  paddingLeft:10,
                  // maxHeight: 238,
                  display:'grid',
                  overflow: 'hidden'
                },
                tiny:{
                  width:40,
                  height:40,
                  margin:5,
                  position:'absolute'
    
                },
                title:{
                  fontSize: 'clamp(30px, 6vw, 46px)',
                  lineHeight: 1.2,
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  color:'#111',
                  float:'left',
                  marginRight:15,
                      marginTop: 0
                },
                subTitle:{
                  fontSize: 46,
                  lineHeight: 1.2,
                  textTransform: 'capitalize',
                  color:'#999',
                  float:'left',
                  marginRight:15,
                      marginTop: 0
                },
                date:{
                  color:'rgb(195 195 195)',
                  fontSize:22,
                  marginBottom: 0,
                  textAlign: 'right',
                  fontWeight:'bold'
                },
                year:{
                  color:'rgb(195 195 195)',
                  fontSize:'large',
                  marginTop: 0,
                  textAlign: 'right',
                  fontWeight:'bold'
                },
                number:{
                  fontSize: 'x-large',
                  color:'#111'
                },
                numberLabel:{
                  color:'#999',
                  fontWeight: 600
                },
                relatedBadge:{
                  color:'#4a4a4a', 
                  backgroundColor:'#ececec',
                  marginTop:11,
                  marginLeft: 33,
                  padding:1,
                  paddingLeft: 15,
                  paddingRight: 5,
                  borderRadius: 5,
                  fontSize: '12px',
                  fontWeight: 700
                },
                related:{
                  display: 'table',
                  paddingBottom:5,
                  marginRight:5,
                  paddingTop: 10
                },
                description:{
                  // fontWeight:'bold'
                  color:'grey',
                  maxWidth:500
                },
                row:{
                  margin:0,
                  // padding: 30
                }
              }

module.exports = Infobox