import React from 'react';
import ReactDOM from 'react-dom';
import TT from './tt.jsx';

const style = {
	start:{marginRight: 0, borderRadius:'30px 0px 0px 30px'},
	middle:{marginRight: 0, marginLeft: 0, borderRadius:'0px'},
	end:{marginLeft: 0, borderRadius:'0px 30px 30px 0px'},
	single:{marginRight: 0, marginLeft: 0, borderRadius:'30px'},
	stars:{
    textShadow: '0 1px 2px #0000004d',
    fontSize: 'xx-large',
    background: 'none',
    boxShadow: 'none',
    padding:5
  },
  subList:{
  	borderRadius:10,
  	// margin:'5px 0px',
  	margin:0,
  	// padding:7,
  	boxShadow:'none'
  },
  dropdown:{
  	cursor:'pointer'
  }
	// light:{
	// 	wrapper:{
	// 		    border: 'solid 2px #f1f0f0'
	// 	}
	// },
	// dark:{

	// }
}

class Flip extends React.Component {

  constructor(props) {
      super(props);
      this.state = {index:0, selections:[]}
  }  

  optionList(options, value, subList){
  	let {suffix, light, stars, dropdown, add, title, multipleOptions} = this.props
  	let {selections} = this.state

  	return options.map((option, i)=>{
  			
  			let optionName = option
        let type = null
        let logo = null
        let icons = window.icons
        
        if(option && (option.url || option.type || option.title || option.id)){
  			 	optionName = option[['name','title', 'channel', 'url'].find(attr=>option[attr])]
  			 	type = option.type ? option.type : (option.url && option.url.includes('@') ? 'email' : {apikey:'wp', token:'api', channel:'slack'}[['apikey', 'token', 'channel'].find(attr=>option[attr])])
        	logo = option.logo || option.avatar
        }
        if(dropdown && !subList && multipleOptions && value.length>0){
        	
        	let selectedValues = this.props.options.filter((op,i)=>value.includes(i))
        	
        	optionName = selectedValues.length > 2 ? selectedValues.length + ' selected' : selectedValues.join(' & ')
        }
  			
  			let dropdownButton = dropdown && !subList
  			let selected = (multipleOptions ? value && value.includes(i) : value===i) && !(dropdownButton)

	    	return option.hidden ? null : <div key={'options'+i}

	    	 onClick={()=>{
	    		if(dropdown && !subList){

	    		}else{
	    			if(multipleOptions){
	    				let newSelections = selections.includes(i) ? selections.filter(s=>s!==i) : selections.concat([i])
	    				this.setState({selections:newSelections})
	    				this.props.onChange(newSelections, optionName)
	    			}else{
			    		this.setState({index:i})
			    		this.props.onChange(i, optionName)
	    			}
	    		}
	    	}}
	    		className={(dropdownButton ? '' : "button") + (selected && !add ? " selected"+(light ? '-light' : '') : (light ? ' light' : ''))} 
	    		style={{textTransform: option.url ? 'initial' : 'capitalize',
	    					...(light || dropdown ? {display: 'flex'} : (i===0 ? (this.props.options.length===1 ? style.single : style.start) : ((i===this.props.options.length-1) ? style.end : style.middle))),
	    					...(stars ? {...style.stars, color:(this.props.value || this.props.value===0) && (i <= value) ? '#428bca' : 'transparent'} : {}),
	    					...{alignItems: 'center'},
	    					...(subList ? {...style.subList, fontWeight:options.length>5 ? 'normal' : undefined, padding: options.length>5 ? '5px 10px' : undefined} : (dropdown ? style.dropdown : {})),
	    					...(dropdownButton ? {display: 'flex', background:'transparent'} : {})}}>
	    		
	    		{(title && !dropdownButton) || !title ? (logo ? <img src={logo} style={{maxWidth:100, marginRight:10, ...(this.props.logoStyle ? this.props.logoStyle : {}), ...(dropdownButton && this.props.logoStyleSelected ? this.props.logoStyleSelected : {})}}/> : (option.icon || (type && icons[type]) ?  <span className={"fa fa-"+ (option.icon ? option.icon : icons[type])} style={{marginRight:10, ...(this.props.iconStyle ? this.props.iconStyle : {})}} aria-hidden="true"/> : null)) : null}

	    		{title && !subList ? title : (stars ? '★' : (selected && multipleOptions && !logo && !option.icon ? '✓ ' : '') + optionName)}


	    		{suffix ? ' '+suffix : ''}

	    		{false && option.profile ? <p style={{color:'lightgrey', float:'right'}}>{option.profile}</p> : null}
	    		{false && option.count ? <span>{option.count}</span> : null}
	    		{dropdownButton ? <span style={{margin: 'auto 0px auto 7px', fontSize: add ? 15 : 10, transform: this.state.drop ? (add ? 'rotate(-45deg)' : 'rotate(-180deg)') : 'rotate(0deg)'}} className={"fa fa-"+(add ? "plus" : "chevron-down")+" animate"} aria-hidden="true"/> : null}
	    
	    	</div>
  		})
  }

  focusDropDown() {
    let dropdown = ReactDOM.findDOMNode(this.refs.dropdown)
    dropdown ? dropdown.focus() : null
  }

  render () {
  	let {suffix, light, stars, defaultValue, dropdown, tooltip, multipleOptions, options, face} = this.props
  	let value = (this.props.value || this.props.value===0) ? this.props.value : (defaultValue!==undefined ? defaultValue : this.state.index)

  	//must be props.value for stars

  	return <TT text={this.state.drop ? null : tooltip}>
  	<div
  	className={"animate"+(this.props.className ? ' '+this.props.className : '')}
  	onClick={()=>{
  		if(dropdown){
	  		this.setState({drop:this.state.drop && !multipleOptions ? false : true},()=>{
	  						if(this.state.drop){
		    					this.focusDropDown()
		    				}
	  		})
  		}
  	}}
  	style={{
  		marginTop:15, 
  		textAlign: 'center', 
    	maxHeight: 50,
  		...(light ? {margin:5, width: 'max-content', border:'solid 2px #f1f0f0', borderRadius:30, padding: 3} : {}),
  		...(this.props.style ? this.props.style : {}),
  		...(dropdown ? {cursor:'pointer'} : {})
  	}}>
  		{face ? face : this.optionList(options.filter((option, i)=>((multipleOptions ? i===0 : value===i) && dropdown) || !dropdown), value)}
		{this.state.drop ? 
	    			<div 
	    			ref="dropdown"
	    			tabIndex={0} 
	    			onBlur={()=>{
				    		if(dropdown){
				    			this.setState({drop:false})
				    		}
				    }}
	    			style={{
	    				position: 'absolute',
					    display: 'flex',
					    flexDirection: 'column',
					  	textAlign: 'left',
					  	overflowY: 'auto',
					  	zIndex:1000,
					  	margin:light ? '30px 0px 0px -15px' : '',
					  	marginTop:this.props.dropOffset ? this.props.dropOffset : 30,
					  	maxHeight: this.props.dropHeight ? this.props.dropHeight : 350, 
					  	...(this.props.dropStyle ? this.props.dropStyle : {})}} 
					  	className="dropdown">
	    					{this.optionList(options, value, true)}
	    			</div> : null}
	</div>
	</TT>

  }
}

export default Flip;