import React, {Component} from 'react'
import { connect } from 'react-redux';
import {browserHistory} from 'react-router';
import Menu from './menu.jsx';
import {settingsToParams} from '../../util';
import store from '../../store';
import {gotoView} from '../../actions/view';
import {setFocusedPuff} from '../../actions/puff';
import {setFeeds} from '../../actions/feed';
import {logout} from '../../actions/auth';

import T from 'i18n-react';
import Flip from '../modal/flip.jsx';

const config = require('../../../settings/config.json')


var p = console.log

  const styles={
      bar:{
        // backgroundColor:'#fbfbfb',//'#f8f8f8', //#f5f5f5
        zIndex:115,
        width:'100%',
        height:'40px',
        position:'fixed',
        left: 0,
        top: 0   
      },
      fieldIcon:{top:1, fontSize: '17px', width:17,marginLeft: -27, position: 'relative', pointerEvents:'all'}
    }


class Tabs extends React.Component {


    constructor(props) {
        super(props);
        this.state = { submenu:this.props.submenu!==undefined ? this.props.submenu : true};
      
    }


    componentDidUpdate(prevProps){
     
      if(this.props.user && this.props.user.feeds && (JSON.stringify(prevProps.user.feeds) !== JSON.stringify(this.props.user.feeds))){ 
        let oldId = prevProps.feeds[this.props.selectedView].id

        //If it's not in the user feeds anymore
        if(prevProps.user.feeds.find(f=>f.id === oldId) && !this.props.user.feeds.find(f=>f.id === oldId)){
        //Selected Feed removed
          oldId = prevProps.feeds[this.props.selectedView+1] ? prevProps.feeds[this.props.selectedView+1].id : prevProps.feeds[this.props.selectedView-1].id
        }
        this.props.setFeeds(this.props.user.feeds, oldId)//TODO: make currentFeed be based on id and not on index
     
      }

    }

    newUser(){
      let {feeds, user} = this.props
      let hasFeeds = feeds && feeds.filter(f=>!['profile', 'search','en','login'].includes(f.id)).length>0
      return user && (!user.apikey && user.feeds && user.feeds.length===0) && !hasFeeds
    }

    userIcon(selectedFeed){
      let {mobile, user, top, feeds} = this.props

      let newUser = this.newUser() 

        let options = [
          ...(user && user.writer ? 
            [
              {icon:'home',title:'Home', id:''},
              {icon:'book',title:'Guidelines', id:'guidelines', url:'https://docs.google.com/document/d/1ZEpz1RgBhPUav0sBNL723Gfp7J68b_mY_6ddX3IkiMw'},
              {icon:'cog',title:'Settings', id:'settings'},
            ]
            : [
              {icon:'inbox',title:'My Articles', id:''},
              (newUser ? null : {icon:'plus',title:'New Feed', id:'new'}),
              (newUser ? null : {icon:'cog',title:'Settings', id:'settings'}),
              {icon:'life-ring',title:'Support', id:'support', url:'https://monok.news/docs/'}
            ].filter(s=>s)),
          {icon:'sign-out',title:'Log out', id:'logout'}
        ]


      let page = selectedFeed && selectedFeed.menu ? selectedFeed.menu.find(p=>p.type==='page' && p.selected) : null
      page = page ? options.findIndex(op=>op.id===page.value) : -1

      let face = (<div className="animate" style={{float: mobile ? 'initial' : 'right', overflow:'hidden', whiteSpace: 'nowrap', width: top ? '100%' : "39px"}} >
            <img className="animate" onError={(e) => {
              e.target.src = "/static/img/avatar.png"
              e.target.onerror = null; // prevents looping
            }} style={{height:40, width:40, padding: (mobile) ? 7 : (top ? 4 : 7), borderRadius:'50%'}} src={user.avatar}/>
              {mobile ? null : user.name}
            </div>)

    
          return <Flip
                  style={{
                    fontWeight:'normal', 
                    margin:0,
                    marginRight:15,
                      border:'none',
                      borderRadius:0,
                      padding:0,
                    // top:40,
                    position:'relative'
                  }}  
                  dropdown={true} 
                  face={face}
                  dropStyle={mobile ? {
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      marginTop:0,
                      margin:0,
                      flexDirection: 'column',
                      width: '100%',
                      fontSize: 'xx-large',
                      height: '100vh',
                      maxHeight:'unset',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background:'radial-gradient(#ffffff, #ffffffb8)'
                    } : {
                    right:0,
                    width: 'max-content',
                    marginTop:40
                  }}
                  iconStyle={
                    {fontSize:'x-large'}
                  }
                  light={true}
                  value={page} 
                  options={options} 
                  onChange={(i,option)=>{
                    let id = options[i].id
                    if(options[i].url){
                      window.open(options[i].url, "_blank")
                    }else if(id==='logout'){
                      this.props.logout()
                      browserHistory.push({pathname: '/login'}) 
                    }else{
                      browserHistory.push({pathname: '/profile/'+id})
                    }
                  }}/>
    }


    render (){

      let selectedFeed = this.props.feeds[this.props.selectedView]

      let isMobile = this.props.mobile
      let hiddenMenu = this.newUser() || selectedFeed && selectedFeed.menu && selectedFeed.menu.find(p=>p.type==='page' && ['settings', 'new'].includes(p.value) && p.selected) || (window.status ==="404")
      
      let forceMenu = selectedFeed && selectedFeed.type==='home' && window.status !=="404" && !this.newUser() 


 

      var bar = <div className="animate"  style={{padding:'0px 15px', 
                                                  justifyContent: (isMobile ? 'center' : 'space-between'), 
                                                  paddingTop: (this.props.top ? 15 : 0), 
                                                  display: 'flex', 
                                                  maxWidth: 1165 , 
                                                  backgroundColor:'#fefefe', 
                                                  margin:'auto'}}>
                        <div 
                        className="animate"  
                        style={{height:40,
                              width:isMobile ? 80 : (this.props.top ? 118 : 90), 
                              background:"center / contain no-repeat url('"+config.server[window.env].staticUrl+"img/icons/monok.gif')",
                              cursor:'pointer'
                        }}
                        onClick={()=>browserHistory.push({pathname: '/'})}>
                       </div>

                       {this.userIcon(selectedFeed)}
                </div>

      return (
        <div className="animate" style={{marginTop: (this.props.top ? 30 : 0)}}>
          <div style={styles.bar} key="tab-bar">
            {bar}
          </div>
          {selectedFeed ? <Menu user={this.props.user} isMobile={isMobile} feedIndex={this.props.selectedView} feed={selectedFeed} open={forceMenu || (this.state.submenu && !hiddenMenu)}/> : null} 
        </div>
      );
    }

 
}


 
const mapStateToProps = function(store) {
  return {
    feeds: store.feeds,
    // userFeeds: store.auth.user.feeds,
    selectedView: store.feeds.findIndex(f=>f.id === store.view.selectedView),
    selectedPuff: store.puff.selectedPuff.puff,
    user:store.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    setFeeds:(newFeeds, goto)=>dispatch(setFeeds(newFeeds, goto)),
   
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Tabs);