var config = require('../../../settings/config.json')
var Widget = require('../puff/widget.jsx')
import Turndown from "turndown";
const td = new Turndown({headingStyle:'atx'});

import Drawdown from './drawdown.js';
let drawdown  = new Drawdown()

class articleParser {

  constructor(props){

  }

  imageUrl(url){
    if(url.indexOf('http')===0){
      return url
    }else{
      let filename = url.indexOf('media')===0 ? url.split('media/')[1] : url
      return (config.server[window.env].S3).replace('{bucketname}',('monokthumb-'+filename[0])) + filename.substring(0,2) + '/' + filename   
    }
    return url
  }

  parseMD(md){
    return drawdown.markdown(md)
  }

  getBlocks(text){
    return text.split("\n") //text.match(/(?:("+)[\s\S]+?\1|.)+/gm)
  }

  toHtml(text){
    return this.getBlocks(text).reduce((a, b, i)=>{  
      if(b.indexOf('{')===0){
        try{
          return a + this.richObject(JSON.parse(b))
        }catch(e){
          console.log('JSON PARSE FAILED')
          // console.log("b:")
          console.log(b)
          // console.log(e)
          return a
        }
      }else{
        return a + drawdown.markdown(b)
      }
    },'')
  }

  richObject(o){
    if(!o){
      return null
    }
    if(o.quotes && o.quotes[0]){
      let quote = o.quotes[0]
      return this.quote(quote)
    }else if(o.type && ['twitter', 'instagram', 'video','person', 'organization', 'facebook','spotify','reddit','tiktok'].includes(o.type)){
      // return `<p id='${JSON.stringify(o).hashCode()}-container' data-widget='${JSON.stringify(o)}' contentEditable="false" readonly="true" >Loading ${o.type}</p>`
      return `<p id='${JSON.stringify(o).hashCode()}-container' data-widget='${JSON.stringify(o).encode()}' contentEditable="false" readOnly="true" ></p>`
      // return `<div id='${JSON.stringify(o).hashCode()}-container' data-widget='${JSON.stringify(o)}' contentEditable="false" readonly="true" ><p>Loading ${o.type}</p></div>`
    }else if(o.type && o.type==='takeaways'){
      return  `<div class="takeaways" data-widget='${JSON.stringify(o).encode()}' contentEditable="false">
                <h2>${o.title}</h2>
                <p class="takeaways-intro">${o.intro}</p>
                <div class="takeaways-points">
                  <ul>
                    ${o.points.map((point, index) => `<li>${point}</li>`).join('\n')}
                  </ul>
              </div>
              </div>`
    }

    return null
  }




  quote(quote){
    let widget = quote.widget ? quote.widget.data[0] : null
    let avatar = widget && widget.image ? {url:this.imageUrl(widget.image.url), type:widget.image.type, description:widget.description} : null
    let speaker = quote.canonicalSpeaker ? quote.canonicalSpeaker : (quote.speaker ? quote.speaker : 'Who said this??') 
    return `<blockquote data-widget='${JSON.stringify({quotes:[quote], type:'quote'}).encode()}'>${quote.text}<footer>${avatar && false ? `<img data-widget='${JSON.stringify(widget).encode()}' class="${avatar.type==='face' ? 'avatar face' : 'avatar'}" ${avatar.type!=='face' ? ('style="background-image: url('+avatar.url+');"') : ('src="'+avatar.url+'"')}></img>` : ''}${speaker}${avatar ? `<p class="desc">${avatar.description}</p>` : ''}</footer></blockquote>`
  }

  toText(element){
   
    let child = element ? element.firstChild : null
    let texts = [];

    while (child) {
      // console.log(child)
        if (['P','H2','H3','DIV'].includes(child.tagName)) {
          
          let widget = child.dataset.widget
          if(widget){
            // console.log(widget.decode())
            texts.push(widget.decode());
          }else{
            let markdown = null

            //Weird medium bug that turns H2 to Span with style
            //TODO: match against H2 style, 30px is because bootstrap sets H2 at that
            if(child.childNodes &&
              child.childNodes[0] && 
              !child.childNodes[1] && 
              child.childNodes[0].nodeName==='SPAN' && 
              child.childNodes[0].style['font-size'] && 
              child.childNodes[0].style['font-size'].replace('px','')==='30'){
              // console.log(child.childNodes[0])
              // console.log(child.childNodes[0].style['font-size'])
              // console.log(child.childNodes[0].nodeName)
              markdown = '## '+td.turndown(child.outerHTML)
            }

            //To markdown
            markdown = markdown ? markdown : td.turndown(child.outerHTML);
            texts.push(markdown)
          }
        }else if(child.tagName === 'BLOCKQUOTE'){
          let quote = [...child.childNodes].filter(node=>node.tagName!=='FOOTER').reduce((a,b)=>a+' '+(b.innerText ? b.innerText : b.textContent),'')
          let description = child.querySelector('.desc')
          let byline = child.querySelector('footer')
          let avatar = child.querySelector('img')

          quote = quote && quote !== '' ? quote.replace(/\s+/g,' ') : null
          description = description ? description.innerText : null
          
          byline = byline ? [...byline.childNodes].filter(node=>node.className!=='desc').reduce((a,b)=>a+' '+(b.innerText ? b.innerText : b.textContent),'') : null
          byline = byline && byline !== '' ? byline.replace(/\s+/g,' ') : null
          
          avatar = avatar ? avatar.dataset.widget : null

          try{
            avatar = JSON.parse(avatar)
          }catch(e){
            avatar = null
          }

          if(quote){ //&& byline
            byline = byline ? byline : ''
            let q = {quotes:[{text:quote.trim(), canonicalSpeaker: byline.trim()}], type:'quote'}
            if(avatar){
              q.quotes[0].widget = {data:[{...avatar,text: byline, description: (description ? description : avatar.description)}]}
            }
            texts.push(JSON.stringify(q))
          }
        }else{
          texts.push(child.innerText);
        }

        child = child.nextSibling;
    }
    // console.log(texts)
    return texts.filter(para=>para!=='').join("\n");
  }


}
export default articleParser