import React from 'react';
import { connect } from 'react-redux';
import {buildPuff} from '../../actions/puff';
import Creditcards from '../billing/creditcards.jsx';
import { browserHistory } from 'react-router';
import store from '../../store';
import { setFocusedPuff } from '../../actions/puff';
import TT from './tt.jsx';
import Mini from './mini.jsx';
import Sources from '../puff/sources.jsx';
import Flip from './flip.jsx';
import {setting} from '../../util.js';
import TagInput from '../feed/taginput.jsx';

var config = require('../../../settings/config.json')

const style = {
	container:{
		width: '65%',
		minHeight:400,
    maxWidth: 900,
    minWidth: 800, 
		display:'flex',
		flexDirection:'column',
		// gridTemplateColumns:'auto auto',
		margin:'27vh auto 200px',
		background: 'linear-gradient(0deg, rgb(242 242 242) 23%, rgb(255 255 255) 87%)',
  	borderRadius: 30,
  	// overflow: 'hidden',
  	zIndex:150
	},
	notice:{
		background: '#ebf3fc',
    padding: 10,
    borderRadius: 30,
    fontWeight: 'bold',
    textAlign: 'center'
	},
	mobileContainer:{
		width:'100%',
		height:'100%',
		position:'absolute',
		margin:0,
		minWidth:0,
		display:'initial',
		overflowY: 'auto',
		borderRadius:0,
		background:'white'
	},
	toggle:{
		fontSize:'x-large',
		color: 'white',
		verticalAlign: 'middle',
		marginLeft: 10,
		float:'right',
		padding:'1px 3px 1px 20px',
		background:'#59bef1',
		borderRadius:20,
		cursor: 'pointer'
	},
	close:{
	    position: 'fixed',
	    right: 15,
	    top: 15,
	    fontSize: 'x-large',
	    color: 'lightgrey',
	    zIndex: 100,
	    width: 45,
	    height: 45,
	    width: 45,
	    height: 45,
	    textAlign: 'center',
	    borderRadius: '50%',
	    padding: 9
	},
	category:{
		// border: '2px solid',
		borderRadius: 30,
		// textShadow:'0px 0px 1px #000000bf',
    padding: '7px 13px',
    background:'white',
    width: 'fit-content',
    display: 'inline-block',
    margin:2
	},
	title:{
		fontSize:'23pt',
		fontWeight:'bold',
		lineHeight:1.1
	},
	preview:{
		color:'black', 
		// backgroundColor:'#59bef1', 
		// paddingLeft:60,
		borderRadius:'30px 0px 0px 30px',
		// overflow:'hidden'
		minWidth:'fit-content',
		display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
	},
	thumbnail:{
	    height: 200,
	    backgroundPosition: 'center 20%',
	    margin: -60,
	    marginBottom:0,
	    backgroundSize: 'cover'
	}
}

const allPlugins = [
      							{type:'articlegeneration'},
      							{type:'photo'},
      							{type:'videoembeds'},
      							{type:'mediaembeds'},
      							{type:'socialmediacomments'}
    							]
let pluginList = [
	  		{title:"Unique Title and Text", type:'articlegeneration', price:2.5, enabled:false, description:"Generate multiple unique texts and headlines for this story using Artificial Intelligence"},
  			{title:"Tone", type:'tone', price:0, enabled:false, description:"Control the tone of the article",
  				options:{
  					tone:['automatic tone','formal & journalistic', 'empowering & conversational', 'narrative & story-driven', 'authoritative & insightful', 'satirical & witty','casual & gossipy','magnetic & persuasive']
  				}},
    		{title:"Relevant photos", type:'photo',
    			options:{
    				attribution:['no embedded text', 'embed attribution', 'embed full description'],
    				branding:['with your branding', 'no branding']
    			},
    		 price:0, enabled:false, description:"Add relevant photos to the story"},
	  		// {title:"Relevant Social Media Comments", type:'socialmediacomments', price:0.5, enabled:false, description:"Include comments from Twitter and Reddit about the story"},
  			{title:"Video embeds", type:'videoembeds', price:0, enabled:false, description:"Embed in the article, relevant videos",
  				options:{amount:['occasionally', 'moderate', 'excessive']}},
  			{title:"Social Media embeds", type:'mediaembeds', price:0, enabled:false, description:"Embed in the article, relevant social media posts",
  				options:{amount:['occasionally', 'moderate', 'excessive']}},
  			{title:"Key Takeaways", type:'takeaways', price:0, enabled:false, description:"Add Key Takeaway highlight box",
  				options:{
  					amount:['always', 'often', 'occasionally'], 
  					style:['Summary Points', 'Data-Driven Nuggets','Surprising Insights','Actionable Advice', 'Motivational Highlights'], 
  					position:['above first subheading', 'top', 'bottom']}
  				},
  			{title:"Interlinking", type:'interlinking', price:0, enabled:false, description:"Add links to previous relevant stories and important pages",
  				options:{
  					amount:['automatic amount','minimal','moderate','generous']
  				}},
  			{title:"Outbound Links", type:'links', price:0, enabled:false, description:"Add outbound links to authorative sources", 
  				options:{
  					// amount:['minimal','moderate','generous'],
  					open:['in new tab', 'in same tab']
  				}
  			},
  		
  			{title:"Target Links", type:'targetlink', price:0, enabled:false, description:"Add anchor text for links to specific pages",
  				options:{
  					amount:['always', 'often', 'occasionally'], 
  					perArticle:['one link per article', 'two links per article', 'three links per article'],
  					linkUsage:['use a link once per feed', 'use a link repeatedly'],
  					target:[{name: "feed specific", id:'this'}, "profile.anchors"], 
  					targets:[{anchorText:"", anchorURL:""}]
  				},
  				settings:{
  					targets:{
  						render:'inputs',
  						visible:'target'
  					}
  				}
  			},
  			{title:"Keyword-driven", type:'keywords', price:0, enabled:false, description:"Ensure content includes certain keywords",
  				options:{
  					frequency:['always', 'often', 'occasionally'], 
  					amount:['automatic amount','few keywords', 'some keywords', 'keyword-heavy'],
  					source:['same as topics', "specific keywords"],
  					keywords:[]
  				},
  				settings:{
  					keywords:{
  						render:'keywords',
  						visible:(plugin)=>{return plugin.source==='specific keywords'}
  					}
  				}
  			}
    	]

class Checkout extends React.Component {

  constructor(props) {
      super(props);
      let showcase = props.showcase

      let targetFeed = props.targetFeed ? props.targetFeed : (props.user && props.user.targetFeed && props.availableFeeds && props.availableFeeds.find(feed=>props.user.targetFeed===feed.id) ? this.props.user.targetFeed : 'profile')
      // let targetFeedObj = props.availableFeeds.find(feed=>targetFeed===feed.id)

      let curator = props.user && props.user.curator

      pluginList = pluginList.filter(p=>p.type==='targetlink' ? (props.user.admin || props.user.email==='tools@apexmedia.ltd') : true)

      let pList = pluginList

      pList = this.expandPluginOptions(pluginList, props.user)
      // pList.forEach(p=>p.type==='targetlink' ? p.options.target = props.user.anchors.map((a,i)=>({id:i+' anchor group', anchors:a})).map(group=>group.id).concat(['feed specific']) : null)

      this.state = {
      	loaded:false,
      	index:0, 
      	prompt:"",
      	targetFeed: targetFeed,
      	pluginList:pList,
      	paymentMethod:(this.props.user && this.props.user.apikey),
      	articleGeneration:this.props.feed && this.props.feed.articleGeneration ? this.props.feed.articleGeneration : [
      			{value: ('words:3')},
      			{value: ('order:' + (curator ? "0" : "2"))}
      			],
      	plugins:this.props.feed && this.props.feed.plugins ? this.newPlugins(this.props.feed.plugins) :
      					 allPlugins.map(p=>pList.find(op=>op.type===p.type)).filter(p=>p)
      }
     
      this.build = this.build.bind(this);
      this.newPlugins = this.newPlugins.bind(this);
      this.handlePaste = this.handlePaste.bind(this);

  }

  expandPluginOptions(plugins, user) {
	  if (user) {
	    plugins.forEach(plug => {
	      Object.values(plug.options ? plug.options : []).forEach((val, j) => {
	     
	        if (Array.isArray(val)) {
	          // Use a standard for loop for better control while modifying `val`
	          for (let k = 0; k < val.length; k++) {
	            let el = val[k];
	            
	            if (typeof el === 'string' && el.startsWith('profile.')) {
	              // Retrieve list from user object
	              let listFromProfile = user[el.split('.')[1].trim()];
	              // console.log(listFromProfile);
	              
	              // Ensure listFromProfile exists and is an array
	              if (Array.isArray(listFromProfile)) {
	                // Replace `el` with items from `listFromProfile`
	                val.splice(k, 1, ...listFromProfile.map(item => typeof val[0] === 'object' && item.name && item.id ? ({name: item.name, id: item.id}) : (item.id||item.name)));
	                k += listFromProfile.length - 1; // Adjust the index to continue correctly
	              }
	            }
	          }
	        }
	      });
	    });
	    // console.log(plugins)
	  }
	  return plugins
	}

  build(event){
  	let aicurate = event.aicurate
  	// console.log(this.props.article)
  	// let isSingle = this.props.article.size===1 && this.props.article.sources && this.props.article.sources[0] && this.props.article.sources[0].url
  	let id = false ? this.props.article.sources[0].url : (this.props.article.id.includes('/') ? this.props.article.id.split('/')[1] : this.props.article.id)
  	// console.log(id)
  	let plugins = this.state.plugins.filter(plugin=>plugin.enabled).map(plugin=>({type:plugin.type}))

  	let newDraft = this.props.article && this.props.article.type==='new'
  	this.props.buildPuff(id, 
  											plugins.concat(this.props.article.size < 2 ? [{type:'single'}] : []), 
  											this.state.targetFeed==='profile' ? null : this.state.targetFeed,
  											{aicurate:aicurate, articleGeneration:this.state.articleGeneration, prompt:this.state.prompt.length>0 ? this.state.prompt : (newDraft ? 'newdraft' : null)})
  	this.setState({index:1})
  }

  articleGenSetting(feed, existingSettings){
  	let curator = false// this.props.user && this.props.user.curator
  	let newArticleGenSettings = existingSettings ? existingSettings : this.state.articleGeneration
		if(feed && feed.articleGeneration){
			let targetLanguage = feed.articleGeneration.filter(s=>s.value.indexOf('language:')===0)
			let targetLength = feed.articleGeneration.filter(s=>s.value.indexOf('order:')===0)
			let typeOfContent = feed.articleGeneration.filter(s=>s.value.indexOf('typeOfContent:')===0)
			if(targetLanguage.length<1){
				targetLanguage = [{value:'language:en'}]
			}
			if(targetLength.length<1){
				targetLength = [{value:'order:1'}]
			}
			if(curator){
				targetLength= []
			}
			newArticleGenSettings = typeOfContent.concat(targetLength).concat(targetLanguage).concat(newArticleGenSettings).filter((sett, i, arr)=>sett.value.indexOf(':')>-1 ? arr.findIndex(oSett=>oSett.value.startsWith(sett.value.split(':')[0]+':'))===i : true)
		}
		return newArticleGenSettings
  }

  componentDidMount(){
  	// let animation = [{},{},{}]
  	setTimeout(()=>{
  			if(this.props.availableFeeds && this.state.targetFeed){
  				//Single Order
  				let feed = this.props.availableFeeds.find(f=>f.id===this.state.targetFeed)
  				
  				let newArticleGenSettings = this.articleGenSetting(feed)
        	this.setState({loaded:true, articleGeneration:newArticleGenSettings, plugins:feed ? this.newPlugins(feed.plugins) : this.state.plugins.map(p=>p.type=='photo' ? ({...p, enabled:true}) : p)});

  			}else{
  				//Feed
  				let newArticleGenSettings = this.articleGenSetting(this.props.feed)
        	this.setState({
        		loaded:true 
        	});
  			}
   
     }, 1500);


  		if(!this.props.feed && !(this.props.availableFeeds && this.state.targetFeed)){
	  	 setTimeout(()=>{
	        this.setState({plugins:this.state.plugins.map(p=>p.type=='articlegeneration' ? ({...p, enabled:true}) : p)});
					
	     }, 1000);
  		}
  }

  newPlugins(newPl){
  	return (this.state && this.state.pluginList ? this.state.pluginList : pluginList).map(p=>{
  		return newPl ? 
  		(newPl.find(op=>op.type===p.type) ? {...p, enabled:true, ...(newPl.find(op=>op.type===p.type))} : {...p, enabled:false}) : p
  	}).filter(p=>p)

  }

  isURL(url){
  	var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
		var regex = new RegExp(expression);

		if (url.match(regex)) {
		  return true
		} else {
		  return false
		}
  }

  convertCamelToText(str){
  	return str.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase());
  }

  handlePaste(e){
  	 // Get pasted data via clipboard API
    let clipboardData = e.clipboardData || window.clipboardData;
    let pastedData = clipboardData.getData('Text');
 
    // insert text manually
    document.execCommand("insertHTML", false, pastedData);
    // Stop data actually being pasted into div
    e.stopPropagation();
    e.preventDefault();
  }

  render () {


	let {article, isMobile, user, forWriter, availableFeeds, showcase} = this.props
	let {index, paymentMethod, plugins, loaded, targetFeed, expanded} = this.state
	// console.log(plugins)
	let cost = plugins.reduce((a,b)=>a+(b.enabled ? b.price : 0),0.00)
	let self = this
	let allFeeds = availableFeeds ? availableFeeds.filter(feed=>!['en','search'].includes(feed.id)) : []
	let hasAPIkey = user && user.apikey
	let acceptedTerms = (user && user.agree || this.state.acceptedTerms)
	let sharedFeed = targetFeed && targetFeed!=='profile' && (!user.feeds || !user.feeds.find(f=>f.id === targetFeed))

  let order = this.props.feed ? setting(this.props.feed.articleGeneration, 'order', 0) : (this.state.articleGeneration ? setting(this.state.articleGeneration, 'order', 0) : 0)
	let targetLanguage = setting(this.state.articleGeneration, 'language', 'en')
	let human = order>0//plugins.find(p=>p.type==='humanedit' && p.enabled)

	let onCredit = user.credit > 0 && !paymentMethod && !human
	let buildable = acceptedTerms && (paymentMethod || sharedFeed || onCredit) && cost > 0
  let confirmed = index===1
  let remaining = "You have " + user.credit + " free articles left"
  	// console.log(article)
  let perDay = this.props.feed ? setting(this.props.feed.articleGeneration, 'curate', 1) : 0
  let freq = this.props.feed ? setting(this.props.feed.articleGeneration, 'frequency', 'daily') : 0

  let prices = {0:0, 1:2.5, 2:5, 3:22.5, 4:12.5, 5:17.5}

  let newDraft = article.type==='new'
  let emptyDraft = newDraft && !(this.state.prompt && this.state.prompt.length>0)
  let isUrl = this.isURL(this.state.prompt)

	let originalStats = {}
	if(!showcase && article.summary){
	   originalStats = JSON.parse(JSON.stringify(article.summary))
	   originalStats['newspaper-o'] = originalStats.sources
	   originalStats['comment-o'] = originalStats.comments
	    delete originalStats.comments
  		delete originalStats.sources
	}

	let blackcat = config.blackcat
	let widthSize = showcase ? null : (hasAPIkey ? '65%' : (forWriter ? '65%' : 900))

	let wordsOption = setting(this.state.articleGeneration, 'words', 0)

	// console.log(article)
  	return <div className={showcase ? '' : 'active-bigpuff'} 
  							onMouseDown={(event)=>{event.stopPropagation()}}
  							style={{...(showcase ? {
  													background:'none'
  												} : style.container),
  												width:widthSize, ...(isMobile ? style.mobileContainer: {}),
  												...(this.props.style ? this.props.style : {})}}>
  	{isMobile ? 
              [<span className={"fa fa-times"} aria-hidden="true" style={{...style.close,...{backgroundColor: 'black', color:'white'}}} onClick={()=>{store.dispatch(setFocusedPuff(0))}}></span>,
            <span className={"fa fa-times"} aria-hidden="true" style={{...style.close, ...{opacity:0.4, zIndex:0, backgroundColor: 'black'}}} ></span>] : null}
  	

  	<div style={{...{display:'flex', position:'relative', flexDirection: isMobile ? 'column' : undefined},	...(showcase ? { 
  	  													margin:'40px 0px 0px 10px',
  	  													flexDirection: 'row-reverse', 
  	  													justifyContent: 'space-between',
  	  													alignItems: 'flex-start'
  	  												} : {})}}>


			  		{user && user.curator ? null  :
			  		<div className="animate" 
			  			style={{...style.preview, 
			  				padding: isMobile ? 0 : '0px 0px 0px 50px',
			  			width:isMobile ? '100%' : 300,
			  			...(forWriter ? {width:confirmed ? 800 : 325, paddingLeft: confirmed ? 0 : 60} : {}),
			  			...(showcase ? {height:null} : {})
			  		}} 
			  			>
			  			 <div className="animate" style={{position:'relative', 
			  						  									minHeight: isMobile ? 0 : 360, 
			  						  									display: 'flex', 
			  						  									flexDirection: 'column', 
			  						  									justifyContent: forWriter ? 'center' : 'flex-end', 
			  						  									marginTop: showcase ? '-25%' : (forWriter ? 50 : -65), 
			  						  									margin:confirmed ?  'auto auto auto 150%' : (showcase ? '-25%' : 'auto auto auto 0%')}}>
			  						  				{!showcase && article.thumbnail && isMobile ? 
			  						  					<div className="animate" style={{...style.thumbnail, margin: '0px 0px 0px -60px', backgroundImage:'linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgb(255 255 255)), url("'+article.thumbnail+'")'}} ></div> : null}
			  							  			{isMobile ? <p style={{...style.title, margin:'0px 60px', textAlign:'center'}}>{article.title}</p> : 
			  							  			<Mini example={showcase} right={showcase} loaded={this.state.loaded} article={newDraft && !emptyDraft && !isUrl ? {...article, title:this.state.prompt} : article} style={{zIndex: 1, margin:'auto', bottom:forWriter ? '0%' : '30%'}} plugins={plugins} confirmed={confirmed}/>}
			  							  		
			  							  		</div>
				  		
			  		</div>
			  		}
			  		
		  			{confirmed ? <div style={{position:'absolute', 
		  																display: 'flex', 
		  																flexDirection: 'column',
		  																justifyContent: 'center', 
		  																textAlign: 'center', 
		  																width:'fit-content', 
		  																padding:'30px 100px',
		  																width:isMobile ? undefined: '50%',
		  																height:'100%',
		  																margin:'auto'}}>
		  							<i className="fa fa-check-circle-o" style={{fontSize: 'xxx-large'}} aria-hidden="true"></i>
		  							<p style={{fontWeight:'bold', fontSize: 'x-large'}}>Success! Your article is being built</p>
						  			 <div>
						  				<div className="whiteButton" style={{padding:"10px 20px", marginTop:10}} onClick={()=>{store.dispatch(setFocusedPuff(0))}}>Back to browsing</div>
						  				<div className="whiteButton" style={{padding:"10px 20px", marginTop:10}} onClick={()=>browserHistory.push({pathname: '/'+ (targetFeed ? targetFeed : 'profile'), query:{refresh:true, ...(targetFeed ? {product:'articlegeneration', sort:'date', view:'masonry', product:'articlegeneration'} : {})}})}>Track your build</div>
						  			</div>
		  						</div> : null}


			  		{confirmed && isMobile ? null : 
			  			<div className="animate" style={{
			  									width: (showcase ? '63%' : '100%'),
			  									pointerEvents: confirmed ? 'none' : null, 
			  									// width: confirmed ? 0 : (showcase ? '50%' : '100%'), 
			  									opacity: confirmed ? 0 : 1, 
			  									padding:(isMobile ? 30 : (showcase ? 0 : 60)), 
			  									display:'grid', 
			  									gridTemplateRows:showcase ? null : 'auto 85px', 
			  									marginLeft:0, overflow:'hidden',
			  									borderRadius:showcase ? null : '0px 30px 30px 0px', 
			  									background:(showcase || forWriter) ? 'transparent' : 'transparent'}}>
			  			<div>
				  			{showcase ? null : <p style={style.title}>{newDraft ? "New Article" : (human ? "A Human written article" : "AI Generate a Draft")}</p>}
				  			{showcase ? null : <p style={{fontSize:'large'}}>{newDraft ? "Type in a title of an article or the URL for an article online" : (human ? "Order a unique, human written version of this story" : (article.sources.length<3 ? "This is a small story, may experience difficulty in generating article text" : "Build a unique, SEO friendly summary of this story"))}</p>}
				  			
				  			{newDraft ? <input 
				  					value={this.state.prompt}
				  					onChange={(e)=>this.setState({prompt:e.target.value})}
				  					style={{
				  					borderRadius: 20,
								    width: '100%',
								    minHeight: 50,
								    border: 'none',
								    boxShadow: '0px 2px 4px inset #0000003d',
								    padding: '0px 20px'}} 
								    placeholder="A title of an article or a URL for an article online"/>: null}

				  			<div style={{
								    display: 'flex',
								    flexWrap: 'wrap',
								    justifyContent: 'center',
								    flexDirection: 'column',
								    margin:'15px 0px'
			  				}}>
					  		{forWriter ? null : <div style={{fontSize: '25px'}}>
					          {Object.keys(originalStats).filter(k=>originalStats[k]>0).map((k,i)=>
					          	<span onClick={e=>{k==='newspaper-o' ? this.setState({expanded:true}) : null}}>
					          		<span key={'cludetail'+i}
			          							className={"fa fa-"+(k==='videos' ? 'film' : k)} 
			          							aria-hidden="true"  style={{marginLeft:i>0 ? 10 : 0}}></span>
			          							{' '+originalStats[k]}
			          			</span>)}
					        </div>}

				  		</div>
				  			<div style={{display:'grid', gridTemplateColumns:'auto auto'}}>
				  				{user && user.curator && sharedFeed ? null  : plugins.map((plugin, i)=>(this.props.hide ? this.props.hide.includes(plugin.type) : ['interlinking'].includes(plugin.type)) ? null :
				  					[<p style={{color:plugin.enabled ? 'initial' : '#a9a9a9', display:'flex',rowGap: 10, flexWrap: 'wrap', overflow:'hidden'}}>
				  								{plugin.title}
				  								{plugin.options ? Object.keys(plugin.options).map(key=>{

				  								// 	//Option Settings
				  									let setting = plugin.settings ? plugin.settings[key] : null

				  									if(setting && setting.visible && typeof setting.visible === 'function'){
				  										
				  											if(!setting.visible(plugin)){
																	return null
				  											}
														}

				  									if(plugin.settings && plugin.settings[key] && plugin.settings[key].render==='keywords'){
				  										
				  										// let feedQueries = this.props.feed ? (this.props.feed.searchQuery ? this.props.feed.searchQuery : this.props.feed.categoryFilter[0].categories) : []

				  										return <div key={"keywordField"+"-"+key+"-"+i} className="animate" style={{padding:plugin.enabled ? 10 : 0, background:'white', borderRadius:10, height:plugin.enabled ? '100%' : '0px', overflow:'hidden', width:'100%'}}>
				  													 <TagInput 
											              placeholder={`Type your keywords here${!['en','en-us','en-gb','en-ie'].includes(targetLanguage) && window.languages.find(l=>l.code===targetLanguage) ? ` (in English or ${window.languages.find(l=>l.code===targetLanguage).title})` : ''}`} 
											              // recommendations={feedQueries}
											              value={plugins[i][key] ? plugins[i][key].map(k=>k) : []}
											              onChange={(newTags)=>{
											              		plugins[i]={...plugins[i], [key]:newTags}

													  						this.setState({plugins:plugins}, ()=>{
														  						if(this.props.update){
														  							this.props.update({plugins:plugins})
														  						}
													  						})
											              }}
											              />
				  													
															</div>

				  									}else if(plugin.settings && plugin.settings[key] && plugin.settings[key].render==='inputs'){
															
															let setting = plugin.settings[key]

															if(setting && setting.visible && plugin[setting.visible] && plugin[setting.visible]!=='this'){
																return null
															}

															return <div className="animate" style={{width:'100%', display: 'flex', padding: 15, marginTop: plugin.enabled ? '0px' : '-100px', overflow:'hidden',height: plugin.enabled ? '100%' : '0%'}}>
																{(plugin[key] ? plugin[key] : plugin.options[key]).map((el, j, items)=>
																	Object.keys(el).map((field, k, arr)=>
																			<div style={{paddingRight:k<arr.length ? 10 : 0, width:'100%'}}>
																				<label>{this.convertCamelToText(field)}</label>
																				<input style={{width:'100%', border: 'solid 1px #00000063', padding: 5, borderRadius: 5}} value={el[field]} placeholder={el[field]}
																					onChange={(e)=>{
																					let newValue = e.target.value
																					items[j][field] = newValue
																					
																					plugins[i]={...plugins[i], [key]:items}
																				
														  						this.setState({plugins:plugins})
														  						if(this.props.update){
														  							this.props.update({plugins:plugins})
														  						}
																				}}/>
																			</div>)
																)}
															</div>

														}else{
														
					  									return <Flip style={{textAlign:'left', margin:'0px 15px', cursor: 'pointer', height: plugin.enabled ? 20 : 0, overflow:'hidden'}} 
																			dropdown={true} 
																			dropOffset={1}
																			key={'option-'+key+'-'+i}
																			value={plugin[key] ? (typeof plugin.options[key][0] === 'object' ? plugin.options[key].map(el=>el.id||el.name||el.title) : plugin.options[key]).indexOf(plugin[key]) : null}//plugin[key] ? (typeof plugin.options[key][0] === 'object' ? plugin.options[key].map(el=>el.id||el.name||el.title) : plugin.options[key]).indexOf(plugin[key]) : null}
																			options={plugin.options[key].map(elem=>(elem.title||elem.name||elem.id||elem))}
																			onChange={(newVal)=>{
																				let elem = plugin.options[key][newVal]
																				plugins[i]={...plugins[i], [key]:elem.id||elem.name||elem.title||elem}
													  						this.setState({plugins:plugins})
													  						if(this.props.update){
													  							this.props.update({plugins:plugins})
													  						}
													  						// console.log(plugins)
																			}}/>


														}
				  								}) : null}
				  								{plugin.type==='articlegeneration' ? 
														<div style={{display:'flex',flexWrap: 'wrap'}}>
															
															{user.admin ? <Flip style={{textAlign:'left', margin:'10px 15px', cursor: 'pointer', height: plugin.enabled ? 20 : 0, overflow:'hidden'}} 
																		dropdown={true} 
																		dropOffset={1}
																		value={wordsOption}
																		options={['AI 1.0', 'AI 1.5', 'AI 1.5 (600 words)','AI 2.0 (300 words)']} 
																		onChange={(i)=>self.setState({articleGeneration:[{value:'words:'+i}]
																									.concat(this.state.articleGeneration)
																									.filter((sett, i, arr)=>sett.value.startsWith('words:') ? arr.findIndex(oSett=>oSett.value.startsWith('words:'))===i : true)
															})}/> : null}
															<Flip style={{textAlign:'left', margin:'10px 15px', cursor: 'pointer', height: plugin.enabled ? 20 : 0, overflow:'hidden'}} 
															dropdown={true} 
															value={setting(this.state.articleGeneration, 'order', 0)}
															dropOffset={1}
															options={['No human edit', '300 words', '600 words', '1200 words']} 
															onChange={(i)=>self.setState({articleGeneration:[{value:'order:'+i}]
																																								.concat(this.state.articleGeneration)
																																								.filter((sett, i, arr)=>sett.value.startsWith('order:') ? arr.findIndex(oSett=>oSett.value.startsWith('order:'))===i : true)})
															}/>
															<Flip style={{textAlign:'left', margin:'10px 15px', cursor: 'pointer', height: plugin.enabled ? 20 : 0, overflow:'hidden'}} 
																		dropdown={true} 
																		dropOffset={1}
																		value={window.languages.map(lang=>lang.code).indexOf(targetLanguage)} 
																		options={window.languages.map(lang=>lang.title)} 
																		onChange={(i)=>self.setState({articleGeneration:[{value:'language:'+window.languages.map(lang=>lang.code)[i]}]
																																											.concat(this.state.articleGeneration)
																																											.filter((sett, i, arr)=>sett.value.startsWith('language:') ? arr.findIndex(oSett=>oSett.value.startsWith('language:'))===i : true)})
																		}/>
														</div> : null}
											</p>, 
											<p style={{textAlign:'right', color:plugin.enabled ? 'initial' : '#a9a9a9'}}>
					  					
					  					{plugin.price ? '€'+plugin.price : null}
					  					<TT text={plugin.description}>
						  					<span className="animate fa fa-circle" style={{...style.toggle, ...(plugins[i].enabled ? {} : {padding:'1px 20px 1px 3px', background:'rgb(213 217 223)'})}} 
						  					onClick={()=>{
						  						if(plugin.type==='articlegeneration'){
						  							// plugin.enabled ? (plugins.find(p=>p.type==='humanedit').enabled=false) : null
						  							// return
						  						}else if(plugin.type==='humanedit'){
						  							// !plugin.enabled ? (plugins.find(p=>p.type==='articlegeneration').enabled=true) : null
						  						}
						  						plugins[i]={...plugins[i], enabled:!plugins[i].enabled}
						  						this.setState({plugins:plugins})
						  						if(this.props.update){
						  							this.props.update({plugins:plugins})
						  						}
						  					}}>
						  					</span>
					  					</TT>
					  					
				  					</p>]
				  					)}
				  				{showcase || forWriter ? null : <div style={{maxHeight:37,overflow:'hidden'}}>{article.categories ? article.categories
					  			.filter(category=>!blackcat.includes(category.name))
					  			.slice(0,5)
					  			.map((category, i)=>(
					  				<div key={i+'-category-big-'+article.id} style={style.category}>
					  					{category.name}
					  				</div>)) : null}</div>}
				  			</div>
				  				 {allFeeds.length > 0 ?  
				  				 	<div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline', marginBottom:15}}>
				  				 		Send article to <Flip style={{textAlign:'left', marginLeft:7, cursor: 'pointer'}} 
				  				 													dropdown={true} 
				  				 													value={allFeeds.findIndex(feed=>feed.id===targetFeed)} 
				  				 													dropOffset={1}
				  				 													options={allFeeds.map(feed=>feed.title)} 
				  				 													onChange={(i)=>{
				  				 														let newArticleGenSettings = this.articleGenSetting(allFeeds[i])
				  				 														self.setState({articleGeneration:newArticleGenSettings, plugins:this.newPlugins(allFeeds[i].plugins), targetFeed:allFeeds[i].id})
				  				 													}}/>
				  				 	</div> : null}

				  			{
				  				// (hasAPIkey || sharedFeed || (!showcase && !human && user.credit>0)) ? 
				  				// (onCredit && !showcase ? <p style={style.notice}>{remaining}</p> : null) : 
				  				// <Creditcards onRefresh={(newBilling)=>{this.props.onNewBilling ? this.props.onNewBilling(newBilling) : 
				  				// 	self.setState({paymentMethod:newBilling})}} small={true} style={{marginTop:30}}/>
				  			}

			  				{user && user.agree ? null : 
							<label for="horns" style={{paddingTop: 10, fontWeight:'normal'}}>
								<input type="checkbox" id="horns" name="horns" checked={acceptedTerms} onChange={(e)=>{this.setState({acceptedTerms:e.target.checked})}}/>&nbsp;I have read and agree to the <a rel="nofollow" href="https://docs.google.com/document/d/e/2PACX-1vQGjfs0yMg8DGs2Oad_cyadIeX05Di0yWq7SaiFWBCV_rY266ggiA-at6Bs8bG8yoGqjuCIkPt_9eSQ/pub" target="_blank">terms of service</a>
							</label>
			  				}
				  		</div>
			  			{this.props.feed ? null : 
			  							<div style={{textAlign:'center'}}>
			  				  			
			  				  			{newDraft || user.curator ? null : <div style={{display: 'grid',
			  				  						  									     gridTemplateColumns: 'auto 100px',
			  				  						  									     fontSize: 30}}>	
			  				  						  				  				<p style={{color:'lightgrey', textAlign:'right'}}>Total</p>
			  				  						  				  				<p style={{textAlign:'right'}}><i className="fa fa-eur" aria-hidden="true" style={{fontSize: 'smaller',paddingRight: 5}}></i>{onCredit ? 0 : prices[order]+cost}</p>
			  				  						  				  			</div>}
			  				  			{newDraft ? 
			  				  			<TT text={emptyDraft ? "Create new empty draft" : "Build a new article draft from this query"}>
			  				  			 	<p onClick={buildable ? this.build : null} className="button blackButton" style={{backgroundColor:buildable ? 'black': '#dfdfdf', margin:0, padding: '10px 40px', float: 'right'}}>{emptyDraft ? "NEW EMPTY DRAFT" : (isUrl ? "BUILD FROM URL" : "BUILD NOW")}</p>
			  			  				</TT> :
			  			  				<TT text={user && user.curator ? "The story will be built and placed in order" : (buildable ? ((human || wordsOption===3) ? "Give it a try!" : (onCredit ? "This article is on us, enjoy!" : "Don't worry, You'll only be charged if you purchase the results")) : (acceptedTerms ? (!(hasAPIkey || sharedFeed) ? "You must insert a paymeny method, don't worry, you won't be charge unless you purchase the results" : "Sorry, Choose a valid combination") : "You must accept the terms of service"))}>
			  				  			 	<p onClick={buildable  ? this.build : null} className="button blackButton" style={{backgroundColor:(buildable ? 'black' : '#dfdfdf'), margin:0, padding: '10px 40px', float: user && user.curator ? undefined : 'right'}}>{user && user.curator ? "SEND TO ORDER" : ((human || wordsOption===3) ? "BUY NOW" : "BUILD NOW")}</p>
			  			  				</TT>}

			  			  			</div>}
			  		</div>}

  		</div>

  		{expanded || (user && user.curator) ? <Sources type="checkout" thumbsize={100} open={2} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 'auto auto 40px auto', padding: 40, maxWidth:600}} article={article}/> : null}
  			
  	
  		
  		{showcase ? null : <img src={config.server[window.env].staticUrl+"img/icons/monok.gif?rand="+window.monokSess} style={{width:100, margin: isMobile ? '50px auto 15px auto' : '-50px auto 15px auto', display: 'inherit'}}/>}

	</div>

  }
}

const mapStateToProps = function(state) {
  return {
  	    user:state.auth.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    buildPuff:(id, plugins, targetFeed, body)=>dispatch(buildPuff(id, plugins, targetFeed, body))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Checkout);

