import React from 'react';
import YouTube from 'react-youtube';
import Spinner from "../modal/spinner.jsx";

const radius=10
const style = {
  container:{
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      borderTopRightRadius: radius,
      borderTopLeftRadius: radius,
      overflow:'hidden',
      backgroundColor: 'black'
  }
} 


class Video extends React.Component {


  isMobile(){
     return window.innerWidth<800
  }

  constructor(props){
    super(props);
    this.state = {
      isLoading:true,
      play: props.play
    }
    this.playVideo = this.playVideo.bind(this);
    this.frameLoaded = this.frameLoaded.bind(this);
  }

  // componentDidUpdate(){
  //   console.log("UPDATE TWITTER VIDEO FOR")
  // }

  componentDidUpdate(){
    if(this.props.focus && this.props.selected && this.props.opened){
      var widget = this.props.video
              // if(!widget.autoplay && !widget.id){
                  
              // }else if(widget.type==='twitter'){

              //   this.loadTwitterVideo()
              // }
    }
  }

  componentDidMount() {
    // console.log(this.refs)
    // if(this.refs.iframe){
    //    // this.refs.iframe.getDOMNode().addEventListener('load', this.frameLoaded);
    // }
   
  }

  frameLoaded(e){
    this.setState({
      isLoading: false
    });
  }


  playVideo(e){
    if(this.props.focus){
      e.stopPropagation();
    }
    this.setState({
      play: true
    });

  }


  contextMenu(e) {
          e.preventDefault();
           // console.log("RIGHT CLICKED")
  } 

  getYoutube(videoObj){
    let experiment = this.props.absolutePosition ?  {} :{     
          width: '100%',
          transition: this.props.transitions ? 'opacity 1s' : null, 
          position: 'relative',
          opacity:1,
          paddingBottom: videoObj.height ? videoObj.height : "56%"
        } 

    let borderRadius = {}//this.props.style && this.props.style.borderRadius ? {borderRadius: this.props.style.borderRadius} : {}



    return (<div style={{...experiment,...this.props.style}}>
              <div style={{...style.container,...borderRadius}} onContextMenu={this.contextMenu}>
              <YouTube 
              videoId={videoObj.id}                            
              // onError={this.videoError} 
              onReady={this.props.play ? (event)=>{
                event.target.playVideo();
              } : null}
            
              containerClassName={"youtube-container"}  
              opts={{
                height: '100%',
                width: '100%',
                playerVars:{
                    modestbranding:0,
                    rel:0,
                    disablekb: true,
                    iv_load_policy: 3,
                    showinfo:0,
                    autoplay: 1
                    }
                }}/>
              </div>
            </div>)
  }

  maxHeight(frameHeight){
    if(Number.isInteger(frameHeight)){
      if(parseInt(frameHeight)>this.props.maxHeight){
        return this.props.maxHeight+"%"
      }
    }else if(frameHeight.indexOf('%')){
      if(parseInt(frameHeight.split('%')[0])>this.props.maxHeight){
        return this.props.maxHeight+"%"
      }
    }
    return frameHeight
  }

  getEmbedVideo(videoObj){
    var url = videoObj.url
    url = url && url.toLowerCase().includes('parent=') ? url + '&parent='+window.location.hostname : url

    if(videoObj.sourceName==='Monok' && videoObj.id){
      let puffId = this.props.puffId ?  this.props.puffId : (videoObj.id.includes('-videoID-') ? videoObj.id.split('-videoID-')[0] : null)
      let vidId = videoObj.id.includes('-videoID-') ? videoObj.id.split('-videoID-')[1] : videoObj.id
      url = 'http://dev.com/puff/'+puffId+'?vid=true&autoplay=false&embed=true&vidId='+vidId
    }
    // var url = videoObj.autoplay ? videoObj.url+videoObj.autoplay : videoObj.url
    
    let borderRadius = {}//this.props.style && this.props.style.borderRadius ? {borderRadius: this.props.style.borderRadius} : {}
    // console.log(borderRadius)
    let frameHeight = this.props.height ? this.props.height : (videoObj.height ? videoObj.height : "56%")

    let facebook = videoObj.sourceName==='Facebook' ? true : false

    let experiment = this.props.absolutePosition ?  {} :{
          width: '100%',
          transition: this.props.transitions ? 'opacity 1s' : null, 
          position: 'relative', 
          // opacity:1,
          paddingBottom: this.maxHeight(frameHeight),
          height:facebook & videoObj.sHeight ? videoObj.sHeight : this.maxHeight(frameHeight),
          backgroundColor:'black',
          borderTopRightRadius: radius,
          borderTopLeftRadius: radius
        } 

    let spinnerContainer={
                  position:'absolute', 
                  left:0, 
                  right:0,
                  top:0, 
                  bottom:0,
                  width:50,
                  height:50, 
                  margin:'auto'}

    let loadingText = {
      position:'absolute',
      width: 500,
      color: 'white',
      left: -225,
      marginTop: 35,
      textAlign: 'center'
    }
    
 

    let mp4 = false

    return (<div style={{...experiment,...this.props.style}}>
              {this.state.isLoading ? 
                <div style={spinnerContainer}>
                  <Spinner/>
                  <div key="videolabel" style={{...style.playTitle,...loadingText}}>
                    Loading {videoObj.sourceName ? ' ' + videoObj.sourceName : ' '+videoObj.type} Video
                  </div>
                </div> : null}
              <div style={{...style.container,...borderRadius,...(this.state.isLoading ? {visibility: 'hidden',transition: 'opacity 1s'} : {})}}>
                              {mp4 ? <video src={url}  width='100%' height='100%' autoplay={true} muted={true} type="video/mp4" controls={false}> </video> : 
                                    <iframe ref={'iframe'}
                                            scrolling="no"
                                            style={{ background: 'linear-gradient(0deg, #e7e7e7, #ffffff)'}} 
                                            src={url} 
                                            sandbox="allow-forms allow-scripts"
                                            width='100%' 
                                            height='100%' 
                                            frameBorder='0' 
                                            scrolling='no' 
                                            allowFullScreen='true' 
                                            marginHeight='0' 
                                            marginWidth='0'
              
                                            onLoad={this.frameLoaded}/>}
                            </div>
            </div>)

  }

  // loadTwitterVideo(){
  //   console.log('Loading twitter video...')
  //   var widget = this.props.video
  //   var containerElement = document.getElementById("twitter-embed-"+widget.id)
  //   // console.log(containerElement.innerHTML)
  //   if(this.twitterVideoNotLoaded()){
  //        twttr.widgets.createVideo(
  //           widget.id,
  //           containerElement,
  //           {
  //             conversation:'none',
  //             lang:'en',//article.language
  //             hide_tweet:true
  //           }
  //         )
  //        // console.log("INIT TWITTER VIDEO FOR "+widget.id)
  //     }else{
  //       // console.log('Video already loaded..')
  //     }
  // }

  // getTwitter(twitterObj){
  //   console.log("TWITTER VIDEO")
  //    let experiment = this.props.absolutePosition ?  {} :{     
  //         width: '100%',
  //         transition: this.props.transitions ? 'opacity 1s' : null, 
  //         position: 'relative',
  //         paddingBottom: twitterObj.height ? twitterObj.height : (this.isMobile() ? '56%':'35%')
  //       } 

  //   let borderRadius = this.props.style && this.props.style.borderRadius ? {borderRadius: this.props.style.borderRadius} : {}

  //   if(!this.twitterVideoNotLoaded()){
  //     setTimeout(()=>{
  //       this.loadTwitterVideo()
  //     }, 500)
  //   }


  //   return (<div style={{...experiment,...this.props.style}} onClick={(e)=>this.stopAllPropagation(e)}>
  //             <div id={"twitter-embed-"+twitterObj.id} style={{...style.container,...borderRadius}} onContextMenu={this.contextMenu}>
              
  //             </div>
  //           </div>)

  // }

  stopAllPropagation(e){
    e.stopPropagation()
  }

  // twitterVideoNotLoaded(){
  //   var widget = this.props.video
  //   var containerElement = document.getElementById("twitter-embed-"+widget.id)
  //   return (this.props.focus && this.props.selected && this.props.opened && 
  //     widget.data && widget.data[0] && widget.data[0].video && widget.type==='twitter' && 
  //     containerElement && containerElement.innerHTML === '')
  // }

  getThumbnail(){
      var videoObj = this.props.video
      return videoObj.thumbnail ? videoObj.thumbnail : null     
  }

  render () {
      var videoObj = this.props.video

      var style = {
        mainImage:{
            width: '100%',
            height: '100%',
            top: '0px',
            cursor:'pointer',
            borderTopRightRadius: radius,
            borderTopLeftRadius: radius,
            background: this.getThumbnail() ? "url('"+this.getThumbnail()+"') center / cover" : "linear-gradient(to top, rgb(33, 150, 243), rgb(0, 188, 212)) rgba(0, 0, 0, 0.48)",
            paddingTop: "56%"
             // this.props.thumbHeight ? this.props.thumbHeight : 
             //            (this.props.height ? this.props.height : 
             //            (videoObj.height ? videoObj.height : "56%"))
        },
        playTitle:{
            backgroundColor:'black',
            color:'white',
            display:'inline-block',
            borderRadius: 20,
            fontSize: 'small',
            fontWeight: 600,
            height: 23,
            lineHeight: 'normal',
            padding: '4px 9px 4px 9px',
            margin: 15,
            opacity: 0.6,
            position:'absolute',
            marginTop:-40,
            textTransform: 'capitalize'
        },
        playButton:{
          fontSize: 40,
          height: 80,
          width: 80,
          float: 'left',
          color: 'white',
          borderRadius: '50%',margin: '-100px 0px 0px 20px',textAlign: 'center',
          backgroundColor: '#00000085',textIndent: 8,
          lineHeight: '80px'
        }        
      }


      let gif = videoObj.source==='Giphy'
      // console.log(videoObj)
      var playImage = (<div onClick={this.playVideo} style={{...style.mainImage,...this.props.style, ...(gif && videoObj.width && videoObj.height ? {width:'100%', height:Math.round((videoObj.height/videoObj.width)*100)+'%'}:{})}}>
                       
                        {!videoObj.id && !this.props.opened && this.props.focus ?  //!videoObj.autoplay  && 
                          <div style={{
                                textAlign: 'center',
                                height: '100%',
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                top: '38%',
                                // paddingTop:videoObj.height ? videoObj.height : "56%"
                          }}>
                          <Spinner/>
                          </div> :
                          (gif ? null : (this.props.editmode || this.props.big ? <i  style={{padding:'0px 5px 0px'}} className={"fa fa-play"} aria-hidden="true" style={style.playButton}></i> : 
                                                      (!this.props.big ? <div key="videolabel" style={style.playTitle}>
                                                        <i className={"fa fa-play"} aria-hidden="true"></i>{(videoObj.sourceName ? videoObj.sourceName : (videoObj.source ? videoObj.source : videoObj.type))} Video
                                                      </div> : null)))
                         }
                          
                        </div>)
          

          if(this.props.focus && this.props.selected && this.props.opened && !gif){
            if((videoObj.sourceName==='Monok' || (!videoObj.id && videoObj.type!=='twitter')) && !this.props.editmode){ //!videoObj.autoplay &&
                return this.getEmbedVideo(videoObj)
            // }else if(videoObj.type==='twitter'){
            //   return this.getTwitter(videoObj)
            }else{
              if(this.state.play){
                if(videoObj.id){
                  if(this.props.play){
                    return this.getEmbedVideo({...videoObj, url:videoObj.url+'?autoplay=1&mute=1'})
                  }
                  return this.getYoutube(videoObj)
                }
                if(videoObj.url){
                  return this.getEmbedVideo(videoObj)
                }
              }else{
                return playImage
              }
            }
          }else{
            return playImage
          }
    }

 
}

module.exports = Video;