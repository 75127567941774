


import React from 'react';
import Integrations from './integrations.jsx';
import { connect } from 'react-redux';
import Flip from './flip.jsx';
import {saveProfile} from '../../actions/auth';
const callbackUrl = encodeURIComponent("https://www.monok.com/profile/settings")

const s =  {
  title:{
      color: 'black',
      fontSize: '50px',
      marginBottom: '30px',
      fontWeight: 'bold',
      fontFamily: "'PT Sans Narrow', sans-serif" 
    },
    integrationButton:{
      marginRight: 10, fontSize: '35px', verticalAlign: 'middle'
    },
    buttonWrapper:{
      padding:10,borderRadius:10, marginTop:10
    },
    notice:{
      background: '#ebf3fc',
      padding: 10,
      borderRadius: 30,
      fontWeight: 'bold',
      textAlign: 'center'
    }
}

class Integrate extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        changedKeys:[],
        profile:JSON.parse(JSON.stringify(this.props.user))
      }
  }  

   componentDidUpdate(prevProps){

    // if(JSON.stringify(prevProps.user.feeds) !== JSON.stringify(this.props.user.feeds) && this.props.user.feeds){ 
    //   console.log('FEEDS CHANGED')
    //   this.props.setFeeds(this.props.user.feeds, 'profile')
    // }
  }

  upd(key, obj, index){

    // if(this.props.updateCallback){
    //   this.props.updateCallback(key, obj, index)
    //   return
    // }

    this.state.profile[key] = this.state.profile[key] ? this.state.profile[key] : []
    // console.log(newState)
    if(index===null || index===undefined){
      this.state.profile[key] = obj
    }else{
      this.state.profile[key][index] = obj
      if(!obj){
        this.state.profile[key].splice(index ,1)
      }
      // this.state.profile[key][index] = this.state.profile[key].filter(o=>o)
    }

    this.setState({
                  profile:this.state.profile,
                  changedKeys:this.state.changedKeys.concat(key).filter((key,i,arr)=>arr.indexOf(key)===i),
                  addTarget:null},
                  this.save)
  }

  hostname(url){
    try{
      let res = new URL(url)
      if(res && res.hostname){
        return res.hostname
      }else{
        return ''
      }
    }catch(e){
      return ''
    }
  }

  save(){

    const allowedFields = this.state.changedKeys//['anchors', 'billingAddress','websites'];
    const filteredUpdates = Object.keys(this.state.profile).reduce((obj, key) => {
        if (allowedFields.includes(key)) {
            obj[key] = this.state.profile[key];
        }
        return obj;
    }, {});

    // this.props.user = this.state.profile
    this.props.saveProfile(filteredUpdates, true)

    this.setState({saved:true, changedKeys:[]})
    // this.props.setFeeds(this.state.profile.feeds, 'profile')
  }

  addTargetToFeed(feed, newTarget){
    this.setState({
      profile:this.state.profile,
      addTarget:null,
      save:true,
      changedKeys:[]
    },
                  ()=>{

      this.props.saveProfile({
                              feeds:{
                                  ...feed, 
                                  onSubmit: feed.onSubmit ? [newTarget].concat(feed.onSubmit) : [newTarget]
                                },
                              websites:newTarget
                            })

                  }, true)
  }

  dropDownIntegration(options, theTargets, type, onUpdate, feedId){
    let buttonStyle = {padding: '5px 20px', fontWeight: 'bold', display: 'inline-block', cursor: 'pointer', color: '#777',height:50, margin:5,background:'none', borderRadius:30, boxShadow:'none', border:'solid 1px rgb(241, 240, 240)'}
    let typedList = [{type:type, icon:'plus', new:true, title:'Add New'}].concat(options.filter(opt=>type ? opt.type===type : true))
    let types = {
      agent:{
        title:'Add Agent',
        icon:'male',
        color:'black',
        draft:{url:"", plchldrurl:"Target URL", instruction:"", plchldrinstruction:"Instruction for how to insert the article into your website", type:'agent'}
      },
      linkedin:{
        title:'Add LinkedIn',
        icon:'linkedin',
        color:'#569edb',
        url:`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77x1zp81pk71z2&redirect_uri=${callbackUrl}&state=lkjSAHDFasdfuy7y678ydskfjhasdf6gHGSDg${feedId ? '-'+feedId : ''}&scope=r_emailaddress%20r_liteprofile%20w_member_social%20rw_organization_admin%20r_organization_social%20r_basicprofile%20w_organization_social%20w_member_social`
      },
      slack:{
        img:"https://slack.com/favicon.ico",
        url:`https://slack.com/oauth/v2/authorize?scope=incoming-webhook&client_id=95830032032.2133752596225&state=lkjsdkljsduvheuhfeueueeoqqieuidSLA${feedId ? '-'+feedId : ''}`
      },
      wp:{
        title:'Add Wordpress',
        icon:'wordpress',
        draft:{url:"", plchldrurl:"Wordpress URL", type:'wp'}
      },
      twitter:{
        title:'Add X',
        img:"/static/img/integrations/xlogo.png",
        url:`https://twitter.com/i/oauth2/authorize?response_type=code&client_id=NUJTLXBCZDdvSnZjTVZEcFZtdmQ6MTpjaQ&redirect_uri=${callbackUrl}&scope=media.write%20offline.access%20tweet.read%20tweet.write%20users.read&state=lkjSAHDFasdfuy7y678ydskfjhasdf6gtwitter${feedId ? '-'+feedId : ''}&code_challenge=challenge&code_challenge_method=plain`
      },
      email:{
        icon:'envelope-o',
        draft:{url:"", type:"email", plchldrurl:"Email Address"}
      },
      api:{
        title:'Add API endpoint',
        icon:'code',
        color:'#673AB7',
        draft:{url:"", type:"api",token:"", title:"", plchldrtitle:"Title Name", plchldrurl:"API URL endpoint", plchldrtoken:"Access Token"}
      },
      youtube:{
        icon:'youtube',
        color:'red',
        url:'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.upload%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fyoutube.readonly&response_type=code&state=jkasdfkjghsdfjdlkjLKJKJHFSDNBSDghd768324sdfjh&client_id=324065030009-q8ipujch8a4iosqi8rm4kpf7d52in94f.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fwww.jorab.monok.com%2Fprofile%2Fsettings'
      },
      facebook:{
        url:`https://www.facebook.com/v19.0/dialog/oauth?client_id=387411317431132&redirect_uri=${callbackUrl}&state=lkfdjglkdjnmrglifdvjnjfkvnhsdwqewqecewfdsdFA${feedId ? '-'+feedId : ''}&scope=pages_manage_posts,pages_show_list,business_management,pages_read_engagement`,
        color:'#569edb',
        icon:'facebook'
      },
      instagram:{
        url:`https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=2226004321164934&redirect_uri=${callbackUrl}&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish%2Cinstagram_business_manage_insights&state=l89j89uj984jf894ehjf8e4hfdwqewqecewINST${feedId ? '-'+feedId : ''}`,
        color:'#569edb',
        icon:'instagram',
        style:{
              background: '-webkit-linear-gradient(#d93bff, #FFEB3B)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
        }
      }
    }


    let iconFun = (type)=>types[type].icon ? <span style={{...s.integrationButton, color:types[type].color ? types[type].color : '#569edb', ...(types[type].style ? types[type].style : {})}} className={"fa fa-"+types[type].icon} aria-hidden="true"/> : <img style={{marginRight: 10, verticalAlign: 'middle', height: 34}} src={types[type].img}/>
    let titleFun = (type)=>types[type].title ? types[type].title : ('Add '+type[0].toUpperCase()+type.slice(1))
    let title = [iconFun(type), titleFun(type)]

    return feedId && typedList.length > 1 ? <Flip tooltip={"Add an integration for "+type[0].toUpperCase()+type.slice(1)} 
                                      style={{float:'left', color:'grey', display: 'flex',flexWrap: 'nowrap', padding:15, fontWeight:'bold'}} 
                                      // dropOffset={10}
                                      dropdown={true}  
                                      light={true}  
                                      add={false}
                                      value={0} 
                                      title={title}
                                      options={typedList} 
                                      onChange={(i, option)=>{
                                        
                                        let original = typedList && typedList[i] ? typedList[i] : null
                                       
                                        if(original.new){
                                          if(types[type].url){
                                            window.location.href = types[type].url
                                          }else if(['email','wp','api','agent'].includes(type)){
                                            this.setState({addTarget:types[type].draft})
                                          }
                                        }else{

                                          let newIntegration = user.websites && original ? 
                                            Object.keys(original).reduce((a,b)=>['instruction','url', 'token', 'apikey', 'type', 'channel', 'title'].includes(b) ? {...a, [b]:original[b]} : a,{}) 
                                            : {url:option}

                                          // console.log(newIntegration)
                                          if(newIntegration.type==='wp'){
                                            newIntegration.publish = true
                                            newIntegration.photoQuality = 'high image quality'
                                          }
                                          if(onUpdate){
                                            this.setState({addTarget:null})
                                            onUpdate([newIntegration, ...theTargets])  
                                          }
                                        }
                                      
                                      }}/> : 
                                      <a style={{float:'left'}} href={types[type].url ? types[type].url : undefined} onClick={()=>{
                                          if(!types[type].url && ['email','wp','api','agent'].includes(type)){
                                            this.setState({addTarget:types[type].draft})
                                          }
                                      }}>
                                      <div style={buttonStyle}>
                                        {title}
                                      </div>
                                      </a>
  }

  render () {
    let {feed, integrations, addNew, onboard, simple, style, onUpdate, onNewTarget} = this.props
    // console.log(feed)
    let validTarget = this.state.addTarget ? Object.keys(this.state.addTarget).reduce((a, field)=>this.state.addTarget[field].length>0 && a,true) : false
    let theTargets = feed ? feed.onSubmit : this.state.profile.websites
    let options = user.websites ? user.websites.filter(target=>!theTargets.find(oTarget=>oTarget.url===target.url && !['facebook'].includes(target.type))) : []
      
    let buttonStyle =  {padding: '5px 20px', fontWeight: 'bold', display: 'inline-block', cursor: 'pointer', color: '#777',height:50, margin:5,background:'none', borderRadius:30, boxShadow:'none', border:'solid 1px rgb(241, 240, 240)'}

    return  <div style={{maxWidth:feed ? 880 : undefined}}>
             <Integrations 
                user={this.state.profile} 
                style={style}
                feed={feed}
                targets={theTargets}
                simple={feed ? false : true} 
                onboard={onboard}
                onNewTarget={onNewTarget}
                onUpdate={onUpdate ? onUpdate : (newTargets)=>{
                  if(feed){

                  }else{
                    this.upd('websites', newTargets)
                  }
                }}/>

       {this.state.addTarget ? <div style={{margin:'30px 0px 10px 0px',textAlign: 'right'}}>
                  <div style={{display: 'grid', gridTemplateColumns: 'repeat( auto-fit, minmax(250px, 1fr) )', gridGap:10, margin:'15px 10px'}}>
                    {Object.keys(this.state.addTarget).filter(field=>!field.startsWith('plchldr') && !['type'].includes(field)).map((field)=>{
                      return <input autoFocus={field==='url'}
                      value={this.state.addTarget[field]} 
                      onChange={e=>this.setState({addTarget:{...this.state.addTarget, ...(field==='url' && this.state.addTarget && this.state.addTarget.title==='' && this.hostname(e.target.value.toLowerCase()).length > 5 ? {title: this.hostname(e.target.value.toLowerCase())} : {}) , [field]:e.target.value.toLowerCase()}})} 
                      className="plugin-title" 
                      placeholder={this.state.addTarget && this.state.addTarget['plchldr'+field] ? this.state.addTarget['plchldr'+field] : "URL" }
                      style={{width: '100%',fontSize:'initial', fontWeight:'normal',padding:10, backgroundColor: 'white', textTransform:'lowercase', border:'solid 1px #d8d8d8'}}/>
                    })}
                  </div>
                  {this.state.addTarget.type === 'wp' ? <p style={{width:'50%', float: 'left', margin: '0px 15px 10px', textAlign: 'left'}}>
                                                          <a href="/static/plugins/wp/monoksync.zip" download className={"normalLink"}>Download the Monok Sync plugin </a> and install it in your Wordpress Admin to complete the integration
                                                        </p> : null}
                  <div onClick={()=>{this.setState({addTarget:null})}} className="whiteButton" style={{borderRadius:10}}>
                    <p style={{margin: 13}}>Cancel</p>
                  </div>
                  <div onClick={()=>{
                      if(validTarget){
                        let newIntegration = Object.keys(this.state.addTarget).reduce((a,b)=>{
                          if(!b.startsWith('plchldr')){
                            a[b] = this.state.addTarget[b]
                          }
                          return a
                        },{})

                        //TODO:move to backend
                        if(this.state.profile && this.state.profile.apikey && newIntegration.type==='wp'){
                          newIntegration.apikey = this.state.profile.apikey
                        }

                        if(newIntegration.url){
                          //remove trailing slash
                          newIntegration.url = newIntegration.url.replace(/\/+$/, '').trim()
                        }

                        if(feed && (newIntegration.type==='wp')){
                          newIntegration.publish = true
                          newIntegration.photoQuality = 'high image quality'
                        }
                      
                        if(onUpdate){
                          this.setState({addTarget:null})
                          onUpdate([newIntegration, ...theTargets])  
                        }else{
                          if(feed){
                            this.addTargetToFeed(feed, newIntegration)
                          }else{
                            this.upd('websites', [newIntegration, ...this.state.profile.websites])
                          }
                        }

                      }
                  }} className="button greenButton" style={{borderRadius:10, backgroundColor:validTarget ? undefined : '#dfdfdf'}}>
                    <p style={{float: 'right', margin: '10px 0px 0px'}}>Add</p>
                    <span style={s.integrationButton} className="fa fa-plus" aria-hidden="true"/>
                  </div>
                </div> : null}
                {addNew && !this.state.addTarget ? 

                  <div style={{margin:'15px 0px', textAlign:feed ? 'left' : 'center'}}>

                  
                  {!this.state.createNewIntegration && feed && feed.onSubmit && feed.onSubmit.length>0 ? 
                    <div style={{...buttonStyle, padding:15, color:'inherit'}} onClick={()=>{
                      this.setState({createNewIntegration:true})
                    }}>Create New Integration <span style={{margin: 'auto 0px auto 7px', fontSize: 15, color: '#5c5c5c'}} className={"fa fa-plus animate"} aria-hidden="true"/></div> : 

                  <div >
                    {['wp','linkedin','twitter','facebook','email','slack','api'].concat(user.admin ? ['youtube','agent', 'instagram'] : [])
                    .map(type=>this.dropDownIntegration(options, theTargets, type, onUpdate, feed ? feed.id : null))}
      
              
             
                  <a href="/static/plugins/wp/monoksync.zip" download>
                  <div  style={{...buttonStyle}}>
                    <img style={{marginRight: 10, verticalAlign: 'middle', height: 34}} src="/static/img/integrations/wpplugin.png"/>
                    <p style={{float: 'right', margin: '10px 0px 0px'}}>Download Wordpress Plugin</p>
                  </div>
                  </a>

                  </div>}
                  
                </div> : null}
      </div>

  }
}
const mapStateToProps = (state)=>{
  return {
    // user:state.auth.user,
    // feeds:state.feeds,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile:(newProfile, partial)=>dispatch(saveProfile(newProfile, partial))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Integrate)