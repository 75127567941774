import React from 'react';
class MessageBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {accepted:this.getCookie('privacypolicy')}
    }  

	  setCookie(name,value,days) {
	      var expires = "";
	      if (days) {
	          var date = new Date();
	          date.setTime(date.getTime() + (days*24*60*60*1000));
	          expires = "; expires=" + date.toUTCString();
	      }
	      document.cookie = name + "=" + (value || "")  + expires + "; path=/";
	  }

	  getCookie(name) {
	      var nameEQ = name + "=";
	      var ca = document.cookie.split(';');
	      for(var i=0;i < ca.length;i++) {
	          var c = ca[i];
	          while (c.charAt(0)==' ') c = c.substring(1,c.length);
	          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
	      }
	      return null;
	  }


    render () {
    	
    	if(this.state.accepted){
    		return null
    	}else{
		    return (<div style={{...this.props.style, ...{backgroundColor:'aliceblue'}}}>
		            <div style={{display:'grid', gridTemplateColumns: '60px auto', padding:30, maxWidth:1180, margin:'auto', textAlign:'left'}}>
		              <img style={{marginRight: 10, maxWidth:50}} src="/static/img/icons/cookie.svg"/>
		              <span>
		              	This Service process personal data and use cookies, which are necessary for its functioning. You can accept this by closing this notice or by continuing to browse otherwise.
			              <span className={"whiteButton"} style={{width:100,marginTop: 10, float:'right', fontSize: 'large', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 40}} onClick={()=>{
			              	this.setCookie('privacypolicy','true',730)
			              	this.setState({accepted:true})
			              }}>Accept</span>
		              </span>
		            </div>
		            </div>)
    	}
	}
}


export default MessageBox;