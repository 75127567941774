import React from 'react';
import T from 'i18n-react';
import Spinner from './spinner.jsx';
var config = require('../../../settings/config.json');
import { browserHistory } from 'react-router';

const s={
    input:{marginTop:10}
}

 const fields = {
    email:{
      validator:/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    },
    password:{ 
      validator:/^(?=.*\d).{8,50}$/ //min 8, max 50 characters in any language and at least one number
    },
    name:{
      validator:/^\S{2,}\s\S{2,}.{0,30}$/i
    }
  }

class Login extends React.Component {

    constructor(props) {
        super(props);
        

        var params = window.location && window.location.search ? window.location.search
                  .slice(1)
                  .split('&')
                  .map(p => p.split('='))
                  .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}) : {}

        this.state = {register:false, forgot:false, resetToken: params && params.resetToken ? params.resetToken : null}
     }

    inValid(f){

      return f.reduce((a, b)=>{
        if(a){
          return a
        }
 
        return a ? a : !fields[b].validator.test(this.state[b] ? this.state[b] : '')
      }, false)

    }

    newPassword(){
      let {resetToken, password, passwordAgain} = this.state
      let fields = ['password']
      for(let i=0;i<fields.length;i++){
        if(this.inValid([fields[i]])){
          this.setState({error:'The password is invalid, please use 8-50 characters and at least one number'})
          return
        }
      }

      if(!resetToken){
        this.setState({error:'This reset password URL is not working!'})
        return
      }

      if(password!==passwordAgain){
        this.setState({error:'The two passwords do not match!'})
        return
      }

      this.setState({loading:true})
      let appEnv = process.env.NODE_ENV;
      let BASE_URL = window.location.origin+config.server[appEnv].apiUrl;

      fetch(BASE_URL + "resetPassword", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({password: password, token: resetToken})
      })
      .then(response => response.status === 200 ? response.json() : null)
      .then(async response => {
        if(response && response.success){
          window.location.href = '/login'
        }else{
          this.setState({error:'Failed to reset your password', loading:false})
        }
      })
      .catch(error => {
        this.setState({error:'Failed to reset your password', loading:false})
      })
    }


    sendResetEmail(){
      let {email} = this.state

      let fields = ['email']
      for(let i=0;i<fields.length;i++){
        if(this.inValid([fields[i]])){
          this.setState({error:'The '+fields[i]+ ' is invalid, please fill out the field correctly'})
          return
        }
      }

      this.setState({loading:true})
      let appEnv = process.env.NODE_ENV;
      let BASE_URL = window.location.origin+config.server[appEnv].apiUrl;

      fetch(BASE_URL + "forgot", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({email: email})
      })
      .then(response => response.status === 200 ? response.json() : null)
      .then(async response => {
          this.setState({error:'Email to reset your password has been sent!', email:'',loading:false})
      })
      .catch(error => {
        this.setState({error:'Failed to send reset email', loading:false})
      })

    }

    monokRegister(){
      let {name, email, password, confirmPassword, agree} = this.state

      if(!agree){
          this.setState({error:'You must accept the terms of service'})
          return
      }

      let fields = ['name', 'email', 'password']
      for(let i=0;i<fields.length;i++){
        if(this.inValid([fields[i]])){
          this.setState({error:'The '+fields[i]+ ' is invalid, please fill out the field correctly'})
          return
        }
      }


      

      this.setState({loading:true})
      let appEnv = process.env.NODE_ENV;
      let BASE_URL = window.location.origin+config.server[appEnv].apiUrl;

      fetch(BASE_URL + "register/monok", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({name: name, email: email, password: password })
      })
      .then(response => response.status === 200 ? response.json() : null)
      .then(async response => {
        if(response && response.success){
          window.location.href = '/profile'//response.redirect;
        }else{
          this.setState({error:'Failed to create account', loading:false})
        }
      })
      .catch(error => {
        this.setState({error:'Failed to create account', loading:false})
      })

    }

    monokLogin(){

      let {email, password} = this.state
      let {redirect} = this.props
      // console.log(redirect)

      if(this.inValid(['email', 'password'])){
        this.setState({error:'Please fill out the fields correctly'})
        return
      }

      this.setState({loading:true})
      let appEnv = process.env.NODE_ENV;
      let BASE_URL = window.location.origin+config.server[appEnv].apiUrl;

      fetch(BASE_URL + "auth/monok", { //+(redirect ? "?redirect="+redirect : '')
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: email, password: password })
      })
      .then(response => response.status === 200 ? response.json() : null)
      .then(async response => {
        if(response && response.success){ // && response.redirect
          window.location.href = redirect && redirect!=='/login' ? redirect : '/profile' //response.redirect;
        }else{
          this.setState({error:'Login failed, please try again', loading:false})
        }
      })
      .catch(error => {
        this.setState({error:'Login failed, please try again', loading:false})
      })
     }

    render(){
        let {register, error, loading, passVisible, email, agree, forgot, resetToken} = this.state
        let {justLogin, style, title, redirect} = this.props

        return <div className="whiteModal" onMouseDown={(e)=>{e.stopPropagation()}} style={style ? style : {}}>
                
                {resetToken ? <h1 style={{marginBottom:50}}>Type new Password</h1> : (forgot ? <h1 style={{marginBottom:50}}>Reset Password</h1> : <h1 style={{marginBottom:50, fontSize:register ? '25px' : undefined}}>{title ? title : ((register ? 'Create an account' : 'Sign in') + ' to continue')}</h1>)}
                
                {forgot || resetToken ? null : <p className="borderButton" style={{margin:10,fontSize:'clamp(1.5rem, 4vw, 2.5rem)', borderRadius: 50, padding:'15px 30px', color: 'black'}} onClick={()=>{
                  window.location = "/auth/google" + (redirect ? '?redirect='+redirect.replace('/','') : '')
                }}><img src={config.server[window.env].staticUrl+"img/icons/google.png"} style={{width:'clamp(18px, 8vw, 45px)', marginRight:15}}/>{T.translate('login.SignInWithGoogle')}</p>}     
                
                {resetToken ? <p style={{color:'grey',margin:10}}>Type in a new password for your account</p> :
                  (forgot ? <p style={{color:'grey',margin:10}}>Reset your password by typing in your email address.</p> : 
                            <p style={{color:'grey',margin:'30px 10px'}}>or {register ? 'create a new' : 'sign in with a'} monok account</p>)}
                
                {register ? <input onChange={(e)=>this.setState({name:e.target.value, error:null})} style={s.input} className="form-control" placeholder="Full name"/> : null}
                {resetToken ? null : <input style={s.input} onChange={(e)=>this.setState({email:e.target.value, error:null})} value={email} className="form-control" placeholder="Email"/>}
                {forgot ? null : <input style={s.input} onChange={(e)=>this.setState({password:e.target.value, error:null})} value={this.state.password} type={passVisible ? "text" : "password"} className="form-control" placeholder={register ? "Password (min 8 characters, 1 number)" : "Password"}/>}
                {forgot ? null : <i className={(passVisible ? "fa fa-eye" : "fa fa-eye-slash") + " eyeIcon"} onClick={()=>this.setState({passVisible:!passVisible})}></i>}
                
                {resetToken ? <input style={s.input} onChange={(e)=>this.setState({passwordAgain:e.target.value, error:null})} value={this.state.passwordAgain} type={"password"} className="form-control" placeholder={"Repeat the password"}/> : null}
                {/*register ? <input onChange={(e)=>this.setState({confirmPassword:e.target.value})}  style={s.input} type="password" className="form-control" placeholder="Confirm Password"/> : null*/}
                {register ? <label for="horns" style={{padding: '30px 0px 10px', display:'block'}}>
                  <input type="checkbox" id="terms" name="terms" checked={agree} onChange={(e)=>this.setState({agree: e.target.checked})}/>&nbsp;I have read and agree to the <a rel="nofollow" href="https://docs.google.com/document/d/e/2PACX-1vQGjfs0yMg8DGs2Oad_cyadIeX05Di0yWq7SaiFWBCV_rY266ggiA-at6Bs8bG8yoGqjuCIkPt_9eSQ/pub" target="_blank">terms of service</a>
                </label> : null}
                
                {!register && !forgot && !resetToken ? <a style={{cursor:'pointer', margin:10}} onClick={()=>this.setState({register:false, error:null, forgot:true})}>Forgot your password?</a> : null}
                
                {loading ? <Spinner style={{margin:'15px auto'}}/> : <p onClick={(e)=>{
                  resetToken ? this.newPassword() : (forgot ? this.sendResetEmail() : (register ? this.monokRegister() : this.monokLogin()))
                }} className="button blackButton" style={{margin:'20px 0px 10px', backgroundColor:(!agree && register) || this.inValid((register ? ['name'] : []).concat(['email', 'password'])) ? '#707070' : undefined}}>{resetToken ? 'Save new password' : (forgot ? 'Send reset email' : (register ? 'Create Account' : 'Sign In'))}</p>}

                {error ? <p style={{color:'#f0505c', maxWidth:290}}>{error}</p> : null}
                {register ? <a style={{cursor:'pointer'}} onClick={()=>this.setState({register:false, error:null})}>Sign In with existing account</a> : 
                (justLogin || forgot || resetToken ? null : <a style={{cursor:'pointer'}} onClick={()=>this.setState({register:true, error:null})}>Create a new account</a>)}

                {forgot ? <a style={{cursor:'pointer'}} onClick={()=>this.setState({register:false, error:null, forgot:false})}>Go back</a> : null}

                <img className="whiteLogo" src={config.server[window.env].staticUrl+"img/icons/monok.svg"} style={{filter: 'invert(80%)', margin: '30px auto -25px', display: 'inherit'}}/>
              </div>
 
    }
               			
}
	              

export default Login;