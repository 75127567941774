module.exports = {
	LOGIN_GOOGLE_REQUEST:'LOGIN_GOOGLE_REQUEST',
	LOGIN_GOOGLE_SUCCESS:'LOGIN_GOOGLE_SUCCESS',
	LOGIN_GOOGLE_FAILURE:'LOGIN_GOOGLE_FAILURE',
	LOGOUT_REQUEST:'LOGOUT_REQUEST',
	LOGOUT_SUCCESS:'LOGOUT_SUCCESS',
	LOGOUT_FAILURE:'LOGOUT_FAILURE',
	SET_PROFILE:'SET_PROFILE',
	PROFILE_REQUEST:'PROFILE_REQUEST',
	PROFILE_SUCCESS:'PROFILE_SUCCESS',
	PROFILE_FAILURE:'PROFILE_FAILURE',
	CHANGE:'CHANGE'
}
