import React from 'react';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import T from 'i18n-react';
import {daysOld, translateRate} from '../../util';

class Summary extends React.Component {

      
    constructor(props) {
        super(props);
    }  

	wrapTT(tt, child,keyId){
	    const ttDom = (<Tooltip id="tooltip"><strong>{tt}</strong></Tooltip>);
	    if(this.props.mobile){
	      return child
	    }else{
	      return (<OverlayTrigger key={keyId} placement="bottom" overlay={ttDom}>
	              {child}
	              </OverlayTrigger>)
	    }
	}

    render(){
    
    let {article, style, added, editmode} = this.props
		
	let originalStats = {}
	if(article.summary && (editmode || !(article.author && this.props.feedId!=='en'))){
		originalStats = JSON.parse(JSON.stringify(article.summary))
	}

	originalStats = {...originalStats, ...(added ? added : {})}
	  // else{
	  //   if(article.cluster && article.widgets){
	  //     originalStats = ['twitter','instagram','video'].reduce((a,b)=>{
	  //       a[b]=article.widgets.filter(w=>w.type === b).length
	  //       return a
	  //     },{})
	  //     originalStats.videos = article.videos ? article.videos.length : 0
	  //   }
	  // }

	  if(false && article.sentiment && (article.sentiment > 0 || article.sentiment <= 0)){
	  // if(article.sentiment && (article.sentiment > 2 || article.sentiment <= 1.5)){
	    originalStats[article.sentiment > 0.5 ? 'smile-o' : (article.sentiment < -0.5 ? 'frown-o' : undefined )] = true//Math.round(article.sentiment * 10)/10
	    // originalStats[article.sentiment > 2 ? 'smile-o' : (article.sentiment > 1.5 ? undefined : 'frown-o')] = true//Math.round(article.sentiment * 10)/10
	  }

	  originalStats['comment-o'] = article.comments && article.comments.length>0 ? article.comments.length : originalStats.comments
	  
	  originalStats['align-left'] = !editmode && article.words ? article.words : null
	  originalStats['newspaper-o'] = article.sources && article.sources.length>1 ? article.sources.filter(source=>!source.duplicate).length : originalStats.sources
	  delete originalStats.comments
	  delete originalStats.sources


	let viral = daysOld(article.date)<7 && article.weight>300
	return <div className="secondary" style={{...s.commentCount,...{float:null}, ...(style ? style : {})}}>

          {Object.keys(originalStats).filter(k=>originalStats[k]>0).map((k,i)=>
            this.wrapTT(T.translate('summary.'+k,{ count: originalStats[k] }),<span style={{color:k==='smile-o' ? 'green' : (k==='frown-o' ? 'red' : 'inherit')}}>
                    <span key={'cludetail'+i} className={"fa fa-"+(k==='videos' ? 'play' : k)} aria-hidden="true"  style={{marginLeft:i>0 ? 10 : 0}}>
                    </span>{originalStats[k]===true ? null : ' '+originalStats[k]}
                  </span>,'ttstat-'+i))}
          
          
          <span style={{color:viral ? 'red' : 'inherit'}}>{article.weight>100 ? <span className={viral ? "glyphicon glyphicon-fire" : "fa fa-eye"} aria-hidden="true" style={{marginLeft:10}}/>:null} {article.weight>100 ? translateRate(article.weight):null}</span>
        </div>
    }
}

var s ={
	commentCount:{
    fontSize: '14px',
    textAlign:'right'
  }
}

export default Summary;