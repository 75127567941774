import auth from './auth';
import feed from './feed';
import puff from './puff';
import view from './view';

module.exports={
	...auth,
	...feed,
	...puff,
	...view
}