var React = require('react');
// import ReactDOM from 'react-dom'
var ReactDOM = require('react-dom')
var config = require('../../../settings/config.json')
import Quote from './quote.jsx';
import MediaPicker from '../media/mediapicker';
import ArticleParser from '../editor/toHTML.js';
let articleParser  = new ArticleParser()
var Widget = require('./widget.jsx')
require('medium-editor/dist/css/medium-editor.css');
require('medium-editor/dist/css/themes/default.css');
var Editor = require('../editor/monokeditor');
import stringSimilarity from "string-similarity";

import {imagesFromYT} from '../../util';

import { MediumEditorScrollOverflowFix } from '../editor/toolbarOverflow.js';
// import BlockquoteEditor from '../editor/blockquote-editor.js';
       
let mediumEditor = null;
let prevParagraph = null

var style={
  button:{position:'absolute', display:'none'}
}



class Article extends React.Component {

  constructor(props){
      super(props);
      this.handleChange = this.handleChange.bind(this);

      let report = undefined
      if(this.props.user && this.props.user.writer && props.article && props.article.report){
        try{
          // console.log(props.article.report)
          report = JSON.parse(props.article.report)
        }catch(e){
          // console.log(e)
        }
      }
      // console.log(report)
      this.state = {contentMenu:false,report:report}

  }

  componentDidUpdate(prevProps, prevState, snapshot){
    let {text, editmode} = this.props

    if(text && editmode){
        this.getBlocks(text).forEach((s, i)=>{

        if(s.indexOf('{')===0 ){
          //TODO: exclude quotes, as they are no longer "rich objects", currently container will return null in edit mode
          let container = document.getElementById(s.hashCode()+'-container')
          if(container && container.children.length<2 && container.innerText.trim()==='' && container.innerHTML.length<10){
            try{
              // let obj = JSON.parse(s)
              this.renderRichObject(s, i)
            }catch(e){
              console.log(e)
            }
          }
        }
      })
    }
  }

  renderRichObject(s, i){
    ReactDOM.render(<div id={s.hashCode()}  
                    contentEditable="false" 
                    onClick={(e)=>{
                      // console.log(e)
                      // console.log('YES THIS WORKED')
                    }}
                    style={{
                     // pointerEvents: this.props.editmode ? 'none' : 'all', 
                    }}
                    readOnly="true">
                      {this.richObject(JSON.parse(s), i)}
                    </div>, 
    document.getElementById(s.hashCode()+'-container'));
  }

  richObject(o, index){

    if(!o){
      return null
    }  
    if(o.quotes && o.quotes[0]){
      return <Quote key={'quo'+index} quote={o.quotes[0]}/>
      // return articleParser.quote(o)
    }else if(o.type && ['twitter', 'instagram', 'video', 'reddit','tiktok','facebook', 'spotify'].includes(o.type)){
        
        if(o.type==='video' && this.props.article && this.props.article.videos && !this.props.article.videos.find(v=>o.id===v.id)){
          let changes = {}
          changes.videos = [o].concat(this.props.article.videos)
          changes.images = imagesFromYT(o).concat(this.props.article.images)
          this.props.update(changes)
        }
      // console.log('HERE')

      return <div key={'w'+index} onClick={(e)=>{
        // e.stopPropagation()
        // console.log(e)
        // console.log(e.target)
        // console.log(e.target.id)
        // this.setState({selected:index, showMedia:true})
        // this.toggleParaArrows(false, '-container')
        // console.log('')
      }} style={{
        padding:30,
        minHeight:150,
        pointerEvents: this.props.editmode && !this.state.showMedia ? 'none' : 'all', 
        background:'url("/static/img/icons/spinner.gif") center center no-repeat'}}>
              <Widget type='card' style={{
                        // pointerEvents:'none', 
                        // pointerEvents:this.props.editmode && !this.state.selected ? 'none' : 'all', 
                        borderRadius:10, overflow:'hidden', boxShadow:['video'].includes(o.type) ? '0px 10px 20px -7px #0000007a' : null}} 
                        thumbSize={20} 
                        article={{}} 
                        widget={o}/>
            </div>
    // }else if(o.type && ['person', 'organization'].includes(o.type)){
      // console.log(o)
      // return <Widget image={true} key={'w'+index} type='card' thumbSize={20} article={{}} widget={o}/>
      // return <img src={o.url}/>
    }else if(o.type==='takeaways'){
      return <div className="takeaways">
              <h2>{o.title}</h2>
              <p className="takeaways-intro">{o.intro}</p>
              <div className="takeaways-points">
                <ul>
                  {o.points.map((point, index) => (
                    <li key={index}>{point}</li>
                  ))}
                </ul>
            </div>
            </div>
    }else{
      return <b key={'quo'+index}>UNKNOWN</b>
    }
    
    return null
  }

  handleChange(text) {
    let txt = articleParser.toText(document.getElementById('monokEditor'))
    this.props.update({article:txt})
  }

  evaluation(grades){
    // console.log(grades)
    let colors = {
      poor:'red',
      fair:'orange',
      good:'yellow',
      excellent:'green'
    }

    let issue=Object.values(grades).find(grade=>['fair','poor'].includes(grade.toLowerCase()))
    return <p>
      {Object.keys(grades).filter(grade=>grade.toLowerCase()!=='overallcomments').map(grade=><span>{grade}: <span style={{background:colors[grades[grade].toLowerCase().trim()], width: 10, display: 'inline-block', height: 10, borderRadius: '50%', margin: '0px 10px'}}></span></span>)}
      {issue ? <p style={{color:'#e9af00'}}>{grades.overallComments}</p> : null}
      </p>
  }

  paragraph(i, text, longText){
    let {rtl, user} = this.props
    let {report} = this.state
    // console.log(report)
    if((!user || !user.writer) && !rtl && i===0 && longText && !['[','#','"','-'].includes(text[0])){
      return <p key={'line-'+i}  className="paragraph" style={{fontFamily:longText ? 'Lora, serif' : undefined}}>
              <span className="bigLetter">{text[0]}</span><p dangerouslySetInnerHTML={{ __html: articleParser.parseMD(text.slice(1, text.length-1)) }} />
            </p>
    }
    
    let line = <p key={'line-'+i} className="paragraph" style={{fontFamily:longText ? 'Lora, serif' : undefined}} dangerouslySetInnerHTML={{ __html: articleParser.parseMD(text) }} />// <p key={'quo'+i} className="paragraph" key={'line-'+i}>{text}</p>
    // console.log(i)
    // console.log(text)
    // console.log(report.textQuality.find(p=>p.paragraph===i))
    return report && report.textQuality ? <p>
       {line}
      {report && report.textQuality && report.textQuality.find(p=>p.paragraph===i) ? this.evaluation(report.textQuality.find(p=>p.paragraph===i).evaluation) : null}
    </p> : line
 
  }

  findFactInText(text){
    let {article} = this.props
    let fact = null
    if(article.candidates){
      article.candidates.forEach((candidate, i)=>{
        if(text.toLowerCase().includes(candidate.text.toLowerCase())){
          fact = {...candidate, sim:1}
        }else{
          text.split(/[,\.]/g).forEach(slice=>{
            let sim = stringSimilarity.compareTwoStrings(slice, candidate.text)
            if(sim>0.3 && (!fact || fact.sim < sim)){
              fact = {...candidate, sim:sim}
            }
          })
        }
      
      })

      // console.log(fact)
      return fact
      
    }else{
      return null
    }
  }


  toggleParaArrows(hide, id){
    let {article, user} = this.props
  
    if(user && !(user.writer || user.admin || user.curator)){
      return
    }

    let currentParagraph = id ? document.getElementById(id) : this.getSelectedParagraph()
    
    if(prevParagraph){
      prevParagraph.style.paddingTop='revert-layer'
      prevParagraph.style.backgroundPositionY='revert-layer'
      if(prevParagraph.firstChild && prevParagraph.firstChild.firstChild && prevParagraph.dataset && prevParagraph.dataset.widget){
        prevParagraph.firstChild.firstChild.style.pointerEvents = 'none'
      }
      prevParagraph = null
    }

    var leftParaButton = document.getElementById("leftParaButton");
    // var rightParaButton = document.getElementById("rightParaButton");
    var addButton = document.getElementById("addButton");
   
   
    leftParaButton.style.display='none'
    // rightParaButton.style.display='none' 
    addButton.style.display='none'

    let isQuote = currentParagraph && ['BLOCKQUOTE'].includes(currentParagraph.tagName)

    let rich = currentParagraph && currentParagraph.dataset &&  currentParagraph.dataset.widget

   
    // {type:'quote',quotes:[{text:currentParagraph.childNodes[0].nodeValue}]}
    // console.log(currentParagraph.dataset.widget)

    if((rich || isQuote) && !hide){
      // console.log(currentParagraph)
      this.setState({showMedia:true, selected:rich ? currentParagraph.dataset.widget : (isQuote ? true : null)})
      if(isQuote){
        currentParagraph.style.paddingTop = '190px'
        currentParagraph.style.backgroundPositionY = '190px'
      }else{
        currentParagraph.style.paddingTop = '140px'
      }
      if(currentParagraph.firstChild && currentParagraph.firstChild.firstChild){
        currentParagraph.firstChild.firstChild.style.pointerEvents = 'all'
      }
      prevParagraph = currentParagraph
    }else{
      this.setState({showMedia:false, selected:null})
    }


    if(currentParagraph && !hide){
      let text = currentParagraph.innerText                     
      // console.log(currentParagraph)
      if(text && text.trim().length===0 || (rich || isQuote)){
        addButton.style.top=currentParagraph.offsetTop-5+'px'
        // addButton.style.top=(currentParagraph.offsetTop+currentParagraph.offsetHeight/2-25)+'px'
        addButton.style.display='initial'
      }else{

        let candidate = this.findFactInText(text)

        if(candidate && candidate.index>-1 && candidate.index !== null && article.candidates){
            let theSource = article.sources[candidate.index]
            if(theSource){
              leftParaButton.style.top=(currentParagraph.offsetTop+currentParagraph.offsetHeight/2-25)+'px'
              leftParaButton.style.display='initial'
              // leftParaButton.innerHTML = '“'+candidate.text+`“ - <b>${theSource.image && theSource.image.original ? `<img src="${theSource.image.original}"/>` : ''}`+theSource.source+'</b>'
              leftParaButton.innerHTML = '“'+candidate.text+'“ - <b>'+theSource.source+'</b>'
              leftParaButton.setAttribute('url','/clean/'+encodeURIComponent(theSource.url)+'?date='+theSource.date+'/#:~:text='+this.highlightEncode(candidate.text))
              // rightParaButton.style.top=(currentParagraph.offsetTop+currentParagraph.offsetHeight/2-25)+'px' 
              // rightParaButton.style.display='initial' 
            }
        }   
      }
    }
  }


  addMedia(e){
    let currentParagraph = this.getSelectedParagraph()
    if(currentParagraph && !this.state.showMedia){
        this.setState({showMedia:true})
        currentParagraph.style.paddingTop='140px'
        prevParagraph = currentParagraph
    }else if(prevParagraph && this.state.showMedia){
        this.setState({showMedia:false})
        prevParagraph.style.paddingTop='revert-layer'
        // currentParagraph.classList.remove("animate");
        // console.log('hide')
    }
   
  }

  getSelectedParagraph(){
    let elem = mediumEditor.getSelectedParentElement()
    if(elem && ['BLOCKQUOTE'].includes(elem.tagName)){
      return elem
    }else if(elem && ['FOOTER'].includes(elem.tagName)){
      let selectedQuote = elem.closest('blockquote');
      return selectedQuote ? selectedQuote : elem//.firstChild
    }else{
      let selectedP = elem.closest('p');
      return selectedP ? selectedP : elem//.firstChild
    }
  }

  highlightEncode(snippet){
    return encodeURIComponent(snippet).replace(/\-/g,'%2D')
  }

  mediumeditor(){

    let {article, editmode, text} = this.props
    let {showMedia, selected} = this.state

    // let sel  = null
    // if(selected){
    //   let k = JSON.parse(selected.decode())
    //   console.log("#############################")
    //   console.log("#############################")
    //   console.log(k)
    //   console.log("#############################")
    //   console.log("#############################")
    //   console.log("#############################")
    //   sel = selected
    // }

    let addButton = <div disabled id="addButton" style={{...style.button, left:-60}} onMouseDown={(e)=>{e.stopPropagation();e.preventDefault();}}>
      <span style={{position:'absolute'}} className={"arrow fa fa-plus animate"+(showMedia ? ' activeTurn' : '')} onMouseDown={(e)=>{e.stopPropagation();e.preventDefault();}} onClick={(e)=>this.addMedia(e)}/>
      {showMedia ? <div id="widgetpicker" onMouseDown={(e)=>{e.stopPropagation();e.preventDefault();}}>
        <MediaPicker
        filter={['quote','video', 'instagram', 'twitter', 'facebook','tiktok','spotify', 'reddit']} 
        update={(e)=>{
          if(prevParagraph){
            // console.log('YESSSSSSSSSSSSSS')
            // console.log(e)
            // console.log('YESSSSSSSSSSSSSS')
            // console.log(prevParagraph)
            if(e.visualFocus){

              if(e.visualFocus.type==='quote' && e.visualFocus.quotes && e.visualFocus.quotes[0]){
                let widgetString = JSON.stringify(e.visualFocus)
                var containerDiv = document.createElement('div');
                containerDiv.innerHTML = articleParser.quote(e.visualFocus.quotes[0])
                let newBlockquote = containerDiv.firstChild
                newBlockquote.style.paddingTop='190px'
                newBlockquote.style.backgroundPositionY='190px'
                newBlockquote.setAttribute('data-widget', widgetString.encode())
                prevParagraph.parentNode.replaceChild(newBlockquote, prevParagraph);
                prevParagraph = newBlockquote
                this.setState({selected:widgetString.encode()})
              }else{
                let widgetString = JSON.stringify(e.visualFocus)
                var newP = document.createElement('p');
                newP.style.paddingTop='140px'
                newP.innerHTML = ''
                newP.setAttribute('data-widget', widgetString.encode())
                newP.setAttribute('id', widgetString.hashCode()+'-container')
                prevParagraph.parentNode.replaceChild(newP, prevParagraph);
                prevParagraph = newP
                this.setState({selected:widgetString.encode()})
              }

            }else{
              //WHEN REMOVING AN OBJECT
              var newP = document.createElement('p');
              newP.style.paddingTop='140px'
              newP.innerHTML = '<br>';
              prevParagraph.parentNode.replaceChild(newP, prevParagraph);
              prevParagraph = newP

             this.setState({selected:null})
            }
            this.handleChange()
          }else{
            // console.log('NOOOOOOOOOOOOOO')
          }
        }}
        selected={selected}
        className="mediapicker"
        article={article}
        style={{background:'none', whiteSpace:'nowrap'}}
        editmode={true}
        />
      </div> : null}
    </div>

    const options = {
           buttonLabels: 'fontawesome',
           // linkValidation:true,
           //  checkLinkFormat:true,
           //      anchorInputPlaceholder:'HELLO WORLD',
           // forcePlainText: false,

            toolbar: { 
              buttons: [ 
              // {
              //   name: 'anchor',
              //   action: 'createLink',
              //   aria: 'link',
              //   tagNames: ['a'],
              //   contentDefault: '<b>#</b>',
              //   contentFA: '<i class="fa fa-link"></i>',
              //   linkValidation:true,
              //   placeholderText:'HELLO'
              //   },
              {
                name: 'h2',
                action: 'append-h2',
                aria: 'header type 2',
                tagNames: ['h2'],
                contentDefault: '<b>H2</b>',
                contentFA: '<b>H2</b>',
                classList: ['custom-class-h2'],
                attrs: {
                    'data-custom-attr': 'attr-value-h2'
                }
            }, {
                name: 'h3',
                action: 'append-h3',
                aria: 'header type 2',
                tagNames: ['h3'],
                contentDefault: '<b>H3</b>',
                contentFA: '<b>H3</b>',
                classList: ['custom-class-h3'],
                attrs: {
                    'data-custom-attr': 'attr-value-h3'
                }
            }, 
            'bold', 
            'italic', 
            'quote', 
            'anchor'
            ] },
            anchor: {
                /* These are the default options for anchor form,
                   if nothing is passed this is what it used */
                // customClassOption: null,
                // customClassOptionText: 'Button',
                // linkValidation: false, //converts spaces to 20%
                // checkLinkFormat:true,
                // placeholderText: 'Paste or type a link',
                // targetCheckbox: false,
                // targetCheckboxText: 'Open in new window'
            },
            extensions: {
              // 'bq': new BlockquoteEditor(),
              'scrollOverflowFix': new MediumEditorScrollOverflowFix()
            },
            placeholder: {
              text: 'Type your article text here',
              hideOnClick: false
            },
          //   paste: {
          //       /* This example includes the default options for paste,
          //          if nothing is passed this is what it used */
          //       forcePlainText: true,
          //       cleanPastedHTML: true,
          //       preCleanReplacements: [[new RegExp(/^.{500,}$/gi),'']],
          //       cleanAttrs: ['class', 'style', 'dir'],
          //       cleanTags: ['meta']
          // //     doPaste: function (e) { 
          // //       console.log(e)
          // //       console.log('THISSSSSSS')
          // //       return 'HELLO'
          // //      }
          //   }

        }

        let feedSettings = article.feedSettings ? article.feedSettings : {}

        //Until we fix the selection position problem
        return <div style={this.props.style ? this.props.style : {position:'relative',marginTop:30}}>

                {addButton}
                <div disabled id="leftParaButton" 
                    style={{...style.button, cursor:'pointer', margin:'-10px 0px 0px 10px', fontSize:'large',color:'darkgrey', left:800, width:400}} 
                    onMouseDown={(e)=>{e.stopPropagation();e.preventDefault();}} 
                    onClick={(e)=>{
                      window.open(e.target.getAttribute('url'), '_blank', 'noopener');
                    }} >Read source</div>
                    
                <Editor
                  id="monokEditor"
                  allowedMedia={(feedSettings.disableVideos ? [] : ['video']).concat(feedSettings.disableMedia ? [] : ['twitter', 'instagram', 'spotify', 'facebook', 'tiktok', 'reddit'])}
                  anchors={feedSettings.targets ? feedSettings.targets : []}
                  // text={this.state.text} 
                  text={articleParser.toHtml(text)}
                  // text={window.virtualText}

                  // onPaste={(e)=>{
                  //   console.log('PASTE')
                  //   console.log(e.clipboardData.getData('Text'));
                  // }}
                  onClick={()=>{
                    this.toggleParaArrows()
                  }}
                  // onFocus={()=>console.log('SELECTED')}
                  onBlur={(e)=>{
                     e.persist(); //react bs
                    let self = this
                    setTimeout(()=>{
                        let active = document.activeElement
                        if(!prevParagraph || !this.contains(prevParagraph, active) || active.nodeName==='BODY'){
                          self.toggleParaArrows(true)
                        }else{
                          e.target.focus({
                            preventScroll: true
                          })
                        }
                    }, 1);
               
                  }}
                  onLoad={(medium)=>mediumEditor ? null : mediumEditor=medium}
                  onChange={this.handleChange}
                  onKeyUp={()=>{
                    this.toggleParaArrows()
                  }}
                  options={options}
                  />
        </div>
  }

  contains(parent, child) {
  return parent !== child && parent.contains(child);
  }

  getBlocks(text){
    return text.split('\n')//text.match(/(?:("+)[\s\S]+?\1|.)+/gm)
  }

  render(){
    let {text, editmode, children, style, locked, blur, rtl} = this.props
    

    return <div id="monokArticleContainer" className={locked ? 'disableSelection' : ''} style={{...{paddingTop: 20, textAlign: rtl ? 'right' : undefined, filter:(blur ? 'blur(2.6px)' : 'none')},...style}}>
            {children}
          
            {editmode ? 
              this.mediumeditor() : 
              text ? this.getBlocks(text).map((s, i, arr)=>{
                if(s.indexOf('{')===0){
                  try{
                    return this.richObject(JSON.parse(s), i)
                  }catch(e){
                    console.log('JSON PARSE FAILED')
                    console.log(s)
                    return this.paragraph(i, s, arr.length>8)
                  }
                }else{
                  return this.paragraph(i, s, arr.length>8)
                }
            }) : null} 
    </div>
  }

}

module.exports = Article;