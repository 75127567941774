import React from 'react';
import { Form, Col, Row, ListGroupItem, ListGroup, ControlLabel, Control, FormControl} from 'react-bootstrap';
import T from 'i18n-react';

import { connect } from 'react-redux';
import moment from 'moment';

import {saveProfile} from '../../actions/auth';

// import Tags from "@yaireo/tagify/dist/react.tagify"

import Config from '../feed/config';
import TT from '../modal/tt.jsx';
import Pie from '../modal/pie.jsx';
import request from 'request';
import {publicationList} from '../../apiFunctions';
import Creditcards from '../billing/creditcards.jsx';
import Integrate from '../modal/integrate.jsx';
import Anchors from './anchors';

var config = require('../../../settings/config.json');

//const callbackUrl = encodeURIComponent("http://dev.com/profile/settings")
const callbackUrl = encodeURIComponent("https://www.monok.com/profile/settings")

const s =  {
  title:{
      color: 'black',
      fontSize: 'clamp(30px, 9vw, 47px)',
      marginBottom: '30px',
      fontWeight: 'bold',
      fontFamily: "'PT Sans Narrow', sans-serif" 
    },
    integrationButton:{
      marginRight: 10, fontSize: '35px', verticalAlign: 'middle'
    },
    buttonWrapper:{
      padding:10,borderRadius:10, marginTop:10
    },
    notice:{
      background: '#ebf3fc',
      padding: 10,
      borderRadius: 30,
      fontWeight: 'bold',
      textAlign: 'center'
    }
}



// const printer = e =>
//   console.log(`%c ${e.type}: `, "background:#222; color:#bada55", e.detail)

// const updatearray = (e)=>{
//   console.log(e.detail.tagify.value.map(v=>v.value))
// }
// tagify.dropdown.position.call(tagify) 
// const tagifyCallbacks = {
//   add: updatearray,
//   remove: updatearray,
//   // input: printer,
//   edit: updatearray,
//   // invalid: printer,
//   // click: printer,
//   // keydown: printer,
//   // focus: printer,
//   // blur: printer,
//   // "edit:input": printer,
//   "edit:updated": updatearray,
//   // "edit:start": printer,
//   // "edit:keydown": printer,
//   // "dropdown:show": printer,
//   // "dropdown:hide": printer,
//   // "dropdown:select": printer
// }




class Settings extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      publications: [], 
      changedKeys:[],
      status:{}, 
      profile:JSON.parse(JSON.stringify(this.props.user)), 
      billing: null, 
      saved:true, 
      selectedFeed:-1
    }

  }


  componentDidUpdate(prevProps){
    // if(JSON.stringify(prevProps.user.feeds) !== JSON.stringify(this.props.user.feeds) && this.props.user.feeds){ 
    //   console.log('FEEDS CHANGED')
    //   this.props.setFeeds(this.props.user.feeds, 'profile')
    // }
  }

 
  upd(key, obj, index, manualSave){

    //reset autosave

    //initiate
    this.state.profile[key] = this.state.profile[key] ? this.state.profile[key] : []
    // console.log(newState)
    if(index===null || index===undefined){
      this.state.profile[key] = obj
    }else{
      this.state.profile[key][index] = obj
      if(!obj){
        this.state.profile[key].splice(index ,1)
      }
      // this.state.profile[key][index] = this.state.profile[key].filter(o=>o)
    }

    // console.log(this.state.changedKeys)

    this.setState({profile:this.state.profile, 
                  saved:false, 
                  changedKeys:this.state.changedKeys.concat(key).filter((key,i,arr)=>arr.indexOf(key)===i),
                  addTarget:null, 
                  selectedFeed:(obj ? this.state.selectedFeed : -1)}, 
                  !manualSave ? this.save : ()=>{})
  }

  save(){
    // this.props.user = this.state.profile
    const allowedFields = this.state.changedKeys//['anchors', 'billingAddress','websites'];
    const filteredUpdates = Object.keys(this.state.profile).reduce((obj, key) => {
        if (allowedFields.includes(key)) {
            obj[key] = this.state.profile[key];
        }
        return obj;
    }, {});

    this.props.saveProfile(filteredUpdates, true)
    // this.props.setFeeds(this.state.profile.feeds, 'profile')
    this.setState({saved:true, changedKeys:[]}) //profile: this.state.profile,
  }

  render () {

    if(!this.props.user){
      return null
    }

    var self = this
    let saved = this.state.saved
    // const user = this.props.user;
    // let feeds = profileFeeds
    let feeds = this.state.profile.feeds
    let month = moment().format('YYYY-MM')
    let bill = this.state.profile.bills.filter(bill=>bill.date===month)
    

    bill = bill ? bill : []
    let websites = this.state.profile.websites

    let role = this.props.user.curator ? 'curator' : (this.props.user.writer ? 'writer' : (this.props.user.admin ? 'admin' : null))

    let charge = 1
    let sum = bill ? bill.reduce((a,b)=>a+b.value*charge,0) : 0
    // console.log(bill)
    const usage = sum > 0 ? bill.map(b=>{
        if(b.id){
          let candidate = feeds.find(f=>f.id === b.id)
          return {name: (candidate ? (candidate.title+' ('+b.id+')') : b.id), value: b.value, logo:candidate ? candidate.logo : null}
        }else{
          return null
        }
      }).filter(bill=>bill) : null
    let validTarget = self.state.addTarget ? Object.keys(self.state.addTarget).reduce((a, field)=>self.state.addTarget[field].length>0 && a,true) : false

    return (
      <div  style={{width:'100%',maxWidth:800, margin:'auto', paddingTop:100, paddingBottom:200, textAlign:'center'}}>
        {saved ? null : <div className={"whitebutton highlight save"} style={{cursor:'pointer'}} onClick={()=>this.save()}>
            Save Changes
          </div>}

        {role ? <p className="rightTopBadge">{role} account</p> : null}
        
            {['curator','writer'].includes(role) ? null :  <Pie data={usage}/>}
            {false && !user.writer && user.credit>0 && !user.apikey ? <p style={s.notice}>{"You have " + user.credit + " free articles left"}</p> : null}

    

        {user.writer ? null : <div style={{margin:0,padding:'0px 15px',paddingTop:100, overflowX:'hidden',width:'100%'}}>
            <p style={s.title}>Integrations</p> 
          

              <div className="listBox">
               
                <Integrate user={user} addNew={true}/>

                <p style={{padding:10, testAlign:'center'}}>Read more about how to <a href="https://monok.news/docs/integration/" target="_blank" style={{color:'#428bca'}}>integrate with Wordpress or other platforms</a></p>
               
              </div>
            
        </div>}

        {user.email==='tools@apexmedia.ltd' || user.admin ? <div style={{margin:0,marginTop:30,padding:'0px 15px',width:'100%'}}>

          <p style={s.title}>Anchor Links <img style={{width:150}} src="https://apexmedia.ltd/wp-content/uploads/2024/05/apex-logo.png"/></p>
          <Anchors anchors={this.state.profile.anchors} onChange={(newAnchors)=>this.upd('anchors', newAnchors, null, true)}/>
        </div> : null}

        {user.writer ? null : <div style={{margin:0,marginTop:30,padding:'0px 15px',width:'100%'}}>

          <p style={s.title}>Your API-key</p>
            <ListGroupItem style={{marginTop:30,borderRadius:15,marginBottom:30, wordBreak: 'break-all'}}>
              {this.state.profile.apikey ? this.state.profile.apikey : 'Insert a payment method (credit card) to receive an API Key.'}
            </ListGroupItem>
          <div style={{marginBottom:20}}>
            <p>Use this key to access your feed content as a JSON through the monok API, Read our <a className={"normalLink"} href="https://monok.news/docs/integration/" target="_blank">documentation here</a>, or with the <a href="/static/plugins/wp/monoksync.zip" download className={"normalLink"}>WordPress plugin</a>.</p>
           </div>
        </div>}

        {(!user.writer && feeds && feeds.length>10)  ? <div style={{margin:0,marginTop:30,padding:'0px 15px',width:'100%'}}>

          <p style={s.title}>Your Data</p>
            <ListGroupItem style={{marginTop:30,borderRadius:15,marginBottom:30, wordBreak: 'break-all'}}>
              <p>Export your feeds as an <a href="/api/v1/exportMyData" download>Excel file (.xlsx)</a></p>
              <p>Export your feeds as a <a href="/api/v1/exportMyData?csv=true" download>CSV file (.csv)</a></p>
            </ListGroupItem>
          
        </div> : null}

        {user.writer ? <div>
          <p style={s.title}>Your Paypal e-mail</p>
          <input
            type="email"
            id="paypal"
            name="paypal"
            placeholder="Paypal e-mail"
            value={this.state.profile.paypal || (this.state.profile.email || '')}
            onChange={(e) => this.upd('paypal', e.target.value, null, true)}
            style={{width:'100%', padding: 10, borderRadius: 15, fontSize:'large', border: 'solid 1px #ccc4c4' }}
          />
        </div> : null}

        {user.writer ? null : <div style={{margin:0,padding:'0px 15px',paddingTop:100, overflowX:'hidden',width:'100%'}}>
          <p style={s.title}>Billing</p>
          <form className="listBox billingForm">
            <div className="">
              <label for="address">Billing Name</label>
              <input 
                id="billingname"
                type="text" 
                value={this.state.profile.billingAddress ? this.state.profile.billingAddress.billingName : ''}
                onChange={(e)=>this.upd('billingAddress', {...(this.state.profile.billingAddress ? this.state.profile.billingAddress : {}), billingName:e.target.value}, null, true)}
                // onChange={(e)=>this.setState({saved:false, profile:{...this.state.profile, billingAddress:{...(this.state.profile.billingAddress ? this.state.profile.billingAddress : {}), billingName:e.target.value}}})}
                spellCheck="false" 
                placeholder="Billing Name" 
              />
            </div>
            <div className="">
              <label for="address">Address</label>
              <input 
                id="address"
                onChange={(e)=>this.upd('billingAddress', {...(this.state.profile.billingAddress ? this.state.profile.billingAddress : {}), address:e.target.value}, null, true)}
                type="text" 
                value={this.state.profile.billingAddress ? this.state.profile.billingAddress.address : ''}
                spellCheck="false" 
                placeholder="Address" 
              />
            </div>
            
            <div className="">
              <label for="city">City</label>
              <input 
                id="city"
                onChange={(e)=>this.upd('billingAddress', {...(this.state.profile.billingAddress ? this.state.profile.billingAddress : {}), city:e.target.value}, null, true)}
                value={this.state.profile.billingAddress ? this.state.profile.billingAddress.city : ''}
                type="text" 
                spellCheck="false" 
                placeholder="City" 
              />
            </div>
            
            <div className="">
              <label for="state">State/Province</label>
              <input 
                id="state"
                type="text" 
                value={this.state.profile.billingAddress ? this.state.profile.billingAddress.state : ''}
                onChange={(e)=>this.upd('billingAddress', {...(this.state.profile.billingAddress ? this.state.profile.billingAddress : {}), state:e.target.value}, null, true)}
                spellCheck="false" 
                placeholder="State/Province" 
              />
            </div>
            
            <div className="">
              <label for="postal-code">Postal Code</label>
              <input 
                id="postal-code"
                value={this.state.profile.billingAddress ? this.state.profile.billingAddress.postalCode : ''}
                onChange={(e)=>this.upd('billingAddress', {...(this.state.profile.billingAddress ? this.state.profile.billingAddress : {}), postalCode:e.target.value}, null, true)}
                type="text" 
                spellCheck="false" 
                placeholder="Postal Code" 
              />
            </div>
            
              <div className="">
              <label htmlFor="country">Country</label>
              <select
              style={{padding: 10, borderRadius: 10,border: 'solid 1px #ccc4c4', background: 'white'}}
                id="country"
                value={this.state.profile.billingAddress ? this.state.profile.billingAddress.country : ''}
                onChange={(e) =>
                  this.upd(
                    'billingAddress',
                    {
                      ...(this.state.profile.billingAddress ? this.state.profile.billingAddress : {}),
                      country: e.target.value
                    },
                    null,
                    true
                  )
                }
              >
                <option value="" disabled>Select a country</option>
                {window.countries.map((country) => (
                  <option key={country.name} value={country.name}>
                    {`${country.emoji} ${country.name}`}
                  </option>
                ))}
              </select>
            </div>

            <div className="">
              <label for="vat">VAT Number</label>
              <input 
                id="vat"
                value={this.state.profile.billingAddress ? this.state.profile.billingAddress.vat : ''}
                onChange={(e)=>this.upd('billingAddress', {...(this.state.profile.billingAddress ? this.state.profile.billingAddress : {}), vat:e.target.value}, null, true)}
                type="text" 
                spellCheck="false" 
                placeholder="VAT Number" 
              />
            </div>
        </form>
     
        </div>}

      

        {(this.props.user.writer || this.state.cards || (!this.props.user.agree && this.state.profile.agree)) ? null : <div style={{marginTop:30}} className="button blackButton" onClick={()=>this.setState({cards:true})}>Payment Method</div>}
        {false ? <a href={"https://billing.stripe.com/p/login/8wM28ocwt6zCaiI9AA?prefilled_email="+encodeURIComponent(user.email)} target="_blank"><div className="button stripeButton">Payment Method with <i style={{fontSize:'14pt', verticalAlign: 'middle'}} className="fa fa-cc-stripe" aria-hidden="true"></i> →</div></a> : null}
        
        
        {(this.state.cards || (!this.props.user.agree && this.state.profile.agree)) ? 
          <div>
            <Creditcards iban={true} user={this.props.user} bills={this.state.profile.bills} onRefresh={(billing)=>{this.setState({billing:billing})}}/>
          </div> : null}
    {user.invoices && user.invoices.length>0 ? 
          <div style={{margin:0,padding:'0px 15px',paddingTop:100, overflowX:'hidden',width:'100%'}}>
            <p style={s.title}>Invoices</p>
             <div className="listBox" style={{maxHeight:400, overflowY:'scroll'}}>
               {user.invoices.map(invoice=><div className="darkHighlight" style={{color:'#6a707a', padding:10,fontSize: 'initial', display:'flex', justifyContent: 'space-between', borderRadius:10}}>
                 <a href={invoice.url}>{invoice.date} - {invoice.amount} EUR</a>
                {invoice.status==='paid' ? <span className="badge" style={{lineHeight:'17px', backgroundColor:'rgb(139, 195, 74)'}}>PAID</span> : null}
               </div>)}
             </div>
          </div> : null}
       {/* <label for="maillist" style={{padding: '30px 0px 10px', display:'block'}}>
          <input type="checkbox" id="maillist" name="maillist" checked={this.state.profile.subscribed} onChange={(e)=>this.upd('subscribed', e.target.checked)}/>&nbsp;Subscribed to our mailing list for updates and offers
        </label>*/}
      </div>
    );
  }
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      padding:'30px',
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "26px",
      "::placeholder": {
        color: "red",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
const mapStateToProps = (state)=>{
  return {
    user:state.auth.user,
    feeds:state.feeds,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile:(newProfile, partial)=>dispatch(saveProfile(newProfile, partial)),
    // setProfile:(newProfile)=>dispatch(setProfile(newProfile)),
    // setFeeds:(newFeeds, goto)=>dispatch(setFeeds(newFeeds, goto)),
    // setProfileOffline:(newProfile)=>dispatch(setProfileOffline(newProfile))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
