var React = require('react');
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import DepthPhoto from './depthPhoto.jsx';
import MonokText from './monokText.jsx';
import {urlToObject, imagesFromYT} from '../../util';

let landscapeWidth = 800
// let landscapeWidth = this.props.forScreenshot ? 1422 : 800
let landscapeHeight = 450
// let landscapeHeight = this.props.forScreenshot ? 800 : 450

// import '@tensorflow/tfjs-backend-webgl';
// const blazeface = require('@tensorflow-models/blazeface');
// var ColorThief = require('color-thief');
// import * as tf from '@tensorflow/tfjs';
// <SmallMap style={{...c,...mapStyle,...this.props.style}} widget={widget} marker={true} />
class VfImage extends React.Component {

  constructor(props){
      super(props);

      this.state = {ready:false}
  }

  setResolution(url, size){
    return url ? url.replace(/\/\d{2,3}px/ig,'/'+size+'px') : url
  }

  isMobile(){
     return !this.props.forScreenshot && window.innerWidth<800
  }

  wrapTT(tt, child,keyId){
    const ttDom = (<Tooltip id="tooltip"><strong>{tt}</strong></Tooltip>);
    if(this.isMobile()){
      return child
    }else{
      return (<OverlayTrigger key={keyId ? keyId : null} placement="bottom" overlay={ttDom}>
              {child}
              </OverlayTrigger>)
    }
  }



  getImage(vf){
    if(!vf){
      return null
    }
    let data =  vf && vf.data && vf.data[0] ?  vf.data[0] : null
    let image =  data && data.image ? data.image : null
    image = image ? image : this.props.image
    image ? (image.description = image.description ? image.description : data.text) : null

    let params = Object.fromEntries(new URLSearchParams(location.search))

    if(!image && vf && vf.thumbnail && params.vf){
      image = {url:vf.thumbnail}
    }

    if(!image && vf && vf.url){
      return vf
    }

    return image
  }

  componentDidMount(){
    let {vf} = this.props

    let image = this.getImage(vf)

    let ratio = image && image.height ? image.width/image.height : 1.1
    let landscape = ratio>1
    if(!landscape && image && image.type!=='logo'){
      this.findFaces(image.url)
    }else if(!this.state.ready && image){
      // if(window.removeBlackPadding){
        this.cropPadding(image.url)
    }else if(!this.state.ready){
        // this.setState({ready:true},()=>{
        //   this.props.onReady ? this.props.onReady() : null
        // })
    }
  }

  componentDidUpdate(prevProps, prevState){

    let {vf} = this.props

    let image = this.getImage(vf)

    let ratio = image && image.height ? image.width/image.height : 1.1

    let landscape = ratio>1
    if(!landscape && JSON.stringify(prevProps.vf)!==JSON.stringify(this.props.vf) && image && image.type!=='logo'){
      this.findFaces(image.url)
    }else if(landscape && this.state.face && image){
      // if(window.removeBlackPadding){
        this.cropPadding(image.url)
      // }else{
      //   this.setState({face:null, ready:true}, ()=>{
      //     this.props.onReady ? this.props.onReady() : null
      //   })
      // }
    }else if(!this.state.ready){
      //Is this state needed?
    //   this.setState({ready:true},()=>{
    //     this.props.onReady ? this.props.onReady() : null
    //   })
    }
  }

  toPortland(candidate, face){
    return candidate ? {
            type:'collage', 
            url:candidate.url, 
            encoded:JSON.stringify([
                  {
                    url:candidate.url, 
                    class:'portland', 
                    background:candidate.url,
                    faceY:face.y,
                    foreground:{
                      backdropFilter: 'sepia(0.5)',
                      background:'linear-gradient(45deg, rgb(92 205 255 / 29%), rgb(246 174 255 / 26%))'
                    }
                  },
                  {
                    class:'portlandForeground', 
                    url:candidate.url,
                    blurred:true
                  }
            ]).encode(), 
            description:candidate.description + " (retouched)"
            } : null
  }

 async cropPadding(url) {

    if(this.state.ready){
      this.setState({ready:false})
    }

    let img = new Image();
    img.crossOrigin='anonymous'
    img.src = this.setResolution(url,128)
    img.onload = async (e)=>{
      setTimeout(async () => {     
        console.log('Predicting crop'); 
        let zoom = undefined
        try{
          zoom = window.removeBlackPadding ? await window.removeBlackPadding.zoom(img) : undefined;
        }catch(e){
          console.log('Prediction of crop failed')
        }
        
        // zoom=150
        this.setState({face:null, ready:true, zoom:zoom},()=>{
          this.props.onReady ? this.props.onReady() : null
        })    
        
      }, window.removeBlackPadding ? 0 : 1000)   
    }
    img.onerror = ()=>{
      if(!url.includes('monok.com') && !img.src.includes('monok.com')){
        // url = 'http://dev.com/api/v1/fx12ha7sjs8djs54?url='+url
        url = 'https://www.monok.com/api/v1/fx12ha7sjs8djs54?url='+encodeURIComponent(url)
        // console.log('TRYING WITH PROXY')
        img.src=url
      }
    }
  }

  async findFaces(url) {

    if(this.state.ready){
      this.setState({ready:false})
    }

    let getFace = (prediction, img)=>{
      const start = prediction.topLeft;
      const end = prediction.bottomRight;
      const size = [end[0] - start[0], end[1] - start[1]];
      return {x:start[0]/img.width, y:start[1]/img.height, width:size[0]/img.width, height:size[1]/img.height, imgHeight:img.height, imgWidth:img.width}
    }

    let img = new Image();
    img.crossOrigin='anonymous'
    img.src = this.setResolution(url,128)
    img.onload = async (e)=>{
      setTimeout(async () => {     
        // console.log('Predicting face'); 
        let predictions = []
        try{
          predictions = window.facedetect ? await window.facedetect.estimateFaces(img, false, false, false) : [];
        }catch(e){
          console.log('Prediction failed')
        }   
        // let zoom = undefined

        // predictions.push({zoom:150})

        // if(predictions.find(prediction=>prediction.zoom)){
        //   zoom = predictions.find(prediction=>prediction.zoom).zoom
        // }
      
        // console.log('predictions done');    
        // console.log("predictions: " + JSON.stringify(predictions))
        if (predictions.length > 0) {
          //sort by largest head
          // predictions = predictions.filter(prediction=>!prediction.zoom)
          predictions = predictions.sort((a,b)=>{
            let aFace = getFace(a, img)
            let bFace = getFace(b, img)
            return bFace.width*bFace.height - aFace.width*aFace.height
          })

          let facePosition = getFace(predictions[0], img)
          let faceHeight = facePosition.height*(landscapeWidth*(facePosition.imgHeight/facePosition.imgWidth))
     
          this.setState({face:{...predictions[0], url:url, ...facePosition, tooBig:faceHeight>(landscapeHeight*0.8)}, ready:true},()=>{
            this.props.onReady ? this.props.onReady() : null
          })    
        }else {
          this.setState({face:null, ready:true},()=>{
            this.props.onReady ? this.props.onReady() : null
          })    
         // console.log('No face found');
        }
      }, window.facedetect ? 0 : 1000)   
    }
    img.onerror = ()=>{
      if(!url.includes('monok.com') && !img.src.includes('monok.com')){
        // url = 'http://dev.com/api/v1/fx12ha7sjs8djs54?url='+url
        url = 'https://www.monok.com/api/v1/fx12ha7sjs8djs54?url='+encodeURIComponent(url)
        // console.log('TRYING WITH PROXY')
        img.src=url
        // img.onerror = ()=>{
        //   if(img.src.includes('monok.com')){
        //     img.src='https://live.staticflickr.com/7335/27371201890_23016c81bf_c.jpg'
        //   }
        // }
      }
    }
  }

  // getPalette(e){
  //   console.log(e)
  //   const colorThief = new ColorThief();
  //   const img = document.querySelector(".whiteLogo");
  //   console.log(img)
  //   if (img.complete) {
  //   console.log(img)
  //     console.log(colorThief.getColor(img))
  //   } else {
  //     image.addEventListener('load', function() {
  //   console.log(img)
  //       console.log(colorThief.getColor(img))
  //     });
  //   }
  // }

  encodeURL(url){
    return url.replace(/\(/g, '%28').replace(/\)/g, '%29')
    // return encodeURI(url).replace(/\(/g, '%28').replace(/\)/g, '%29')
  }


  monokText(text, keywords, topIcon, intro, wordBefore, wordAfter, paused, style){
  
    return <MonokText text={text} force={true} 
            style={{background:'none',
             top: 0,
             lineHeight: 1.2,
            fontSize: 'large' ,
             width: '100%',
             height: 'inherit',
             position: 'relative',
             height: 'unset',
             padding: 0, ...(style ? style : {})}}></MonokText>
  }

  monokSlide(slide, style, key){

    let slideStyle = Object.keys(slide).reduce((a,b)=>(!['class','value'].includes(b) ? {...a, [b]:slide[b]} : a),{})
    return <div key={key}
            style={typeof slide.value === 'string' ? {} : {...(style ? style : {}),...slideStyle}}
            className={slide.class ? slide.class : ''}
            >
        {Array.isArray(slide.value) ? slide.value.map((child, i)=>
          typeof child === 'string' ? 
          this.monokText(child, undefined, undefined, undefined,undefined, undefined, undefined, {...child, class:null, value:null}) : 
          this.monokSlide(child, null, i+'k')) : 
        (typeof slide.value === 'object' ? this.monokSlide(slide.value) : 
        (typeof slide.value === 'string' ? this.monokText(slide.value, undefined, undefined, undefined,undefined, undefined, undefined, slideStyle) : null))}

      </div>
  }

  handleText(text, tweet){
    let newText = text
    
    if(newText[0]==='"' && newText[newText.length-1]==='"'){
        newText = newText.slice(1, -1)
    }
    if(newText.length>100){
      let texts = newText.match(/.+?[\.\–\-,]/g)
      texts = texts ? texts.reduce((a,b)=>a.length+b.length > 160 ? a : a + ' ' + b.trim(),'') : newText
      newText = texts
    }

    if(tweet){

    }else{
      newText = newText.replace(/^[",]*|[",]*$/g,'')
      newText = newText.replace(/(\$(\d{1,3}[\.,]?)+)/,`$1 💵`)
    }
                                                          
    return newText.split(' ')
  }

  render(){
    let {topImage, article, vf, update, animate, user} = this.props
    let params = Object.fromEntries(new URLSearchParams(location.search))
    let image = this.getImage(vf)

    let paused = this.props.paused
    let intro = params.vid || this.props.intro || animate

    if(!image && !(vf && vf.slide)){
      return null
    }

    if(vf && vf.slide){
      return this.monokSlide(vf.slide, {position:'absolute', backgroundSize: 'cover', backgroundPosition: 'center',top:0,left:0,width: "100%", height:'100%'})
    }

    if(vf.supervised){
      return <div onBlur={()=>{
        // update({visualFocus:originalArticle.visualFocus})
        // console.log('ITH APPENEDED')
      }} style={{padding:30, background:'rgb(241 241 241 / 16%)', borderRadius:30,marginBottom:60}}>
          <div style={{fontSize:'xxx-large', textAlign:'center'}}>
          <i className="fa fa-picture-o" aria-hidden="true" style={{marginRight:10}}></i>
          <i className="fa fa-youtube" aria-hidden="true"></i>
          </div>
          <input onChange={(e)=>{
            let url = e.target.value
            let obj = urlToObject(url, ['video', 'image'], article.sources.map(source=>source.title).join(' '))
            if(obj){
              let changes = {visualFocus:obj, thumbnail:obj.thumbnail ? obj.thumbnail : (obj.data && obj.data[0] && obj.data[0].image && obj.data[0].image.url ? obj.data[0].image.url : url)}
              if(obj.type==='video'){
                changes.videos = [obj].concat(article.videos)
                let imagesFromVideos = imagesFromYT(obj)
                changes.images = imagesFromVideos.concat(article.images)
                if(imagesFromVideos && imagesFromVideos[0]){
                  
                  changes.visualFocus = {
                    "data": [
                      {"image":imagesFromVideos[0]}
                    ],
                    "name": "stock",
                    "type": "person",
                    "thumbnail": imagesFromVideos[0].url
                    }
                    changes.thumbnail = imagesFromVideos[0].url
                }
              }
              update(changes)
            }
          }} style={{width:'100%', fontSize:'large', background:'white', color:'black', textTransform:'inherit'}} className="plugin-title" placeholder="Enter URL for photo or youtube video"/>
        <p style={{marginTop:15}}>Please ensure that you have permission to use an image or video before including it in your article</p>
        {user && (user.writer || user.admin || user.curator) ? <div>
          <p style={{marginTop:15}}>Here are some sources you may use: <a href="https://commons.wikimedia.org/w/index.php?title=Special:MediaSearch&go=Go&type=image" target="_blank">Wikimedia</a>, <a href="https://www.unsplash.com" target="_blank">Unsplash</a>, <a href="https://www.pexels.com" target="_blank">Pexels</a>, <a href="https://flickr.com/search/?license=2%2C3%2C4%2C5%2C6%2C9" target="_blank">Flickr</a></p>
          <p>The original sources at the bottom of this article may contain marketing material or other fair to use images</p>
        </div> : null}
  
        </div>
    }

    if(this.state.face && this.state.face.tooBig){
      image = this.toPortland({url:this.state.face.url, description:image.description}, this.state.face)
    }

    let ratio = image.height ? image.width/image.height : 1.1

    let landscape = ratio>1
    let perfectFit = ratio > 1.7 && ratio < 1.8
    let logo = !this.state.face && image.copyright!=='unsplash' && !topImage && (image.description + ' ' + image.url).match(/(\b|\W|_)(logo|emblem|seal|coat of arms|patch)(\b|\W)/gi) || vf.type==='organization' && ratio>2.2 && image.url.toLowerCase().indexOf('.png')>-1

    let imgStyle = s.newImg

    //Suited for narrow portraits with small faces at a distance
    let faces = [{ratio:0.6, height:3}, {ratio:0.65, height:5}, {ratio:0.7,height:10}, {ratio:1.1,height:15}]//, {ratio:2,height:15}

    let height = 300
    // console.log(faces)
    let facePosition = landscape ? null : faces.find((f, i)=>ratio < f.ratio && (faces[i].ratio > ratio || i===faces.length-1)).height
    
    //Suited for large faces close to camera
    if(this.state.face && !this.state.face.tooBig && ((intro && !paused) || !intro)){
      let percentFromTop=(100*this.state.face.y)
      //If there's a face, but detected below equator, assume bad detect of small face, and go to top
      facePosition = percentFromTop<0 || percentFromTop>50 ? 0 : percentFromTop
      //Large head, todo: center based on eyes and mouth
      facePosition = this.state.face && this.state.face.height > 0.5 ? facePosition*2 : facePosition
    }

    let faceStyle = facePosition!==null ? {backgroundPosition:'center '+facePosition+'%',backgroundSize:'cover'} : {}
    let landscapeStyle = {backgroundPosition:'center',backgroundSize:'cover'}
    let logoStyle = {backgroundPosition:'center',backgroundSize:'contain', backgroundRepeat:'no-repeat'}
    let shade = {position: 'absolute', top: 0, width: '100%', height: '100%',background:'linear-gradient(183deg, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.63) 67%, rgba(0, 0, 0, 0.83) 95%)'}
    let topStyle = {height:448}//, backgroundPosition:'0 40%'}

    let collage = image.data ? image.data : (image.encoded ? JSON.parse(image.encoded.decode()) :  null)


    if(collage && collage.slide){
      return this.monokSlide(collage.slide, {position:'absolute', backgroundSize: 'cover', backgroundPosition: 'center',top:0,left:0,width: "100%", height:'100%'})
    }
    

    imgStyle = (landscape && !this.state.face) ? {...imgStyle, ...landscapeStyle} : {...imgStyle, ...faceStyle}//(ratio < 0.6 ? slimStyle : portraitStyle)
    // console.log(imgStyle)
    imgStyle = logo ? logoStyle : imgStyle
    imgStyle = topImage ? {...imgStyle, ...topStyle} : imgStyle
    // console.log(imgStyle)
    imgStyle = this.props.bigPuff  ? {...imgStyle, ...{height:this.props.height ? this.props.height : landscapeHeight, margin:null}} : imgStyle
    // console.log(imgStyle)
    imgStyle = collage && collage.length>1 ? {...imgStyle, margin:null} : imgStyle
    
    if(this.state.zoom && this.state.zoom>100){
      imgStyle.backgroundSize = this.state.zoom + '%'
    }

    let wrapper = logo ? {marginBottom: "30px", borderRadius:5} : s.wrapper
   
    // console.log(image)
    let defaultResolution = (this.props.resolution ? this.props.resolution : landscapeWidth)
    let url = image ? this.setResolution(image.url,(this.props.resolution ? this.props.resolution : defaultResolution)) : ''
    
    // console.log(url)
    url = url ? url.replace('http://','https://') : ''
    
    let citeNotice = this.props.forScreenshot && this.props.citeNotice ?  this.props.citeNotice : null

    let credit = image.copyright ? image.copyright : null
 
    let legal = credit && credit==='unsplash' ? <a className="credit" rel="nofollow" target="_blank" href={"https://unsplash.com/?utm_source=monok&utm_medium=referral"}><i>{credit}</i></a> :
                this.wrapTT(credit ? 'This is an automatic attribution' : 'Creative Commons Attribution-Share Alike 2.0 Generic license. This is an automatic attribution, click to read more.', 
                <span onClick={credit ? null : ()=>{window.open('https://creativecommons.org/licenses/by-sa/2.0/legalcode', '_blank');} } style={{cursor:'pointer'}}>{credit ? credit : [<span className={"fa fa-creative-commons"} aria-hidden="true"></span>, ' BY-SA']}</span>)

    let author = image.author && image.author.name ? <span>by {credit==='unsplash' && image.author.username ? <a className="credit" rel="nofollow" target="_blank" href={"https://unsplash.com/@"+image.author.username+"?utm_source=monok&utm_medium=referral"}><i>{image.author.name}</i></a> : image.author.name}{credit==='unsplash' ? ' of ' : ''}</span> : null
    let by = <span>{credit ? 'Photo' : ''} {author ? author : null} {legal} {user && user.writer ? <a href={url} target="_blank">link</a> : null}</span>
    
    //################ HOTFIX TEMPORARY DEPTHPHOTO ###########
    let hotfix=[{url:'2019-10-01_Demonstration_Hong_Kong_11.jpg',
                gif:'hongkong.jpg'},
                //https://www.monok.com/puff/esper-and-milley-refuse-to-testify-on-military-s-role-in-controlling-protests-source-says
                {url:'George_Floyd_protests%2C_downtown_Indianapolis%2C_2020-05-29.jpg',
                gif:'indianapolis1.jpg'},
                //https://www.monok.com/puff/man-shot-dead-after-suspect-drove-car-through-crowd-of-protesters-in-seattle-according-to-authorities
                {url:'man_stands_on_a_burned_out_car_on_Thursday_morning',
                gif:'manoncar.jpg'},
                //https://www.monok.com/puff/four-seriously-injured-after-jumping-off-dorset-cliff
                {url:'Durdle_Door_Overview.jpg',
                gif:'dorset.jpg'},
                //https://www.monok.com/puff/up-to-200-000-seafarers-trapped-from-vessels-according-to-un
                {url:'Funding_Bill_for_Coronavirus_Response',
                gif:'bill.jpg'},
                //http://restsocialexample.com/puff/debate-heats-up-at-the-white-house-on-using-an-anti-malaria-drug-to-treat-covid?hl=en
                {url:'White_House_Coronavirus_Update_Briefing',
                gif:'fac.jpg'},
                //http://restsocialexample.com/puff/democrats-pledge-sweeping-change-with-police-reform-bill
                //https://www.monok.com/puff/trump-s-press-secretary-appears-to-disclose-presidential-banking-details-to-world-cameras
                // {url:'White_House_Press_Briefing',
                // gif:'White_House.jpg'}
                ]
    let found = hotfix.find(h=>image.url && image.url.indexOf(h.url)>-1)
    let depthPhoto = found ? found.gif : false
    //################ HOTFIX TEMPORARY DEPTHPHOTO ###########
    let gradient = 'linear-gradient(183deg, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.63) 67%, rgba(0, 0, 0, 0.83) 95%),'
 
    // console.log(collage)

    let bylineText = `${image.description && image.description.length ? (image.description.length>200 ? image.description.slice(0,200)+'... - ' : image.description + ' - ') : ''}`
    let byline =  intro ? null : <p style={{...s.description,...(topImage ? s.overlayDescription : {}),...this.props.descriptionStyle}}>{bylineText}{by}</p>
    
    let cleanByline = <p style={{margin:0}}>{citeNotice === 'full' ? bylineText+' ' : ''}{credit ? 'Photo' : ''} {author ? author : null} {legal}</p> //
    // let byline =  intro ? null : <p style={{...s.description,...(topImage ? s.overlayDescription : {}),...this.props.descriptionStyle, color:by.includes('.com') && !['upload.wikimedia.org','live.staticflickr.com'].find(ok=>by.includes(ok)) && user && user.team==='review' ? 'red' : undefined}}>{image.description && image.description.length ? (image.description.length>200 ? image.description.slice(0,200)+'... - ' : image.description + ' - ') : ''}{by}</p>
    //background:'url("https://www.monok.com/static/img/icons/monok.gif?k") center center / 40% no-repeat'
   
    let gradients = {
      'darkQuote photoQuote':'linear-gradient(105deg, rgba(15, 32, 39, 0) 36%, rgba(44, 83, 100, 0.55) 78%), url(IMAGEURL) transparent center 0px / cover no-repeat',
      darkQuote:'linear-gradient(to right, #08121600 0%, #203a43 8%, #2c53648c 113%), url(IMAGEURL) transparent 61px 0px / cover no-repeat',
      // logoImage:'linear-gradient(43deg, rgb(65, 88, 208) 0%, rgb(200 80 192 / 76%) 46%, rgb(255 204 112 / 59%) 100%), url(IMAGEURL) center 0px / cover no-repeat transparent'
    }


      // linear-gradient(to right, #08121600 0%, #203a43 8%, #2c53648c 113%), url('+image.url+') transparent 61px 0px / cover no-repeat
      // console.log("FEED:")
      // console.log(this.props.feed)
      // console.log("COLLAGE")
      // console.log(collage)
    //URL

    let feedLogo = this.props.forScreenshot && this.props.feed && this.props.feed.logo ? this.props.feed.logo : (article && article.status && article.status.logo ? article.status.logo : (params && params.logo ? params.logo : (this.props && this.props.logo ? this.props.logo : null)))
   
    feedLogo = this.props.user && this.props.user.writer ? null : feedLogo

    let ourImage = this.props.forScreenshot && ['Monok','Unsplash'].includes(image.copyright) 
 
    

    //Feed Logo Style
    let feedLogoStyle = feedLogo ? {
        // background: "url(\"http://dev.com/api/v1/logoProxy?url="+feedLogo+"\")",
        background: "url(\"https://www.monok.com/api/v1/logoProxy?url="+feedLogo+"\")",
        width: 248,
        height: 46,
        backgroundSize: 'contain',
        backgroundPosition: ourImage ? 'right' : 'center',
        backgroundRepeat: 'no-repeat'
      } : {}


    // const img = document.querySelector('#visualFocusContainer');

    // // Make sure image is finished loading
    // if (img && img.complete) {
    //   let color = window.colorThief.getColor(img);
    //   console.log(color)
    // } else if(img) {
    //   image.addEventListener('load', function() {
    //       let color = window.colorThief.getColor(img);
    //       console.log(color)
    //   });
    // }
 
    let duration = this.props.vf &&  this.props.vf.duration ? Math.round(this.props.vf.duration) : 10
    let animations = {
      before:{
        transition:'background-position '+duration+'s linear, background-size '+duration+'s linear',
        ...(landscape ? {backgroundPosition:'0% center'} : {backgroundSize:'140%'}),
        ...(perfectFit ? {backgroundSize:'200%'} : undefined)
      },
      after:{
        transition:'background-position '+duration+'s linear, background-size '+duration+'s linear',
        ...(landscape ? {backgroundPosition:'100% center'} : {backgroundSize:'100%'}),
        ...(perfectFit ? {backgroundSize:'100%'} : undefined)
      }
    }
    




    // console.log(collage)
    return (<div  onClick={this.props.onClick ? this.props.onClick : null} style={{...wrapper,...this.props.style}} className={this.props.className ? this.props.className : null}>
      {collage && collage.length>1 ? 
       
        <div id={this.props.id ? this.props.id : undefined} style={{    
          display: 'grid',
          gridTemplateColumns: collage.map(image=>'auto').join(' '),
          gridGap: 4, 
          margin: null,
          background: collage && collage[1] && collage[1].location ? 
                      '#d4dadc' : (collage[0] && collage[0].speaker ? '#426673' : 
                        (collage[0] && collage[0].class==='logoImage' ? 
                          (collage[0].background ? 'linear-gradient(43deg, rgb(65, 88, 208) 0%, rgb(200 80 192 / 94%) 46%, rgb(255 204 112 / 50%) 100%), url('+this.setResolution(collage[0].background,defaultResolution)+')  center center / cover no-repeat' : 'linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%)')
                           : (collage[0].background ? 'url('+this.setResolution(collage[0].background, defaultResolution)+')   center 0px / 100% 180% no-repeat' : 'white'))),
          overflow:'hidden',
          position:'relative',
          ...(this.isMobile() ? {
            width: '160%',
            transform: 'scale(0.65)',
            margin: '-83px 0px -66px -30%'
          } : {})
        }}>
        {collage && collage[0] && collage[0].topIcon ? <div className="outline" style={{margin: '30px 270px', zIndex:10, position:'absolute', width:260, height:100,background:'url('+collage[0].topIcon+') center / contain no-repeat'}}></div> : null}
        {collage && collage[0] && collage[0].foreground ? <div className="foreground" style={{background:typeof collage[0].foreground === 'string' ? collage[0].foreground : undefined, ...(typeof collage[0].foreground === 'object' ? collage[0].foreground : {})}}></div> : null}
            {collage.map((image, i, arr)=>{

        // console.log(image)
              if(image && image.location){
                return null
              }else{
                
                let backgroundImage = image.url ? (image.class && gradients[image.class] ? gradients[image.class].replace('IMAGEURL',this.setResolution(image.url,defaultResolution)) : (topImage ? gradient : '')+'url('+this.encodeURL(this.setResolution(image.url, defaultResolution))+')') : undefined
                
                backgroundImage = image.blurred ? backgroundImage+', '+backgroundImage : backgroundImage
                
                // backgroundImage = true ? backgroundImage+', url(https://monokimages.s3.nl-ams.scw.cloud/c/cf/cf36dfca544e02a9c48c03d19e0078049fd86483eb79017d18018186bd4ca997.jpg)' : backgroundImage

                let imageStyle=image.class && gradients[image.class] ? {background:backgroundImage} : {...{backgroundImage:backgroundImage},...imgStyle, backgroundPosition: 'center 0%', position: 'relative', display: 'flex', alignItems: 'center'}
                // console.log('intro '+i+': '+intro+ ' - '+paused)
             
                if(image.faceY){
                  // console.log(image)
                  imageStyle.backgroundPosition = 'center '+Math.round(image.faceY*100)+'%'
                }

                // backgroundSize:collage[0] && collage[0].class==='logoImage' ? '100% 100%, 100% 100%' : undefined
                let logoImages = collage[0] && collage[0].class==='logoImage'
                let quoteFrame = collage[0] && collage[0].text
                let animations = [
                  {
                    before:{
                      backgroundPosition:'80%'
                    },//logoImages ? {marginLeft:undefined, marginRight:'70%'} : {transform:'scale(1.2)',marginLeft:'-100%', marginRight:undefined},
                    after:{},//{boxShadow:'rgb(0 0 0) 5px 0px 85px'},
                    quoteBefore:{fontSize:'40pt', width:'100%', transform:'scale(2) perspective(1000px) rotateY(80deg)'},
                    quoteAfter:{fontSize:'40pt', width:'100%',transform:'scale(1) perspective(1000px) rotateY(0deg)'},
                    logoBefore:{transform:'perspective(150px) rotateX(90deg)'},
                    logoAfter:{transform:'perspective(150px) rotateX(0deg)'},
                    wordBefore:{opacity:0, transform: 'perspective(10px) rotateY(-5deg)'},
                    wordAfter:{opacity:1, transform: 'perspective(10px) rotateY(0deg)'},
                    quoteBoxBefore:{maxWidth:336},
                    quoteBoxAfter:{maxWidth:336}
                  },

                  {
                    before:{
                      backgroundPosition:'80%',
                      marginLeft:image.class==='logoImage' ? '70%' : undefined
                    },after:{

                    }
                  },
                  {}
                ].map((frame,i)=>{
                  return {...frame,
                    letterBefore:{
                      opacity:0,
                      transform:'scale(5)  translateX(10px) perspective(5px) rotateY(-60deg)',
                    },
                    letterAfter:{
                      margin:1,
                      opacity:1,
                      transform:'scale(1) translateX(0px) perspective(5px) rotateY(0deg)',
                    },
                    speakerBefore:{
                      boxShadow:'0px 0px 119px 12px #ffc529',
                      transform:'scale(2) perspective(150px) rotateY(70deg)'},
                      // margin:'auto auto 35px auto'
                    speakerAfter:{
                      transform:'scale(1) perspective(150px) rotateY(0deg)',
                      // margin:'auto auto 35px auto'
                    },
                    nameBefore:{
                      backgroundSize: '0%'
                    },
                    nameAfter:{
                      backgroundSize: '100%'
                    }
                  }
                })



                if(intro){//params.vid???
                  image = {...image, ...animations[i]}
                }
                // console.log(collage)
                return <div className={(intro ? "slowAnimate " : "")+(image.class ? image.class : '')} 
                            style={{...imageStyle, ...(intro ? (paused ? image.before : image.after): {})}}>
                
                {intro && image.description && !logoImages && !quoteFrame ? 
                      <p className={(intro ? "animate " : "")+"nametag"} 
                        style={intro ? {transitionDelay:i*3000+'ms', ...(paused ? image.nameBefore : image.nameAfter)}: {}}
                        >
                        {image.description.split("").map((l,j)=>
                          <div className={(intro ? "animate " : "")+"tag"} 
                              style={intro ? {transitionDelay:i*3000+j*100+'ms', ...(paused ? image.letterBefore : image.letterAfter), width:l===' ' ? 10 : undefined} : {}}>
                          {l}
                          </div>)}
                      </p>: null}

                {i===0 && collage[0] && collage[0].class==='logoImage' && feedLogo ? 
                <div  className={(intro ? "slowAnimate " : "")+"logoQuote"} 
                      style={{...feedLogoStyle, ...{position: 'absolute', marginLeft: '75%', bottom: -75},...(intro ? (paused ? image.logoBefore : image.logoAfter): {})}}/> 
                      : null}
                {image.text ? <div className={(intro ? "slowAnimate " : "")+"quoteBox"}>
                  {<div style={{margin:'auto 0px 0px', display: 'flex', flexDirection: 'column',alignItems: 'flex-end', justifyContent: 'center'}}>
                    
                    {image.speaker && !image.avatar ? 
                      <p className={(intro ? "slowAnimate " : "")+"speaker"} 
                        style={intro ? (paused ? image.speakerBefore : image.speakerAfter): {}}>
                        {image.speaker}
                      </p>: null}
                    {image.avatar ? <img  className="shadowed" style={{width:55, margin:5}} src="/static/img/twitter.png"/>: null}
                    <p className={(intro ? "slowAnimate " : "")+""} style={{...(intro ? (paused ? image.quoteBoxBefore : image.quoteBoxAfter) : {}),marginTop:collage && collage[0] && collage[0].topIcon ? 60 : 0, fontSize:image.text.length>160 ? 'large' : (image.text.length > 20 ? 'x-large' : 'xx-large')}}>
                      {image.speaker ? <i className={(intro ? "slowAnimate " : "")+"fa fa-quote-left"} style={intro ? (paused ? image.quoteBefore : image.quoteAfter) : null } aria-hidden="true"></i> : null}
                      {

                        this.handleText(image.text, image.avatar).map((word,i)=>{
                                  // console.log(image.keywords)
                                  var emoji = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

                                  if(word.replace(emoji, '') === ''){
                                    return <div className="emoji">{word}</div>
                                  }
                                  let keys = image.keywords ? image.keywords.sort((a,b)=>a.length-b.length).filter(keyword=>
                                    (image.topIcon ? !image.topIcon.includes(keyword) : true) && 
                                    image.text.toLowerCase().split(' ').indexOf(keyword)>-1
                                  ).slice(0,2) : []

                                  if(intro || (keys.length > 1 ? (keys.indexOf(word.toLowerCase())>-1 || word.match(/[^\$]\d{1,4}(-\d{1,3})?/)) : false)){
                                    return <div className={(intro ? "animate " : "")+"tag"} style={(intro ? {transitionDelay:i*400+'ms',...(paused ? image.wordBefore : image.wordAfter)} : {})}>{word}</div>
                                  }
                                  

                                  return word+' '
                                })}

                      {image.text.length>160 ? '...' : ''}
                    </p>
                    {image.avatar ? 
                          <div style={{display:'flex'}}>
                            <img className="avatar" src={image.avatar}/>
                            <div style={{display:'flex',marginLeft: 15, flexDirection: 'column'}}>
                              {image.speaker ? <p style={{fontSize:'larger'}}>{image.speaker}</p>: null}
                              {image.username ?  <p style={{fontWeight:'normal', fontSize:'large', margin:'-15px 0px'}}> {image.username}</p> : null}
                            </div>
                          </div> : null}
                  </div>}
                  {feedLogo ? <div  className={(intro ? "slowAnimate " : "")+"logoQuote"} 
                                    style={{...feedLogoStyle, height:image.avatar ? 20 : 40,
                                          ...(intro ? (paused ? image.logoBefore : image.logoAfter): {})}}/> : null}
                </div> : null}
              </div>

              }
            })}

        </div> 


      : (
            depthPhoto ? 
            <DepthPhoto url={"/static/img/"+depthPhoto} target={this.props.target} offset={this.props.offset}>
              {topImage ? <div style={shade}></div> : null}
              {topImage && article ? <h1 style={s.title}>{article.title}</h1> : null}
              {topImage ? <div style={s.byline}>
                {this.props.children}
              </div> : null}
            </DepthPhoto>
            :
            <div id={this.props.id ? this.props.id : undefined} 
                className={(params.vf ? "" : (intro ? "slowAnimate " : "animate-fast "))+"collageContainer"} 
                style={{...{backgroundImage:(topImage ? gradient : '')+(feedLogo ? 'linear-gradient(351deg, #0000006b -2%, transparent 35%), ' : '')+'url('+this.encodeURL(url)+')'
                        +(this.props.vf && this.props.vf.thumbnail && this.props.vf.thumbnail!==url ? ', url('+this.props.vf.thumbnail+')' : '')},...imgStyle, ...(intro ? (paused ? animations.before : animations.after): {})}}>
                
                {false && intro && this.state.face ? 
                  <div className={intro ? "lateAnimate " : ""} style={true ? {
                        ...s.circle,
                        top:(100*this.state.face.y-18)+'%', 
                        left:(100*this.state.face.x-18)+'%', 
                        width:(landscapeWidth*this.state.face.width+300)+'px',//(100*this.state.face.width/128) 
                        height:(landscapeWidth*this.state.face.width+300)+'px', 
                        // background: paused ? 'transparent': '#0000005e',
                        boxShadow: paused ? 'none': '5px 0px 85px #000',
                       
                        // filter: 'blur(10px)',
                        border:null
                        // top:(100*this.state.face.y)+'%', 
                        // left:(100*this.state.face.x)+'%', 
                        // width:(100*this.state.face.width)+'%',//(100*this.state.face.width/128) 
                        // height:(this.state.face.height*(800*(this.state.face.imgHeight/this.state.face.imgWidth))), 
                        // border:'solid #00fa9a6e'
                  } : {
                        ...s.circle,
                        top:(landscapeWidth*this.state.face.y-600)+'px', 
                        left:(landscapeHeight*this.state.face.x-480)+'px', 
                        width:(landscapeWidth*this.state.face.width+1200)+'px',
                        height:(landscapeWidth*this.state.face.width+1200)+'px',
                        border: (paused ? '500px solid #ffffff42' : '400px solid #ffffff42')
                      }}> </div> : null}
              {topImage && article ? <h1 style={s.title}>{article.title}</h1> : null}
                        
              {topImage ? <div style={s.byline}>
                {this.props.children}
              </div> : null}
              
              {feedLogo && ourImage ? <div  className={(intro ? "slowAnimate " : "")+"logoQuote"} 
                                    style={{...feedLogoStyle, position: 'absolute', bottom: 10, right: 14, height:image.avatar ? 20 : 40,
                                          ...(intro ? (paused ? image.logoBefore : image.logoAfter): {})}}/> : null}
            </div>

        )}
      {citeNotice ? <div style={{bottom: 2, position: 'absolute', left: 3, color: 'rgb(255 255 255 / 79%)', fontSize: '7px', maxWidth: '60%'}}>
                {cleanByline}
              </div> : (this.props.forScreenshot ? null : byline)}
     </div>) 
  }
  

}

module.exports = VfImage;

const s = {
    thumb:{
    verticalAlign: 'text-top', 
    width: '20%', 
    paddingBottom:'10%',
    marginRight:10,
    minHeight: 40, 
    minWidth: 40,
    borderRadius: "50%",
    backgroundSize:'cover',
    float:'left',
    backgroundPositionX: 'center'
  },
  wrapper:{
    marginBottom: "30px",
    // borderRadius:5,
    // zIndex:10,
    // backgroundColor:'grey',
    position:'relative'
  },
  description:{
    fontSize: '14px',
    color: '#999',
    overflow:'hidden',
    // marginTop: 5,
    // backgroundColor: 'white',
    paddingTop: 6,
    paddingRight:30,
    maxWidth: 600,
    margin: '0 auto 10px auto'
  },
  newImg:{
    minHeight:350
  },
  title:{
    color:'white',
    position:'absolute',
    bottom:60,
    margin:0,
    padding:30,
    fontSixe:30,
    fontWeight:'bolder',
    textShadow:'0px 2px 8px #000'
  },
  img:{
    maxHeight: 300,
    minHeight: 180,
    /* width: 100%; */
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block'
  },
  landscape:{
    width:"100%"
  },
  byline:{
    position: 'absolute',
    bottom: 67,
    paddingLeft: 30,
    paddingRight: 30,
    width: '100%'
  },
  overlayDescription:{
    position: 'absolute',
    // bottom: 67,
    fontSize:11,
    // top:30,
    paddingTop:0,
    paddingBottom:0,
    marginBottom:0,
    bottom:20,
    left:30,
    color:'#ffffffbd',
    paddingLeft: 0,
    paddingRight: 30
  },
  circle:{
    top: -667,
    left: -441,
    width: 1600,
    height: 1600,
    position: 'absolute',
    borderRadius: '50%',
    border: '500px solid #ffffff42'
  }
}