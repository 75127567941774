module.exports = {
	FEED_REQUEST:'FEED_REQUEST',
	FEED_SUCCESS:'FEED_SUCCESS',
  	FEED_FAILURE:'FEED_FAILURE',
	ADD_FEED_REQUEST:'ADD_FEED_REQUEST',
	// ADD_FEED_REQUEST:'ADD_FEED_REQUEST',
	ADD_FEED_SUCCESS:'ADD_FEED_SUCCESS',
  	ADD_FEED_FAILURE:'ADD_FEED_FAILURE',
	REMOVE_FEED_REQUEST:'REMOVE_FEED_REQUEST',
	REMOVE_FEED_SUCCESS:'REMOVE_FEED_SUCCESS',
  	REMOVE_FEED_FAILURE:'REMOVE_FEED_FAILURE',
	// SEARCH_REQUEST:'SEARCH_REQUEST',
	// SEARCH_SUCCESS:'SEARCH_SUCCESS',
	// SEARCH_FAILURE:'SEARCH_FAILURE',
	UPDATE_MENU:'UPDATE_MENU',
	ADD_FEED:'ADD_FEED',
	REMOVE_FEED:'REMOVE_FEED',
	SET_FEEDS:'SET_FEEDS',
	SET_FEED:'SET_FEED',
	SET_TABS:'SET_TABS',
	SET_PUFFS:'SET_PUFFS',
	SET_PUFF:'SET_PUFF'

}
