import * as MediumEditor from 'medium-editor';

export const MediumEditorScrollOverflowFix = MediumEditor.Extension.extend({
  name: 'scrollOverflowFix',
  scrollElement: null,
  toolbar: null,
  initialScrollPosition: 0,
  initialToolBarPosition: 0,

  init() {
    // console.log('INITIATED')
    this.setToolBarPosition();
    this.listenForScrollEvent();
  },

  destroy() {
    // console.log(this)
    // console.log(this.unsubscribe)
    // this.unsubscribe('positionedToolbar')
    // this.destroy()
    // console.log('COOL')
    window.removeEventListener('scroll', this.updatePositionOnScroll.bind(this), true);
  },

  setToolBarPositionFunction(){
      // console.log('adadasdlaöskdölsakd')
      this.toolbar = document.querySelector('[class*="medium-editor-stalker-toolbar"');
      // this.toolbar = document.querySelector('[id*="medium-editor-toolbar"');
      // console.log(this.toolbar)
      // this.toolbar = document.querySelector('.medium-editor-toolbar');
      this.initialToolBarPosition = parseFloat(this.toolbar.style.top)
      this.initialScrollPosition = this.scrollElement.scrollTop;
  },

  setToolBarPosition() {
    // console.log(this.subscribe)
    // console.log("setToolBarPosition")
    this.subscribe('positionedToolbar', this.setToolBarPositionFunction.bind(this));
  },

  listenForScrollEvent() {
    this.scrollElement = document.querySelector('#modalContainer');
    // console.log('listening')
    // console.log(this.scrollElement)
    if (this.scrollElement) {
      // console.log('listening.......')
      this.initialScrollPosition = this.scrollElement.scrollTop;
      // console.log( this.initialScrollPosition)
      window.addEventListener('scroll', this.updatePositionOnScroll.bind(this), true);
      // window.addEventListener('click', this.updatePositionOnScroll.bind(this), true);
    }
  },

  updatePositionOnScroll(e) {
    // console.log('IT*S HAPPENING')
    // if(this.toolbar){
      // console.log(this.toolbar)
      // console.log(this.toolbar ? this.toolbar.className : 'poroc')
    if (this.toolbar && this.toolbar.className.indexOf('medium-editor-toolbar-active')>-1) {
      let modalContainer = document.querySelector('#modalContainer');
      if(modalContainer){
        const diff = modalContainer.scrollTop - this.initialScrollPosition;
        // console.log(diff)
        // console.log("e.target.scrollTop")
        // console.log(e.target)
        // console.log(e.target.scrollTop)
        // console.log( "this.initialScrollPosition" )
        // console.log( this.initialScrollPosition )
        this.toolbar.style.top = (this.initialToolBarPosition - diff) + 'px';

      }
    }else if(!this.toolbar || (this.toolbar && !this.toolbar.className.indexOf('medium-editor-toolbar-active')>-1)){
      // console.log('no')
      this.initialScrollPosition = e.target.scrollTop;
      // console.log( this.initialScrollPosition )
    }
    // else{
      // console.log('THIS CASE')
    // }
  }
});