import React from 'react';
import { Form,Col,FormGroup, ControlLabel, FormControl, Button, Alert} from 'react-bootstrap';
import T from 'i18n-react';
import {browserHistory} from 'react-router';
import BottomBar from '../bottomBar';
import { connect } from 'react-redux';

import {setTabs} from '../../actions/feed';
import Modal from '../modal/modal.jsx';
import LoginForm from '../modal/login.jsx';

// var config = require('../../../settings/config.json');
var request = require('request');



const s =  {
    depthPhoto:{
      boxShadow:'rgb(0 0 0 / 45%) 1px 32px 50px -19px, rgb(238 206 183) 1px -10px 52px -6px',
      borderRadius:15,
      overflow:'hidden',
      height:'fit-content'
    },
    highlight:{
      backgroundColor: '#6c7282',
      color: 'white',
      borderRadius: 10,
      padding: '1px 10px',
      whiteSpace:'nowrap'
    },
    title:{
        color: 'black',
        margin:'auto',
        fontSize: 'calc(40px + 1.5vw)',
        marginBottom: 30,
        fontWeight: 'bold',
        fontFamily: "'PT Sans Narrow', sans-serif",
        maxWidth: '80%',
        lineHeight: 1
      },
      subtitle:{
        color: 'black',
        fontSize: 'calc(45px + 0.5vw)',
        marginBottom: '5px',
        fontWeight: 'bold',
        fontFamily: "'PT Sans Narrow', sans-serif" 
      },
      card:{
        fontSize: '40pt',
        color: '#001c40',
        backgroundColor: '#001c40',
        padding: 10,
        backgroundColor: '#ffd43b',
        borderRadius: 11,
        width: 100,
        height: 100,
        textAlign: 'center',
        lineHeight: 'unset',
        margin: 15
      },
      article:{
        padding: '0px 30px 40px',
        color:'#444444',
        fontSize: '12pt',
        maxWidth:600,
        margin:'auto',
        fontFamily: "'Open Sans', sans-serif" // "'Open Sans', sans-serif"
      },
      page:{
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 1000,
        background: 'linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%)',
        backgroundSize: 'cover',
        overflow:'hidden scroll'
      },
      loginButton:{
        color:'white',
        padding:'15px 30px',
        borderRadius:25,
        fontWeight:'bold',
        backgroundColor:'rgb(216, 61, 56)'
      },
      graphic:{
        width: '50%',
        marginBottom: 15
      }
}


class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}


  }



 
    isMobile(){
      return window.innerWidth<800
    }
  // updateScroll(e){
  //     let top = e.currentTarget.scrollTop
  //     console.log(top)
  //     this.setState({scroll:top})
  // }
  // onScroll={this.updateScroll}

    render () {

      const user = this.props.user
      let mobile = this.isMobile()
      if(user && user.feeds){
          const userFeeds = user.feeds//.map(f=>({name:f,title:f.title,id:f}));
    
            // const userTabs = user.tabs.map(t=>({name:t.title, title:t.title, id:t.title, dbid:t.id, query:{q:t.query},  type:"virtual", prefix:"feed"}))
          if(userFeeds[0] && !userFeeds[0].type){
            userFeeds[0].type = 'home'
          }
          this.props.setTabs(userFeeds, user);
      }

      var self = this  
      const first_name = user ? user.first_name : null;  

      var pageStyle = {width:800,marginLeft:'auto',marginRight:'auto', marginBottom:mobile ? 0 : 200}
      
    
      let customers = [
        {width:200, margin:20,url:"/static/img/customers/jakartadaily.png"},
        {width:220, margin:20,url:"/static/img/customers/catenamedia.png"},
        {width:139, margin:20,url:"/static/img/customers/xpress.png"},
        {width:139, margin:20,url:"/static/img/customers/grueling.png"},
        {width:211, margin:20,url:"/static/img/customers/sportswriters.png"},
        {width:225, margin:20,url:"/static/img/customers/fw.png"}
      ]

      return (
            <div style={s.page} > 
              <LoginForm redirect={this.props.redirect} style={{fontSize: 'initial', fontWeight: 'initial', color: 'initial',zIndex: 100}}/>
                <div style={{
                  '-webkit-mask-image':"-webkit-linear-gradient(bottom left, transparent 0%, black, black, black, transparent 100%)", 
                  zIndex: -100,
                  position: 'relative',
                  bottom: 0,
                  overflow: 'hidden',
                  margin: 'auto',
                  width: '100%',
                  maxWidth: 600
                }}>
                  <div style={{ whiteSpace: 'nowrap', animation: 'scroller 40s linear infinite', filter:'brightness(0)'}}>
                      {customers.concat(customers).map(customer=><img style={{...customer, url:undefined}} src={customer.url} />)}
                      
                  </div>
                  
                </div>

        </div>
        );
    }

}
         
const mapStateToProps = (state)=>{
  // console.log(state)
  return {
    user:state.auth.user,
    selectedPuff: state.puff.selectedPuff.puff
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setTabs:(feeds, user)=>dispatch(setTabs(feeds, user)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Login)
