import React from 'react';

// Example CSS (include this in your stylesheet):
// @keyframes duplicateHighlight {
//   0% { background-color: #ffcccc; }
//   100% { background-color: transparent; }
// }
// .duplicate-highlight {
//   animation: duplicateHighlight 1s ease-out;
// }

class TagInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: props.value ? props.value : [],
      inputValue: '',
      editingIndex: null,
      editingValue: '',
      editInputWidth: 50, // default minimum width
      duplicateHighlight: null, // index of tag to highlight (if duplicate detected)
      highlightedIndex: 0,      // for dropdown arrow navigation
      isDropdownOpen: false,    // whether dropdown is open
    };

    this.hiddenSpan = null;
    this.containerRef = null; // container ref for click-away

    // Bind methods
    this.setHiddenSpanRef = this.setHiddenSpanRef.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.addTag = this.addTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.removeLastTag = this.removeLastTag.bind(this);
    this.editTag = this.editTag.bind(this);
    this.handleEditChange = this.handleEditChange.bind(this);
    this.handleEditKeyDown = this.handleEditKeyDown.bind(this);
    this.handleEditBlur = this.handleEditBlur.bind(this);
    this.updateEditedTag = this.updateEditedTag.bind(this);
    this.highlightDuplicate = this.highlightDuplicate.bind(this);
    this.getRecommendationValue = this.getRecommendationValue.bind(this);
    this.handleRecommendationClick = this.handleRecommendationClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setContainerRef = this.setContainerRef.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setContainerRef(node) {
    this.containerRef = node;
  }

  handleClickOutside(event) {
    if (this.containerRef && !this.containerRef.contains(event.target)) {
      this.setState({ isDropdownOpen: false });
    }
  }

  setHiddenSpanRef(span) {
    this.hiddenSpan = span;
  }

  highlightDuplicate(index) {
    this.setState({ duplicateHighlight: index }, () => {
      setTimeout(() => {
        this.setState({ duplicateHighlight: null });
      }, 1000);
    });
  }

  // Helper to extract the string value from a recommendation item.
  getRecommendationValue(rec) {
    if (typeof rec === 'string') {
      return rec;
    }
    return rec.title || rec.name || rec.value || '';
  }

  // Handlers for adding new tags
  handleInputChange(e) {
    const value = e.target.value;
    this.setState({ 
      inputValue: value, 
      isDropdownOpen: value.trim() !== "", 
      highlightedIndex: 0 
    });
  }

  addTag() {
    const newTag = this.state.inputValue.trim();
    if (newTag) {
      if (this.state.tags.includes(newTag)) {
        // Duplicate found: highlight the matching tag.
        const duplicateIndex = this.state.tags.indexOf(newTag);
        this.highlightDuplicate(duplicateIndex);
      } else {
        this.setState(prevState => ({
          tags: [...prevState.tags, newTag],
          inputValue: '',
          isDropdownOpen: false,
          highlightedIndex: 0
        }));
      }
    }
  }

  handleKeyDown(e) {
    const { inputValue, isDropdownOpen, highlightedIndex } = this.state;
    const trimmedInput = inputValue.trim();
    const recommendations = this.props.recommendations || [];
    const filteredRecommendations = trimmedInput
      ? recommendations.filter(rec => {
          const value = this.getRecommendationValue(rec);
          return value.toLowerCase().includes(trimmedInput.toLowerCase());
        })
      : [];

    // If dropdown is open and there are recommendations, handle arrow keys and Enter.
    if (isDropdownOpen && filteredRecommendations.length > 0) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        this.setState({
          highlightedIndex: (highlightedIndex + 1) % filteredRecommendations.length
        });
        return;
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        this.setState({
          highlightedIndex: (highlightedIndex - 1 + filteredRecommendations.length) % filteredRecommendations.length
        });
        return;
      } else if (e.key === 'Enter') {
        e.preventDefault();
        const selectedRec = filteredRecommendations[highlightedIndex];
        if (selectedRec) {
          this.handleRecommendationClick(selectedRec);
        }
        return;
      }
    }

    // Create a tag on comma, Enter, or tab when dropdown is not active.
    if (e.key === ',' || e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      this.addTag();
    } else if (e.key === 'Backspace' && this.state.inputValue === '') {
      e.preventDefault();
      this.removeLastTag();
    }
  }

  handlePaste(e) {
    e.preventDefault();
    const paste = e.clipboardData.getData('text');
    const newTags = paste
      .split(/[\n,]+/) // Split on newline or comma
      .map(tag => tag.trim())
      .filter(tag => tag.length > 0 && !this.state.tags.includes(tag));
    if (newTags.length > 0) {
      this.setState(prevState => ({
        tags: [...prevState.tags, ...newTags],
        inputValue: '',
        isDropdownOpen: false,
        highlightedIndex: 0
      }));
    }
  }

  removeTag(tagToRemove, e) {
    // Prevent click from also triggering edit mode.
    if (e) {
      e.stopPropagation();
    }
    this.setState(prevState => ({
      tags: prevState.tags.filter(tag => tag !== tagToRemove)
    }));
  }

  removeLastTag() {
    this.setState(prevState => ({
      tags: prevState.tags.slice(0, -1)
    }));
  }

  // Enter editing mode on single click.
  editTag(index) {
    this.setState({
      editingIndex: index,
      editingValue: this.state.tags[index]
    });
  }

  handleEditChange(e) {
    this.setState({ editingValue: e.target.value });
  }

  handleEditKeyDown(e) {
    // Update the edited tag on comma, enter, or tab.
    if (e.key === ',' || e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      this.updateEditedTag();
    }
  }

  handleEditBlur() {
    this.updateEditedTag();
  }

  updateEditedTag() {
    const { editingIndex, editingValue, tags } = this.state;
    if (editingIndex === null) return;
    // Split the editing value on newline, comma, or tab.
    const parts = editingValue
      .split(/[\n,\t]+/)
      .map(part => part.trim())
      .filter(part => part.length > 0);

    if (parts.length === 0) {
      // If empty, remove the tag.
      const newTags = [...tags];
      newTags.splice(editingIndex, 1);
      this.setState({ tags: newTags, editingIndex: null, editingValue: '', editInputWidth: 50 });
    } else {
      // Check for duplicates in any of the new parts (ignoring the one currently being edited).
      for (let i = 0; i < parts.length; i++) {
        const part = parts[i];
        const duplicateIndex = tags.findIndex((tag, idx) => tag === part && idx !== editingIndex);
        if (duplicateIndex !== -1) {
          // Duplicate detected: highlight the matching tag and cancel editing.
          this.highlightDuplicate(duplicateIndex);
          this.setState({ editingIndex: null, editingValue: '', editInputWidth: 50 });
          return;
        }
      }
      // No duplicates: update the tag at editingIndex with the first part,
      // and insert any additional parts as new tags.
      const newTags = [...tags];
      newTags.splice(editingIndex, 1, parts[0]);
      if (parts.length > 1) {
        newTags.splice(editingIndex + 1, 0, ...parts.slice(1));
      }
      this.setState({ tags: newTags, editingIndex: null, editingValue: '', editInputWidth: 50 });
    }
  }

  // When a recommendation is clicked, add it as a tag.
  handleRecommendationClick(rec) {
    const value = this.getRecommendationValue(rec);
    if (!value) return;
    if (this.state.tags.includes(value)) {
      const duplicateIndex = this.state.tags.indexOf(value);
      this.highlightDuplicate(duplicateIndex);
    } else {
      this.setState(prevState => ({
        tags: [...prevState.tags, value],
        inputValue: '',
        isDropdownOpen: false,
        highlightedIndex: 0
      }));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Update the input width during editing if needed.
    if (this.state.editingIndex !== null && this.hiddenSpan) {
      const newWidth = this.hiddenSpan.offsetWidth + 10; // extra space for caret
      if (newWidth !== this.state.editInputWidth) {
        this.setState({ editInputWidth: newWidth });
      }
    }

    // Call onChange if the tags have changed.
    if (
      prevState.tags.length !== this.state.tags.length ||
      !prevState.tags.every((tag, idx) => tag === this.state.tags[idx])
    ) {
      if (typeof this.props.onChange === 'function') {
        this.props.onChange(this.state.tags);
      }
    }
  }

  render() {
    const { inputValue, editingIndex, isDropdownOpen } = this.state;
    // Compute filtered recommendations if the prop exists, we're not editing,
    // and the input value is not empty.
    let filteredRecommendations = [];
    if (!editingIndex && this.props.recommendations && inputValue.trim() !== "") {
      const lowerInput = inputValue.trim().toLowerCase();
      filteredRecommendations = this.props.recommendations.filter(rec => {
        const value = this.getRecommendationValue(rec);
        return value.toLowerCase().includes(lowerInput);
      });
    }
    return (
      <div ref={this.setContainerRef} style={{ width: '100%' }}>
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: '8px',
          padding: '8px',
          borderRadius: '4px',
          position: 'relative'
        }}>
          {this.state.tags.map((tag, index) => {
            // If this tag is being edited, show the editing input.
            if (this.state.editingIndex === index) {
              return (
                <div key={index} style={{ position: 'relative' }}>
                  <input
                    value={this.state.editingValue}
                    onChange={this.handleEditChange}
                    onKeyDown={this.handleEditKeyDown}
                    onBlur={this.handleEditBlur}
                    autoFocus
                    style={{
                      padding: '4px 8px',
                      borderRadius: '4px',
                      border: '1px solid #03A9F4',
                      width: this.state.editInputWidth,
                      boxSizing: 'content-box',
                      outline: 'none'
                    }}
                  />
                  {/* Hidden span to calculate the width of the text */}
                  <span
                    ref={this.setHiddenSpanRef}
                    style={{
                      position: 'absolute',
                      visibility: 'hidden',
                      whiteSpace: 'pre',
                      fontSize: 'inherit',
                      fontFamily: 'inherit',
                      padding: '4px 8px',
                      border: '1px solid'
                    }}
                  >
                    {this.state.editingValue || ' '}
                  </span>
                </div>
              );
            }
            // Try to find a matching recommendation object for this tag.
            let matchingRec = null;
            if (this.props.recommendations) {
              matchingRec = this.props.recommendations.find(rec => this.getRecommendationValue(rec) === tag);
            }
            let visualPrefix = null;
            if (matchingRec && (matchingRec.icon || matchingRec.emoji)) {
              visualPrefix = matchingRec.icon || matchingRec.emoji;
            }
            return (
              <span
                key={tag}
                className={`tagInputTag ${this.state.duplicateHighlight === index ? 'duplicate-highlight' : ''}`}
                onClick={() => this.editTag(index)}
              >
                {visualPrefix && <span style={{ marginRight: '4px' }}>{visualPrefix}</span>}
                {tag}
                <button
                  className="delete-tag"
                  onClick={(e) => this.removeTag(tag, e)}
                  style={{
                    marginLeft: '8px',
                    border: 'none',
                    cursor: 'pointer'
                  }}
                >
                  &times;
                </button>
              </span>
            );
          })}
          <div style={{ position: 'relative', flexGrow: 1 }}>
            <input
              type="text"
              value={this.state.inputValue}
              onChange={this.handleInputChange}
              onKeyDown={this.handleKeyDown}
              onPaste={this.handlePaste}
              placeholder={
                this.props.placeholder
                  ? this.props.placeholder
                  : "Add tags (comma, enter, tab, paste, or backspace to remove)"
              }
              style={{
                width: '100%',
                outline: 'none',
                padding: '4px',
                background: 'none',
                border: 'none'
              }}
              onFocus={() => {
                if (this.state.inputValue.trim() !== "") {
                  this.setState({ isDropdownOpen: true });
                }
              }}
            />
            {/* Recommendations Dropdown */}
            {this.props.recommendations && isDropdownOpen && inputValue.trim() !== "" && filteredRecommendations.length > 0 && (
              <div 
              className="dropdown"
              style={{
                position: 'absolute',
                top: '100%',
                left: 0,
                width: 'fit-content',
                zIndex: 1000,
                maxHeight: '150px',
                overflowY: 'auto'
              }}>
                {filteredRecommendations.map((rec, index) => {
                  const value = this.getRecommendationValue(rec);
                  let recommendationIcon = null;
                  if (typeof rec !== 'string' && (rec.icon || rec.emoji)) {
                    recommendationIcon = rec.icon || rec.emoji;
                  }
                  return (
                    <div
                      key={index}
                      className="light"
                      onMouseDown={(e) => e.preventDefault()} // Prevents the input from losing focus.
                      onClick={() => this.handleRecommendationClick(rec)}
                      style={{
                        padding: '8px',
                        cursor: 'pointer',
                        borderRadius: 10,
                        backgroundColor: this.state.highlightedIndex === index ? '#eee' : 'revert-layer'
                      }}
                    >
                      {recommendationIcon && <span style={{ marginRight: '4px' }}>{recommendationIcon}</span>}
                      {value}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TagInput;
