import React from 'react';
import T from 'i18n-react';
import {browserHistory} from 'react-router';
import Integrate from '../modal/integrate.jsx';

class MessageBubble extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        downloadPluginText: "Download the Plugin",
        downloadPluginFade: false,
      };
      // this.state = {pathname:window.location.pathname}
  }  

  downloadPluginClick = (url) => {
    // Fade out the current text
    this.setState({ downloadPluginFade: true });
    setTimeout(() => {
      // Change text to "Redirecting you..." and downloadPluginFade in
      this.setState({ downloadPluginText: "Redirecting you...", downloadPluginFade: false });

      // After 2 seconds, open a new tab and then reset text
      setTimeout(() => {
        window.open(url+'/wp-admin/plugin-install.php', "_blank");

        // downloadPluginFade out before resetting text
        this.setState({ downloadPluginFade: true });
        setTimeout(() => {
          this.setState({ downloadPluginText: "Download the Plugin", downloadPluginFade: false });
        }, 500); // duration for downloadPluginFade reset
      }, 2000);
    }, 500); // duration for initial downloadPluginFade
  };

  render () {
  	let {message, text, mobile, feed, user} = this.props
    let icon = this.props.icon ? this.props.icon : null
    let type = this.props.type ? this.props.type : null
    const { downloadPluginText, downloadPluginFade } = this.state;

    let emojis = {
      started:'/static/img/assets/emoji/like.gif',
      paused:'/static/img/assets/emoji/sleepy.gif',
      waiting:'/static/img/assets/emoji/thinking.gif',
      cry:'/static/img/assets/emoji/cry.gif',
      sad:'/static/img/assets/emoji/no.gif'
    }

  	if(message === 'private'){
      icon = 'lock'
      type = 'alert'
  		text = <b>This feed is private and does not belong to this account, please <p className={"whiteButton"} onClick={()=>browserHistory.push({pathname: window.location.pathname,query:{signin:true}})}>Sign In</p> with the appropriate account to view its content.</b>
  	}else if(message === 'paused'){
  		message = <p style={style.container}><span className={"fa fa-pause"} style={{fontSize:'20px',marginRight:10}} aria-hidden="true" /> 
		        This feed is currently paused, enable it to view its content.
		        </p>
  	}else if(['404','410'].includes(message)){
      message = <div style={{maxWidth:1170, margin:'auto', marginTop:30, width:'100%', marginBottom:100}}>
      <div key="herobox" style={{display:'grid'}}>
  
         <div className="thefont hero" style={{display:mobile ? 'inline-block' : 'grid', gridTemplateColumns:'60% 40%', padding:50, background:'linear-gradient(183deg, #FF9800 50%, #f36f21 67%, rgb(181 63 63) 95%)'}}>
              <p style={{float:'left',margin:'auto auto 15px auto'}}><T.span text={message}/></p>
              {mobile ? null : <img className="shadowed" src={emojis.cry} style={{ width:300,margin:'-50px 0px -80px auto'}}/>}
              
              <div style={{textAlign: 'center'}}>
                <p style={{fontSize: 'large'}}>The requested URL {window.location.pathname} was not found</p> 
                </div>
            </div>
           
            </div>
      </div>

    }else if(['notfound','noorders', 'noarticles'].includes(message)){

      let wpConnection = feed && feed.onSubmit ? feed.onSubmit.find(target=>target.type==='wp') : null
      let disconnectedWp = wpConnection && user && user.websites && user.websites.find(integration=>integration.url === wpConnection.url && integration.status!='200')


      message = <div style={{maxWidth:1170, margin:'auto', marginTop:30, width:'100%', marginBottom:100}}>
          <div key="herobox" style={{display:'grid'}}>
  
            <div className="thefont hero" style={{fontSize:mobile ? 'xxx-large' : undefined, display:mobile ? 'inline-block' : 'grid', gridTemplateColumns:'60% 40%', padding:50, color:'#333', background:'#e8f4ff'}}>
              <p style={{float:'left',margin:'auto', paddingBottom:15}}>{message==='noarticles' ? (feed && feed.enabled ? 'Your articles are on the way!' : 'This feed is paused') : (this.props.query ? 'Could not find' : 'There are currently no orders')}</p>
              {mobile ? null : <img className="emojiShadow" 
                                    src={message==='noarticles' && feed && feed.enabled ? emojis.started : (this.props.query ? emojis.sad : emojis.paused)} 
                                    style={{ width:300,margin:'-30px 0px -80px auto'}}/>}
              
              <div style={{textAlign: 'center'}}>
                <p style={{fontSize: 'large'}}>{this.props.query ? <T.span text={{key:"feed.noResultsFound", query:this.props.query}}/> : (feed && feed.enabled ? 'You will receive an email when the first one is finished' : (message==='noorders' ? 'Come back later' : 'Start the feed to receive your first articles'))}</p>
              </div>
            </div>
            {message==='noarticles' && disconnectedWp ?
              <div className="thefont hero" style={{marginTop: 15, fontSize:'clamp(30px, 5vw, 80px)', display:mobile ? 'inline-block' : 'grid', gridTemplateColumns:'60% 40%', padding:50, color:'#333', background:'rgb(246 232 255)'}}>
              
              <div style={{textAlign: 'center'}}>
                <p style={{float:'left',margin:'auto', width:'100%', paddingBottom:15}}>Get the articles on your website</p>
                <p style={{fontSize: 'clamp(16px, 2vw, 20px)', marginBottom:25}}>Download your WordPress plugin and install it on your site</p>
              </div>
              <div style={{margin: 'auto'}}>
              <a className="download-button" style={{background: 'black',
                    color: 'white',
                    borderRadius: '30px',
                    fontSize: 'clamp(10px, 4vw, 25px)',
                    padding: '15px 30px',
                    height: 'fit-content',
                    width: 'fit-content',
                    cursor: 'pointer'}} href="/static/plugins/wp/monoksync.zip" 
                    download
                    onClick={()=>this.downloadPluginClick(disconnectedWp.url)}
                    >
                <span style={{marginRight:10}} className="fa fa-wordpress"/>
                <span className={`text-transition ${downloadPluginFade ? 'fade' : ''}`}>{downloadPluginText}</span>
              </a>
              <p style={{fontSize:'small', opacity:0.7}}>you will be redirected to your wordpress</p>
              </div>
              
            </div>
             : null}
            <div>
            
            </div>
            </div>
      </div>
    }else if(message === 'notfound'){
       text = <T.span text={{key:"feed.noResultsFound", query:this.props.query}}/>
    }

    if(text){
      message = <p className={"message-container" + (type ? (' '+type) : '')} style={{...((this.props.sticky||this.props.shadow) ? style.shadow: {}), ...(this.props.button ? {width:'96%',margin: '30px 0px 15px 0px', textAlign:'left'} : {})}}>
                {icon ? <span className={"fa fa-"+icon} style={{fontSize:'20px',marginRight:10}} aria-hidden="true" /> : null}
                {text}
                {this.props.button ? <span onClick={()=>browserHistory.push({pathname: '/login',query:{}}) } className="button" style={{margin:mobile ? 5 : '-9px -13px', float:'right'}}>{this.props.button}</span> : null}
               </p>
    }

    return  <div style={{textAlign: 'center',...(this.props.sticky ? style.sticky : {})}}>
              {message}
            </div>

  }
}


const style = {
  container:{
    fontWeight: 'bold',
    padding: '15px 20px',
    color:'#5d5d5d',
    backgroundColor: '#e9e9e9',
    borderRadius: 30,
    margin: 40,
    display: 'inline-block'
  },
  sticky:{    
    position: 'sticky',
    top: -10,
    zIndex: 100
  },
  shadow:{boxShadow:'#0e487559 0px 10px 21px -6px'}
}

export default MessageBubble;