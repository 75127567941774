// CheckoutForm.js
import React from 'react';
import {injectStripe, CardElement} from 'react-stripe-elements';
import {addCard} from '../../apiFunctions';
import Spinner from "../modal/spinner.jsx";
import { Button} from 'react-bootstrap';
import IbanForm from './IbanForm'

const style ={
  disclosure:{
    color: '#a2abae',
    fontSize: 13,
    margin: '15px 0px 0px'
  }
}


class CheckoutForm extends React.Component {

  constructor(props){
    super(props);
    this.addMethodResult = this.addMethodResult.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {change:{empty:true}}
  }
  
  addMethodResult(result){
    this.props.refresh()
    let change = this.state.change
    change.error = result.error ? result.error : change.error
    if(!result.error){
      this._element.clear();
    }
    this.setState({loading:false, change:change})
  }

  handleSubmit(ev){
    if(ev){
      ev.preventDefault();
    }
    this.setState({loading:true})
      const cardElement = this.props.elements.getElement('card');
      addCard((intent)=>{
        if(intent){
          this.props.stripe.confirmCardSetup(intent.cs, {
            payment_method: {
              card: cardElement,
            },
          }).then(this.addMethodResult).catch((error) => {
            // General error handling
            this.setState({ loading: false, change: {...this.state.change, error:{message:'Unknown Error, contact Monok support or try again.'}} });
          });
        }else{
          // Handle case where `intent` is invalid
          this.setState({ 
            loading: false, 
            change: {...this.state.change, error: { message: 'Stripe is unable to process your card at this time.' } } 
          });
        }        
      })
  };

  render() {
    let {small, method, user} = this.props
    
    let secureWith = <p style={style.disclosure}>{(!this.state.change.empty && !this.state.change.error) ? "Press Enter to Submit - " : ""}secure with <i style={{fontSize:'14pt', verticalAlign: 'middle'}} className="fa fa-cc-stripe" aria-hidden="true"></i></p>
    let headerStyle = {width:'100%',textAlign:'left', fontSize:'large', color:small ? '#333' : '#797979'}
    let errorMessage = this.state.change.error && this.state.change.error.message ? <div style={{
          display: 'inline-block',
          color: '#ff3e3e',
          marginTop:10
        }}>{this.state.change.error.message}</div>: null

    if(method===1){
      return  <div>
        <label style={headerStyle}>
                  New Bank Account Details
        </label>
        <IbanForm loading={this.state.loading} 
                  onSubmit={()=>this.setState({loading:true})} 
                  onReady={(c) => this._element = c} 
                  user={user} 
                  addMethodResult={this.addMethodResult}/>
        {errorMessage}
        {secureWith}
      </div>
    }

    return (
      <form onSubmit={this.handleSubmit}  id="form1" ref="theform">
     
        <label style={headerStyle}>
                  New Card Details
        </label>
        <CardElement 
          onReady={(c) => this._element = c} 
          style={{base: {fontSize: '18px'}}} 
          onChange={(change)=>{
            if(change!==this.state.change){
             this.setState({change})
             if(this.state.change.complete){
               this.handleSubmit(); // Call the submit handler directly
              // this.refs.theform.dispatchEvent(new Event("submit"), { cancelable: true });
             }
            }
        }}/>
        {errorMessage}
        {!this.state.loading && this.state.change.complete ? 
          <Button className="button blackButton" type="submit" form="form1" value="Submit" style={{float:(small ? 'right' : 'left'), boxShadow: '0px 14px 15px -10px #0000004d', marginLeft:30, marginTop:10}}>
          Add Card
          </Button> : null}
        {this.state.loading ? <div style={{textAlign:'center', marginTop:10}} ><Spinner/></div> : null}
        {secureWith}
      </form>
    );
  }
}

export default injectStripe(CheckoutForm);