import React from 'react';

class Spinner extends React.Component {
	constructor(props) {
	    super(props);
	}  
	render () {
		return <span style={{...{color:'#afafaf', fontSize:'30px', width:30, height:30, display:'inline-box'},...this.props.style}} className="fa fa-spinner fa-pulse"/>
	}
}

export default Spinner;