var React = require('react');
var VfImage = require('./vfImage.jsx')
var Widget = require('../puff/widget.jsx')
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import Summary from '../modal/summary.jsx'

const importedContent ={
  data: [
    {
      image: {
        copyright: "Copyrighted",
        width: 640,
        description: "Import a photo or video",
        weight: 100,
        type: "person",
        url: "",
        height: 360
      }
    }
  ],
  supervised:true,
  name: "stock",
  type: "person"
}

class MediaPicker extends React.Component {

  constructor(props){
      super(props);
      this.state = {
        failed:[],
        succeded:[]
      }
  }


 
  componentWillUnmount() {
    this.refs.container ? this.refs.container.removeEventListener('wheel', this.handleWheel, { passive: false }) : null;
  }

  handleWheel(e){
      // console.log(e.deltaY)
      // console.log( e.clientX+ ' # '+e.clientY)
      // if(e.deltaY>0){
      //   if(e.currentTarget.scrollLeft === (e.currentTarget.scrollWidth - e.currentTarget.clientWidth)){   
      //     // e.preventDefault()
      //     e.stopPropagation()
      //   }else{  
      //     e.preventDefault()
      //     e.stopPropagation()
      //     e.currentTarget.scrollLeft += e.deltaY;
      //     return false;
      //   }
      // }else if(e.deltaY<0){
      //   if(e.currentTarget.scrollLeft===0){
      //     // e.preventDefault()
      //     e.stopPropagation()
        
      //   }else{
      //     e.preventDefault()
      //     e.stopPropagation()
      //     e.currentTarget.scrollLeft += e.deltaY;
      //     return false;
      //   }
      // }
      if((Math.abs(e.deltaY) > 0.1) && (e.currentTarget.scrollWidth > e.currentTarget.clientWidth)){
        e.currentTarget.scrollLeft += e.deltaY;
        e.preventDefault()
        e.stopPropagation()
        return false
      }
  }

  componentDidMount(prevProps){
    this.refs.container ? this.refs.container.addEventListener('wheel', this.handleWheel, { passive: false }) : null
    this.evalYTImages()
  }

  componentDidUpdate(prevProps){
  
    // console.log(prevProps.article.images)
    // console.log(this.props.article.images)
    if((this.props.article.images && !prevProps.article.images) || 
      (this.props.article.videos.length !== prevProps.article.videos)){
      this.evalYTImages()
    }
  }

  evalYTImages(){
      let images = this.props.article && this.props.article.images && this.props.article.images.length>0 ? this.props.article.images : []
      images.map((image, i)=>{
        if(image){
          let thumb = image.url  
          if(thumb && thumb.includes('/maxres') && !this.state.succeded.includes(thumb) && !this.state.failed.includes(thumb)){   
              this.imgExists(thumb,(exists)=>{})
          }
        }
      })
  }

  isMobile(){
     return window.innerWidth<800
  }

  wrapTT(tt, child,keyId){
    const ttDom = (<Tooltip id="tooltip"><strong>{tt}</strong></Tooltip>);
    if(this.isMobile()){
      return child
    }else{
      return (<OverlayTrigger key={keyId ? keyId : null} placement="bottom" overlay={ttDom}>
              {child}
              </OverlayTrigger>)
    }
  }

  usedWidgets(article, vf){
    let used = article.article ? article.article.split('\n').filter(s=>s.indexOf('{')===0).map(s=>s.hashCode()) : []
    if(article.visualFocus && !vf){
      used = used.concat(JSON.stringify(article.visualFocus).hashCode())
    }
    return used
  }

    imageExists(image_url){
        var http = new XMLHttpRequest();
        // http.setRequestHeader('Access-Control-Allow-Origin', '*')
        http.open('HEAD', image_url, true);
    
        http.send();

        return http.status != 404;

    }


    imgExists(url, callback){
      // console.log('CHECKING FOR '+ url)
      var img = new Image();
      img.onload = ()=>{
        if(img.naturalHeight <= 90 && url.includes('/maxres')){
          img.onload = null
          this.setState({failed:this.state.failed.concat(url)})
          callback(false)
        }else if(url.includes('/maxres')){
          this.setState({succeded:this.state.succeded.concat(url)})
          callback(true)
        }
      }; 
      img.src = url;
  }

  stripQuote(quote){
    if(quote.url){
      delete quote.url
    }
    if(quote.pubName){
      delete quote.pubName
    }
    quote.canonicalSpeaker =  quote.canonicalSpeaker ? quote.canonicalSpeaker : quote.speaker
     if(quote.speaker){
      delete quote.speaker
    }
    return quote 
  }

  render(){
    let {editmode, widgetFilter,filter, omit, locked, className, article, embed, vfPicker, user, selected, previewAmount, film} = this.props
    let {images, videos, widgets, quotes} = article
    let givenStyle = this.props.style
    // console.log("locked")
    // console.log(locked)

    let hide = this.usedWidgets(article, vfPicker)
    
    try{
      hide = selected ? hide.filter(wi=>wi!==selected.decode().hashCode()) : hide
    }catch(e){

    }

    let mediaList = film ? film : (quotes ? quotes.map(q=>({quotes:[{...this.stripQuote(q)}], type:'quote'})).filter(w=>(filter ? filter : (widgetFilter ? widgetFilter : [])).includes(w.type)) : [])
                                  .concat(videos && !vfPicker ? videos : [])//.filter(video=>vfPicker ? video.source!=='Giphy' : true)
                                  .concat((widgets ? widgets.filter(w=>(filter ? filter : (widgetFilter ? widgetFilter : ['video'])).includes(w.type)) : []))
                                  .concat(images ? images.filter(im=>im && im.description) : [])


    // console.log(vfPicker)
    // console.log(filter)
    // console.log(widgetFilter)

    if(!film){
      mediaList = mediaList.sort((a,b)=>{
                                  if(!vfPicker){
                                    return (a.source==='Giphy' && b.source!=='Giphy') ? 1 : (a.source!=='Giphy' && b.source==='Giphy' ? -1 : 0) 
                                  }
                                  return 0
                                }) 
    }


    mediaList = filter ? mediaList.filter(w=>filter.includes(w.type)) : mediaList

    let fullLength = mediaList.length

    mediaList = omit ? mediaList.filter(w=>!omit.includes(w.type)) : mediaList
    
    mediaList = !hide ? mediaList : mediaList.filter(widget=>!hide.includes(JSON.stringify(widget).hashCode()))


    mediaList = locked ? mediaList.slice(0,previewAmount ? previewAmount : mediaList.slice(0,3).reduce((a,b)=>a+(b.type==='video' ? 1 : 2),0)) : mediaList

    // console.log('mediaList')
    // console.log(mediaList)

    let types = {
      twitter:{
        icon:"fa fa-twitter",
        gradient:'#2196F3, #00BCD4'
      },
      reddit:{
        icon:"fa fa-reddit",
        gradient:'#FF5700, #FF4500'
      },
      spotify:{
        icon:"fa fa-spotify",
        gradient:'#1ED760, #10c952'
      },
      facebook:{
        icon:"fa fa-facebook",
        gradient:'#2196F3, #00BCD4'
      },
      instagram:{
        icon:"fa fa-instagram",
        gradient:'#feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5'
      },
      video:{
        icon:"fa fa-play",
        gradient:'#2196F3, #00BCD4',
        width:160
      }
    }

    return editmode && mediaList && (vfPicker || mediaList.length > 0 || editmode) ? 
    <div   
          ref="container" 
          onClick={this.props.onClick ? this.props.onClick : null}
         
           // onWheel={(e)=>{
           //  // console.log('YEA')
           //  console.log(e.deltaY)
           //  // console.log(e.deltaX)
           //  // console.log(e.currentTarget)
           //    // var container = document.getElementById('container')
           //    // var containerScrollPosition = document.getElementById('container').scrollLeft
              
           //    // if(Math.abs(e.deltaY)>5){
           //      // e.currentTarget.scrollBy({
           //      //   left: e.deltaY < 0 ? -30 : 30,
           //      // });
           //    // }

           //    if(e.deltaY>0){
           //      if(e.currentTarget.scrollLeft === e.currentTarget.scrollWidth - e.currentTarget.clientWidth){
           //        // e.currentTarget.style['overscroll-behavior'] = 'unset'
           //          e.preventDefault()
           //      e.stopPropagation()
           //         return false;
           //      }else{
           //        // e.currentTarget.style['overscroll-behavior'] = 'contain'
           //        e.currentTarget.scrollLeft += e.deltaY;
           //      }
           //    }else if(e.deltaY<0){
           //      if(e.currentTarget.scrollLeft===0){
           //        // e.currentTarget.style['overscroll-behavior'] = 'unset'
           //          e.preventDefault()
           //      e.stopPropagation()
           //         return false;
           //      }else{
           //        // e.currentTarget.style['overscroll-behavior'] = 'contain'
           //        e.currentTarget.scrollLeft += e.deltaY;
           //      }
           //    }

           //    // if((e.currentTarget.scrollLeft===0) || 
           //    //   (e.currentTarget.scrollLeft === e.currentTarget.scrollWidth - e.currentTarget.clientWidth)){
           //    //   console.log('LIMIT REACHED')
           //    //   e.currentTarget.style['overscroll-behavior'] = 'unset'
           //    // }else{
           //    //   e.currentTarget.style['overscroll-behavior'] = 'contain'

           //    // }

           //    // container.scrollTo({
           //    // e.currentTarget.scrollTo({
           //    //     left: e.deltaY,
           //    //     behaviour: 'smooth' //if you want smooth scrolling
           //    // })
           // }}
           // onScroll={(e)=>{
               // e.preventDefault()
               //  e.stopPropagation()
               //   return false;
            // console.log(e.target.scrollWidth - e.target.scrollLeft)
            // console.log( e.target.clientWidth)
           // }}
           style={{...style.container, ...(givenStyle ? givenStyle : style.sidePicker)}} 
           className={(locked ? '' : "toolbox mediapicker") + (className ? ' '+className : ' ')}>

              {locked || !selected ? null : 
                  <div key={'thumbimagecandidate-remove'}
                    onClick={()=>{this.props.update ? this.props.update({visualFocus:null,thumbnail:null}) : null}} 
                    className="photoCard"
                    style={{...style.card, background:'none', boxShadow:'none'}}>
                      <i className="fa fa-ban" style={style.cross} aria-hidden="true"></i>
                </div>}
      {

      mediaList.map((media, i, arr)=>{
        let thumb = (media.thumbnail ? media.thumbnail : (media.url && media.type!=='video' ? media.url : (media.avatar ? media.avatar : null))) 
        if(thumb){
          if(this.state.failed.includes(thumb)){
            thumb = thumb.replace('/maxres', '/hq')
            media.url = thumb
          }
        }

        let vf = ['quote','video','instagram','twitter', 'reddit', 'spotify', 'tiktok', 'facebook'].includes(media.type) || embed ? media : {type:"person", name:"stock",data:[{image:media}]}
        
        let collage = media.data ? (media.data) : (media.encoded ? JSON.parse(media.encoded.decode()) :  null)
        //media.images ? media.images.map(img=>({url:img})) :

        if(collage && media.type==='collage'){
          return <div key={'thumbimagecandidate-'+i} 
                      className={"photoCard"+ (selected===JSON.stringify(media).encode() ?  " photoCardselected" : "") + (locked ? ' disableSelection' : '')}
                      onClick={()=>{this.props.update ? this.props.update({visualFocus:vf, thumbnail:thumb}) : null}} >
                  <VfImage 
                  style={{transform:"scale(0.26)", margin:'-180px -344px 0px -348px'}}
                  className={"lol"}
                  feed={null}
                  bigPuff={true}
                  resolution={250}
                  vf={vf}/>
                  </div>
        }

        let firstImage = collage && collage[0] ? collage[0] : null
       
                      // console.log(media)
                      // console.log(collage)

        let type = media.sourceName==='Facebook' && media.type==='video' ? 'facebook' : media.type
        // || (media.type=='twitter' && media.images)
        // if(type==='twitter'){
        //   return <div key={'thumbimagecandidate-'+i} 
        //               className={"photoCard"+ (selected===JSON.stringify(media) ?  " photoCardselected" : "") + (locked ? ' disableSelection' : '')}
        //               onClick={()=>{this.props.update ? this.props.update({visualFocus:vf, thumbnail:thumb}) : null}} >
        //               <Widget style={{pointerEvents:'none', position: 'inherit', textAlign: 'none',transform:"scale(0.26) translate3d(-574px, -559px, 0px)", width:400,height:400}} article={article ? article : {}} widget={vf}/>
        //   </div>
        // }

        if(type==='quote' &&  vf.quotes && vf.quotes[0]){

          let quote = vf.quotes[0]
          return <div key={'thumbimagecandidate-'+i} 
                      className={"photoCard"+ (selected===JSON.stringify(media).encode() ?  " photoCardselected" : "") + (locked ? ' disableSelection' : '')}
                      onClick={()=>{this.props.update ? this.props.update({visualFocus:vf, thumbnail:thumb}) : null}} >
                    <div style={{display:'flex'}}>
                      <img style={{width:30, margin:5}} src={'/static/img/quote.png'}/>
                      <span style={{fontWeight: 'bold', margin:'auto', overflow:'hidden', fontSize:'8px', color:'black'}}>{quote.canonicalSpeaker ? quote.canonicalSpeaker : (quote.speaker ? quote.speaker : '')} </span>
                    </div>

                    <p style={{color:'black', whiteSpace: 'pre-wrap', fontSize: 'xx-small', textAlign: 'left', padding: 5, margin:0}}>
                      {quote.text ? quote.text : "A Quote"}
                    </p>
                    {vf.images && vf.images.length>0 ? <div style={{bottom:0, position:'absolute', width:'100%', height:40, background:'url('+vf.images[0]+') center / cover'}}></div> :
                      (vf.data && vf.data[0] && vf.data[0].text && vf.data[0].text.includes('pic.twitter') ? 
                        <i className="fa fa-camera" aria-hidden="true" style={{color: 'rgb(29, 161, 242)', fontSize: '17px', float: 'right', margin: 'auto', background: '#ededed', width: '100%', padding: 10, position: 'absolute', left: 0, bottom: 0}}></i>
                      : null)}
          </div>
        }

        if(type==='twitter' && vf && vf.data && vf.data[0] && vf.data[0].text){
          return <div key={'thumbimagecandidate-'+i} 
                      className={"photoCard"+ (selected===JSON.stringify(media).encode() ?  " photoCardselected" : "") + (locked ? ' disableSelection' : '')}
                      onClick={()=>{this.props.update ? this.props.update({visualFocus:vf, thumbnail:thumb}) : null}} >
                    <div style={{display:'flex'}}>
                      <img style={{width:17, height:17, margin:5, borderRadius:'50%'}} src={vf.images && vf.images.length>0 && vf.avatar ? vf.avatar : '/static/img/avatar.png'}  onError={({target})=>{target.onerror=null;target.src='/static/img/avatar.png'}}/>
                      <span style={{fontWeight: 'bold', margin:'auto', overflow:'hidden', fontSize:'8px'}}>{vf.data && vf.data[0] && vf.data[0].name!=='unknown' ? vf.data[0].name : (vf.username ? vf.username : 'username')} </span>
                      <i className="fa fa-twitter" aria-hidden="true" style={{color:'rgb(29, 161, 242)', fontSize: '17px', float: 'right', margin: 5}}></i>
                    </div>

                    <p style={{whiteSpace: 'pre-wrap', fontSize: 'xx-small', textAlign: 'left', padding: 5, margin:0}}>
                      {vf.data && vf.data[0] && vf.data[0].text ? vf.data[0].text.replace(/\n+/g,' ').replace(/pic\.twitter\.com\/.+/g,' ').trim() : "A relevant tweet"}
                    </p>
                    {vf.images && vf.images.length>0 ? <div style={{bottom:0, position:'absolute', width:'100%', height:40, background:'url('+vf.images[0]+') center / cover'}}></div> :
                      (vf.data && vf.data[0] && vf.data[0].text && vf.data[0].text.includes('pic.twitter') ? 
                        <i className="fa fa-camera" aria-hidden="true" style={{color: 'rgb(29, 161, 242)', fontSize: '17px', float: 'right', margin: 'auto', background: '#ededed', width: '100%', padding: 10, position: 'absolute', left: 0, bottom: 0}}></i>
                      : null)}
          </div>
        }
  
        let bgImage = (types[type] && types[type].linearGradient ? types[type].linearGradient+', ' : '')+
                      ((thumb && thumb.includes('/maxres') ? this.state.succeded.includes(thumb) : true) ?  'url('+thumb+'), ' : '')+
                      (collage && collage[1] && collage[1].url ? 'url('+collage[1].url+'), ' : '')+
                      (types[type] && types[type].background ? types[type].background : ('linear-gradient(to top, '+(types[type] && types[type].gradient ? types[type].gradient : '#2196f300, #ffffff')+')'))
        // console.log(selected)
        // console.log(media)
        return <div key={'thumbimagecandidate-'+i}
            onClick={()=>{this.props.update ? this.props.update({visualFocus:vf, thumbnail:thumb}) : null}} 
            className={"photoCard"+ (selected===JSON.stringify(media).encode() ?  " photoCardselected" : "") + (locked ? ' disableSelection' : '')}
            style={{
                    width:media.duration ? media.duration*5 : (types[type] && types[type].width ? types[type].width : 100), 
                    background:(collage && collage.length>1 ? collage.map((img,i)=>{
                                          
                                            let left = i===0

                                            return img.class==="logoImage" ? 'no-repeat  '+(left ? 'left' : 'right')+' / 50%' : 'no-repeat '+(type==='tweetCollage' ? 0 : (left ? -51: 50))+'px center / cover'
                                          
                                        }).join(',') : 'no-repeat center center / cover #0000007a'), 
                    backgroundImage:bgImage,
                    ...(types[type] && types[type].style ? types[type].style : {})}}>
           
              {types[type] ? <i className={types[type].icon} style={{...style.cross, color:'white',textShadow:'0px 1px 6px #0000006b', margin:'35px auto', fontSize:'xx-large'}} aria-hidden="true"></i> : null}
            </div>
      })}
      
      {locked || !vfPicker ? null : <div key={'thumbimagecandidate-add'}
        onClick={()=>{this.props.update ? this.props.update({visualFocus:importedContent,thumbnail:null}) : null}} 
        className="photoCard"
        style={{...style.card, background:'linear-gradient(0deg, #2abee3, #6dd5ed)'}}>
          <i className="fa fa-plus" style={{...style.cross, color:'white'}} aria-hidden="true"></i>
        </div>}
        
              {locked ?  <Summary article={article} added={{photo:fullLength}} style={{marginTop: 15, fontSize:'20pt',textAlign:'center'}}/> : null}
    </div> : null
  }



}

module.exports = MediaPicker;

const style = {
  container:{
    display: 'block', 
    textAlign: 'center', 
    padding: 12,
    maxHeight:500,
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace:'nowrap'
  },
  cross:{
    margin: '26px auto',
    display: 'table',
    fontSize: 'xxx-large',
    color: '#9E9E9E'
  },
  card:{  
       
  },
  sidePicker:{
    margin:'-60px -100px 0px -100px',
    padding:'100px 60px 60px 100px',
  }
}