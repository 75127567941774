// middleware/api.js

// import {BASE_URL} from '../api-config.js';
let appEnv = process.env.NODE_ENV;
let config = require('../../settings/config.json');

// const serverRendering = true
// let BASE_URL = "";
// if(!serverRendering){
  // let BASE_URL = "http://www.stage.monok.com"+config.server[appEnv].apiUrl;
  let BASE_URL = window.location.origin+config.server[appEnv].apiUrl;
// }

function callApi(callAPI, token) {

  let { endpoint, types, body, authenticated, method, exactEndpoint } = callAPI
  let config = {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Accept': 'application/json'
      },
      credentials: 'include'
    }

  if(token) {
    config.headers['Authorization'] =`Bearer ${token}`;
  }

  if(body){
    config['body'] = JSON.stringify(body);
    config['method'] = method||'POST';
  }
  if(method){
    config['method'] = method
  }
  
  // console.log("API MIDDLEWARE")
  // console.log(BASE_URL + endpoint)
  return fetch(BASE_URL + endpoint, config)
    .then(response =>
      response.text().then(text => ({ text, response }))
    ).then(({ text, response }) => {
      if (!response.ok) {
        // console.log('KNASKNAS')
        return Promise.reject(text)
      }
      return JSON.parse(text)
    })
    // .catch(e=>{
    //   console.log('HEREEEEEEEEEEEE')
    //   console.log('HEREEEEEEEEEEEE')
    //   console.log('HEREEEEEEEEEEEE')
    //   console.log('HEREEEEEEEEEEEE')
    //   return null
    // })
}

export const CALL_API = Symbol('Call API')

export default store => next => action => {
  
  const state = store.getState();
  
  const callAPI = action[CALL_API]

  // So the middleware doesn't get applied to every single action
  if (typeof callAPI === 'undefined') {
    return next(action)
  }

  let { endpoint, types, body, authenticated } = callAPI
  const [ requestType, successType, errorType ] = types
  store.dispatch({ ...callAPI, type:requestType, request:callAPI });
  let token = null;
  if( state.auth && state.auth.user ){
    token = state.auth.user.accessToken
  }

  if(authenticated && !token) {
      throw "No token saved!"
  }


  // Passing the authenticated boolean back in our data will let us distinguish between normal and secret quotes
  return callApi(callAPI, token).then(
    response =>
      next({
        ...callAPI,
        response,
        authenticated,
        request:callAPI,
        type: successType
      }),
    error => next({
      ...callAPI, 
      error: error,
      // error: error.message || 'There was an error.',
      request:callAPI,
      type: errorType
    })
  )
}