import React from 'react';
import ReactDOM from 'react-dom';
import Video from './video.jsx';
import {addCollage, addQuoteImages, addLogoImages} from '../../util';
import TweetEmbed from 'react-tweet-embed'
var MediaPicker = require('../media/mediapicker.jsx')
import EmbedContainer from 'react-oembed-container';

var VfImage = require('./vfImage.jsx')

// let vidRef = {}

let loop = null

const ss={
  transition: 'all 1s cubic-bezier(0.165, 0.84, 0.44, 1)'
}

let audio = null

class MonokVideo extends React.Component {

  constructor(props) {
      super(props);

      let offset = props.controls ? -7 : -1

      // let frames = [{type:'outro'}]
      let frames = this.timeLine(props.article)
      console.log(frames)
      // frames.forEach((frame,i)=>{
      // 	if(frame.type==='mp4'){
      // 		vidRef[i] = useRef(null);
      // 	}
      // })
      let options = props.options
      console.log(options)
      this.state = {time:offset,
      							duration:Math.round(frames.reduce((a,b)=>a+b.duration,0)),
      							frames:frames,
      							play:(options.autoplay || props.record) ? true : false}

      audio = new Audio("/static/audio/news.mp3")

      if(audio && !props.record && options.autoplay){
			    audio.play()
      }


   		
   		let ticker = setInterval(()=>{



   			if((this.props.record || options.embed) && this.state.time>=this.state.duration){
   				// clearInterval(ticker)
   				this.setState({play:false, time:offset, current:0})
   				// audio.pause()
   				audio.load()
   				return
   			}

				let newState = {
												time:this.state.time>=this.state.duration ? 0 : this.state.time+1, 
												done:props.record && this.state.time+offset>=this.state.duration
											}
    		
				//start mp4's
				let current = frames.findIndex(frame=>newState.time >= frame.start && newState.time <= (frame.start+frame.duration))
				if(this.state.play && frames[current] && frames[current].source==='Giphy' && this.refs['vidRef'+current] && newState.time <= frames[current].start+1){
					// console.log(this.refs['vidRef'+current])
					// console.log('RATE:')
					// console.log(frames[current].duration > (this.refs['vidRef'+current].duration % 60) ? ((this.refs['vidRef'+current].duration % 60)/frames[current].duration) : 1)
					// console.log('VIDEO DURATION: ' + this.refs['vidRef'+current].duration)
					// console.log('FRAME DURATION: '+frames[current].duration)
			
					let playbackRate = 1
					if((this.refs['vidRef'+current].duration > 1) && (frames[current].duration > this.refs['vidRef'+current].duration)){
						playbackRate = ((this.refs['vidRef'+current].duration % 60)/frames[current].duration)
						if(playbackRate<0.1){
							playbackRate = 0.1
						}
					}else if(this.refs['vidRef'+current].duration < 1){
						this.refs['vidRef'+current].loop=true
					}

					this.refs['vidRef'+current].crossOrigin = 'anonymous';
					this.refs['vidRef'+current].playbackRate = playbackRate
					this.refs['vidRef'+current].play();
				}

    		if(window.onSendStatus){
      		window.onSendStatus({...this.state, ...newState, current:current})
      	}

      	if(this.state.play && !this.state.done){
      		this.setState({...newState, current:current})
      	}
      }, 1000);
  }  

  componentDidUpdate() {
    // const height = this.tweet3.clientHeight;
    // console.log({ height });
  }

  embedControls(){
  	if(this.props.record){
  		return null
  	}

  	return<div className="animate embedControls">
  		<div style={{height:450, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
						<div onClick={e=>{
				  		this.setState({time:this.state.time-1})
				  	}}
				  	className="videoButton fa fa-backward"
				  	style={{}}>
				  	</div>
				  	
				  	<div onClick={e=>{
				  		if(this.state.play){
				  			//Pause
				  			if(this.refs['vidRef'+this.state.current]){
				  				this.refs['vidRef'+this.state.current].pause()
				  			}
				  			audio.pause()
				  		}else{
				  			audio.play()
				  		}
				  		this.setState({play:!this.state.play})
				  	}}
				  	style={{paddingInlineStart: 37}}
				  	className={"videoButton fa fa-"+(this.state.play ? 'pause' : 'play')}>
				  	</div>

				  	<div onClick={e=>{
				  		this.setState({time:this.state.time+1})
				  	}}
				  	className="videoButton fa fa-forward"
				  	style={{}}>
				  	</div>
         </div>
         <div className="animate" style={{
         				position: 'absolute', 
         				bottom: 0,
         				background:'red', 
         				height:10, 
         				margin:10, 
         				width:(this.state.time/this.state.duration)*780}}></div>
         </div>
         
  }

  getControls(){
  	if(this.props.record){
  		return null
  	}
  	let butt = {width: 50, background: '#e0e0e0',textAlign: 'center', lineHeight: '50px', height: 50, borderRadius:0, margin:0, padding:'0px 20px'}
  	return <div>

  		<MediaPicker 
        article={this.props.article}
        selected={null}
        editmode={true}
        film={this.state.frames.map(frame=>frame.data && frame.type==='person' ? {...frame.data[0].image, start:frame.start, duration:frame.duration} : frame)}
        update={(v)=>{
        	this.setState({time:v.visualFocus.data ? v.visualFocus.data[0].image.start : v.visualFocus.start})}}
        // vfPicker={!expandMediapicker && locked ? false : true}
        style={{padding: '50px 0px', 
        				left:-212,
        				top:450,
        				whiteSpace:'nowrap'}}
        locked={false}
        previewAmount={30}/>

         <div style={{position:'absolute', top:600, left:10, display:'flex'}}>
			  	<div onClick={e=>{
			  		this.setState({play:!this.state.play})
			  	}}
			  	style={{left:20, ...butt, width:'auto'}}
			  	className={"button fa fa-"+(this.state.play ? 'pause' : 'play')}> Current time: {this.state.time}s / {Math.round(this.state.duration/60)}m
			  	</div>
					<div onClick={e=>{
			  		this.setState({time:this.state.time-1})
			  	}}
			  	className="button fa fa-backward"
			  	style={{left: 250, ...butt}}>
			  	</div>
			  	
			  	<div onClick={e=>{
			  		this.setState({time:this.state.time+1})
			  	}}
			  	className="button fa fa-forward"
			  	style={{left: 330, ...butt}}>
			  	</div>
         </div>

	  		  	</div>
  }

 

  overlay(text, start, duration, highlight, time, style, position, force, sentence, options){


  	let active = force || (time>=start && time<start+duration)
  
  	//optimization
  	if(!force && (time<(start-1) || time>(start+duration+1))){
  		return null
  	}

  	let emoji = [
	  	{file:'laugh',keywords:['laughable', 'laughing']},
	  	{file:'angry',keywords:['upset']},
	  	{file:'beer',keywords:['drinks']},
	  	{file:'burger',keywords:['hamburger']},
	  	{file:'curse',keywords:['fuck']},
	  	{file:'earth',keywords:['world']},
	  	{file:'gross',keywords:['disgusting']},
	  	{file:'happy',keywords:['joy', 'glad']},
	  	{file:'heart',keywords:['empathy']},
	  	{file:'kiss',keywords:['flirt']},
	  	{file:'love',keywords:['crush']},
	  	{file:'pizza',keywords:['dominos']},
	  	{file:'shit',keywords:['poop']},
	  	{file:'shock',keywords:['shocking','infuriating']},
	  	{file:'victory',keywords:['peace']},
  	]

  	if(position==='bottom'){
  		style = {...style,
				alignContent: 'flex-end',
				justifyContent: 'flex-start',
				// lineHeight: '35px',
				lineHeight: '55px',
				// fontSize: 'xx-large',
				fontSize: '55px',
				backgroundImage:'linear-gradient(0deg, black 5%, transparent 50%)',
				backgroundPositionY: active ? 0 : 400
			}
  	}

  	let icon = {    
  		position: 'absolute',
    	width: '100%',
    	bottom: 0,
    	left: '0%'
    }

  	let styleWord = (word)=>{
  		let badge = {padding:'0px 10px', textShadow:'0 0 3px black', borderRadius:7, ...(active ? {} : {backgroundPositionX:-100})}

  		if(word.startsWith('@')){
  			return{color: '#03a9f4'}
  		}else if(!isNaN(word) && word.toString().indexOf('.') !== -1 && word.indexOf('.')!==word.length-1){
  			return {background:'linear-gradient(360deg, rgb(218 129 0), rgb(255 152 0)) no-repeat', ...badge}
  		}else if(highlight.includes(word.toLowerCase())){
  			return {background:'red', ...badge}
  		}

  		return {}
  	}

  	return 		<div className={(this.state.play ? "animate " : "") + "txtOverlay"} 
  									style={{top:0, 
														lineHeight:text.length<100 ? '49px' : '35px',
														fontSize: text.length<100 ? 'xxx-large' : 'xx-large', 
														backgroundPositionY: active ? 0 : 400,
														width: '100%',
														...(style ? style : {})}}>
														{sentence.badge ? <div style={{
																													position: 'relative',
																											    top: -60,
																											    // top: -38,
																											    left: 5,
																											    width: 0
																												}}>
																												<div style={{
																											    background: options && options.primary ? '#'+options.primary : '#FF9800',
																											    display: 'inline-block',
																											    padding: '0px 20px',
																											    borderRadius:5,
																											    textShadow: 'none',
																											    width:'max-content',
																											    position:'absolute',
																											    textTransform: 'uppercase',
																											    fontSize: '30px',
																												}}>{sentence.badge}</div>
																												</div> : null}
	  						{text.split(' ').map((word,i)=>{
	  							let delay = i*50
	  							let emoticon = emoji.find(em=>[em.file].concat(em.keywords).includes(word.toLowerCase().replace(/[!"\?,\.]/g,'')))
	  							return <div className={(this.state.play ? "animate " : "") + "tag"} style={{
	  								position:'relative',
	  								transitionDelay:delay+'ms',
	  								...styleWord(word),
	  								...(active ? {opacity:1} : {opacity:0, transform:'translateY(40px) scale(0.7)'})
	  							}}>{word.startsWith('\n') ? <br/> : null}{word}
	  							{emoticon ?
	  								<img className="emojiIcon" src={"/static/img/assets/emoji/"+emoticon.file+".gif"} style={{...icon, animationDelay:delay+500+'ms', transitionDelay:delay+500+'ms',...(active ? {animation:'1.2s ease 3s 1 normal forwards running gelatine2', opacity:1} : {opacity:0})}}/>
	  							 : null}
	  							</div>
	  						})}
	  					</div>
  }

  subTitles(text){
  	let {time} = this.state
  	return 	<div style={{
	  			zIndex: 100,
			    display: 'flex',
			    padding: '5px 100px',
			    margin: 'auto',
			    color: 'white',
			    fontWeight: 'bold',
			    position: 'absolute',
			    bottom: 50,
			    alignItems:'flex-end',
			    width: '100%',
			    flexDirection: 'row',
			    justifyContent: 'center',
			    flexWrap: 'wrap'
			}}>
	  			{text.split(' ').slice(time*10,time*10+10).map(word=><div className="tag" style={{padding:'5px 5px 5px 5px', margin:0, background:'#00000082'}} >{word}</div >)}
	  		</div>
  }



  timeLine(article){

  	if(article.monokVideo){
  		// console.log(article.monokVideo.timeline)
  		return article.monokVideo.timeline
		}

  }


 

// http://dev.com/puff/ujz0mp-cyberpunk-2077-unveil-phantom-liberty-expansion-keanu-reeves-return?hl=en-US&vid=true
// http://dev.com/puff/ujz0mp-cdpr-announces-new-cyberpunk-2077-expansion-phantom-liberty-for-2023?hl=en-US&vid=true
  render () {
  	let {article, controls, options, slides, format} = this.props
  	let {time, play, frames} = this.state

  	let width = ['portrait', 'square'].includes(format) ? 800 : 1422
  	let height = ['square'].includes(format) ? 800 : (['portrait'].includes(format) ? 1000 : 800)

  	let logo = frames && frames[0] && frames[0].logo ? frames[0].logo : this.props.logo

  	// logo = logo ? 'https://www.monok.com/api/v1/logoProxy?url='+logo : null

  	let text = article.article.replace(/\{.+\}/g, '')

  	// console.log(options)


  	return <div style={{height:slides ? '100vh' : 500,
  											width:width, 
  											marginBottom: 30, 
  											position: 'relative', 
  											overflow:slides?'scroll' : undefined
  											}}>
  											{options.embed ? this.embedControls() : null}
	  	<div style={{overflow:slides ? 'scroll' : 'hidden',background:'black',pointerEvents: controls ? undefined : 'none'}}>

	  			{
	  				
	  				frames.map((frame,i)=>{


	  				let frameComp = null
	  				let active = slides || ((time >= frame.start) && (time < (frame.start+frame.duration)))
	  				let passed = time > (frame.start+frame.duration+1)
	  				// let ahead = time < (frame.start-1)
	  				let dur = Math.round(frame.duration)

	  				let soonToBeActive = time >= frame.start-8

	  				if(slides ? false : (passed || !soonToBeActive)){
	  					return null
	  				}

	  				if(frame.source==='Giphy'){
	  					//autoPlay={active ? true : false}
	  					frameComp = <video ref={"vidRef"+i} muted  style={{objectFit: 'cover', objectPosition: (active ? 'center' : 'top'), position:'absolute',transition:'all 20s ease-in-out 0s'}} width={width} height={height} src={frame.url} type="video/mp4" controls={false}>
							</video>
	  				}else if(frame.type==='video'){
	  					frameComp = <Video style={{borderRadius:0}} play={true} opened={true} selected={true} focus={true} video={frame} />
	  				}else if(frame.type==='instagram'){
	  						let ani = {
	  						before:{
	  							transform:"scale(1.5) perspective(252px) rotateX(34deg)"
	  						},
	  						after:{
	  							marginTop:-(this['tweet'+i] && this['tweet'+i].clientHeight>400 ? this['tweet'+i].clientHeight/2-50 : 0),
	  							filter:'drop-shadow(rgba(0, 0, 0, 0.9) 0px 23px 22px)'
	  						},
	  						transition: 'all 5s cubic-bezier(0.165, 0.84, 0.44, 1), margin-top '+dur+'s ease-in'
	  					}


	  					let matches = frame.url.match(/\/p\/(.*?)\//)
      				let cleanUrl = matches && matches[0] ? 'https://www.instagram.com'+matches[0] : frame.url
      				let post = '<blockquote class="instagram-media" data-instgrm-captioned data-instgrm-permalink="'+cleanUrl+'?utm_source=ig_embed&amp;utm_campaign=loading" data-instgrm-version="13" style="border:0; border-radius:3px; box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); margin: 1px; max-width:658px; min-width:326px; padding:0; width:99.375%; width:-webkit-calc(100% - 2px); width:calc(100% - 2px);"></blockquote><script async src="//platform.instagram.com/en_US/embeds.js"></script>'
				     
	  					frameComp = <div style={{
	  									height:height, 
	  									width:width,
	  									overflow:'hidden', 
	  									transition:'all 30s linear',
	  									textAlign: '-webkit-center',
    									padding: 30,
	  									background:"url('/static/img/assets/gradient.gif')",
	  									backgroundPosition:active ? "100% 100%" : undefined}}>
	  										<div ref={ (divElement) => { this['tweet'+i] = divElement } } 
	  										style={{padding:'10px',...(active ? ani.after : ani.before), transition:ani.transition}}>
		  										<EmbedContainer markup={post}>
									          <div dangerouslySetInnerHTML={{ __html: post}} />
									        </EmbedContainer>
	  										</div>
	  								</div>
	  				}else if(frame.type==='twitter'){

	  					let ani = {
	  						before:{
	  							transform:"scale(1.5) perspective(252px) rotateX(34deg)"
	  						},
	  						after:{
	  							marginTop:-(this['tweet'+i] && this['tweet'+i].clientHeight>400 ? this['tweet'+i].clientHeight/2-50 : 0),
	  							filter:'drop-shadow(rgba(0, 0, 0, 0.9) 0px 23px 22px)'
	  						},
	  						transition: 'all 5s cubic-bezier(0.165, 0.84, 0.44, 1), margin-top '+dur+'s ease-in'
	  					}

	  					frameComp = <div style={{
	  									height:height, 
	  									width:width,
	  									overflow:'hidden', 
	  									transition:'all 30s linear',
	  									textAlign: '-webkit-center',
    									padding: 30,
	  									background:"url('/static/img/assets/xlogowhite.png') repeat 0% 80% / 18%, linear-gradient(90deg, #FC466B 0%, #3F5EFB 100%)",
	  									backgroundPosition:active ? "100% 100%" : undefined}}>
	  								<div
	  										ref={ (divElement) => { this['tweet'+i] = divElement } } 
	  										style={{padding:'10px',...(active ? ani.after : ani.before), transition:ani.transition}}><TweetEmbed id={frame.id} 
	  									
	  									options={{conversation:'none',lang:'en'}}/></div>
	  								</div>
	  								
	  				}else if(frame.type==='outro'){
	  					frameComp = <div style={{
	  						backgroundImage: 'url('+frame.background+')',
						    minHeight: 350,
						    backgroundPosition: 'center 10.279%',
						    backgroundSize: '100%',
						    height: height,
						    filter:active ? 'brightness(0)' : 'brightness(1)',
						    animation:active ? '3s ease 0s 1 normal forwards running outro' : null,
						    transition: 'filter 5s linear 0s',
						    '-webkit-mask': 'url(https://www.monok.com/api/v1/logoProxy?url='+logo+') center center / 75% no-repeat'
	  					}}>
	  						
	  					</div>
	  				}else{
	  					
	  					frameComp = <VfImage 
			  									animate={slides ? false : (this.state.play && this.props.animate)}
							            id={'vframe-'+i}
							            intro={!slides}
							            paused={!active}
							            height={height}
							            bigPuff={true} 
							            format={this.props.format}
							            onReady={()=>{
							              // this.setState({vfReady:true})
							            }}
							            description={controls}
							            logo={logo}
							            feed={this.props.feed}
							            vf={frame}/>
	  				}
	  			
	  				let watermark = logo ? <img src={'https://www.monok.com/api/v1/logoProxy?url='+logo} style={{
	  					  position: "absolute",
							  width: "15%",
							  minWidth:150,
							  objectFit: "contain",
							  objectPosition: "right top",
							  height: "60px",
							  zIndex: 1000,
							  top: "15px",
							  right: "50px",
							  filter: "brightness(0) invert(1)",
							  opacity: 0.7
	  				}} /> : null

	  				
	  				
	  				return <div 
	  							// className="slowAnimate"
				          style={{transition: slides ? undefined : ss.transition, position:slides ? 'relative' : 'absolute', width:width, height:height, opacity:(time<0 && i==0) || (active ? 1 : 0)}}>
		  							{watermark ? watermark : null}
		  							{frameComp}
				         		{i===0 && logo && !slides ? <img className={this.state.play ? "animate" :""} style={{    
				         			position: 'absolute',
									    bottom: 28,
									    padding: '0px 259px 0px',
									    width: '100%',
									    maxHeight:'20%',
									    transform: active ? 'scale(1)' : 'scale(0)',
									    transitionDelay:'2s',
									    zIndex:1000
  										}} src={'https://www.monok.com/api/v1/logoProxy?url='+logo}/> : null}
					  				{
					  					frame.hideOverlay ? null : 
					  					frame.sentences ? frame.sentences.map(sentence=>{
					  						return sentence.text ? this.overlay(sentence.text, 
					  																								frame.start+sentence.start, 
					  																								sentence.duration, 
					  																								['COD'], 
					  																								time, 
					  																								{height:height}, 
					  																								sentence.style ? sentence.style : ((i===0 || sentence.headline) ? null: 'bottom'), 
					  																								slides,
					  																								sentence, 
					  																								options) : null
					  					}) : null
					  				}
				          </div>
	  			})}

	  	</div>
	  	{this.state.done ? <div id="videoFinished"></div> : null}
	  	{controls && !slides ? this.getControls() : null}
	  	
  	</div>
  }
}

module.exports = MonokVideo;