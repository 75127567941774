var React = require('react');
var config = require('../../settings/config.json');

class BottomBar extends React.Component {

	constructor(props){
    	super(props);
	}

	render(){


    var mediaLinks =  socialMedia.map((social, i)=>{
                        return (<a key={"socialMedia-"+i} target="_blank" href={social.url} style={{color:'inherit'}}>
                                    { social.image ? <img style={{width: 20,marginTop: -8}} src={social.image}/> :
                                      <span style={{fontSize: '15pt', color: '#444444', padding: 10, color:'inherit'}} className={"fa fa-"+social.icon} aria-hidden="true"></span>}
                                </a>)
                      })
  
    let cards = ['mastercard', 'visa', 'amex' ].map((card, i)=>
      <i key={"acceptedcard-"+i} className={"fa fa-cc-"+card} style={{fontSize:'xx-large', margin:'0px 10px'}} aria-hidden="true"></i>)

        // {langFlags}
		return (
      <footer style={{color:'#2d2d2d', padding:30, maxHeight: 211, margin: 'auto 0px 0px', textAlign:'center', ...(this.props.style ? this.props.style : {})}}>
        <div>{cards}</div>
        {mediaLinks}
        <a className={"normalLink"} href={'mailto:'+config.app.email}>{config.app.email}</a>
        <p style={{paddingTop:5}}>
        <p>We protect your personal information, read more about this in our  <a target="_blank" href="https://monok.news/terms-of-service/" className={"normalLink"}>terms of service and privacy policy</a>.</p>
        <img style={{width:110,paddingRight:10}} src={config.server[window.env].staticUrl+"img/icons/monok.svg"}/>
        Copyright © {new Date().getFullYear()} Monok.</p>
       
      </footer>
      );
	}

 

}

const socialMedia=[
            {icon:'github',url:'https://github.com/monoklabs'},
            {icon:'facebook',url:'https://www.facebook.com/MonokNews'},
            {image:'/static/img/integrations/xlogo.png',url:'https://twitter.com/monok_com'},
            {icon:'instagram',url:'https://www.instagram.com/monok_com'},
            {icon:'reddit',url:'https://www.reddit.com/r/monok'},
            {icon:'linkedin',url:'https://www.linkedin.com/company/monok'},
            {icon:'youtube',url:'https://www.youtube.com/channel/UCoBhUcVRmZeMvocHhpf3SVg/'}
            ]



export default BottomBar;

