var React = require('react');
var config = require('../../../settings/config.json')
import { connect } from 'react-redux';
import Article from './article.jsx';
import {Grid, Col, Row, Glyphicon,Tooltip, Button, OverlayTrigger} from 'react-bootstrap';
var Comments = require('./comments.jsx');
var TimeAgo = require('react-timeago').default
var Scroll = require('react-scroll');
import Summary from '../modal/summary.jsx'
var scroller = Scroll.scroller;
var Element = Scroll.Element;
import {ShareButtons} from 'react-share';
var Widget = require('./widget.jsx')
var VfImage = require('../media/vfImage.jsx')
var MonokVideo = require('../media/monokVideo.jsx')
var MediaPicker = require('../media/mediapicker.jsx')
import T from 'i18n-react';
import {browserHistory} from 'react-router';
import Sources from './sources.jsx';
import Dropdown from '../modal/dropdown.jsx';
import Spinner from '../modal/spinner.jsx';
import Checkout from '../modal/checkout.jsx';
import Flip from '../modal/flip.jsx';
import {sendPuff, savePuff, buyPuff, orderPuff, setFocusedPuff} from '../../actions/puff';
import {setPuff} from '../../actions/feed';
import {saveProfile} from '../../actions/auth';
import MessageBubble from "../modal/messagebubble.jsx";
import {get, post} from '../../apiFunctions';
import {maskAuthor, setting, settingsToParams, daysOld, addCollage, addQuoteImages, addLogoImages} from '../../util';
import ArticleParser from '../editor/toHTML.js';
let articleParser  = new ArticleParser()

const {
  FacebookShareButton,
  TwitterShareButton
} = ShareButtons;

const icons = window.icons

let orderType = [
                  {desc:"Correct and flesh out the text up to 300 words, select graphics and fact check",price:2, minLength: 200, maxLength:350, words:300},
                  {desc:"Correct and increase the length to 600 words, select graphics and fact check",price:3.5, minLength: 550, maxLength:600, words:600},
                  {desc:"Rewrite into a long in-depth analysis of the subject and fact check",price:10, minLength: 1100, maxLength:1200, words:1200},
                  {desc:"Correct and increase the length to 800 words, select graphics and fact check",price:4.5, minLength: 770, maxLength:900, words:800},
                  {desc:"Correct and increase the length to 1000 words, select graphics and fact check",price:6, minLength: 970, maxLength:1100, words:1000}
 ]

let autosaveTimer = null //PERHAPS THIS SHOULDNT BE OUTSIDE THE REACT COMPONENT

class BigPuff extends React.Component {

  constructor(props){
      super(props);
      // this.textAreaRef = React.createRef();
      this.update = this.update.bind(this);
      this.save = this.save.bind(this);
      // this.state = {scroll:0, article: props.article)}
      // console.log(props.article)

      this.state = {saveStatus:'unsaved',scroll:0, update:{}, article: JSON.parse(JSON.stringify(props.article)), status:props.article.status}
  }

  componentDidMount(prevProps) {
    if(this.state.article.sources && this.state.article.sources.length>1){
      // this.props.article.images = newImageList
      let newImageList = this.addSyntheticImages(this.state.article)
     
      this.setState({article:{...this.state.article, images:newImageList}})//, videos:newVideoList

    }

   

        // get('extractPuff/'+(this.state.article.id.includes('/') ? this.state.article.id.split('/')[1] : this.state.article.id)+'?feed=true',(err, res)=>{
        //     console.log(res.body)
        //     console.log(this.state.article)
            
        //     this.setState({article:{...this.state.article, article:res.body.text}})
        // })
    
  }

  
  addSyntheticImages(article){

    // let getImage = (widget)=>widget && widget.data && widget.data[0] && widget.data[0].image ?  widget.data[0].image : (widget && widget.url ? widget.url : null)
    // let image = getImage(this.state.article.visualFocus)

    // let img = new Image();
    // img.crossOrigin='anonymous'
    // img.src = image.url
    // img.onload = async (e)=>{
    //   setTimeout(async () => {     
    //       // Classify the image.
    //       let predict = await window.objectdetect.classify(img)
    //       let extract = predict.map(pr=>pr.className).join(' ')
    //         console.log(extract)
    //       if(extract.includes('website') && this.state.article && this.state.article.images){
    //         let ind=this.state.article.images.findIndex(im=>im.url===image.url)
    //         let newImg = this.state.article.images[ind+2]
    //         console.log("newImg")
    //         console.log(newImg)
    //         this.setState({article:{...this.state.article, visualFocus:{name:'Stock', type:newImg.type, data:[{image:newImg}]}}})
    //       }
    //   }, 3000)   
    // }

    return [].concat(addCollage(article))
          .concat(addQuoteImages(article))
          .concat(this.addTwitterImages(article))
          .concat(addLogoImages(article))
          // .concat(this.addPortlandImages(article))
          // .concat(this.addMapImages(article))
          .concat(article.images)
          // .concat(this.addTitleImages(article))
                         
  }

  deselectPuff(){

    if(!this.state.editmode){
      if(this.props.user && this.props.user.writer){
        browserHistory.push({pathname: '/search', query: {feed:'orders', sort:'date'}})  
      }else if(window.entrypointMonok===window.location.href && this.props.article){
        browserHistory.push({pathname: '/'+this.props.article.feed})
      }else{
        browserHistory.goBack()
      }

    }
  }

  componentDidUpdate(prevProps) {

    //Sams idee: prevProps.article.status.type !=='submitted' || !prevProps.article.status osv...
    let closeDown = this.props.user && (this.props.user.writer || this.props.user.curator) && this.props.article.status && this.props.article.status.type === 'submitted' && ((user && this.props.article && this.props.article.author && this.props.article.author.name === user.name) || this.props.selectedFeed && this.props.selectedFeed.id==='search' && this.props.selectedFeed.menu.find(m=>m.value==='orders' && m.selected===true))
    if(this.state.editmode){
      this.resizeTextArea(this.textAreaRef)
    }

    let self = this
    document.onkeyup = function(e) {
      
      if (e.which == 27) {
        //ESCAPE
        self.deselectPuff()
      }
    };

    if(this.state.closeDown){

      setTimeout(()=> {
        // if(){
          browserHistory.push({pathname: '/search', query: settingsToParams(this.props.selectedFeed)})  
        // }
      }, 1000);

    }
      // console.log(this.props.selectedFeed)
      // console.log(prevProps.article)
      // console.log(this.props.article)

    if(this.props.article.loading !== prevProps.article.loading || 
      this.props.article.msg !== prevProps.article.msg || 
      this.props.article.status !== prevProps.article.status || 
      (prevProps.article.sources && prevProps.article.sources.length<1 && this.props.article.sources && this.props.article.sources.length>1) ||
      (prevProps.article.widgets && prevProps.article.widgets.length<1 && this.props.article.widgets && this.props.article.widgets.length>1)) {
    
      if(prevProps.article.sources && prevProps.article.sources.length<1 && this.props.article.sources && this.props.article.sources.length>1){
       this.props.article.images = this.addSyntheticImages(this.props.article)                   
      }

     // console.log(this.props.article.images)
      this.setState({status:this.props.article.status, article:JSON.parse(JSON.stringify(this.props.article)), closeDown:closeDown})
    }
  }

   translateRate(rate){
    if(rate>999999){
      rate = (Math.round(rate / 100000) / 10).toString()
      return rate+'M'
    }
    if(rate>9999){
      return rate.toString().slice(0,rate.toString().length-3) + 'K'
    }
    if(rate>1000){
      return Math.round(rate /100)/10 + 'K'
    }
    return rate
  }

  extractArticleText(article){
    return article ? article.split('\n').reduce((a,b)=>{
      try{
        //This will break if it's not a json
        let obj = JSON.parse(b)
        return a + '\n'
      }catch(e){
        return a  +  b + '\n'
      }
    },'') : ''
  }




  buyButton(article, user, editable){

    return article.locked ? this.wrapTT(user && user.curator ? 'Send to Order for human edit' : (editable ? ('Instantly unlock this exclusive article') : (user.email ? 'This article was not generated for your account' : 'You need to Sign In first')),
                    <div onClick={()=>{
                        if(editable){
                          if(user && user.curator && this.props.puffFeed){
                            
                            let order = setting(this.props.puffFeed.articleGeneration, 'order', 0)
                            this.placeOrder(null, {order:order-1})
                          }else if(user && !user.curator){
                            this.buyArticle(article.id.split('/')[1])
                          }
                        }
                      }}
                      className="button blackButton purchaseButton" 
                      style={{paddingLeft:user.avatar ? 10 : 35}}>
                  {user.avatar ? <div style={{float:'left', borderRadius:'50%', marginRight:10, background:'url("'+user.avatar+'") center center / cover', width:40,height:40}}></div> : null}
                  {user && user.curator ? "SEND TO ORDER" : "Buy Now"}
                  </div>) : null
  }



  resizeTextArea(elem){
    if(elem){
      elem.style.height='40px'
      elem.style.height=elem.scrollHeight+'px'
    }
  }

  header(article, editmode){
    let titles = article.candidates ? article.candidates.filter(candidate=>candidate.index===0) : []
    let rtl = ['ar'].includes(article.language)

    if(editmode){
      return <div style={{display: 'flex'}}>
      <textarea ref={(comp)=>{this.textAreaRef = comp}} 
                      className="plugin-title"
                      onBlur={(e)=>{
                        let cleaned = e.target.value.trim()==='' ? 'Missing Header' : e.target.value.trim().replace(/\s+/g,' ')
                        article.title === cleaned ? null : this.update({title:cleaned})
                      }} 
                      onChange={(e)=>{this.update({title:e.target.value.replace(/\n+/g,' ')})}} 
                      type="text" value={article.title} 
                      style={{height:this.textAreaRef ? this.textAreaRef.scrollHeight : 'initial',
                            fontSize: 'xx-large', 
                            width: '100%',
                            textAlign: rtl ? 'right': undefined,
                            resize: 'none'}}/>
      </div>
    }else{
      return <h1 style={{...style.title, ...{
        paddingTop:(article.visualFocus ? 30 : 60),
        textAlign:rtl ? 'right': undefined
      }}}>{article.title}</h1>
    }
  }

  comment(text){
    return text.split('\n').map(para=><p style={para.toLowerCase().startsWith('no,') ? {background: 'rgb(255 77 77 / 21%)', padding: 5, borderRadius: 10} : {}}>{para}</p>)
  }

  extractMarkdownLinks(text) {
    const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
    let match;
    const links = [];

    while ((match = regex.exec(text)) !== null) {
      const anchorText = match[1];
      const url = match[2];
      links.push({ anchorText, url });
    }

    return links;
  }

  navbar(navStyle, show){
    let bar = (value, maxValue, minLength)=><div style={{marginBottom:10, background: 'linear-gradient(to right, '+(value && (value<minLength) ? '#fb7c7c ' : '#4ad295 ')+Math.round(100*((value ? value : 0) / maxValue))+'%, #eee 0%)', height:4}}></div>
    let {article, update, saveStatus} = this.state
    let {user} = this.props
    let longOrder = article.status && article.status.order===1
    let words = this.extractArticleText(article.article).trim().split(/\s+/).length


    let modal = {
      fontSize: 'medium',
      fontWeight: 'bold',
      overflow: 'scroll',
      // position: 'fixed',
      background: 'white',
      color: 'black',
      height: '80%',
      top: '10%',
      zIndex: 1000,
      left: '10%',
      width: '90vw',
      margin: '3vw',
      maxHeight:'unset',
      borderRadius: 10,
      padding: 40,
      boxShadow:'0px 56px 134px'
    }
    // console.log(this.extractMarkdownLinks(article.article))
  

    return <div className="puffNavbar editMenu" style={navStyle ? navStyle : {}} onMouseDown={(event)=>{event.stopPropagation()}}>
                  {(!show || show.includes('nav')) && saveStatus !== 'failed' ? 
                  <div className="button" 
                    onClick={()=>{
                      // window.virtualText = null
                      if(user.writer){
                        this.save({editmode:false})
                      }else{
                        //Don't save just setState
                        this.setState({editmode:false, update:{}, article:JSON.parse(JSON.stringify(this.props.article))})
                      }
                    }}>
                      <i className="fa fa-times" aria-hidden="true"></i> {Object.keys(update).length && !user.writer ? 'Cancel' : 'Close'}
                  </div> : null} 
                  {Object.keys(update).length && (!show || show.includes('nav')) && (!user.writer || saveStatus === 'failed') ? <div className="button greenButton-o" onClick={this.save}>Save</div> : null}
                  {(Object.keys(update).length ? <p style={{color:saveStatus === 'failed' ? 'red' : undefined, float: 'right'}}>{saveStatus === 'failed' ? 'Failed to save' : (saveStatus==='loading' ? 'Saving..' : 'Unsaved Changes')}</p>  : (article.updated ? <p style={{...style.timestamp, width:160, padding: '0px 10px', float: 'right'}}>Last saved <TimeAgo date={article.updated}  formatter={window.timeagoFormatter}/></p> : null))}
                  {show && show.includes('seo') ? <div style={{    marginLeft: 20}}>
                    {article.status && article.status.comment ?
                    <p style={{marginTop:30}}><p style={{fontWeight:'bold'}}><i className="fa fa-exclamation-circle" aria-hidden="true"></i> Rejection Comment:</p> {this.comment(article.status.comment)}</p> : null}
                    <p style={{marginTop:30}}>{article.status && article.status.type==='assigned' && orderType[article.status.order] ? orderType[article.status.order].desc : ''}</p>
                    {longOrder && !article.article.includes('##') ? <p>Add some <b>sub-headings</b></p> : null}
                    {(user.writer || user.curator) && article.status && article.status.deadline && (article.status.type!=='submitted' || !(article.published && article.published.length>0)) ? <p>This article must be submitted <b style={{color:'red'}}>no later than  <TimeAgo date={article.status.deadline} formatter={window.timeagoFormatter}/></b></p> : null}
                    {longOrder && !article.article.includes('{') && (article.videos.length>0 || article.widgets.filter(w=>['twitter', 'instagram', 'tiktok','facebook', 'spotify', 'reddit'].includes(w.type)).length>0) ? <p>Include some relevant <b>media embeds</b></p> : null}
                    {article && article.feedSettings && article.feedSettings.notes ? 
                    <p 
                    tabIndex="0"
                    onFocus={(e)=>{
                      this.setState({notesFocused:true})
                    }} 
                    onBlur={(e)=>{
                      this.setState({notesFocused:false})
                    }} 
                    className="red" 
                    style={{fontSize: article.feedSettings.notes.length>500 ? 'medium' : 'x-large', fontWeight: 'bold', maxHeight: 500, overflow: 'scroll',
                    ...(this.state.notesFocused ? modal : {})}}  dangerouslySetInnerHTML={{ __html: articleParser.toHtml(article.feedSettings.notes) }}></p> : null}
                    {article && article.feedSettings && article.feedSettings.targetWebsite ? <p style={{fontWeight: 'bold'}}>Will be published at <a href={article.feedSettings.targetWebsite} target="_blank">{article.feedSettings.targetWebsite}</a></p> : null}
                    
                    <p><b>{words}</b> Words</p>
                    {article.status && orderType[article.status.order] ? bar(words, orderType[article.status.order].maxLength, orderType[article.status.order].minLength) : null}
                    {user && user.writer ? null : <div>
                      <textarea  value={article.shortTitle} 
                        onChange={e=>this.update({shortTitle:e.target.value})} 
                        className="plugin-title" 
                        placeholder="Short Title" 
                        style={{padding:10, height: 70, fontSize:'inherit', width: '100%', resize: 'none'}}/>
                        {article.shortTitle ? bar(article.shortTitle.length, 70, 47) : null}
                      <textarea  value={article.description} 
                        onChange={e=>this.update({description:e.target.value})} 
                        className="plugin-title" 
                        placeholder="Description" 
                        style={{padding:10, height: 110, fontSize:'inherit', width: '100%', resize: 'none', fontWeight:'normal'}}/>
                        {article.description ? bar(article.description.length, 140, 107) : null}
                    </div>}
                    {user && user.writer && article.feedSettings ? 
                      <div>
                      
                        <p>Read the <a href="https://docs.google.com/document/d/1ZEpz1RgBhPUav0sBNL723Gfp7J68b_mY_6ddX3IkiMw" target="_blank">writer's guide</a></p>
                        
                        {article.feedSettings.outbound ? <div>
                          <h3 style={{marginBottom:10}}>Add {article.feedSettings.outbound.amount} links to an authorative source</h3>
                        </div> : null}

                        {article.feedSettings.linksto ? <div>
                          <h3 style={{marginBottom:10}}>Add {article.feedSettings.linksto.amount} links to anything on <a href={article.feedSettings.linksto.target} target="_blank">{article.feedSettings.linksto.target}</a></h3>
                        </div> : null}

                        {article.feedSettings.targets && article.feedSettings.targets.filter(target=>!this.linkInText(target, article.article)).length>0 ? <div>
                          <h3 style={{marginBottom:10}}>Add these links</h3>
                          {article.feedSettings.targets.filter(target=>!this.linkInText(target, article.article)).map(target=><div><b>Text:</b> {target.anchorText} <br/><a href={target.anchorURL} target="_blank" style={{fontSize:'small'}}>{target.anchorURL}</a></div>)}
                        </div> : null}
                        

                        {article.feedSettings.keywords ? <div>
                          <h3 style={{marginBottom:10}}>SEO Keyword{article.feedSettings.keywords.keywords.length>1 ? 's' : ''}</h3>
                          <p>Mention at least {article.feedSettings.keywords.amount}</p>
                          <div style={{scrollbarWidth: 'none', overflow:'scroll', paddingBottom: 110, maxHeight:400, '-webkit-mask':'-webkit-linear-gradient(bottom, transparent  10%, black, black, black)'}}>
                          {article.feedSettings.keywords.keywords.map(query=>({name:query, inText:(article.title+' '+article.article.replace(/\[([^\]]+)\]\([^)]+\)/gi, '$1')).toLowerCase().includes(query.toLowerCase())}))
                              .sort((a,b)=>a.inText ? -1 : (b.inText ? 1 : 0)).map((query,i)=>{
                              let highlight = {background: 'rgb(196 255 229)', border: '1px solid #3d5322', color: '#3d5322'}
                              return <span key={'topictag'+i} style={{display:'inline-block',padding: '5px 10px', borderRadius: 20, border: 'solid 1px', margin: '5px 5px 0px 0px', ...(query.inText ? highlight : {})}}>{query.name}</span>
                            })}
                          </div>
                        </div> : null}
                        

                        {article.feedSettings.query && article.feedSettings.query.length>0 ? 
                          <div>
                            <h3 style={{marginBottom:10}}>Must be about</h3>
                            <div style={{scrollbarWidth: 'none', overflow:'scroll', paddingBottom: 110, maxHeight:400, '-webkit-mask':'-webkit-linear-gradient(bottom, transparent  10%, black, black, black)'}}>
                            {article.feedSettings.query.map(query=>({name:query, inText:(article.title+' '+article.article).toLowerCase().includes(query.toLowerCase())}))
                                .sort((a,b)=>a.inText ? -1 : (b.inText ? 1 : 0)).map((query,i)=>{
                                let highlight = {background: 'rgb(196 255 229)', border: '1px solid #3d5322', color: '#3d5322'}
                                return <span key={'topictag'+i} style={{display:'inline-block',padding: '5px 10px', borderRadius: 20, border: 'solid 1px', margin: '5px 5px 0px 0px', ...(query.inText ? highlight : {})}}>{query.name}</span>
                              })}
                            </div>
                          </div> : null}

                      </div> : 


                      null }
                  </div> : null}
    </div>
  }

  linkInText(target, articleText){
    return articleText.toLowerCase().includes(`[${target.anchorText.toLowerCase()}](${target.anchorURL.toLowerCase()})`)
  }

  copyToClip(id) {
    let doc = document.getElementById(id);
    let clonedDoc = doc.cloneNode(true);  // Create a deep copy of the element

    if (clonedDoc.firstElementChild) {
      clonedDoc.removeChild(clonedDoc.firstElementChild);
    }

    let firstElement = clonedDoc.firstElementChild;

    if (firstElement) {
      let bigLetterSpan = firstElement.querySelector('span.bigLetter');
      if (bigLetterSpan) {
        // Get the big letter and remove the span element
        let bigLetterContent = bigLetterSpan.innerText;
        bigLetterSpan.remove();

        // Find the first <p> element to add the big letter content
        let paragraphElement = firstElement.querySelector('p');
        if (paragraphElement && paragraphElement.firstElementChild) {
          // Prepend the big letter content while preserving formatting
          paragraphElement.firstElementChild.innerHTML = bigLetterContent + paragraphElement.firstElementChild.innerHTML;
        }
      }
    }

    let h1 = document.createElement("h1");
    h1.textContent = this.state.article.title;

    // Insert the new H1 element at the start of the cloned document
    clonedDoc.insertBefore(h1, clonedDoc.firstChild);

    let htmlContent = clonedDoc.innerHTML;

    function listener(e) {
      e.clipboardData.setData("text/html", htmlContent);
      e.clipboardData.setData("text/plain", htmlContent);
      e.preventDefault();
    }
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
  };

  temporarVFClickHandler(event){
    event.stopPropagation()  
    if(!this.state.showPicker){
      this.setState({showPicker:true})
    }          
  }

                  // <p style={{float:'left', padding:10}}><b>words</b> {this.props.article.words}</p>
  render(){
    let self = this
    let {user, writeAccess, smallWidth, puffFeed, mobile} = this.props
    let {editmode, article, status, expandMediapicker} = this.state
    
    let rtl = ['ar'].includes(article.language)

    let theFeed = user && user.feeds ? user.feeds.find(feed=>feed.id===article.feed) : null


    let usedWidgets = article.article ? article.article.split('\n').filter(s=>s.indexOf('{')===0).map(s=>s.hashCode()) : []

    let widgetInContent = article.widgets ? article.widgets.concat(article.videos).map(widget=>JSON.stringify(widget).hashCode()).some(r=> usedWidgets.includes(r)) : []

    // if(this.state.checkout){
    //   return <Checkout targetFeed={this.props.selectedFeed.id === 'search' ? null : this.props.selectedFeed.id} article={article} /> 
    // }

    let assignedToYou = status && status.type === 'assigned' && status.user && ((user.id && status.user.id === user.id) || ((status.user.name===user.name) && (status.user.avatar === user.avatar)))
    let orderPage = (this.props.user && this.props.user.writer) || (this.props.selectedFeed && this.props.selectedFeed.id==='search' && this.props.selectedFeed.menu.find(m=>m.value==='orders' && m.selected===true))
    let showOrderMenu = article.purchased && 
                        !article.locked && 
                        status &&
                        !['translate', 'manualbuild', 'rated'].includes(status.type) && 
                        ((orderPage && status.type==='submitted') || status.type!=='submitted') && 
                        !self.state.editmode && 
                        user && 
                        user.email && 
                        (!(this.props.selectedFeed && this.props.selectedFeed.id==='search') || orderPage)
    
    //Reject a human edit
    let rejectEdit= !orderPage && status && status.type==='submitted' && daysOld(status.date)<2 && !article.published
    showOrderMenu = showOrderMenu || rejectEdit

    let locked = article.locked || (status && status.type==='assigning' && user && user.email && (!(this.props.selectedFeed && this.props.selectedFeed.id==='search') || orderPage))
    let author = article.author

  
    let editable = user.admin || writeAccess || theFeed// || article.editable

    let readyToEdit = !article.author && editable


    // console.log(writeAccess)
    let puffId = article.id.split('/')[1]
    let actions = user && user.websites  && user.websites.length > 0 ? user.websites : []
    // let publishable = actions.filter(action=>action.apikey).map(action=>({...action, publish:true}))
    //.concat(publishable.length > 0 ? [{label:'Publish to'}].concat(publishable) : [])

    actions = actions.concat(actions.filter(link=>['linkedin','facebook'].includes(link.type) && link.organizations).reduce((a,link)=>a.concat(link.organizations.map(org=>({...link, title:org.title, target:org.id}))),[]))
    actions = actions.filter(action=>action.type==='facebook' ? action.target : true)

    actions = actions.map(ac=>({url:ac.url, type:ac.type, title:ac.title, target:ac.target}))

    let websites = actions.length > 0 ? [{label:"Publish Article"}]
                                        .concat(this.props.puffFeed && this.props.puffFeed.onSubmit ? this.props.puffFeed.onSubmit.map(action=>({...action, backgroundColor:'#ecffec'})) : [])
                                        .concat(actions)
                                        .map((link, i)=>
                              {
                                return  link.label ? 
                                              (<p style={{
                                                padding: '5px 16px 0px',
                                                fontWeight: 'bold',
                                                color: '#1a4156',
                                                borderRadius: 5
                                              }}>{link.label}</p>) : 

                                              (<p style={{backgroundColor:link.backgroundColor ? link.backgroundColor : undefined}}
                                               onClick={(e)=>{
                                              e.stopPropagation();
                                              if(link.channel){
                                                // console.log(link)
                                                self.puffAction(link)
                                              }else if(!link.apikey && !['linkedin', 'wp'].includes(link.type)){
                                                self.setState({ pushMenuLoading: true })
                                                self.puffAction({...link, puffId:puffId})
                                              }
                                            }} key={"link-"+i} className="list-item">
                                            <span>
                                              <span style={{...style.button,...style.round}} className={"fa fa-"+ (icons[link.type] ?  icons[link.type] : (link.channel ? 'slack' : (link.url && link.url.indexOf('@')>-1 ? "envelope-o" : (link.token ? "share" : "wordpress"))))} aria-hidden="true"/>
                                              {link.title ? link.title : (link.channel ? link.channel : link.url)}
                                            </span>
                                            {link.apikey || ['linkedin', 'wp', 'youtube'].includes(link.type) ? 
                                              <div className="list-item-expand animate">
                                                {link.target ? null : <div className="item-button" onClick={(e)=>{
                                                                              e.stopPropagation();
                                                                              self.setState({ pushMenuLoading: true })
                                                                              self.puffAction({...link, publish:false, puffId:puffId})
                                                                            }}>Draft</div>}
                                                <div className="item-button" onClick={(e)=>{
                                                  e.stopPropagation();
                                                  self.setState({ pushMenuLoading: true })
                                                  self.puffAction({...link, puffId:puffId, publish:true})
                                                }}>Publish</div>
                                            </div> : null}
                                        </p>)
                              }) : null


    //TODO temporary hotfix
    let widgetVF = (article.visualFocus && 
                    article.visualFocus.data && 
                    article.visualFocus.data[0].image) 
    let vfImage = widgetVF ? article.visualFocus.data[0].image : null
                    //&& article.visualFocus.data[0].image.width/article.visualFocus.data[0].image.height>1)
    let LandscapePhoto = !editmode && vfImage && vfImage.width/vfImage.height>1 && vfImage.type!=='logo' && article.title.split(' ').length<15 && !locked //&& article.article.length>10
    

    // LandscapePhoto=true
    author = maskAuthor(user, author)

    let authorName = author ? author.name : 'Monok Writer'
    let authorAvatar = editmode ? user.avatar : (author ? author.avatar : null)

    let byline = this.wrapTT((editmode ? 'This Article is being edited by '+user.name : (authorName ? 'This article is written by ' + authorName : 'This whole article has been automatically generated by an Artificial Intelligence and may contain errors.')), 
      <span className={'secondary'} style={{cursor:'pointer'}}>
      {(author || editmode) ? <img src={authorAvatar} style={{width: 21, marginTop:-4, boxShadow:'0px 0px 1px #000', borderRadius: '50%', marginRight: 5}}/> : "by"} <b>{editmode ? user.name : (author ? authorName : "Monok AI")}</b> - </span>)

    let single = true//LandscapePhoto || !widgetVF

    const popover = <Dropdown style={{background:'white', maxHeight:800, overflowY:'auto'}}>    
                         {editable ? [<p onClick={(e)=>{
                                        self.editArticle()
                                      }} key={"editarticle"} className="list-item">
                                        <span>
                                        <span style={{...style.button,...style.round}} className={"fa fa-pencil"} aria-hidden="true"/>
                                        Edit Article
                                        </span>
                                      </p>
                                      ,(user && user.admin ? <p onClick={()=>{
                                                                self.setState({status:{order:0}})
                                                              }} key={"orderedit"} className="list-item">
                                                                <span>
                                                                <span style={{...style.button,...style.round}} className={"fa fa-male"} aria-hidden="true"/>
                                                                Order Human Edit
                                                                </span>
                                                              </p> : null)
                                      ].filter(ac=>ac) : null}
                        <p onClick={()=>{
                            this.copyToClip('monokArticleContainer')
                            self.setState({pushMenu:false, addTarget:false})
                          
                          }} key={"link-clipboard"} className="list-item">
                            <span>
                              <span style={{...style.button,...style.round}} className={"fa fa-clone"} aria-hidden="true"/>
                              Copy to Clipboard
                            </span>
                          </p>
                        {user && (user.admin || user.curator) && !article.videoScript ? <p onClick={()=>{
                            // self.setState({pushMenu:false, addTarget:false})
                            let puffId = article.id.split('/')[1]
                            self.puffAction({generateVideo:puffId})
                          }} key={"generate-video"} className="list-item">
                            <span>
                              <span style={{...style.button,...style.round}} className={"fa fa-video-camera"} aria-hidden="true"/>
                              Generate Video
                            </span>
                          </p> : null}
                        {user && (user.admin || user.curator) ? <p onClick={()=>{
                            // self.setState({pushMenu:false, addTarget:false})
                            let puffId = article.id.split('/')[1]
                            self.puffAction({cancelPurchase:puffId})
                          }} key={"cancel-purchase"} className="list-item">
                            <span>
                              <span style={{...style.button,...style.round}} className={"fa fa-ban"} aria-hidden="true"/>
                              Cancel Purchase
                            </span>
                          </p> : null}
                          {user && article.feed!=='en' ? <p onClick={()=>{
                            // self.setState({pushMenu:false, addTarget:false})
                            let puffId = article.id.split('/')[1]
                            self.puffAction({lessLikeThis:puffId})
                          }} key={"less-like-this"} className="list-item">
                            <span>
                              <span style={{...style.button,...style.round}} className={"fa fa-thumbs-o-down"} aria-hidden="true"/>
                              Less stories like this
                            </span>
                          </p> : null}
                          {user && user.admin ? <p onClick={()=>{
                            // let puffId = article.id.split('/')[1]
                            // self.puffAction({move:puffId})
                          }} key={"move-to"} className="list-item">
                            <span>
                              <span style={{...style.button,...style.round}} className={"fa fa-paper-plane"} aria-hidden="true"/>
                              Move to
                            </span>
                          </p> : null}
                        {true ? null : <p onClick={()=>{
                                                  self.setState({pushMenu:false, addTarget:false})
                                                  self.props.sendPuff(article.id.split('/')[1], 'me')
                                                }} key={"link-me"} className="list-item">
                                                  <span>
                                                  <span style={{...style.button,...style.round}} className={"glyphicon glyphicon-user"} aria-hidden="true"/>
                                                  Copy to my profile
                                                  </span>
                                                </p>}
                        {this.state.pushMenuLoading ? <p style={{textAlign: 'center'}}><Spinner/></p> : websites}
                        {true ? null : <p onClick={(e)=>{
                                                    e.stopPropagation()
                                                    browserHistory.push({pathname:  window.location.pathname,search: '?configureFeed=true'})
                                                    // self.state.addTaret ? null : self.setState({addTarget:{value:''}})
                                                  }} key={"addadestination"} className="list-item">
                                                  {self.state.addTarget ? <span>
                                                    <p style={{
                                                      padding: 5,
                                                      fontWeight: 'bold',
                                                      color: '#1a4156'
                                                    }}>Add a Destination</p>
                                                    <input autoFocus 
                                                      onKeyDown={ (event) => {
                                                      if (event.key === 'Enter') {
                                                        if(self.state.addTarget.value && self.state.addTarget.value.trim().length>0){
                                                          self.props.saveProfile({websites:{url:self.state.addTarget.value}})   
                                                          self.setState({addTarget:false})
                                                        }
                                                      }
                                                    }}
                                                    value={self.state.addTarget ? self.state.addTarget.value : ''} 
                                                    onChange={e=>self.setState({addTarget:{value:e.target.value.toLowerCase()}})} 
                                                    className="plugin-title" 
                                                    placeholder="Email or Wordpress Url" 
                                                    style={{width: '100%',fontSize:'initial', fontWeight:'normal',padding:10, backgroundColor: 'white', textTransform:'lowercase'}}/>
                                                    </span>:
                                                    <span>
                                                      <span style={{...style.button,...style.round}} className={"fa fa-plus"} aria-hidden="true"/>
                                                      Add a Destination
                                                    </span>}
                                                  </p>}
                    </Dropdown>
                    
    let shareBar = (article.cluster || editmode || locked || showOrderMenu ? null : 
                  <div style={{marginBottom: 50}}>
                    {article.feed!=='en' ? null : [
                    <div style={{display: 'inline-block'}} onClick={self.stopPropagation}>
                      <FacebookShareButton url={this.getPuffUrl(article)}>
                        {this.wrapTT('Share on Facebook',
                          <span style={{...style.button,...style.round}} className={"fa fa-facebook"} aria-hidden="true"></span>)}
                      </FacebookShareButton>
                    </div>,
                    <div style={{display: 'inline-block'}} onClick={self.stopPropagation}>
                      <TwitterShareButton  url={this.getPuffUrl(article)} title={article.title} >
                          {this.wrapTT('Share on Twitter',
                            <span style={{...style.button,...style.round}} className={"fa fa-twitter"} aria-hidden="true"></span>)}
                      </TwitterShareButton>
                    </div>]}
                    

                 

                    {article.weight ? this.wrapTT(article.weight+ ' Views', <span className="badge"  style={{...style.button,...style.popularity}}>{this.translateRate(article.weight)} <span className="fa fa-eye" aria-hidden="true"/></span>) : null}
                 
                    {
                      // !article.author && editable ? [ 
                      //                   this.wrapTT('Edit this article',
                      //                   <span onClick={()=>{
                      //                     self.editArticle()
                      //                   }} style={{...style.button, padding:'0px 20px 0px 10px', backgroundColor:'black', color:'white',marginRight:15, textAlign:'center', width:'fit-content',display:'inline-block'}}>
                      //                     <span style={{margin:10}} className={"fa fa-pencil"} aria-hidden="true"/>Edit
                      //                   </span>)
                      //                   ,this.wrapTT('Order a human edit for this article',
                      //                   <span onClick={()=>{
                      //                     self.setState({status:{order:0}})
                      //                   }} className="highlight" style={{...style.button, padding:'0px 20px 0px 10px',marginRight:15, backgroundColor:'#3f51b5', color:'white', textAlign:'center', width:'fit-content',display:'inline-block'}}>
                      //                     <span style={{margin:10}} className={"fa fa-male"} aria-hidden="true"/>Order an Edit
                      //                   </span>)
                      //                   ] : null
                                      }

                    {self.wrapTT(self.props.user && self.props.user.name ? (self.state.pushMenu ? null : 'More Options') : 'You need to Sign In first',
                      <span onClick={(e)=>{
                      e.stopPropagation()
                      self.props.user && self.props.user.name ? self.setState({pushMenu:!self.state.pushMenu}) : self.gotoLogin()
                    }} style={{...style.button,...style.round, textAlign:'initial', width:80, display:'inline-block', float:'right'}} aria-hidden="true"> <p style={{display:'inline'}}>More <span style={{paddingLeft:5}} className={"fa fa-ellipsis-v"}  aria-hidden="true"></span></p>{this.state.pushMenu ? popover : null}</span>)}
                   
                    {
                      // <button onClick={(e)=>{
                      //        this.getScreenShot(document.getElementById('visualFocusContainer'), {}, (newAction)=>{
                      //         console.log("newAction")
                      //         console.log(newAction)
                      //         console.log(newAction.thumbImageData)
                      //         })
                      //   }}>SCREENSHOT</button>
                    }

                  </div>)
   
    // console.log("status")
    // console.log(status)
    // console.log(user)
    
    // console.log( article.author )
    // console.log(user)
 
    let submittedOrder = status && status.type==='submitted' && (orderPage || (user && user.writer && article.author && article.author.name === user.name))

    let warningMessage = (msg, color, icon="exclamation-circle")=><p style={{background: color ? color : '#c5e4ff', 
                                          color: 'black', 
                                          fontWeight: 'bold', 
                                          padding: 15,
                                          fontSize: 'large' 
                                           }}>
                                            <i className={'fa fa-'+icon} aria-hidden="true" style={{marginRight: 10}}></i>{msg}
                                          </p>

    let warnings = daysOld(article.date)>2 ? ["This Story is old"] : []
    warnings = status && status.order>0 && !article.sources.find(source=>source.words>350) ? warnings.concat("the sources for this story are short") : warnings 
    warnings = status && ['lessLikeThis','buildingVideo'].includes(status.type) ? [] : warnings

    let checkout = showOrderMenu ? 
    <div style={{background:submittedOrder ? 'white' : 'linear-gradient(to top, rgb(255, 255, 255), rgb(245 252 255))', boxShadow:submittedOrder ? null : 'rgb(66 139 202 / 27%) 0px 5px 40px -12px', minHeight:100, zIndex:article.loading ? 1100 : 10, display:'grid', gridTemplateColumns:'auto auto', padding:'30px 100px', margin:'30px -100px 0px', position: 'sticky', top: (rejectEdit ? undefined : 0), bottom:(rejectEdit ? -1 : undefined)}}>    
      <div style={{margin: "-30px -100px 30px -100px",gridArea: '1 / 1 / 1 / 4', color:'black'}}>
      {article && article.msg ? warningMessage(article.msg, 'rgb(255 204 197)') : null}
      {article && !article.author &&  article.feedSettings && article.feedSettings.query ? warningMessage(`The target topics are: ${article.feedSettings.query.slice(0,(article.status && article.status.type==='curated' ? 100 : 7)).join(', ')+(article.feedSettings.query.length>7 ? '...' : '')}`, 'rgb(208 210 255)', 'commenting') : null}
      {article &&  article.feedSettings && article.feedSettings.unverifiedFacts && article.feedSettings.unverifiedFacts.length>0 ? warningMessage(`There's ${article.feedSettings.unverifiedFacts.length} unverified facts (${article.feedSettings.unverifiedFacts.join(', ')}) in this article`, 'rgb(208 240 255)') : null}
      {(user.team==='review' || user.writer) && article.report ? this.score(article.report) : null}
      {rejectEdit ? null : (warnings.legth>0 && daysOld(article.date)>2 ? warningMessage(warnings.join(' and ')+'.'): null)}          
      </div>

        {status.type==='buildingVideo' ? <div style={{fontWeight:'bold', color:'#3f3f3f',marginRight:10}}>
          <p style={{fontSize:'x-large'}}>A Video is being generated based on this article...</p>
        </div> : status.type==='curated' ? 
          <div style={{color:'#3f3f3f',marginRight:10}}>
            <p style={{fontWeight:'bold', fontSize:'x-large'}}>Does this story match the target topics?</p>
            <p style={{fontSize:'large'}}>One or more of the target topics should be relevant for this story, the sources should be enough to write a <b>{orderType[article.status.order].words} word</b> article about</p>
            <div className="button blackButton" onClick={()=>this.placeOrder()} style={{float:'right'}}>Yes!</div>
            <div className="button blackButton" onClick={()=>this.placeOrder('cancel', null, ()=>{
              this.deselectPuff()
            })} style={{float:'right'}}>No</div>
          </div> 
        :
        status.type==='lessLikeThis' ? 
        <div style={{fontWeight:'bold', color:'#3f3f3f',marginRight:10}}>
          <p style={{fontSize:'x-large'}}>This story has been marked as off topic</p>
        </div> : status.type==='assigning' ? 
        <div style={{fontWeight:'bold', color:'#3f3f3f',marginRight:10}}>
          <i className="fa fa-user-o" aria-hidden="true" style={{position:'relative',float: 'left', fontSize: 'xxx-large', height: '100%', padding:'35px 25px 0px 0px'}}>
            <span style={{position:'absolute', top:66, left:29, borderRadius:'50%', boxShadow:'inset 0px 0px 0 6px white', fontSize:'23px'}} className="fa fa-spinner fa-pulse"/>
          </i>
          <p style={{fontSize:'x-large'}}>Waiting for editor to accept</p>
          <p>{orderType[status.order] ? orderType[status.order].desc : 'Loading...'}</p>
          {orderPage ? null :<p>(you can close this window)</p>}
        </div>
        :
        (status.type==='assigned' && status.user ? 
          <div style={{display:'grid', gridTemplateRows:assignedToYou ? 'auto 40px' : 'initial'}}>
              <div style={{fontWeight:'bold', color:'#3f3f3f', height: 'fit-content', margin: 'auto', minWidth:320}}>
            <img style={{float:'left', borderRadius:'50%',height:70, width:70, marginRight:10, border:'solid white', boxShadow:'0px 1px 5px -2px #000'}} 
                src={maskAuthor(user, status.user).avatar}/>
            {user && user.team==='review' ? null : <p style={{fontSize:'x-large'}}>
                          {assignedToYou ? <div>
                              <p>Assigned To You</p>
                              <p style={{color:'red'}}>You have max 2 hours to deliver</p>
                              {status.assigned ? <p style={{color:'grey', fontSize:'medium'}}>You have been holding this story since <TimeAgo style={{color:'red'}} date={status.assigned}  formatter={window.timeagoFormatter}/></p> : null}
                            </div> : 
                          <div>Currently being edited by <span style={{fontWeight:'bold'}}>{maskAuthor(user, status.user).name && maskAuthor(user, status.user).name.indexOf(' ') ? maskAuthor(user, status.user).name.split(' ')[0] : maskAuthor(user, status.user).name}</span>
                          </div>}
                        </p>}
            <p>{orderType[status.order] && user.writer ? orderType[status.order].desc : ''}</p>
          </div>
           {(assignedToYou || user.admin) ? 
                       (article.loading ? <p className="loadingText">Loading, please wait...</p> : <div>
                         {user && article.author && (user.admin || user.team==='review' || (article.author.avatar === user.avatar)) ? 
                          ((this.state.vfReady || !article.visualFocus || article.visualFocus.type==='video') ? <div className="button greenButton" onClick={()=>this.placeOrder('submitted')} style={{float:'right', marginLeft:50}}>Submit now</div> :
                            <div style={{padding:'5px 45px', float:'right'}}><Spinner style={{color:'#3f3f3f'}}/></div>) : null}
                         {user && (user.team==='review' || user.admin || user.curator || user.writer) ? <div className="button blackButton" onClick={()=>this.placeOrder()} style={{float:'right'}}>Cancel Job</div> : null}
                         {user && (user.team==='review' || user.admin || user.curator) && article.author && article.author.id!==user.id ? <div className="button blackButton" onClick={()=>this.placeOrder('reject')} style={{float:'right'}}>Reject Draft</div> : null}
                         <div className="button" onClick={()=>{self.setState({editmode:true})}} style={{float:'right'}}>Edit Article</div>
                     </div>) : null}
          </div> :
          (submittedOrder ? 

                    <div style={{fontWeight:'bold', color:'#58b051',fontSize: 'xxx-large', textAlign: 'center'}}> 
                      Article Submitted!
                      <img style={{height:140}}src={config.server[window.env].staticUrl+"img/icons/check-circle.gif"+"?x="+Math.random()}/>
                    </div> :

                    <div style={{fontWeight:'bold', color:'#3f3f3f'}}>
                      <p style={{fontSize:'x-large'}}><i className="fa fa-male" aria-hidden="true" style={{fontSize: 'smaller', paddingRight: 10}}></i>{rejectEdit ? ((status.rating!==0 ? 'Rate or ' : '') + 'Reject this edit') : 'Order an edit for this article'}</p>
                      {rejectEdit ? null : <p>{orderType[status.order] ? orderType[status.order].desc : null}</p>}
                      {rejectEdit && status.rating===0 ? <textarea value={status.comment} onChange={(e)=>this.setState({status:{...status, comment:e.target.value}})} className="plugin-title" style={{border:'solid 2px #b0b0b0',fontSize:'inherit', background:'white',textTransform:'none', width:'100%', height:100}} placeholder="Comment to editor"/> : null}
                      {rejectEdit ?
                      <Flip onChange={(value)=>this.setState({status:{...status, rating:value, comment:undefined}})} 
                            stars={true}
                            defaultValue={null}
                            value={status.rating} 
                            options={['Reject', 'Poor','Good', 'Very Good', 'Excellent']}/> : 
                      <Flip onChange={(value)=>this.setState({status:{...status, order:value}})} 
                            value={status.order} 
                            options={["medium","long","in depth"]}/>}
                      
                    </div>))}
        {status.type==='assigning' || user.team==='review' ? <div style={{display:'grid'}}>
            {orderPage && !user.curator && (user.team!=='review') ? 
            <div className="button blackButton" onClick={()=>{this.placeOrder('assigned')}} style={{margin:'auto'}}>Take Order</div> : 
            (this.props.selectedFeed && this.props.selectedFeed.id==='search' && !user.curator && user.team!=='review' ? null : <div className="button" onClick={()=>{this.placeOrder('cancel')}} style={{margin:'auto'}}>Cancel Order</div>)}
        </div> : 
          ((submittedOrder) || ['assigned','curated'].includes(status.type) ? null :
          <div style={{display:'grid', gridTemplateRows:'auto 40px'}}>
              {['lessLikeThis','buildingVideo'].includes(status.type) || rejectEdit ? <div></div> : 
                <div style={{color:'#3f3f3f',fontSize:'xx-large', textAlign:'right',backgroundColor:'rgb(83 170 213 / 17%)',padding:'0px 20px', marginLeft:'auto', height:'fit-content', width:'fit-content',borderRadius:30}}>
                  <i className="fa fa-eur" aria-hidden="true" style={{fontSize: 'smaller', paddingRight: 5}}></i>{orderType[status.order] ? orderType[status.order].price : null}
                </div>}
            <div>
              {['lessLikeThis','buildingVideo'].includes(status.type) || rejectEdit && !status.rating && status.rating!==0 ? null : <div className="button blackButton" onClick={()=>this.placeOrder(rejectEdit && status.rating>0 ? 'rated' : null)} style={{float:'right'}}>{rejectEdit ? 'Submit' :  'Order now'}</div>}
              {['lessLikeThis'].includes(status.type) ? 
                <div className="button" onClick={()=>{this.placeOrder('cancel')}} style={{float:'right'}}>Undo</div>:
                <div className="button" onClick={()=>{this.setState({status:null})}} style={{float:'right'}}>Cancel</div>}
            </div>
          </div>)}

    </div> : null

    shareBar = showOrderMenu ? null : shareBar

    let depthPhoto = true
    
    let fullCoverContainer = {zIndex: 10000, position: 'fixed', inset: 0, overflowY: 'scroll'}

    let visualFocusPicker = <MediaPicker 
                  update={this.update}
                  user={user}
                  className={!expandMediapicker && locked ? 'blueBack' : null}
                  article={article}
                  selected={article.visualFocus && article.visualFocus.data && article.visualFocus.data[0] && article.visualFocus.data[0].image ? JSON.stringify(article.visualFocus.data[0].image).encode() : (article.visualFocus ? JSON.stringify(article.visualFocus).encode() : null)}
                  vfPicker={true}
                  // vfPicker={!expandMediapicker && locked ? false : true}
                  style={editmode ? {padding:'30px 60px 60px 100px', margin:'-100px -100px 0px'} : 
                    (expandMediapicker && locked ? 
                      {padding: '50px 0px', maxHeight:'none',whiteSpace:'initial'} : 
                      {padding: '60px', borderRadius:'none', margin: mobile ? 'none' : '0px -100px',minWidth:'none', width:locked ? 'none' : '70vw'})}
                  locked={locked}
                  widgetFilter={locked ? ['video', 'instagram','spotify','facebook','reddit','tiktok', 'twitter', 'quote'] : null} 
                  omit={expandMediapicker && locked ? ['video', 'instagram', 'spotify','facebook','reddit','tiktok', 'twitter','quote'] : null}
                  previewAmount={expandMediapicker && locked ? 30 : null}
                  editmode={editmode || locked} 
                  onClick={!expandMediapicker && !locked ? (e)=>{
                    e.stopPropagation()
                  } : ()=>{
                    this.setState({expandMediapicker:true})
                  }}
                  />
    let toBeTranslated = (!user.curator && !user.writer) && (article.status && article.status.language && article.status.language!==article.language)
    let inOrder = ((!user.admin && !user.curator && !user.writer) && article.status && (['assigning', 'assigned'].includes(article.status.type) || toBeTranslated))

    return (
        <Element   
        key={'Big-'+article.id} 
        name={this.props.id} 
        onClick={()=>{(this.state.pushMenu || this.state.showPicker) ? this.setState({showPicker:false, pushMenu:false, addTarget:false}) : null}}>
          {editmode && !smallWidth ? this.navbar({paddingBottom: 90, margin:'0px -300px 0px 0px', float:'left', maxWidth:300, textAlign: 'left', top:30}, ['nav','seo']) : null}
          {editmode && smallWidth ? this.navbar({backgroundColor: 'rgb(252 252 252 / 95%)', boxShadow: '0px 1px 20px -2px #0000001f'}) : null}
        <div  style={{
                ...style.wrapper,
                ...(mobile? style.mobile : style.puff),
                 ...this.props.style,...((editmode) ? {overflow:'hidden', maxWidth:'initial', margin:'initial',borderRadius:0, ...(this.props.fullPuff ? fullCoverContainer : {})} : {margin:mobile ? 'initial' : '30px auto 200px'}),
                ...(article.loading ? {overflow:'hidden', maxHeight:'100vh',marginBottom: 0} : {}),
                 ...(this.props.mapStyle ? this.props.mapStyle : {})}} 
              className={'active-bigpuff' + (this.props.mapStyle ? ' side-article-wrapper' : '')} 
              onMouseDown={(event)=>{
                // console.log('LOL')
                event.stopPropagation()
              }}>
       
        {article.loading ? <div style={{position: 'fixed', left:0, top:0, width:'100%', height:'110vh', background: '#00000063', zIndex: 1000}}></div> : null}
           
          <div className="puffNavbar" style={{height:0}}>{self.buyButton(article, user, editable)}</div>
           
           
           {!widgetVF && !editmode ? 
            <Widget transitions={true} type={'header'} opened={true} selected={true} focus={true} article={article} widget={article.visualFocus}/>
            : null}

              {
                 editmode ? null : <div style={{padding:!widgetVF ? '30px 100px 0px 100px':'30px 100px', textAlign:rtl ? 'right':undefined}}>
                   {toBeTranslated ? <span style={{float:'left', padding:'5px 10px',backgroundColor:'#2196f3'}} className="badge">{window.languages.find(lang=>lang.code===article.status.language).title}</span> : null}
                   {this.header(article, editmode)}{byline}
                   <TimeAgo className="secondary" style={style.timestamp} date={article.updated ? article.updated : article.created}  formatter={window.timeagoFormatter}/>
                   {user.writer ? null : this.getLocation(article)}
                 </div>
              }

            {editmode ? null : (article.images && false ? <MonokVideo feed={puffFeed} article={article} animate={true}/> :
                                      <VfImage 
                                        id={'visualFocusContainer'}
                                        article={article}
                                        user={user}
                                        bigPuff={true} 
                                        onReady={()=>{
                                          this.setState({vfReady:true})
                                        }}
                                        feed={theFeed ? theFeed : puffFeed}
                                        vf={article.visualFocus} 
                                        scroll={this.state.scroll}/>)}
         
          
          <div style={style.row}>
        

              <Col  md={single ? null : 7} style={{paddingLeft:0, paddingRight:0, maxWidth: editmode ? 800 : 600, margin: 'auto', marginBottom:60, position:'relative'}}>
                  
                  

             
                  {
                     !editmode ? null : <div style={{paddingTop:30,textAlign: rtl ? 'right' : undefined}}>
                       {this.header(article, editmode)}{byline}

                       <TimeAgo className={"secondary"} style={style.timestamp} date={article.date}  formatter={window.timeagoFormatter}/>
                       {user.writer ? null : this.getLocation(article)}
                     </div>
                  }

                  {article.byline ? <div>
                                      <p style={style.article}>
                                        {shareBar}
                                        <MessageBubble type="alert" icon='exclamation-triangle' text="This is a very old story"/> 
                                        {article.article.trim()}.. <br/>  
                                        <span style={style.byline} >{article.byline}</span>
                                      </p>
                                    </div> : null}
                  
                  {
                    // article.sources.length===1 ? <span className="button blackButton" onClick={()=>{
                    //                     this.setState({checkout:true})
                    //                 }}>Build AI Article</span> : null
                  }
             
                  {article.cluster ? <MessageBubble sticky={true} type="alert" icon='exclamation-triangle' text="This is a preview and not a generated article"/> : null}
                  {showOrderMenu && !rejectEdit ? checkout : null}
                  {!article.byline ? 
                  <Article update={this.update} 
                          editmode={editmode}
                          rtl={rtl}
                          user={user}
                          style={inOrder ? {height:300, overflow:'hidden'} : null}
                          article={article}
                          includeAd={article.feed==='en' && !(user && user.name)}
                          locked={!(user.curator || user.admin) && (locked || article.cluster)} 
                          blur={article.cluster || inOrder}
                          text={article.article}>
                    
                    
                            {!widgetVF && editmode ? 
                            <div onClick={(e)=>this.temporarVFClickHandler(e)}  onMouseUp={(e)=>this.temporarVFClickHandler(e)} onMouseDown={(e)=>this.temporarVFClickHandler(e)}>
                              <Widget style={{pointerEvents: this.state.showPicker ? 'all' : 'none', borderRadius:null, width:'initial', borderRadius:0, paddingBottom:0, paddingTop:article.visualFocus && article.visualFocus.type==='video' ? '56%' : undefined}} transitions={true} opened={true} selected={true} focus={true} article={article} widget={article.visualFocus}/>
                            </div>
                            : null}
                            {editmode ? <VfImage 
                                        id={'visualFocusContainer'}
                                        article={article}
                                        editmode={true}
                                        user={user}
                                        update={this.update}
                                        bigPuff={true} 
                                        style={{zIndex:'10', cursor:'pointer'}}
                                        onClick={()=>this.setState({showPicker:!this.state.showPicker})}
                                        feed={theFeed ? theFeed : puffFeed}
                                        vf={article.visualFocus} 
                                        scroll={this.state.scroll}/> : null}
                            
                            {shareBar}
                            {(article && article.feedSettings ? !article.feedSettings.disableImages : true) && !locked && editmode && (!article.author || this.state.showPicker || !article.visualFocus || (article.visualFocus && article.visualFocus.supervised)) ? 
                                <div style={{height:155}}>{visualFocusPicker}</div> : 
                            null}
                  </Article> : null}

                  {showOrderMenu && rejectEdit ? checkout : null}


                  {editmode && smallWidth ? this.navbar({margin: 'auto', padding: '0px 20px 0px 0px', zIndex: 0,position:'relative'},['seo']) : null}

              </Col>

              <Col md={null}  style={{padding:0, maxWidth: editmode ? 800 : 600, margin: 'auto'}}>
                   
                    {user && user.writer ? null : <div style={style.categoryContainer}>
                      {article.categories ? article.categories.filter(cat=>!config.blackcat.includes(cat.name)).slice(0,5).map((category,i)=>{
                        return <div key={i+'-category-big-'+article.id}  className="badge" style={style.buttonLined}>{category.name}</div>
                      }) : null}
                    </div>}

                    {article.published && user && !user.curator ? 
                      <Sources onPublish={source=>{
                        // console.log(source)
                       let candidate = user.websites ? user.websites.find(link=>source.url.includes(link.url)) : null
                        
                        if(candidate){
                          let puffId = article.id.split('/')[1]
                          self.puffAction({...candidate, puffId:puffId, publish:true})
                        }
                      }} 
                      type='published' 
                      thumbsize={100} 
                      open={this.props.single ? 2 : 1} 
                      article={article}/> : null}
                    {inOrder|| !article.sources || article.sources.length<1 || (!user.admin && !user.writer && !user.curator && !editmode) ? null : <Sources user={user} disabled={!(user.admin || user.curator || user.team==='review') && status && status.type==='assigning'} thumbsize={100} open={this.props.single ? 2 : 1} article={article}/>}
              </Col>
            </div>
           
              <Comments big={false} open={this.props.single ? 50 : 1} style={{width: '100%',margin:'30px auto auto', maxWidth:800, paddingRight: 30, paddingLeft: 30}} article={article}></Comments>

              {this.props.single ? <img className="whiteLogo" src={config.server[window.env].staticUrl+"img/icons/monok.svg"} style={{filter: 'invert(80%)', margin: 'auto', marginTop: 20, display: 'inherit'}}/> : null}
        </div>

	      </Element>
	    );
	}

  score(report){
    const reportS={
      notice:{
        background: 'red',
        color: 'white',
        borderRadius: 10,
        padding: '0px 10px',
        width: 'fit-content',
        margin: 10
      }
    }
    try{
      let reportObj = JSON.parse(report)

      return <div style={{maxHeight: 300, overflow: 'scroll'}}>
      <p style={{
        background:'black',
        padding: '0px 10px',
        color:'white',
        width: 'fit-content',
        borderRadius: 10,
        margin:10,
      }}>Changed: {Math.round(reportObj.changed*100)}%</p>
      <p style={{
        background:'green',
        padding: '0px 10px',
        color:'white',
        width: 'fit-content',
        borderRadius: 10,
        margin:10,
      }}>Score: {Math.round(reportObj.score*100)}</p>
      {reportObj.onTopic===false ? <p style={reportS.notice}>This story is Off-Topic</p> : null}
      {reportObj.expired && reportObj.expired.expired ? <p style={reportS.notice}>This story has expired, upcoming dates are in the past: {reportObj.expired.explanation}</p> : null}
      {reportObj.competitors ? reportObj.competitors.map(comp=><p style={reportS.notice}>Mention of competitor: {comp}</p>) : null}
      {reportObj.localization ? reportObj.localization.map(issue=><p style={{...reportS.notice, background:'#2196F3'}}>{issue}</p>) : null}
      {reportObj.textQuality.filter(p=>p.evaluation && Object.values(p.evaluation).filter(grade=>['poor','fair'].includes(grade.toLowerCase())).length>0).map(p=>
        <p style={{...reportS.notice, background:Object.values(p.evaluation).find(grade=>grade.toLowerCase()==='poor') ? 'red' : 'orange'}}>Issue with Paragraph {p.paragraph}</p>)}
      {reportObj.factCheck && this.props.user && this.props.user.team==='review' ? reportObj.factCheck.map(fact=><p style={{...reportS.notice, background:fact.report && fact.report.toLowerCase().startsWith('yes') ? '#45bc45' : '#d68a00'}}><b>{fact.fact}</b> {fact.report}</p>) : null}
      </div>
    }catch(e){
      // console.log(e)
      return <p>Failed to read report</p>
    }
  }

  fetchFull(){
    let id = this.state.article.id
      get('puff/'+(id.includes('/') ? id.split('/')[1] : id),(err, res)=>{
          this.setState({article:res.body})
      })
  }

  editArticle(){
    if(this.props.fullPuff){
      this.fetchFull()
    }
    this.setState({editmode:true})
  }

  autoSave(partial){
    let {editmode, update} = this.state

    if(editmode && ((update && !update.visualFocus) || (update && update.visualFocus && !update.visualFocus.supervised))){
      this.save(partial)
    }
  }

  save(partial){
    let {article, update} = this.state

    let changes = JSON.parse(JSON.stringify(update))
    let newArticle = JSON.parse(JSON.stringify(article))


    if(changes && changes.visualFocus && changes.visualFocus.supervised){
      delete changes.visualFocus
      delete newArticle.visualFocus
    }

    if(!changes || Object.keys(changes).length<1){
      if(partial && Object.keys(partial).length>0){
        this.setState(partial)
      }
      return
    }

    if(this.props.user && this.props.user.avatar){
      let author = {
        "name": this.props.user.name,
        "avatar": this.props.user.avatar
      }
      changes.author = author
      newArticle.author = author
      newArticle.updated = new Date()
    }

    this.setState({saveStatus:'loading'}, async ()=>{
      // try{
        let res = await this.props.savePuff(article.id.split('/')[1], changes)
  
        if(res && res.response && res.response.status ==='success'){
          // console.log('SUCCESS')
          this.props.setPuff({id:article.feed}, newArticle)
          this.setState({saveStatus:'',update:{}, article:newArticle,...(partial ? partial : {})})//editmode:false,
        }else{
          // console.log('FAILED')
          this.setState({saveStatus:'failed'})//editmode:false,
        }

      // }catch(e){
      //   console.log(e)
      //     this.setState({saveStatus:'failed'})//editmode:false,
      // }

    })//editmode:false,
    // window.virtualText=null
    // console.log(changes.text)
  }

  shouldCollage(article){
    return false//article.visualFocus && article.visualFocus.type!=='video'
  }

  placeOrder(type, partial, callback){
    let {article, status} = this.state
    let newArticle = JSON.parse(JSON.stringify(article))
    let newStatus = type==='cancel' ? null : {...status, type: type ? type : 'assigning', ...(partial ? partial : {})}
    // newArticle = {...newArticle, status:newStatus}

    // if(type==='submitted' && this.shouldCollage(article)){
    //   this.getScreenShot(document.getElementById('visualFocusContainer'), newStatus, (statusWithPNG)=>{
    //     this.props.orderPuff(article.id.split('/')[1], statusWithPNG, {puff:{id:article.id}, feed:{id:article.feed}})
    //   })
    // }else{
      this.props.orderPuff(article.id.split('/')[1], newStatus, {puff:{id:article.id}, feed:{id:article.feed}})
    // }

    this.setState({article:{...article, loading:true, msg:null}}, callback ? callback : undefined)
  }

  buyArticle(id){
    post('buy', {id:id}).then(data=>{
      if(data && data.update){
        let newArticle = {...this.state.article, ...data.update}
          // this.props.article = newArticle
          this.props.setPuff({id:this.state.article.feed}, newArticle)
          this.props.setFocusedPuff(id, newArticle)
          this.setState({article:newArticle})
          // console.log(this.state.article.feed)
          // console.log(id)
          // console.log(newArticle)
      }
    })
  }

  puffAction(action){
   
    let self = this
    let {article} = this.state

    let sendRequest = (action)=>{
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json'},
          body: JSON.stringify({action: action, text:'*'+article.title+'*\n'+article.article})
      };
      let url = config.server[window.env].url+config.server[window.env].apiUrl.slice(1)+'puffAction'
     
      fetch(url, requestOptions)
          .then(response => response.json())
          .then(data => {

            let newState = { pushMenu: false, pushMenuLoading: false, addTarget:false }

            if(data && data.postID && !action.publish){
              window.open(self.getWordPressDraftUrl(action.url, data.postID), "_blank")
            }
            // console.log(data)
            if(data && data.partial){
              newState.article = {...article, ...data.partial}
            }
            this.setState(newState)
      });
    }

    // console.log(article.visualFocus)
    // if((action.token || action.apikey) && this.shouldCollage(article)){
    //   // console.log(action)
    //   this.getScreenShot(document.getElementById('visualFocusContainer'), action, (newAction)=>{
    //     // console.log("newAction")
    //     // console.log(newAction)
    //     // console.log("action")
    //     // console.log(action)
    //     sendRequest(newAction)
    //   })
    // }else{
      // console.log("OLDMETHOD")
    sendRequest(action)
    // }

  }

  // subreddits(subreddits){
  //   return null
  //     let article = this.state.article
  //     //TODO: when this becomes more complex, refactor to its own component
  //     return subreddits.map((subreddit,i)=>{
  //       return <div key={i+'-subreddit-big-'+article.id}  style={style.button}>
  //               <a rel="nofollow" href={'https://www.reddit.com/r/'+subreddit.subreddit+'/comments/'+subreddit.post} target="_blank">
  //                 <span className={"fa fa-reddit"} aria-hidden="true"></span>
  //                 &nbsp;r/{subreddit.subreddit}
  //                 <div style={style.timestamp}>{subreddit.post}</div>
  //               </a>
  //             </div>
  //     })
  // }


  addTwitterImages(article){
    let safe = ['video_thumb']
    let compareCorpus = article.title + ' ' + article.sources.map(source=>source.title).join(' ')
    
    let existsInCorpus = (personName, username) =>{
      // return (personName + ' ' + (username ? username : '').replace(/([A-Z])/g, " $1")).split(' ').find(name=>name.length>0 && compareCorpus.includes(name))
      return compareCorpus.includes(personName)
    } 
    
    // console.log(compareCorpus)

    let p = article && article.widgets ? article.widgets.filter(widget=>widget.images).reduce((a,b)=>{
      let data = b.data && b.data[0] ? b.data[0] : {}
      let personName = (data.name ? b.data[0].name : b.username)
      // console.log(personName.split(' '))
      let nameExistsInCorpus = existsInCorpus(personName, b.username)
      //Add Safe Images
      let k = a.concat(b.images.filter(url=>{
                                  return nameExistsInCorpus || safe.find(keyword=>url.includes(keyword))
                                })
                                .map(url=>({type:'person', copyright: '@'+b.username+' (twitter)', description:(data.text ? data.text : 'An image from a tweet by '+personName), url:url})))
      
      //Add uncertain as collage
      k = k.concat(b.images.filter(url=>{
                                  return true//!nameExistsInCorpus && !safe.find(keyword=>url.includes(keyword))
                                })
                                .map((url, i, arr)=>({
                                  type:'collage', 
                                  copyright: '@'+b.username+' (twitter)', 
                                  description:(data.text ? data.text.replace(/http.+($|\s|\n)/i,'').trim() : 'A tweet by '+personName), 
                                  url:url,
                                  encoded:JSON.stringify([
                                    {
                                      url:url, 
                                      class:'darkQuote photoQuote', 
                                      text:data.text ? data.text.replace(/http.+($|\s|\n)/i,'').trim() : '',
                                      speaker:data.name,
                                      username:'@'+b.username,
                                      avatar:b.avatar
                                    }, 
                                    {
                                      url:arr && arr.length>0 ? arr.find(oUrl=>oUrl!==url) : b.avatar, 
                                      class:'darkQuote'
                                    }]).encode()
                                })))
      // console.log(k.map(pp=>JSON.parse(pp.encoded.decode())))
      //Add Avatar
      k = nameExistsInCorpus && !k.find(img=>img.url===b.avatar) ? k.concat({type:'person', copyright:'@'+b.username+' (twitter)', description:personName+ ' twitter avatar', url:b.avatar}) : k
      return k
    },[]) : []
    return p
  }

  


 
  // getWordPressPushUrl(article, url){
    // let dockerLocal = '&source=https://www.monok.com'
    // let urlParams = window.env==='development' ? dockerLocal : '&source='+location.protocol+"//"+location.host
    // if(url.indexOf('http')===-1){
    //   url = '//'+url
    // }
    // return url+(url.charAt(url.length-1)==='/' ? '' : '/' )+"wp-admin/post-new.php?monokid="+article.id.split('/')[1] + urlParams
  // }

  getWordPressDraftUrl(url, postID){
    if(url.indexOf('http')===-1){
      url = '//'+url
    }
    return url+(url.charAt(url.length-1)==='/' ? '' : '/' )+"wp-admin/post.php?action=edit&post="+postID
  }


  update(value){

    //Auto Save
    if(this.state.editmode && this.props.user && this.props.user.writer){
      if(autosaveTimer){
        window.clearTimeout(autosaveTimer)
      }
      autosaveTimer = setTimeout(() => {
          this.autoSave()
      }, "3000");
    }

    let newArticle = JSON.parse(JSON.stringify(this.state.article))
    let newUpdate = JSON.parse(JSON.stringify(this.state.update))

    Object.keys(value).forEach(key=>{
      if(key==='article'){
        //Remove stylin artifacts - html vs markdown
        let articleText = value[key]
          .replace(/## \\?##\s*/g,'## ') //remove markdown header issue
          .replace(/\[\s*\]\(.+?\)/g,'') //remove empty anchor texts
          .split('\n')
          .filter((line,i,arr)=>{
            if(line.startsWith('{') && arr.indexOf(line)!==i){
              return false
            }
            return line.toLowerCase().trim()!=='null' && !/^###?\s*$/.test(line)
          }).map(line=>{
            if(!line.startsWith('{') && line.includes('](')){
              let urls = [...line.matchAll(/\[(.+?)\]\((.+?)\)/gi)]
              // .map(match=>match && match[1] ? match[1] : null).filter(m=>m)
              // console.log(urls)
              urls.forEach(url=>{
                if(url[1] && url[2] && (!url[2].trim().startsWith('http') || url[2].trim().includes(' '))){
                  line = line.replace(url[0], url[1])
                }
              })
            }
       

            return line
          }).join('\n')
        newArticle[key] = articleText
        newUpdate[key] = articleText
      }else{
        newArticle[key] = value[key]
        newUpdate[key] = value[key]
      }
    })

    this.setState({article:newArticle, update:newUpdate})
  }

  getLocation(article, style){
    var text = null
    var place = null
    style = style ? style : {}
    if(article.place && article.place.data && article.place.data[0] && article.place.data[0].description){

      place = article.place.data[0]
      text = place.description
      if(text.indexOf(',')>-1){
        var temp = text.split(',')
        text = temp[0]+", "+temp[temp.length-1]
      }

      if(place.text && text.length>20){
        text = place.text
      }

    }else if(article.place && article.place.name){
      place = {lat:article.place.location.lat, lng:article.place.location.lon}
      text = article.place.name
    }

    if(text && text.length<30){
      return (<span onClick={this.gotoMap.bind(this, place.lat, place.lng)} style={{...{fontSize: 14 ,cursor: 'pointer'}, ...style}} className={'secondary'}> - <Glyphicon glyph="map-marker" /> {text}</span>)
    }else{
      return null
    }
  }

  


  gotoMap(lat, lng, e){
    e.stopPropagation();
    browserHistory.push({pathname: '/map',search: '?lat='+lat+'&lng='+lng}) 
  }

  gotoLogin(e){
    browserHistory.push({pathname: window.location.pathname, query:{signin:true}}) 
  }


  getPuffUrl(article){
    var url = config.server[window.env].url + "puff/"+article.id.split("/")[1]
    return url
  }

  isMobile(){
     return window.innerWidth<800
  }

  wrapTT(tt, child,keyId){
    if(!tt || this.isMobile()){
      return child
    }
    const ttDom = (<Tooltip id="tooltip"><strong>{tt}</strong></Tooltip>);
    return (<OverlayTrigger key={keyId} placement="bottom" overlay={ttDom}>
            {child}
            </OverlayTrigger>)
    
  }
 

}

const mapStateToProps = function(store) {
  return {
    // selectedPuff: store.puff.selectedPuff,
    profile: store.profile,
    user:store.auth.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfile:(newProfile)=>dispatch(saveProfile(newProfile)),
    sendPuff:(id, to)=>dispatch(sendPuff(id, to)),
    savePuff:(id, puff)=>dispatch(savePuff(id, puff)),
    orderPuff:(id, status, options)=>dispatch(orderPuff(id, status, options)),
    buyPuff:(id, puff)=>dispatch(buyPuff(id)),
    setFocusedPuff:(id, puff)=>dispatch(setFocusedPuff(id, puff)),
    setPuff:(feed, puff)=>dispatch(setPuff(feed, puff))
  }
}



const style = {
	wrapper:{
		marginTop:30, 
    backgroundColor:'#fcfcfc'
    // overflow:'hidden'
		// display:'table'
	},
	puff:{
	    borderRadius: 10,
	    marginRight:5,
	    marginLeft:5,
	    paddingBottom:30,
	    // boxShadow: 'rgba(136, 136, 136, 0.5) 0px 2px 6px', //'0px 1px 4px #888888'
	    // overflow: 'hidden'  
  }, 
  mobile:{
    paddingBottom:50

  },
  arrowButton:{
    position:'absolute',  
    marginTop: 50
  },
  title:{
    fontSize: '30px',
    marginBottom: '5px',
    marginTop:'0px',
    fontWeight: 600,
    fontFamily: "'Lora', serif"// "'Cabin', sans-serif" 
  },
  "byline":{
    color: 'grey',
    fontStyle: 'italic'
  },
  article:{
    marginTop:'10px',
    marginBottom:'10px',
    fontSize: '12pt',
    fontFamily: "'Open Sans', sans-serif", // "'Open Sans', sans-serif"
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap'
  },
  content:{
    padding: 30,
    paddingBottom: 0,
    width:'60%',
    float:'left'
  },
  timestamp:{
    fontSize: '14px'
  },
  button:{
    marginRight: '5px',
    padding: '10px',
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize:'large',
    fontWeight:100,
    color: 'rgb(153, 153, 153)'
  },
  round:{
    width:'34px',
    height:'34px',
    textAlign: 'center',
    borderRadius: '50%',
    backgroundColor:'transparent',
    // fontSize: 'larger',
    padding:8
  },
  popularity:{
    backgroundColor:'#d83d38',
    padding: 8,
    borderRadius: '50%',
    height: 34,
    textAlign: 'center',
    backgroundColor: 'transparent',
    fontSize: 'large',
  },
  row:{
  },
  buttonLined:{
    marginRight: '5px',
    borderRadius: '20px',
    padding:9,
    backgroundColor:'transparent',
    borderStyle: 'solid',
    borderWidth: 1,
    height:34,
    fontSize:'small',
    marginTop: 5
  },
  categoryContainer:{
        textAlign: 'center',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // display: 'table',
    marginTop:20
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BigPuff);

