import React from 'react';
import request from 'request';
import Flip from './flip.jsx';
var config = require('../../../settings/config.json');

const icons = window.icons
const callbackUrl = encodeURIComponent("https://www.monok.com/profile/settings")

const integrationConnections = {
  facebook:`https://www.facebook.com/v19.0/dialog/oauth?client_id=387411317431132&redirect_uri=${callbackUrl}&state=lkfdjglkdjnmrglifdvjnjfkvnhsdwqewqecewfdsdFA&scope=pages_manage_posts,pages_show_list,business_management,pages_read_engagement`,
  twitter:`https://twitter.com/i/oauth2/authorize?response_type=code&client_id=NUJTLXBCZDdvSnZjTVZEcFZtdmQ6MTpjaQ&redirect_uri=${callbackUrl}&scope=media.write%20offline.access%20tweet.read%20tweet.write%20users.read&state=lkjSAHDFasdfuy7y678ydskfjhasdf6gtwitter&code_challenge=challenge&code_challenge_method=plain`,
  linkedin:`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77x1zp81pk71z2&redirect_uri=${callbackUrl}&state=lkjSAHDFasdfuy7y678ydskfjhasdf6gHGSDg&scope=r_emailaddress%20r_liteprofile%20w_member_social%20rw_organization_admin%20r_organization_social%20r_basicprofile%20w_organization_social%20w_member_social`
}

class Integrations extends React.Component {

    constructor(props) {
        super(props);

        this.state = {status:{}, targets:props.targets, show:10}
     }

    componentDidMount(){
        this.refreshIntegrations()
     	// this.setState({mounted:true})
    }

    componentDidUpdate(prevProps, prevState){
      if(prevState.targets && (JSON.stringify(prevState.targets) !== JSON.stringify(this.state.targets))){ 
        this.refreshIntegrations()
      }
    }

    componentWillReceiveProps(nextProps) {
      if(nextProps.targets!==this.props.targets){
        this.setState({targets:nextProps.targets});
      }
    }

    refreshIntegrations(){
  
        let {user, onNewTarget} = this.props
        let {targets, status, show} = this.state
        if(targets){
          targets.slice(0, show).forEach((integration, i)=>{
            if(integration.url && !status[integration.url] && integration.type==='wp' && (user.apikey||integration.apikey)){

              const pluginInfoUrl = config.server[window.env].url + "api/v1/plugininfo" + "?url=" + integration.url+'&apikey='+(integration.apikey ? integration.apikey : user.apikey)
              status[integration.url] = {loading:true}
              this.setState({status:status}, ()=>{
              
                request(pluginInfoUrl, (error, response, body)=>{
               
                  
                  if(!error && response.statusCode == 200 && body){
                    let data = body.startsWith('{') ? [body] : body.match(/{.+}/gms)
                    data = data && data[0] ? JSON.parse(data[0]) : null
                    status[integration.url] = data && data.version ? data : {failed:true, loading:false}
              
                    onNewTarget ? onNewTarget(status[integration.url]) : null
                    this.setState({status:status})
                  }else{
                    status[integration.url]={failed:true, loading:false}
                    this.setState({status:status})
                  }
                })
              })
          
            }
          })  
        }
    }
    
    update(newTargets){
      if(this.props.onUpdate){
        this.props.onUpdate(newTargets)
      }
      this.setState({targets:newTargets})
    }

    render(){
        let {targets, status, show} = this.state
        let {style, user, simple, onboard, feed} = this.props
        let options = user.websites ? user.websites.filter(target=>!targets.find(oTarget=>oTarget.url===target.url)) : []
        // let websites = this.state.targets
        // options = options.concat(options.filter(link=>link.type==='linkedin' && link.organizations).reduce((a,link)=>a.concat(link.organizations.map(org=>({...link, title:org.title, target:org.id}))),[]))
        // <a style={{cursor:'pointer', margin: 10, display: 'block'}} onClick={()=>this.setState({edit:'accessRights'})}>Add another website/target</a>
        // console.log(targets)
        return <div >
                {targets ? targets.slice(0, show).map((link, i)=>{
                  let wp = link.type==='wp'
                  let title = link[['name','title', 'channel', 'url','id'].find(attr=>link[attr])]
                
                  let fullInfo = status[link.url]

                  let vf = ['featured image in theme', 'featured image in article']
                  // let imgDesc = ['no image descriptions', 'description inside Image', 'description under image']
                  let sections = ['automatic section']
                  let authors = [{name:'automatic author', id:'automatic'}]
                  let postTypes = ['automatic type']
                  let tags = ['automatic tag', 'no tags', 'create tags']
                  let embedMethods = ['default embed', 'shortcode embed', 'html embed']
                  let languages = window.languages
                  let photoQuality = ['low image quality', 'medium image quality', 'high image quality']
                  let textLength = ['teaser text', 'short description', 'full article text']
                  let formatting = ['formatted text', 'unformatted text']
                  let hashtags = ['with hashtags', 'no hashtags']
                  // let media = ['link preview', 'image highlight', 'slideshow']
                  let media = ['link preview', 'image highlight']
                  let aspectRatio = ['square', 'portrait', 'landscape']
                  let params = ['UTM parameters', 'No UTM']
                  let schedule = ['immediately', 'a day later', '3 days later','a week later', 'two weeks later']

                  let organizations = []


                  if(['linkedin', 'facebook'].includes(link.type) && user.websites && user.websites.find(web=>web.organizations && web.url === link.url)){
                    organizations = user.websites.find(web=>web.organizations && web.url === link.url).organizations
                    if(['linkedin'].includes(link.type)){
                      organizations = [{title:'Personal Profile'}].concat(organizations)
                    }
                  }
                  // console.log(web.type)
                  // console.log(organizations)
                  if(fullInfo){
                    // console.log(fullInfo)
                    let cand = ['name','title', 'channel', 'url'].find(attr=>fullInfo[attr])
                    // sections = ['automatic section','automatic multiple sections'].concat(fullInfo.categories ? fullInfo.categories : [])
                    sections = ['automatic section'].concat(fullInfo.categories ? fullInfo.categories : [])
                    postTypes = ['automatic type'].concat(fullInfo.post_types ? fullInfo.post_types : [])
                    authors = [{name:'automatic author', id:'automatic'}].concat(fullInfo.authors ? fullInfo.authors : [])
                    // console.log(fullInfo.authors)
                    tags = ['automatic tag', 'no tags', 'create tags'].concat(fullInfo.tags ? fullInfo.tags : [])
                    title = fullInfo[cand] ? fullInfo[cand] : title
                  }

                  
                  let iconComp = ['twitter'].includes(link.type) ? <img style={{width: 35, marginRight: 15, height: 'fit-content'}} src="/static/img/integrations/xlogo.png"/> : <span style={{marginRight: 15, fontSize: '35px', width: 35, textAlign: 'center'}} className={"fa fa-"+ (icons[link.type] ? icons[link.type] : (link.channel ? 'slack' : (link.url && link.url.indexOf('@')>-1 ? "envelope-o" : (link.token ? "share" : "wordpress"))))} aria-hidden="true"/>
                  
                  return <div className="darkHighlight" style={{position:'relative', color:'#6a707a', padding:10, fontSize: 'initial', display:'flex', borderRadius:10, ...(style ? style : {})}}>
                          
                          {wp ? <a href={link.url} target="_blank">{iconComp}</a> : iconComp}

                          <div style={{display: 'flex', flexDirection: 'column'}}>
                            {fullInfo && fullInfo.logo ? <img style={{maxHeight: 19, maxWidth:'fit-content', margin: 'auto 15px auto 0px', filter: 'brightness(0)'}} src={status[link.url].logo}/> : null}
                            <div style={{paddingRight: 30,margin:'auto 0px'}}>
                              {title && title.length>50 ? title.slice(0,50).trim()+'...' : title}

                              {!simple && targets.findIndex(otherLink=>otherLink.url==link.url && (otherLink.schedule || null) === (link.schedule || null))!==i ? 
                                <p style={{
                                    width: '100%',
                                    fontSize: 'small',
                                    color:'red'
                                }}>You already have another post going out to this page at the same time, this one will be ignored</p> : null}

                                {!simple && user && !user.admin && !user.websites.find(ot=>ot.url===link.url) && ['facebook','twitter','linkedin'].includes(link.type) ? 
                                <p style={{
                                    width: '100%',
                                    fontSize: 'small',
                                    color:'red'
                                }}>This integration is missing from your account, press <a href={integrationConnections[link.type]} className={"normalLink"} target="_self">here</a> to reconnect.</p> : null}

                            </div>
                            {link.type==='api' ? <p className="secondary">{link.url}</p> : null}
                            <div style={{display:'flex', flexWrap: 'wrap'}}>
                            {['wp', 'linkedin', 'youtube'].includes(link.type) && !link.target && !simple ? 
                            <Flip tooltip={link.publish ? "Automatically publish" : "Send as a draft"} 
                                        style={{margin:'0px 15px 0px 0px', border:'none'}} 
                                        dropOffset={10}
                                        light={true}  
                                        dropdown={true} 
                                        value={link.publish ? 0 : 1} 
                                        options={['Publish', 'Draft']} 
                                        onChange={(j, option)=>{
                                          link.publish = j===1 ? false : true
                                          targets[i] = link
                                          this.update(targets)
                                        }}/> : null}
                            {wp && !simple ? <Flip tooltip={link.section ? "Send this article to this section" : "Automatically guess section"} 
                                        style={{margin:0, marginRight:15, border:'none'}} 
                                        dropOffset={10}
                                        light={true}
                                        dropdown={true} 
                                        value={link.section ? sections.indexOf(link.section) : 0} 
                                        options={sections} 
                                        onChange={(j, option)=>{
                                          link.section = sections && j>0 ? sections[j] : null
                                          targets[i] = link
                                          this.update(targets)
                                        }}/> : null}

                            {wp && !simple && false ? <Flip tooltip={link.section ? "Send this article to this section" : "Automatically guess section"} 
                                        style={{margin:0, marginRight:15, border:'none'}} 
                                        dropOffset={10}
                                        light={true}
                                        dropdown={true} 
                                        value={link.sections ? link.sections.map(s=>sections.indexOf(s)) : [0]} 
                                        options={sections} 
                                        multipleOptions={true}
                                        onChange={(newSections, option)=>{
                                         

                                          link.sections = sections && newSections.length>0 ? newSections.map(s=>sections[s]) : null

                                          if(link.sections && link.sections.includes(option) && 
                                            (option.includes('automatic section') || option.includes('automatic multiple sections'))){
                                            link.sections = [sections.indexOf(option)]
                                          }

                                          targets[i] = link
                                          this.update(targets)
                                        }}/> : null}


                            {wp && !simple ? <Flip tooltip={link.postType ? "Deliver the article as this post type" : "Automatically guess post type"} 
                                        style={{margin:0, marginRight:15, border:'none'}} 
                                        dropOffset={10}
                                        light={true}
                                        dropdown={true} 
                                        value={link.postType ? postTypes.indexOf(link.postType) : 0} 
                                        options={postTypes} 
                                        onChange={(j, option)=>{
                                          link.postType = postTypes && j>0 ? postTypes[j] : null
                                          targets[i] = link
                                          this.update(targets)
                                        }}/> : null}
                            {wp && !simple ? <Flip tooltip={link.tags ? (link.tags.includes('create tags') ? "Create new tags on your website or use existing tags when applicable, and apply them to the article" : "Deliver the article with this tag") : "Automatically pick a relevant tag"} 
                                        style={{margin:0, marginRight:15, border:'none'}} 
                                        dropOffset={10}
                                        light={true}
                                        dropdown={true} 
                                        value={link.tags ? tags.indexOf(link.tags[0]) : 0} 
                                        options={tags} 
                                        onChange={(j, option)=>{
                                          link.tags = tags && j>0 ? [tags[j]] : null
                                          targets[i] = link
                                          this.update(targets)
                                        }}/> : null}
                           {['linkedin'].includes(link.type) && !simple ? <Flip tooltip={link.textLength ? {full:"Post with full article text", short:"Post with a short description only"}[link.textLength.split(' ')[0]] : "Post with a short teaser text"} 
                            style={{margin:0, marginRight:15, border:'none'}} 
                            dropOffset={10}
                            light={true}
                            dropdown={true} 
                            value={link.textLength ? textLength.indexOf(link.textLength) : 0} 
                            options={textLength} 
                            onChange={(j, option)=>{
                              link.textLength = j>0 ? textLength[j] : null
                              targets[i] = link
                              this.update(targets)
                            }}/> : null}

                            {
                              ['instagram','linkedin', 'facebook', 'twitter'].includes(link.type) && !simple ? <Flip tooltip={link.formatting ? "No bold or cursive formatting in the post" : "Format the post with bold and cursive highlights"} 
                              style={{margin:0, marginRight:15, border:'none'}} 
                              dropOffset={10}
                              light={true}
                              dropdown={true} 
                              value={link.formatting ? formatting.indexOf(link.formatting) : 0} 
                              options={formatting} 
                              onChange={(j, option)=>{
                                link.formatting = j>0 ? formatting[j] : null
                                targets[i] = link
                                this.update(targets)
                              }}/> : null
                            }

                            {
                              ['instagram','linkedin', 'facebook', 'twitter'].includes(link.type) && !simple ? <Flip tooltip={link.hashtags ? "No hashtags in the post" : "Include relevant hashtags in the post"} 
                              style={{margin:0, marginRight:15, border:'none'}} 
                              dropOffset={10}
                              light={true}
                              dropdown={true} 
                              value={link.hashtags ? hashtags.indexOf(link.hashtags) : 0} 
                              options={hashtags} 
                              onChange={(j, option)=>{
                                link.hashtags = j>0 ? hashtags[j] : null
                                targets[i] = link
                                this.update(targets)
                              }}/> : null
                            }

                            {
                              ['facebook', 'twitter', 'linkedin'].includes(link.type) && !simple ? <Flip tooltip={link.media === null ? "The platform's own link thumbnail preview" : (link.media==='image highlight' ? 'Include an image in the post, that highlights the story' : 'Include several images as slides that highlight the story')} 
                              style={{margin:0, marginRight:15, border:'none'}} 
                              dropOffset={10}
                              light={true}
                              dropdown={true} 
                              value={link.media ? media.indexOf(link.media) : 0} 
                              options={media} 
                              onChange={(j, option)=>{
                                link.media = j>0 ? media[j] : null
                                targets[i] = link
                                this.update(targets)
                              }}/> : null
                            }
                            {
                              (['image highlight'].includes(link.media) || link.type==='instagram') && ['facebook', 'twitter', 'linkedin', 'instagram'].includes(link.type) && !simple ? <Flip tooltip={!link.aspectRatio ? "Square aspect ratio" : link.aspectRatio+' apect ratio'} 
                              style={{margin:0, marginRight:15, border:'none'}} 
                              dropOffset={10}
                              light={true}
                              dropdown={true} 
                              value={link.aspectRatio ? aspectRatio.indexOf(link.aspectRatio) : 0} 
                              options={aspectRatio} 
                              onChange={(j, option)=>{
                                link.aspectRatio = j>0 ? aspectRatio[j] : null
                                targets[i] = link
                                this.update(targets)
                              }}/> : null
                            }
                            {
                              ['facebook'].includes(link.type) && !simple ? <Flip tooltip={!link.schedule ? "Once the story is published" : 'Schedule the publication of the post'} 
                              style={{margin:0, marginRight:15, border:'none'}} 
                              dropOffset={10}
                              light={true}
                              dropdown={true} 
                              value={link.schedule ? schedule.indexOf(link.schedule) : 0} 
                              options={schedule} 
                              onChange={(j, option)=>{
                                link.schedule = j>0 ? schedule[j] : null
                                targets[i] = link
                                this.update(targets)
                              }}/> : null
                            }

                            {['linkedin','facebook'].includes(link.type) && !simple ? <Flip tooltip={link.target ? "Send to this organization timeline" : "Send to this profile timeline"} 
                            style={{margin:0, marginRight:15, border:'none'}} 
                            dropOffset={10}
                            dropdown={true}
                            light={true}
                            value={link.target ? organizations.findIndex(org=>org.id===link.target) : 0} 
                            options={organizations ? organizations.map(org=>org.title)  : []} 
                            onChange={(j, option)=>{
                              link.target = organizations[j].id ? organizations[j].id : null 
                              targets[i] = link
                              this.update(targets)
                            }}/> : null}

                            {
                              ['facebook', 'twitter', 'linkedin'].includes(link.type) && !simple ? <Flip tooltip={!link.params ? "Include UTM parameters for all links" : 'Keep URL\'s unchanged'} 
                              style={{margin:0, marginRight:15, border:'none'}} 
                              dropOffset={10}
                              light={true}
                              dropdown={true} 
                              value={link.params ? params.indexOf(link.params) : 0} 
                              options={params} 
                              onChange={(j, option)=>{
                                link.params = j>0 ? params[j] : null
                                targets[i] = link
                                this.update(targets)
                              }}/> : null
                            }

                            {wp && !simple ? <Flip tooltip={link.photoQuality ? {high:"High image quality with larger file size (~80Kb)", medium:"Medium file size with average quality"}[link.photoQuality.split(' ')[0]] : "Small file sizes (~15Kb) with very low quality"} 
                                          style={{margin:0, marginRight:15, border:'none'}} 
                                          dropOffset={10}
                                          dropdown={true} 
                                          light={true}
                                          value={link.photoQuality ? photoQuality.indexOf(link.photoQuality) : 0} 
                                          options={photoQuality} 
                                          onChange={(j, option)=>{
                                            link.photoQuality = j>0 ? photoQuality[j] : null
                                            targets[i] = link
                                            this.update(targets)
                                          }}/> : null}
                            {wp && !simple ? <Flip tooltip={link.featuredImage ? "Embed the featured image inside the article at the top (can result in double images if your theme already handles the image)" : "Rely on your theme to handle the display of the featured image (most common)"} 
                                          style={{margin:0, marginRight:15, border:'none'}} 
                                          dropOffset={10}
                                          dropdown={true} 
                                          light={true}
                                          value={link.featuredImage ? vf.indexOf(link.featuredImage) : 0} 
                                          options={vf} 
                                          onChange={(j, option)=>{
                                            link.featuredImage = j>0 ? vf[j] : null
                                            targets[i] = link
                                            this.update(targets)
                                          }}/> : null}
                           
                            {wp && !simple ? <Flip tooltip={link.embedMethod ? {html:"Embed media as html", shortcode:"Embed media using wordpress shortcodes [twitter=\"...\"]"}[link.embedMethod.split(' ')[0]] : "Embed just the url for a video, tweet etc"} 
                                          style={{margin:0, marginRight:15, border:'none'}} 
                                          dropOffset={10}
                                          dropdown={true} 
                                          light={true}
                                          value={link.embedMethod ? embedMethods.indexOf(link.embedMethod) : 0} 
                                          options={embedMethods} 
                                          onChange={(j, option)=>{
                                            link.embedMethod = j>0 ? embedMethods[j] : null
                                            targets[i] = link
                                            this.update(targets)
                                          }}/> : null}
                            {feed && ['sjpgpo','slagg','v7idus','xv59c'].includes(feed.id) && wp && !simple ? <Flip tooltip={link.language ? "Send out another version in "+languages.find(l=>l.code===link.language).title : "No Second Language"} 
                                              style={{margin:0, marginRight:15, border:'none'}} 
                                              dropOffset={10}
                                              dropdown={true} 
                                              light={true}
                                              value={link.language ? languages.findIndex(l=>l.code===link.language) : 0} 
                                              options={languages.map(l=>l.title)} 
                                              onChange={(j, option)=>{
                                                link.language = j>0 ? languages[j].code : null
                                                targets[i] = link
                                                this.update(targets)
                                              }}/> : null}
                            {wp && !simple ? <Flip tooltip={link.author ? "Assign this account as author to every article" : "Automatically select an appropriate author for each article"} 
                                        style={{margin:0, marginRight:15, border:'none'}} 
                                        dropOffset={10}
                                        light={true}
                                        logoStyle={{width:50, height:50, borderRadius:'50%', overflow:'hidden'}}
                                        logoStyleSelected={{width:25, height:25}}
                                        dropdown={true} 
                                        value={link.author ? authors.findIndex(au=>link.author.id===au.id) : 0} 
                                        options={authors}
                                        onChange={(j, option)=>{
                                          link.author = authors && j>0 ? {id:authors[j].id, name:authors[j].name} : null
                                          targets[i] = link
                                          this.update(targets)
                                        }}/> : null}
                            </div>
                            {wp ? <div style={{fontSize: 'small', margin: 0}}>
                                    {status[link.url] && status[link.url].loading ? <p><span style={{color:'#d68000'}}>Loading, please wait...</span></p> : (
                                      status[link.url] && !status[link.url].failed ? 
                                      <p><span style={{color:'#03a84e'}}>Connected</span>. Plugin version {status[link.url].version ? this.state.status[link.url].version : 'unknown'}</p> : 
                                      <p><span style={{color:'red'}}>Not Synced</span>. Download the <a href="/static/plugins/wp/monoksync.zip" download className={"normalLink"}>wordpress plugin</a> and install it <a href={link.url+'/wp-admin/plugin-install.php'} target="_blank">here</a></p>
                                      ) }
                              </div> : null}
                              {['facebook','linkedin','twitter'].includes(link.type) && ['440'].includes(link.status) ? <p><span style={{fontSize: 'small', color:'red'}}>Session disconnected by {link.type}, try to <a className={"normalLink"} href={integrationConnections[link.type]}>reconnect</a></span></p> : null}
                            {link.instruction ? <p>{link.instruction.slice(0,30) + (link.instruction.length>30 ? '...' : '')}</p> : null}
                          </div>

                          

                          <span onClick={(e)=>{
                            targets.splice(i, 1)
                            this.update(targets)
                          }}
                          className="fa fa-times submenu deleteButton" aria-hidden="true" 
                          style={{padding: '5px 7px', 
                                  borderRadius: '50%', 
                                  marginLeft: 'auto', 
                                  cursor: 'pointer', 
                                  width: 30, 
                                  height: 30, 
                                  background: '#59bef1', 
                                  color: 'white', 
                                  fontSize: '15pt'}}>
                          </span>
                        </div>
                }) : null}
                {targets.length > show ? <div style={{width:'100%', textAlign:'center', padding:15, cursor:'pointer'}}><a className="normalLink" onClick={e=>{
                  e.stopPropagation()
                  this.setState({show:show+10}, ()=>{
                    this.refreshIntegrations()
                  })

                }}>Show 10 more integrations</a></div>: null}
               </div>
    }

               			
}
	              

export default Integrations;