var React = require('react');
var ReactGA = require('react-ga')
var config = require('../../../settings/config.json');

var TimeAgo = require('react-timeago').default; //import TimeAgo from 'react-timeago'

import {Glyphicon,Tooltip,OverlayTrigger} from 'react-bootstrap';
import { connect } from 'react-redux';
import store from '../../store';
import {savePuff, setFocusedPuff} from '../../actions/puff';
import {setPuff} from '../../actions/feed';
import {browserHistory} from 'react-router';
import {get} from '../../apiFunctions';
import {getLocation, daysOld, hoursOld, maskAuthor} from '../../util';
import Widget from './widget.jsx'
import Summary from '../modal/summary.jsx'
// import Mini from '../modal/mini.jsx'
import { Textfit } from 'react-textfit';

import {
  ShareButtons
} from 'react-share';

import T from 'i18n-react';
import ReactDOM from 'react-dom';

const s = {
  puff:{
    cursor: 'pointer',
    margin: '5px 10px',
    padding: 15,
    borderRadius: 10,
    position:'relative' ,
    fontSize:'14pt'
  },
  visualFocus:{
    borderRadius:10,
    overflow:'hidden'
  },
  title:{
    // fontSize: 22,
    marginBottom: 0,
    marginTop:10,
    fontWeight: 600,
    fontFamily: "'Cabin', sans-serif",
    color:'#444444'
  },
  subtext:{
    paddingTop: 10,
  },
  timestamp:{
    fontSize: '14px',
    color: 'rgb(0 0 0 / 34%)',
    float:'left'
  },
  button:{
    marginRight: '5px',
    padding: '10px',
    borderRadius: '20px'
  },
  badge:{
      label:{
        color:'white',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '8pt',
        borderRadius:7,
        padding:'5px 10px',
        fontFamily: 'ubuntu, system-ui'
      },
      badge:{
        display:'inline-block',
        width:'100%',
        marginBottom:5
      },
      icon:{
        marginRight:5,
        fontSize:'larger'
      }
    }
}

const p = console.log
const thumbComments = true
let hovering = null

class Puff extends React.Component {

  constructor(props){
    super(props);
    this.stopPropagation = this.stopPropagation.bind(this);
    this.preventDefault = this.preventDefault.bind(this);
    this.deepCompare = this.deepCompare.bind(this);
    // this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
    // this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
    // this.scrollToPuff = this.scrollToPuff.bind(this);

    this.state = {selected: false}
    this.closedHeight = 0;
    this.openedHeight = 0;
    // this.delaySmoothScroll = false;
    this.state = {article:null}

  }

  deepCompare(arg1, arg2){
    let self = this
    if (Object.prototype.toString.call(arg1) === Object.prototype.toString.call(arg2)){
      if (Object.prototype.toString.call(arg1) === '[object Object]' || Object.prototype.toString.call(arg1) === '[object Array]' ){
        if (Object.keys(arg1).length !== Object.keys(arg2).length ){
          return false;
        }
        return (Object.keys(arg1).every(function(key){
          return self.deepCompare(arg1[key],arg2[key]);
        }));
      }
      return (arg1===arg2);
    }
    return false;
  }

  componentDidUpdate(prevProp, props){
    if(prevProp.selectedPuff && !this.props.selectedPuff.id && prevProp.selectedPuff.id===this.props.id){
      // console.log(prevProp.selectedPuff.id)
      // console.log(this.props.id)
      // console.log('I WAS CLOSED')
      // console.log(prevProp.selectedPuff.puff)
        //   get('puff/'+id.split('/')[1],(err, res)=>{
        //   store.dispatch(setFocusedPuff(id, res.body, {writeAccess:this.props.writeAccess}));
        //   this.setState({article:res.body})
        // })
        // console.log(prevProp.selectedPuff.puff.feed)
        // console.log(prevProp.selectedPuff.puff)
        let currentPuff = this.state.article ? this.state.article : this.props.article
        let hash = (puff)=>['visualFocus','article','title','status', 'purchased'].map(field=>puff[field])
          // console.log('DIFF')
          // console.log(hash(prevProp.selectedPuff.puff))
          // console.log(hash(currentPuff))

        if(!this.deepCompare(hash(prevProp.selectedPuff.puff), hash(currentPuff))){
          // console.log('OMG CHANGED #########')
          // if(hash(prevProp.selectedPuff.puff)!==hash(this.props.article)){
            // console.log(prevProp.selectedPuff.puff.status)
          this.setState({article:prevProp.selectedPuff.puff})
          this.props.setPuff({id:prevProp.selectedPuff.puff.feed}, prevProp.selectedPuff.puff)
        }
    }
  }

   // onMouseEnterHandler(){
   //  hovering = setTimeout(()=>{
   //      this.setState({
   //          hover: true
   //      });
   //   }, 500);
   //  }
   //  onMouseLeaveHandler() {
   //    if(this.state.hover){
   //      this.setState({
   //          hover: false
   //      });    
   //    }
   //    clearTimeout(hovering)
   //  }


  timeAge(date){
    return Math.abs(new Date().getTime() - new Date(date).getTime());
  }

  getPuffUrl(article){
    // console.log(article)
    var url = config.server[window.env].url + "puff/"+(article.id.includes('/') ? article.id.split("/")[1] : article.id)
    return url
  }



  getMetaLine(article){
 
    if(article.sources.length===1 && !article.place){
      return <span style={{fontSize: 14 ,cursor: 'pointer', float:'left'}} className={'mouseOverText'}>{article.sources[0].source}&nbsp;-&nbsp;</span>
    }
    if(article.author && this.props.user && (!this.props.user.writer || this.props.user.team==='review')){

      article.author = maskAuthor(this.props.user, article.author)
      let authorName =  article.author.name.indexOf(' ') ? article.author.name.split(' ')[0] : article.author.name
      return <span className='mouseOverText' style={{fontSize: 14, float:'left'}}><img src={article.author.avatar} style={{margin:"-2px 5px 0px 0px", width:20, boxShadow:'0px 0px 1px #0000009e', borderRadius:'50%'}}/>
              {authorName}&nbsp;-&nbsp;</span>
    }
    if(!article.locked){
      if(article.place){
        let loc = user.writer ? null : this.getLocation(article)
        return loc ? this.wrapTT((article.places && article.places.length>1 ? '... '+article.places.slice(1).map(p=>p.name).join(', ') : 'Location'), loc) : null
      }else if(article.widgets && article.widgets[0] && article.widgets[0].data && article.widgets[0].data[0] && article.widgets[0].data[0].text  && !article.widgets[0].avatar){
        var widgetName = article.widgets[0].data[0].text.replace(/\([^\()]+\)/,'')

        return widgetName.length> 20 ? null :  <span style={{fontSize: 14 ,cursor: 'pointer', float:'left'}} className={'mouseOverText'}>{widgetName}&nbsp;-&nbsp;</span>
      }else{
        return article.publication && article.publication.name ?  <span style={{fontSize: 14 ,cursor: 'pointer', float:'left'}} className={'mouseOverText'}>{article.publication.name}&nbsp;-&nbsp;</span> : null
      }
      
    }else{
      return <span style={{fontSize: 14 ,cursor: 'pointer', float:'left'}} className={'mouseOverText'}><span className="fa fa-shopping-cart" aria-hidden="true" />&nbsp;-&nbsp;</span>
    }

  }

  getLocation(article){
    let text = getLocation(article)
    return text ? (<span  style={{fontSize: 14 ,cursor: 'pointer', float:'left'}} className={'mouseOverText'}><Glyphicon glyph="map-marker" /> {text}&nbsp;-&nbsp;</span>) : null
  }
 

  isBuilding(article){
    return article.status && ['building', 'buildfailed'].includes(article.status.type) //(article.status && ['assigning', 'assigned'].includes(article.status.type)) || !article.cluster && !article.language && !article.sort
  }

  toggleFocus(id, event){
    var article = this.state.article ? this.state.article : this.props.article
    event.stopPropagation()

    // console.log(article.id)
    // console.log(id)

    if(article.status && article.status.type==='submitted' && this.props.page==='orders'){
      return
    }
    let user = this.props.user
    if((user && user.admin) || !this.isBuilding(article) || (article.status &&  ['assigning', 'assigned'].includes(article.status.type))){ 
      article.tabId = this.props.tabId
      // if(article.cluster){
      //   store.dispatch(setFocusedPuff(id, article, {writeAccess:this.props.writeAccess}));
      // }else{
        store.dispatch(setFocusedPuff(id, article, {writeAccess:this.props.writeAccess}));
        if((article.cluster && !article.widgets) || !article.cluster){
          // console.log('puff/'+(article.id.includes('/') ? article.id.split('/')[1] : article.id)+(article.cluster ? '?cluster=true' : ''))
          get('puff/'+(article.id.includes('/') ? article.id.split('/')[1] : article.id)+(article.cluster ? '?cluster=true' : ''),(err, res)=>{
            // console.log(res)
            if(this.props.selectedPuff && this.props.selectedPuff.id===id){
              store.dispatch(setFocusedPuff(id, res.body, {writeAccess:this.props.writeAccess}));
            }
            // this.setState({article:res.body})
          })     
        }
      // }
      // console.log(article)
      browserHistory.push({pathname: '/puff/'+(article.id.includes('/') ? article.id.split('/')[1] : article.id), query:{quantum:article.cluster, feed:article.cluster ? article.tabId : undefined}})
    }
  
  }

  stopPropagation(e){
    e.stopPropagation();
  }

  preventDefault(e){
    e.preventDefault();
  }


    myFeed(puffId){

      if(puffId && puffId.length>1){
        let feed = puffId.split('-')[0]
        return feed === this.props.id.split('-')[0]
      }else{
        return false
      }
    }

  wrapTT(tt, child,keyId){
    const ttDom = (<Tooltip id="tooltip"><strong>{tt}</strong></Tooltip>);
    if(this.props.mobile){
      return child
    }else{
      return (<OverlayTrigger key={keyId} placement="bottom" overlay={ttDom}>
              {child}
              </OverlayTrigger>)
    }
  }

  keywords(curationTags){
    return curationTags.map(tag=><p className="badge" style={{fontSize: 'large', background: '#FF9800',marginRight:5, borderRadius:7}}>{tag}</p>)

  }

  badge(article){
    let {feedId} = this.props
    let failed = article.status && (article.status.type==='buildfailed' || (article.status.type==='building' && hoursOld(article.created)>2))


    let orders = [{t:'300 words',c:'#03A9F4'}, {t:'600 words',c:'#3F51B5'}, {t:'in-depth',c:'black'},{t:'800 words',c:'blueviolet'},{t:'1000 words',c:'cadetblue'}]
    let statusColor = {
      assigned:{
        backgroundColor:'white',
        color:'#545454',
        boxShadow:'rgb(0 0 0 / 0.1) 0px 8px 16px -2px'
      },
      assigning:{
        boxShadow:'0px 8px 16px -2px #00bcd430'
      },
      submitted:{
        backgroundColor:'#8BC34A',
        boxShadow:'rgb(139 195 74 / 16%) 0px 8px 16px -2px'
      },
      buildfailed:{
        backgroundColor:'rgb(255 7 7)',
      },
      ordercanceled:{
        backgroundColor:'rgb(255 7 7)',  
      },
      orderRejected:{
        backgroundColor:'rgb(255 7 7)',  
      }
    }
    let color = failed ? 'rgb(255 7 7)' : '#FFC107'
    let icon = <i className={failed ? "fa fa-exclamation-circle " : "fa fa-clock-o"} aria-hidden="true" style={s.badge.icon}></i>
    let message = (failed ? "Build Failed" : "Building Article")
    let statusMsg = ''

    if(article.status && article.status.type && !['translate','rated'].includes(article.status.type)){
          statusMsg = article.status.type 
          if(statusMsg === 'buildingVideo'){
            message = "Generating Video..."
            color = '#9C27B0'
            icon = <i className="fa fa-video-camera" aria-hidden="true" style={s.badge.icon}></i>
          }else if(statusMsg === 'lessLikeThis'){
            message = "Less Like This"
            color = 'rgb(255 7 7)'
            icon = <i className="fa fa-thumbs-o-down" aria-hidden="true" style={s.badge.icon}></i>
          }else if(statusMsg === 'assigned'){

            article.status.user = maskAuthor(this.props.user, article.status.user)
            let authorName =  (article.status.user && article.status.user.name.indexOf(' ') ? article.status.user.name.split(' ')[0] : (article.status.user ? article.status.user.name : 'Someone'))
            let avatar = article.status.user.avatar

            let assignedToYou = (article.status.user && this.props.user && article.status.user.avatar === this.props.user.avatar)
            message = "Assigned to " + (assignedToYou ? 'you' : authorName)
            icon = <img src={avatar} className="puff-avatar"/>
          }else if(statusMsg === 'buildfailed'){
            failed = true
            color = 'rgb(255 7 7)'
            icon = <i className="fa fa-exclamation-circle" aria-hidden="true" style={s.badge.icon}></i>
          }else if(statusMsg === 'assigning' && feedId !== 'search'){
            color = '#00BCD4'
            message = "Waiting for editor"
          }else if(statusMsg === 'ordercanceled'){
            message = "Order Canceled"
          }else if(article.published && article.published[0] && daysOld(article.published[0].date)<2){
            message = "Published"
            icon = null
          }else if(statusMsg === 'submitted'){
            if(!article.status.date || (article.status.date && daysOld(article.status.date)>1)){
              return null
            }
            message = "Order Complete"
            icon=<i className="fa fa-check" aria-hidden="true" style={s.badge.icon}></i>
          }else if(statusMsg === 'buildfailed'){
            //not yet implemented
            message = "Build Failed"
          }else if(statusMsg === 'assigning' && feedId === 'search'){
            if(article.status.rating===0){
              message = 'Article Rejected'
              color = 'rgb(255 7 7)'
            }else{
              message = orders[article.status.order].t
              color = orders[article.status.order].c
            }
            icon = null
          }else if(statusMsg === 'manualbuild'){
            if(article.purchased || !article.updated || (article.updated && daysOld(article.updated)>1)){
              return null
            }
            message = 'Build Finished'
            color = 'rgb(157 187 201)'
            icon = <i className="fa fa-exclamation-circle" aria-hidden="true" style={s.badge.icon}></i>
          }else if(statusMsg === 'curated'){
            message = 'Curated'// + article.relevance
          }else if(statusMsg === 'recommended'){
            message = 'recommended'// + article.relevance
            color='#4caf50'
            icon = null
          }
    }else{
      return null
    }


  

    return <div style={s.badge.badge}>
                        {failed ? [
                                  // <div onClick={(event)=>{
                                  //       event.stopPropagation()
                                  //       this.props.setPuff({id:article.feed}, {delete:true,id:article.id})
                                  //       this.props.savePuff(article.id.split('/')[1], {delete:true})
                                  //       this.setState({article:{delete:true}})
                                  // }}className="blueButton borderButton highlight" style={{float: 'right', marginTop: 0, marginRight: 0}}>
                                  //   Retry
                                  //   <span className="fa fa-repeat buttonConf ani"></span>
                                  // </div>,
                                  <div onClick={(event)=>{
                                        event.stopPropagation()
                                        this.props.setPuff({id:article.feed}, {delete:true,id:article.id})
                                        this.props.savePuff(article.id.split('/')[1], {delete:true})
                                        this.setState({article:{delete:true}})
                                  }}className="blueButton borderButton highlight" style={{float: 'right', marginTop: 0, marginRight: 0}}>
                                    Delete
                                    <span className="fa fa-trash buttonConf ani"></span>
                                  </div>] : null}
                <span style={{...s.badge.label, ...{backgroundColor:color},...(statusColor[statusMsg] ? statusColor[statusMsg] : {})}}>{icon}{message}</span>
                        {failed && article.status && article.status.comment ? 
                          <p style={{marginTop: 10, borderRadius: 10, marginBottom: 0, color: 'red'}}>{article.status.comment}</p> : null}
            </div>
  }

  openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
  }

  setResolution(url, size){
    return url.replace(/\d{2,3}px/ig,size+'px').replace('http://','https://')
  }

  // recommended(puff){
  //   const importance = {
  //     sources: 0.05,
  //     twitter: 0.2,
  //     videos: 0.5,
  //     instagram: 0.2,
  //     comments: 0.1,
  //     weight: 0.1,
  //   }

  //     console.log(puff.relevance)
  //   if(puff.relevance>0.6 && this.daysOld(puff.date)<2){
  //     let val = 0
  //     Object.keys(puff.summary ? puff.summary : {}).forEach(v=>{
  //       val += puff.summary[v]*importance[v]
  //     })
  //     puff.recommended = puff.relevance + val
  //     console.log("######## val rel rec")
  //     console.log(puff.relevance)
  //     console.log(val)
  //     console.log(puff.recommended)
  //     console.log("#####################")
  //     let ye = puff.recommended > 1.1
  //     if(ye){
  //     console.log('recommended: '+puff.title)
        
  //     }
      
  //     return ye
  //   }else{
  //     return false
  //   }
  // }

  getPuff(){

    let {mobile, isMagazine, page, user, smallFeatured} = this.props
    let self = this;
    var id = self.props.id
    var article = this.state.article ? this.state.article : self.props.article

    let thumbSize = 100

  //Styles if selected or not in masonry
  //If i'm not selected, but some puff is
  let activeStyle ='present puff-card'

  let video =  article.visualFocus && article.visualFocus.type === 'video'
  var validVideo = !(isMagazine && (this.props.small || (!this.props.small && mobile)))  && article.visualFocus && ((article.visualFocus.type === 'video' && article.visualFocus.sourceName && article.visualFocus.sourceName.toLowerCase()==='youtube') || (article.visualFocus.data && article.visualFocus.data[0] && article.visualFocus.data[0].lat && article.visualFocus.data[0].source!=='wikipedia'))



  let thumb = null// article.thumbnail ? {url:article.thumbnail} : null
  
  thumb  = thumb ? thumb : (article.visualFocus && article.visualFocus.data &&  article.visualFocus.data[0] &&  article.visualFocus.data[0].image ?  article.visualFocus.data[0].image : thumb)
  
  thumb =  thumb ? thumb : (article.visualFocus && !['video','twitter'].includes(article.visualFocus.type) && article.visualFocus.url ? article.visualFocus : thumb)

  thumb = thumb ? thumb : (article.image ? {url:article.image} : null)

  if(!thumb && video){
    let candidate = article.sources.find(source=>source.image && source.image.thumbnail)
      let image = candidate && candidate.image && candidate.image.thumbnail ? candidate.image : (article.thumbnail ? {thumbnail:article.thumbnail} : null)
      if(image){
        let original = image.thumbnail.indexOf('http')===0 
        let filename = image.thumbnail.indexOf('media')===0 ? image.thumbnail.split('media/')[1] : image.thumbnail
        thumb = {url:!original ? ((config.server[window.env].S3).replace('{bucketname}',('monokthumb-'+filename[0])) + filename.substring(0,2) + '/' + filename) : filename}
      }else{
        thumb = null
      }
    // thumb = candidate ? {url:config.server[window.env].S3 + candidate.image.thumbnail} : null
  }

  let jolie = article.id==='en/jolie-seeks-to-oust-private-judge-in-pitt-divorce-case' ? 
  'url("https://monokthumb-0.s3.nl-ams.scw.cloud/02/02b8494f44f8a5fd47bc570a9562004878f313baf06898780800d79572dca056-animatedIcon.gif") 0% 0% / cover, linear-gradient(0deg, rgb(229, 226, 218) 0%, rgb(185, 234, 240) 100%)' : null

  var  thumbNail = !validVideo && thumb ?
  <div style={{
    // background:'url('+this.setResolution(article.thumbnail,150)+')',
    background:jolie ? jolie : 'url("'+this.setResolution(thumb.url,150)+'")'+(article.visualFocus && article.visualFocus.thumbnail && article.visualFocus.thumbnail!==thumb.url ? ', url("'+article.visualFocus.thumbnail+'")' : ''),
    // background:'url('+this.setResolution(thumb.url,150)+')',
    float:"right",
    // marginLeft:10,
    // marginBottom: 10,
    backgroundColor:jolie ? undefined : '#eeeeee',
    borderRadius:10,
    width:thumbSize,
    height:thumbSize,
    borderBottomRightRadius: 10,
    boxShadow: 'rgb(68 68 68 / 23%) 0px 0px 3px inset',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column'
  }} >{video ? <span style={{color:'white', fontSize:'x-large', margin: 'auto',textShadow:'#00000040 0px 0px 10px'}} className="fa fa-play-circle"/> : null}</div>:null;

  var details = <Summary article={article}/>

  let featured = this.props.featured && article.thumbnail && thumbNail && (!validVideo || (isMagazine && mobile))
  
  // let titleText =  article.title.length>90 ? article.title.substr(0,90)+'...' : article.title
  let titleText =  article.title.length>90 ? article.title.split(' ').slice(0,14).reduce((a,b,i,arr)=>a+' '+ (i===arr.length-1 && b.length>3 ? b.substr(0,3) : b),'')+'...' : article.title
  let title =   true ? <Textfit mode="multi" className="puff-header" style={{
                height: '100px',
                // maxHeight:100,
                width:'100%',
                paddingLeft: featured && video ? '20%' : 0,
                // height:'40%',
                // width:'200px',
                // paddingRight: featured ? 20 : thumbSize+15, 
                textShadow: featured ? 'rgb(0, 0, 0) 0px 2px 8px' : null,
                position: featured && false ? 'absolute' : 'initial',
                bottom: featured ? 5 : 'initial',
                color: featured ? 'white' : '#444444',
                // fontSize: '26px', 
                fontWeight:  600, 
                // fontWeight: featured ? 600 : 'initial', 
                // lineHeight: 'normal',
                // wordBreak: 'break-word',
                fontFamily: "sans-serif"
                // fontFamily: "Lora"
                // font: "Lora"
              }}>
                {titleText}
              </Textfit>  : <p className="cardtitle" style={{
     color:featured ? 'white' : '#444444',
     textShadow: featured ? 'rgb(0, 0, 0) 0px 2px 8px' : null,
     fontSize: 'inherit',
     bottom: 0,
      position: featured ? 'absolute' : 'relative',
      margin: featured ? 25 : 0
  }}>{titleText}</p>

  // article.status = this.recommended(article) ? {type:'recommended'} : article.status
  let updated = (article.updated ? article.updated : (article.created ? article.created : article.date))
  // let recent = article.sources && article.sources.length>1 ? article.sources.sort((a,b)=>this.hoursOld(b.date)-this.hoursOld(a.date))[0].date : article.date


  var puffElement = (
    <div key={article.id} 
          style={{...s.puff, ...(this.props.style ? this.props.style : {}), minWidth:200}} 
          onClick={this.props.onClick ? this.props.onClick : (article.url ? this.openInNewTab.bind(this, article.url) : this.toggleFocus.bind(this, id))} 
          onMouseDown={(event)=>{event.stopPropagation()}} 
          // onMouseEnter={this.onMouseEnterHandler}
          // onMouseLeave={this.onMouseLeaveHandler}
          className={(article.locked ? 'purchase ' : '')+(featured ? '' : activeStyle)}>
          {
            // this.state.hover ? <Mini article={article} style={{bottom: '26%', zIndex: 1000, left: '12%', margin:0}} confirmed={true}/> : null
          }
      {(article.status || article.published) && user && user.email ? this.badge(article) : (page==='orders' ? this.badge({status:{type:'ordercanceled'}}) : null)}
      {article.curationTags ? this.keywords(article.curationTags) : null}
      {(user.writer || user.curator) && article.status && article.status.deadline && (article.status.type!=='submitted' || !(article.published && article.published.length>0)) ? <div style={s.badge.badge}>{this.wrapTT("This is a priority article, please take this first",<span style={{...s.badge.label, ...{backgroundColor:'red'}}}>PRIORITY</span>)}</div> : null}
      {page==='store' && !article.cluster ? <div style={s.badge.badge}><span style={{...s.badge.label, ...{backgroundColor:'#8BC34A'}}}>Free</span></div> : null}
      {false && page==='orders' && article.status && article.status.logo ? <img src={'https://www.monok.com/api/v1/logoProxy?url='+article.status.logo} style={{maxHeight:19, filter: 'brightness(0)', maxWidth:'50%', float:'right', margin:'-22px 0px 10px'}}/> : null}
      {validVideo ?
        <Widget article={{}} style={{borderRadius:10,maxHeight:190}} widget={article.visualFocus}/>
      :null}

      {featured ? <div className="thumbZoom card" style={{position:'relative', 
                                borderRadius:10, 
                                width:'100%',
                                // boxShadow:'rgba(68, 68, 68, 0.23) 0px 0px 3px inset',
                                // linear-gradient(183deg, rgb(255 255 255 / 0%) 15%, rgb(0 0 0 / 65%) 63%, rgb(0 0 0) 95%)
                                background:'linear-gradient(183deg, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.32) 67%, rgba(0, 0, 0, 0.83) 95%), url("'+this.setResolution(article.thumbnail,500)+'")  center 0% / cover'+
                                          (article.visualFocus && article.visualFocus.thumbnail && article.visualFocus.thumbnail!==article.thumbnail ? ', url("'+article.visualFocus.thumbnail+'")' : ''),
                                padding:10, 
                                paddingTop: '55%'
                                // padding: '55% 10px 10px;'
                              }}>
                              {video ? <i className={"fa fa-play"} aria-hidden="true" style={{
                                                                  color:'white',

                                                                  fontSize: '20pt',
                                                                  fontSize: 'clamp(20pt, 3vw, 30pt)',
                                                                  float:'left',
                                                                  padding:'20px 0px',
                                                                  width: '20%',
                                                                  textAlign: 'center'
                                                            }}></i> : null}
        <a onClick={self.preventDefault} href={article.url ? article.url : this.getPuffUrl(article)}>
           {title}         
        </a>
      </div> : null}

      {!featured ? (thumbNail ?
                <div  style={{
                        // height:thumbSize, 
                        width:'100%',
                        overflow:'hidden', 
                        display:'grid', 
                        gridTemplateColumns:'auto 100px', 
                        columnGap: 10
                      }} >      
                    <a onClick={self.preventDefault} href={article.url ? article.url : this.getPuffUrl(article)} style={{
                      // height:thumbSize
                    }}>
                     {title}
                    </a>
                    {thumbNail}
                </div>:
                <a onClick={self.preventDefault} href={article.url ? article.url : this.getPuffUrl(article)}>{title}</a>)
         : null}

        <div className="wrap clearfix" style={s.subtext}>
        
            <div style={{float:'right'}}>
                 {details}
            </div>
            {mobile && smallFeatured ? null : this.getMetaLine(article)}{(!user.curator || (mobile && smallFeatured)) ? null : this.wrapTT("Average date",<TimeAgo title="" style={s.timestamp} date={article.date} formatter={window.timeagoFormatterShort}/>)}
            {(window.env==='development' || user.admin) && article.status && article.status.rating ? <span>{['⭐','⭐','⭐','⭐','⭐'].slice(0,parseInt(article.status.rating)+1)}</span> : null}
            {!user.curator || (mobile && smallFeatured) || hoursOld(updated)>5 ? null : this.wrapTT("Last time updated",<span style={s.timestamp}><i className="fa fa-clock-o" style={{margin:'0px 0px 0px 10px'}}/> <TimeAgo  title="" date={updated} formatter={window.timeagoFormatterShort}/></span>)}
            {user.curator ? null : this.wrapTT("Last time updated",<span style={s.timestamp}><TimeAgo  title="" date={updated} formatter={window.timeagoFormatterShort}/></span>)}
        </div>
    </div>)

  return puffElement
          
  }

  render () {
    let article = this.state.article ? this.state.article : this.props.article
    return article && !article.delete ? this.getPuff() : null
  }
}


const mapStateToProps = function(store) {
  return {
    selectedPuff: store.puff.selectedPuff
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    savePuff:(id, puff)=>dispatch(savePuff(id, puff)),
    setPuff:(feed, puff)=>dispatch(setPuff(feed, puff)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Puff);